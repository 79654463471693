import React, { useEffect } from 'react'
import { useState,useRef } from 'react';
import useStateWithCallback from "use-state-with-callback";
import { IAGrid } from '../../Components/IInterfaces';
import { CommonUtil } from '../../utils/CommonUtil';
import { Constants } from '../Languages/Constants';
import InspectionRenderer from '../InspectionGrid/InspectionRenderer';
import InterchangeDocumentStatusRenderer from './InterchangeDocumentStatusRenderer';
import { NetworkManager } from '../../Components/NetworkManager';
import InspectionGrid from '../InspectionGrid/InspectionGrid';
import { useNavigate } from 'react-router';


const ExitInitiated = () => {

    const [users,setUsers] = useStateWithCallback([],()=>{
        if(userGridRef1.current){
            userGridRef1.current.columnFittoSize();
        }
    });
    const userGridRef1 = useRef<IAGrid>(null);
    
    const [userColumns] = useState([
        { field: 'documentCode', headerName: CommonUtil.getMessageText(Constants.DOCUMENT_ID, 'Document No'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '12px', 'fontFamily': 'poppins', 'color': 'black', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter', cellRenderer: InspectionRenderer },
        { field: 'companyName', headerName: CommonUtil.getMessageText(Constants.COMPANY_NAME, 'Company Name'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter' },
        { field: 'truckOwner', headerName: CommonUtil.getMessageText(Constants.TRUCK_OWNER, 'Truck Owner'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter' },
        { field: 'truckNumber', headerName: CommonUtil.getMessageText(Constants.TRUCK_ID, 'Truck Id'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter' },
        { field: 'inspectorName', headerName: CommonUtil.getMessageText(Constants.INSPECTOR_NAME, 'Inspector Name'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter' },
        { field: 'inspectionExitLocation', headerName:CommonUtil.getMessageText(Constants.INSPECTION_LOCATION,'Inspection Location'),resizable:true,flex:1,width:100,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','paddingTop':'5px' },headerClass: 'header-white',filter: 'agTextColumnFilter'},
        { field: 'vinNumber', headerName:CommonUtil.getMessageText(Constants.VIN_NO,'Vin Number'),resizable:true,flex:1,width:100,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','paddingTop':'5px' },headerClass: 'header-white', filter: 'agTextColumnFilter'},
        { field: 'unitNumber', headerName:CommonUtil.getMessageText(Constants.UNIT_NUMBER,'Unit Number'),resizable:true,flex:1,width:100,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','paddingTop':'5px' },headerClass: 'header-white', filter: 'agTextColumnFilter'},
        // { field: 'equipmentType', headerName:CommonUtil.getMessageText(Constants.EQUIP_TYPE,'Equipment Type'),resizable:true,flex:1,width:100,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','paddingTop':'5px' },headerClass: 'header-white'},
        // { field: 'status', headerName:CommonUtil.getMessageText(Constants.STATUS,'Status'),resizable:true,flex:1,width:100,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','paddingTop':'5px' },headerClass: 'header-white',cellRenderer: InterchangeDocumentStatusRenderer},
        // { field: 'expectedEntryTime', headerName:CommonUtil.getMessageText(Constants.ENTRY_DATE,'Expected Release Date'),resizable:true,flex:1,width:100,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','paddingTop':'5px' },headerClass: 'header-white',valueGetter:(params:any)=>CommonUtil.getDateInterchange([params.data.expectedReleaseDate])},
        // { field: 'expectedExitTime', headerName:CommonUtil.getMessageText(Constants.EXIT_DATE,'Expected Receipt Date'),resizable:true,flex:1,width:100,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','paddingTop':'5px' },headerClass: 'header-white',valueGetter:(params:any)=>CommonUtil.getDateInterchange([params.data.expectedReciptDate])},
        // { field: 'referenceNo', headerName:CommonUtil.getMessageText(Constants.REFERENCE_NUMBER,'Reference No'),resizable:true,flex:1,width:100,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','paddingTop':'5px' },headerClass: 'header-white'},
        // { field: 'createdBy', headerName:CommonUtil.getMessageText(Constants.CREATED_BY,'Created By'),resizable:true,flex:1,width:100,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','paddingTop':'5px' },headerClass: 'header-white'},
        { field: 'creationDate', headerName: CommonUtil.getMessageText(Constants.CREATED_DATE, 'Created Date'), flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter', valueGetter: (params: any) => CommonUtil.getDateInterchange([params.data.creationDate]) },
        // { field: 'creationTime', headerName:CommonUtil.getMessageText(Constants.CREATED_TIME,'Creation Time'),flex:1,width:100,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','paddingTop':'5px' },headerClass: 'header-white',filter: 'agSetColumnFilter'},
        { field: 'manifestNumber', headerName:CommonUtil.getMessageText(Constants.MANIFEST,'Manifest #'),resizable:true,flex:1,width:100,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','paddingTop':'5px' },headerClass: 'header-white',filter: 'agTextColumnFilter'},
      ]);


let navigate = useNavigate();

let onClickUserDetails = (userData:any) =>{
    console.log(userData)    
    localStorage.setItem("documentId", userData.documentId);    
    navigate("/menu/InterchangeApplication/interchangeDocumentDetails");
}

let getAllEntrySubmitted = () =>{

    var orgUrl = `api/InspectionDocuments/all?ownerOrgId=${CommonUtil.getSaasOrgId()}&status=EXIT_INITIATED`
    NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onGetAllEntrySubmitted,onLoginError);
}

useEffect(()=>{
    getAllEntrySubmitted();
  },[]);

let onGetAllEntrySubmitted = (dataObj:any)=>{
    console.log(dataObj);
    if(dataObj) {
        var restData = JSON.parse(dataObj);
        console.log(restData)
        if(restData){
            restData.map((org:any)=>{
                console.log(org.documentId)
            })
        }
        setUsers(restData)
        if(restData){
            var users = [];
            if(Array.isArray(restData)){
                users = restData;
            }else{
                users.push(restData);
                
            }
            let ascendingUsers:any = [...restData].sort((a,b)=>{
                return b.documentId - a.documentId
              }) 
              setUsers(ascendingUsers)    
            console.log(users)    
        }
  
  }
  

    
  
  }

let onLoginError = (err:any)=>{
    console.log(err);
}

  return (
    <div className="container-fluid container-fluid-padding mt-3" style={{backgroundColor:'white'}}>
                    <div className="row fs-7 pt-1">
                    <div className="ag-theme-alpine ag-theme-customeTheme gridHeight" style={{ height: "calc(100vh - 330px)", width: "100%",paddingLeft:"8px",paddingRight:"5px" }}>
                                <InspectionGrid cols={userColumns} rows={users} pageSize={100} addNew={true} tooltip={"Add Company"} name={"Add Company"}
                                        headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={userGridRef1} method={{onClickUserDetails}}/>
                    </div>
                    </div>
                </div>
  )
}

export default ExitInitiated