import React, {useEffect, useRef, useState} from 'react'
import SingleSelectComp from '../../Components/SingleSelectComp'
import AGGrid from '../../Components/AGGrid'
import OrgCellRenderer from '../Organization/OrgCellRenderer'
import orgUserStatucRenderer from '../Organization/OrgUserStatucRenderer'
import {IAGrid} from '../../Components/IInterfaces'
import useStateWithCallback from 'use-state-with-callback'
import {useNavigate} from 'react-router-dom'
import {CommonUtil} from '../../utils/CommonUtil'
import {NetworkManager} from '../../Components/NetworkManager'
import ToastManager from '../../Components/ToastManager'
import {StatusCodeUtil} from '../../utils/StatusCodeUtil'
import {CloseButton, Modal} from 'react-bootstrap'
import DraggableComponent from '../../Components/DraggableComponent'
import $ from 'jquery'
//import specific from '../../images/specific-equipment.png'
//import generic from '../../images/general-equipment.png'

import dummyData from './dummy.json'
import EquipCellRenderer from "../Organization/EquipCellRenderer";
import ReservationCellRenderer from "../Organization/ReservationCellRenderer";
import Flatpickr from "react-flatpickr";
import ReservationEquipCellRenderer from "../Organization/ReservationEquipCellRenderer";
import {subscribe} from "../Login/CustomEvents";
import EquipAvailTypeRenderer from "../Organization/EquipAvailTypeRenderer";
import {Events} from "../Languages/Events";
import DetectDevice from '../../utils/DetectDevice'
import MGrid from '../../Components/MGrid'

const OfferReport = () => {
    const { isMobile } = DetectDevice();
    const [orgData, setOrgData] = useState(null)
    const [equipData, setEquipData] = useStateWithCallback<any>([], () => {
        if(gridRef.current){
            gridRef.current.columnFittoSize();
        }
    })

    const [showEquipPopup, setShowEquipPopup] = useState(false);

    const fromRef = useRef<Flatpickr>(null);
    const toRef = useRef<Flatpickr>(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [dateOptions, setDateOptions] = useState(CommonUtil.DATE_FORMAT);

    const gridRef = useRef<IAGrid>(null)

    const navigate = useNavigate();
    const [isCompanyVisible,setCompanyVisible] = useState(CommonUtil.isRootUser());

    const [eqpColumnDefs] = useState([
        {field: 'reservationCode', headerName: 'Equipment Number / Name', resizable: true, width: 200, sortable: true, cellRenderer: EquipCellRenderer, filter: 'agTextColumnFilter'},
        {field: 'equipmentType', headerName: 'Equipment Type', resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'brand', headerName: 'Brand', resizable: true, width: 150,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'reservationType', headerName: 'Availability Type', resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'startDate', headerName: 'Availability From Date',resizable: true, width: 250,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'endDate', headerName: 'Availability  Date', resizable: true, width: 150,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'renterName', headerName: 'Offer Made to', resizable: true, width: 200,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'ownerName',headerName: 'Offer Received From',resizable: true,width: 150,sortable: true,filter: 'agNumberColumnFilter'},
        {field: 'details',headerName: 'Availability Details',resizable: true,width: 150,sortable: true,filter: 'agNumberColumnFilter',cellRenderer: EquipAvailTypeRenderer},
        {field: 'ratings',headerName: 'Equipment Ratings',resizable: true,width: 150,sortable: true,filter: 'agNumberColumnFilter'},
    ]);

    const [companies, setCompanies] = useState([])
    const [selCompanyObj, setSelCompanyObj] = useState<any>(null)

    const [assetTypes, setAssetTypes] = useState<any>() //CommonUtil.ASSET_TYPES
    const [selAssetObj, setSelAssetObj] = useState<any>(null)

    const [equipTypes, setEquipTypes] = useState<any>([]) //CommonUtil.TRAILER_EQP_TYPES
    const [selEquipObj, setSelEquipObj] = useState<any>(null)

    const [brands, setBrands] = useState<any>([]) //CommonUtil.TRAILER_BRAND
    const [selBrandObj, setSelBransObj] = useState<any>(null)

    const [modals, setModals] = useState<any>([]) //CommonUtil.EQUiP_MODELS
    const [selModalObj, setSetModalObj] = useState<any>(null)
    const [page,setPage] = useState("");

    //const [rowData,setRowData] = useState([]);

    useEffect(() => {
        subscribe(Events.SWITCH_CUSTOMER_EVENT,()=>{onSwitchCustomer()});
        getEquipmentList()
        if(CommonUtil.isRootUser()){
            CommonUtil.getAllOrgs((data: any) => {
                setOrgData(data)
            })
        }

        CommonUtil.getAllAssetTypes((data: any) => {
            CommonUtil.ASSET_TYPES = data;
            setAssetTypes(data)
        })
        CommonUtil.getAllModels((data: any) => {
            CommonUtil.EQUiP_MODELS = data;
            setModals(data)
        })

    }, []);

    let onSwitchCustomer = ()=>{
        //console.log("swich customer");
        getEquipmentList();
        setCompanyVisible(CommonUtil.isRootUser());
        setPage(""+Math.random()*10000);
    }



    let getEquipmentList = () => {
        let qParams: string = "";
        if(CommonUtil.isRootUser()){
            qParams= 'saasOrgId=' + (selCompanyObj ? selCompanyObj.orgId : '');
        }else{
            qParams= 'saasOrgId=' + CommonUtil.getSelectedOrgId();
        }

        setEquipData([]);
        let equipAllAPI: string = 'api/offers/all/';//?' + qParams;
        let obj: any = {};
        obj.saasOrgId = CommonUtil.getSelectedOrgId();
        obj.startDate = fromDate;
        obj.endDate = toDate;
        //obj.equipmentTypeId = selEquipObj?selEquipObj.key:null;
        NetworkManager.sendJsonRequest(equipAllAPI, obj, '', 'POST', true, onGetAlOffers, onLoginError);
    }

    let onGetAlOffers = (dataObj: any) => {
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                let equips = []
                if (Array.isArray(restData)) {
                    equips = restData
                } else {
                    equips.push(restData)
                }
                setEquipData(equips);
            }
        }
    }

    let onClickLink = (data: any) => {
        //console.log(data);
        CommonUtil.SELECT_RESERVATION_ID = data.reservationId;//"5";
        CommonUtil.SELECT_EQUIP_ID = data.equipmentId;
        CommonUtil.EQUIP_RESERVE_ASSET_TYPE = data.assetType;
        CommonUtil.EQUIP_RESERVE_EQUIPMENT_TYPE = data.equipmentType;
        CommonUtil.EQUIP_RESERVE_IS_GENERIC = data.isGeneric;

        navigate('viewreservation');
        //
        /*CommonUtil.SELECT_EQUIP_ID = data.equipmentId
        // console.log(data);
        navigate('viewreservation');*/
        //navigate('equippublic');
    }
    let onClickAddEquipment = () => {
        CommonUtil.SELECT_EQUIP_ID = ''
        navigate("viewreservation");
       // setShowEquipPopup(true)
        // navigate("equipDetails");
    }

    let onCompanyChange = (dataObj: any) => {
        setSelCompanyObj(dataObj)
    }

    let onAssetTypeChange = (dataObj: any) => {
        setSelAssetObj(dataObj)
        // getEquipmentTypes(dataObj);
        setSelEquipObj(null);
        setEquipTypes([]);
        setSelBransObj(null);
        setBrands([]);

        if (dataObj) {
            CommonUtil.getEquipmentTypes(dataObj.key, (data: any) => {
                setEquipTypes(data)
            })
            CommonUtil.getBrands(dataObj.key, (data: any) => {
                setBrands(data)
            })
        }
    }

    let onEquipmentTypeChange = (dataObj: any) => {
        setSelEquipObj(dataObj)
    }

    let onModalChange = (dataObj: any) => {
        setSetModalObj(dataObj)
    }

    let onBrandChange = (dataObj: any) => {
        setSelBransObj(dataObj)
    }

    let onCloseEquipPopup = () => {
        setShowEquipPopup(false)
    }

    let onClickGeneric = () => {
        CommonUtil.IS_GENERIC_EQUIP = true
        navigate('equipDetails')
        onCloseEquipPopup()
    }

    let onClickSpecific = () => {
        CommonUtil.IS_GENERIC_EQUIP = false
        navigate('equipDetails')
        onCloseEquipPopup()
    }
    let onChangeFromDate = (dateValue: any) => {
        let strDate: string = CommonUtil.getDate(dateValue)
        setFromDate(strDate);
        onChangeDates();
    }

    let onChangeToDate = (dateValue: any) => {
        let strDate: string = CommonUtil.getDate(dateValue)
        setToDate(strDate);
        onChangeDates();
    }

    let onChangeDates = ()=>{
        if(fromRef.current && toRef.current){
            var startDate = fromRef.current.flatpickr.selectedDates[0];
            var endDate = toRef.current.flatpickr.selectedDates[0];

            if (endDate) {
                endDate = new Date(endDate);
                fromRef.current.flatpickr.set("maxDate",endDate);
                // stDtOptions.maxDate = new Date(endDate);
            } else if (startDate) {
                toRef.current.flatpickr.set("minDate",new Date(startDate));
                //stDtOptions.minDate = new Date(startDate);
            }
        }
    }

    let deSelectButtons = ()=>{
        let buttons = ['btnAll','btnUpcoming','btnInProgress','btnDone','btnPending','btnPending','btnCancel','btnLateActivation','btnLateDeactivation'];
        buttons.forEach((item:any,idx:number)=>{
            $("#"+item).removeClass("btn-success");
            if( !$("#"+item).hasClass("btn-warning")){
                $("#"+item).addClass("btn-warning");
            }

        })
    }

    let onClickStatusButton = (btnId:string)=>{
        deSelectButtons();
        $("#"+btnId).removeClass("btn-warning")
        if(! $("#"+btnId).hasClass("btn-success")){
            $("#"+btnId).addClass("btn-success")
        }
    }


    let onLoginError = (err: any) => {
        console.log(err)
    }

    return (
        <>
            <Modal show={showEquipPopup} dialogAs={DraggableComponent} className={'modal-dialog-centered modal-lg'}>
                <div className="modal-content" style={{height: '450px'}}>
                    <div className="modal-header modalPopupHeader">
                        <h5 className="modal-title mt-0 fw-bold text-center black-text" style={{width: '97%', marginLeft: '3%'}}>{'New Equipment'}</h5>
                        {/* <button type="button" className="close" data-dismiss="modal" style={{ width:'3%', height:'10%' }} aria-label="Close" onClick={onCloseEquipPopup} /> */}
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseEquipPopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body color-black my-2" style={{paddingTop: '10px'}}>
                        <div className="row fs-7 px-6">
                            <div className="col-md-6 text-center">
                                <div className="col-md-12 specific-equip p-3" style={{height: '289px'}} onClick={onClickSpecific}>
                                    <img src={"../../images/general-equipment.png"} style={{width: '110px'}}/>
                                    <p style={{marginTop: 0, marginBottom: '1rem'}}>
                                        <strong>Specific Equipment</strong>{' '}
                                    </p>
                                    <p style={{marginTop: 0, marginBottom: '1rem'}}>
                                        Thi specific equipment <br/>
                                        Will be the one made available <br/>
                                        for the renters.
                                    </p>
                                    <p style={{marginTop: 0, marginBottom: '1rem'}}>
                                        You will need to add <br/>
                                        availabilities for this specific equipment to available for
                                        the renter.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 text-center">
                                <div className="col-md-12 generic-equip p-3" style={{height: '289px'}} onClick={onClickGeneric}>
                                    <img src={"../../images/specific-equipment.png"} style={{width: '110px'}}/>
                                    <p style={{marginTop: 0, marginBottom: '1rem'}}>
                                        <strong>Generic Equipment </strong>{' '}
                                    </p>
                                    <p style={{marginTop: 0, marginBottom: '1rem'}}>
                                        Display multiple similar equipment in a single listing to
                                        simplify the management of their availabilites.
                                    </p>
                                    <p style={{marginTop: 0, marginBottom: '1rem'}}>
                                        When a generic equipment is rented, you will need to assign
                                        a specific equipement before the start of the reservation.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFootr">
                        {/* <button type="button" className="btn btn-secondary waves-effect" data-bs-dismiss="modal"
                                onClick={onCloseEquipPopup}>Cancel</button> */}
                        <button type="button" className="btn btn-outline-dark btn-sm"
                            style={{backgroundColor: '#00CFB4', width: '14%', color: '#fff'}}
                            data-bs-dismiss="modal" onClick={onCloseEquipPopup}>
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
            <div className="container-fluid container-fluid-padding" style={{paddingTop: '1px'}}>
                <div className="row">
                    <div className="col-md-2 col-12 pt-2" style={{paddingLeft: '5px', zIndex: 999,display:(isCompanyVisible?"":"none")}}>
                        <span className="black-text css-16ihq8s">Company</span>
                        <SingleSelectComp dataSource={orgData} selectedValue={selCompanyObj}
                                          width={'100%'} handleChange={onCompanyChange}
                                          value={'orgId'} label={'orgName'}/>

                    </div>
                    <div className="col-md-2 col-6 pt-2" style={{zIndex: '999'}}>
                        <span className="black-text css-16ihq8s">Duration</span>
                        <SingleSelectComp dataSource={equipTypes} selectedValue={selEquipObj}
                                          width={'100%'} handleChange={onEquipmentTypeChange}
                                          value={'key'} label={'value'} />
                    </div>
                    <div className="col-md-2 col-6 pt-2" style={{zIndex: '999'}}>
                        <span className="black-text css-16ihq8s">From Date</span>
                        <div className="input-group">
                            <Flatpickr placeholder="From Date" ref={fromRef} options={dateOptions} onChange={onChangeFromDate}
                                       className="form-control"  />
                        </div>
                    </div>
                    <div className="col-md-2 col-6 pt-2" style={{zIndex: '999'}}>
                        <span className="black-text css-16ihq8s">To Date</span>
                        <div className="input-group">
                            <Flatpickr placeholder="To Date" ref={toRef} options={dateOptions} onChange={onChangeToDate}
                                       className="form-control"  />
                        </div>
                    </div>
                    <div className="col-md-2 col-6 pt-2" style={{zIndex: '999'}}>
                        <span className="black-text css-16ihq8s">Asset Type</span>
                        <SingleSelectComp dataSource={assetTypes} selectedValue={selAssetObj}
                                          width={'100%'} handleChange={onAssetTypeChange}
                                          value={'key'} label={'value'} />
                    </div>
                    <div className="col-md-2 col-6 pt-2" style={{zIndex: '999'}}>
                        <span className="black-text css-16ihq8s">Equipment Type</span>
                        <SingleSelectComp dataSource={equipTypes} selectedValue={selEquipObj}
                                          width={'100%'} handleChange={onEquipmentTypeChange}
                                          value={'key'} label={'value'} />
                    </div>
                    <div className="col-md-12 col-lg-1 row">
                        <div className="col-md-1" style={{paddingTop: '22px'}}>
                            <span className="black-text css-16ihq8s"> </span>
                            <button type="button" className="btn btn-warning cus-btn btn-sm button-height" onClick={getEquipmentList}>
                                Search
                            </button>
                        </div>
                    </div>

                </div>
                <div className="row pt-2" style={{display:"none"}}>
                    <div className="col-12">
                        <div className="btn-group">
                            <button id={"btnAll"}  type="button" className="btn btn-success waves-effect waves-light  button-height"
                                    onClick={()=>{onClickStatusButton('btnAll')}}>All</button> &nbsp;
                            <button id={"btnUpcoming"} type="button" className="btn btn-warning waves-effect waves-light  button-height"
                                    onClick={()=>{onClickStatusButton('btnUpcoming')}}>Upcoming</button> &nbsp;
                            <button id={"btnInProgress"} type="button" className="btn btn-warning waves-effect waves-light  button-height"
                                    onClick={()=>{onClickStatusButton('btnInProgress')}}>In progress</button> &nbsp;
                            <button id={"btnDone"} type="button" className="btn btn-warning waves-effect waves-light  button-height"
                                    onClick={()=>{onClickStatusButton('btnDone')}}>Done</button> &nbsp;
                            <button id={"btnPending"} type="button" className="btn btn-warning waves-effect waves-light  button-height"
                                    onClick={()=>{onClickStatusButton('btnPending')}}>Pending</button> &nbsp;
                            <button  id={"btnCancel"} type="button" className="btn btn-warning waves-effect waves-light  button-height"
                                     onClick={()=>{onClickStatusButton('btnCancel')}}>Cancelled</button> &nbsp;
                            <button id={"btnLateActivation"} type="button" className="btn btn-warning waves-effect waves-light  button-height"
                                    onClick={()=>{onClickStatusButton('btnLateActivation')}}>Late Activation</button> &nbsp;
                            <button id={"btnLateDeactivation"} type="button" className="btn btn-warning waves-effect waves-light  button-height"
                                    onClick={()=>{onClickStatusButton('btnLateDeactivation')}}>Late Deactivation</button> &nbsp;
                        </div>
                    </div>
                    <div className="col-1">

                    </div>
                </div>
                <div className="row  pt-1">
                    {
                        isMobile ? (
                            <MGrid id="OrgOfferGrid" cols={eqpColumnDefs}
                            rows={equipData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                            ref={gridRef} addNew={false} method={{onClickLink}} />
                        ) : (
                        <div className="ag-theme-alpine gridHeight"
                        style={{height: 'calc(100vh - 160px)', width: '100%', paddingLeft: '8px', paddingRight: '5px'}} >
                        <AGGrid id="OrgOfferGrid" cols={eqpColumnDefs}
                            rows={equipData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                            ref={gridRef} addNew={false} method={{onClickLink}} />
                    </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default OfferReport;
