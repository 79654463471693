import React, {useEffect, useState} from "react";
import {loadMapApi} from "../utils/GoogleMapsUtils";
import Map from "../Map";

var googleMapKey = "";

interface IProps {

}

const GoogleMap: React.FC<IProps> = () => {

    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [distanceInKm, setDistanceInKm] = useState<number>(-1);

    useEffect(() => {
        // window.google = "kddk";
        if (window.google) {
            console.log(google.maps.MapTypeId.ROADMAP);
            setScriptLoaded(true);
        } else {
            const googleMapScript = loadMapApi();
            console.log(googleMapScript);
            if (googleMapScript) {
                googleMapScript.addEventListener('load', function () {
                    console.log("Map loaded");
                    console.log(window.google);
                    setScriptLoaded(true);
                });
            }
        }

        /*let flag:boolean = false;
        const mapsURL = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDh9Z8jcOImXusnShqznpUqYGuJgaEcGYE&libraries=geometry,places`;
        const scripts:any = document.getElementsByTagName('script');
        if(scripts){
           // console.log(scripts.length);
            for (let i = 0; i < scripts.length; i++) {
                if(scripts[i] && scripts[i].src){
                    if (scripts[i].src.indexOf(mapsURL) === 0) {
                        flag = true;
                        break;
                    }
                }

            }
        }
        if(!flag){
            const googleMapScript = loadMapApi();
            console.log(googleMapScript);
            if(googleMapScript){
                googleMapScript.addEventListener('load', function () {
                    console.log("Map loaded");
                    console.log(window.google);
                    setScriptLoaded(true);
                });
            }
        }else{
            setScriptLoaded(true);
        }*/
    }, []);

    const renderDistanceSentence = () => {
        return (
            <div className='distance-info'>
                {`Distance between selected marker and home address is ${distanceInKm}km.`}
            </div>
        );
    };

    return (
        <>
            {scriptLoaded && (
                <Map
                    mapType={google.maps.MapTypeId.ROADMAP}
                    mapTypeControl={true}
                    setDistanceInKm={setDistanceInKm}
                />
            )}

        </>
    );
}
export default GoogleMap;
