import React, { useState,useEffect } from 'react'
import { CommonUtil } from '../../utils/CommonUtil';
import EquipmentHeader from '../Login/EquipmentHeader';
import OffersReceived from './OffersMade';

const OfferRenterView = () => {

    const [isLogin,setIsLogin] = useState(false);
useEffect(()=>{
    if(CommonUtil.getSelectedOrgId()){
        setIsLogin(true);
    }
},[]);

let onLoginSuccess = ()=>{
    console.log("login success");
    localStorage.setItem("offerView","true");
    setIsLogin(true);
}
  return (
    <>
    {
        CommonUtil.getSelectedOrgId()?
            <EquipmentHeader isLogin={"false"} isSearchDB={"false"} isSignUp={"false"} isLogout={"true"} />:
            <EquipmentHeader isPLogin={"true"} isSearchDB={"false"} isSignUp={"false"} isLoginPopup={"true"} onLoginSuccess={onLoginSuccess} />
    }

    <div style={{paddingTop:"40px"}}>
        <OffersReceived isLogin={isLogin} />
    </div>
    </>
  )
}

export default OfferRenterView