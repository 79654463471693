import React, {useEffect, useRef, useState,useMemo} from "react";
import useStateWithCallback from "use-state-with-callback";
import {IAGrid} from "../../Components/IInterfaces";
import {Modal} from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import {CommonUtil} from "../../utils/CommonUtil";
import {NetworkManager} from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import {Constants} from "../Languages/Constants";
import PhoneInput from "react-phone-input-2";
import {useNavigate} from "react-router-dom";
import InspectionGrid from "./InspectionGrid";
import '../InspectionCompany/Inspection.css'
import InspectionRenderer from "./InspectionRenderer";
import InspectionStatusRenderer from "./InspectionStatusRenderer";
import { loadMapApi } from "../../utils/GoogleMapsUtils";
import BusyLoader from "../../Components/BusyLoader";
import SingleSelectComp from "../../Components/SingleSelectComp";
import { Events } from "../Languages/Events";

var userMode:string = "";
let userObj:any = null;
var defStatusObj = {key:"Active",value:"Active"}
const AddInspectionCo = ()=>{

    const [users,setUsers] = useStateWithCallback([],()=>{
        if(userGridRef1.current){
            userGridRef1.current.columnFittoSize();
        }
    });

    const [inspectors,setInspectors] = useStateWithCallback({},()=>{
        if(userGridRef1.current){
            userGridRef1.current.columnFittoSize();
        }
    });

    const inputRef = useRef<any>(null);

   

    const [showUserPopup,setShowUserPopup] = useState(false);
    const [showUserStatusPopup,setShowUerStatusPopup] = useState(false);
    const userGridRef1 = useRef<IAGrid>(null);
    const divRef = useRef<HTMLDivElement>(null);



    const fnRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const usRef = useRef<HTMLInputElement>(null);
    const insRef = useRef<HTMLInputElement>(null);
    const lnRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();
    
    const [inspectionData,setInspectionData] = useState(null)
    const [usdot,Setusdot] = useState('');
    const [rawAddress,setRawAddress] = useState("");
    const [email,setEmailId] = useState("");
    const [contactName,setContactName] = useState("");
    const [contactNumber,setContactNumber] = useState("");
    const [instructions,setInstructions] = useState("")
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [address1,setAddress1] = useState("");
    const [inspectionOrgName,setInspectionOrgName] = useState('');

    const [addInfo, setAddInfo] = useState(true);




    const [status,setStatues] = useState(CommonUtil.INSPECTION_STATUS);
    const [statusObj,setStatusObj]  = useState<any>(CommonUtil.DEFAULT_INSPECTION_STATUS);

    const [inspectionType,setInspectionType] = useState(CommonUtil.INSPECTION_TYPE_STATUS);
    const [insTypeObj,setInsTypeObj] = useState<any>(CommonUtil.INSPECTION_TYPE_STATUS);

    const [country, setCountry] = useState(CommonUtil.country);
    const [countryObj, setCountryObj] = useState<any>(null);
    

    useEffect(()=>{
        loadGooglePlaces();
    })

    const [insTypeClass,setInsTypeClass] = useState("mandatory");
    const [statusClass,setStatusClass] = useState("mandatory");
    const [countryClass, setCountryClass] = useState("mandatory");
    const [phoneClass, setPhoneClass] = useState('mandatory');
    const [userStatus,setUserStatus] = useState("");
    const [userColumns] = useState([

        { field: 'inspectionOrgName', headerName:CommonUtil.getMessageText(Constants.COMPANY_NAME,'Inspection Company'),resizable:true,sortable:true, cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px !important','fontFamily':'poppins','color':'black','marginTop':'5px'},headerClass: 'header-white', filter: 'agTextColumnFilter'},
        { field: 'inspectionType', headerName:CommonUtil.getMessageText(Constants.INSPECTION_TYPE,'Inspection Type'),resizable:true,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','marginTop':'5px' },headerClass: 'header-white', filter: 'agTextColumnFilter'},
        { field: 'usDot', headerName:CommonUtil.getMessageText(Constants.USDOT,'USDOT'),sortable:true,resizable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','marginTop':'5px' },headerClass: 'header-white', filter: 'agTextColumnFilter'},
        { field: 'userEmail', headerName:CommonUtil.getMessageText(Constants.EMAIL,'Email'),sortable:true,resizable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','marginTop':'5px' },headerClass: 'header-white', filter: 'agTextColumnFilter'},
        { field: 'phoneNumber', headerName:CommonUtil.getMessageText(Constants.CONTACT_NUMBER,'Contact Number'),resizable:true,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','marginTop':'5px' },headerClass: 'header-white', filter: 'agTextColumnFilter'},
        { field: 'rawAddress', headerName:CommonUtil.getMessageText(Constants.LOCATION,'Location'),sortable:true,resizable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','marginTop':'5px'},headerClass: 'header-white', filter: 'agTextColumnFilter'},
        { field: 'country', headerName:CommonUtil.getMessageText(Constants.COUNTRY,'Country'),resizable:true,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','marginTop':'5px' },headerClass: 'header-white', filter: 'agTextColumnFilter'},
        { field: 'organizationStatus', headerName:CommonUtil.getMessageText(Constants.STATUS,'Status'),sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','marginTop':'5px' },headerClass: 'header-white', filter: 'agTextColumnFilter',cellRenderer:InspectionStatusRenderer},
      ]);

    let onClickUserDetails = (data: any)=>{
        localStorage.setItem("inspectionorgId", data.saasOrgId);
        setInspectionData(data)
        console.log(data)
        navigate("/menu/inspectionCompany/EditCompany");
    }

    let onClickActiveUser = (userData:any)=>{
        //console.log(userData);
        if(userData.status == CommonUtil.ACTIVE || userData.status == CommonUtil.IN_ACTIVE){
            userObj = userData;
            setUserStatus(userObj.status);
            setTimeout(()=>{
                setShowUerStatusPopup(true);
            })
        }
    }

    let getOrgType = ()=>{
        if(localStorage.getItem('orgType')=="OWNER" || localStorage.getItem('orgType')=="OWNER_RENTER" || localStorage.getItem('orgType')=="INSPECTION_COMPANY"){
          setAddInfo(false)
        }
      }

    useEffect(()=>{
        getOrgType();
    },[])  


    let onCloseUserStausPopup = ()=>{
        setShowUerStatusPopup(false);
    }

    let onClickAddUser = ()=>{
        clearUserData();
        userMode = CommonUtil.CREATE_MODE;
        setShowUserPopup(true);
    }
    let onCloseUserPopup = ()=>{
        setShowUserPopup(false);
    }

    let onStatusChange = (data:any)=>{
        let obj = {}
        if(data){
            obj = data;
        }else{
            obj ={key:CommonUtil.INSPECTION_ACTIVE,value:CommonUtil.INSPECTION_ACTIVE}
        }
        (data)? setStatusClass("select-box-valid"): setStatusClass("select-box-invalid");
        setStatusObj(obj);
    }

    let onCountryChange = (data:any)=>{
        setCountryObj(data);
        (data)? setCountryClass("select-box-valid"): setCountryClass("select-box-invalid");

    }

    let onInsTypeChange = (data:any)=>{
        setInsTypeObj(data);
        (data)? setInsTypeClass("select-box-valid"): setInsTypeClass("select-box-invalid");
      
    }


    let onCreateUser = ()=>{

        var isFn:boolean = CommonUtil.isMandatory(fnRef);
        var isUs:boolean = CommonUtil.isMandatory(usRef);
        var isIns:boolean = CommonUtil.isMandatory(insRef);
        var isContact:boolean = true;
        var isMail:boolean = CommonUtil.isEmailMandatory(emailRef);
        (insTypeObj)? setInsTypeClass("select-box-valid"): setInsTypeClass("select-box-invalid");
        (statusObj)?setStatusClass("select-box-valid"): setStatusClass("select-box-invalid");

        if (!contactNumber.trim() || contactNumber.length < 11) {
            isContact = false
            setPhoneClass('is-invalid')
        }

        if(isUs && isIns && isFn && insTypeObj && isContact && isMail && statusObj){

            var restObj:any = {};
            restObj.usDOT = usdot;
            restObj.rawAddress = address1;
            restObj.userEmail = email;
            restObj.phoneNumber = contactNumber;
            restObj.firstName = contactName;
            restObj.lastName = "";
            restObj.department = "Administrative";
            restObj.inspectionOrgName = inspectionOrgName;
            restObj.inspectionType = insTypeObj?"Interchange":"";
            restObj.companyStatus = statusObj?statusObj.key:"";
            restObj.country = countryObj?countryObj.Key:"";
           

            if(userMode == CommonUtil.UPDATE_MODE){
                if(userObj){
                    restObj.sassOrgId = userObj.sassOrgId;
                }
            }
            var userUrl = "";
            var method = "POST";
            var qParam = CommonUtil.getInspectionSaasOrgId();
            if(userMode == CommonUtil.CREATE_MODE){
                userUrl = "api/InspectionOrganization/";
            }else{
                method = "PUT";
                userUrl = "api/InspectionOrganization/"+qParam
            }
            BusyLoader.showLoader()
            NetworkManager.sendJsonRequest(userUrl,restObj,"",method,true,onUserCreate,onLoginError);
        }
        }
            
    

    let onUserCreate = (data:any)=>{
        if (data) {
            var restData = CommonUtil.parseData(data);
            if (restData) {
                onCloseUserPopup();
                let strSuccess = CommonUtil.getMessageText(Constants.INSPECTION_CREATED_SUCCESS,"Inspection Company Created Successfully");
                ToastManager.showToast(strSuccess, "Info");
                getAllUsers();
            }
        }
    }

    let getAllUsers = ()=>{
        if(localStorage.getItem('orgType')=="OWNER" || localStorage.getItem('orgType')=="OWNER_RENTER"){
        setUsers([]);
        var orgUrl = "api/ownerInspectionOrgAssociation/getOrganizationAssociated?ownerSaasOrgId="+CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onOrgGetAllUsers,onLoginError);
        }
        else if(localStorage.getItem('orgType')=="Trailer Hub Provider"){
            setUsers([]);   
            var orgUrl = "api/InspectionOrganization/all";
            NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onOrgGetAllUsers,onLoginError);  
        }
        else{
            setUsers([]);
            var orgUrl = "api/InspectionOrganization/getAllUsingSaasId"+CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onOrgGetAllUsers,onLoginError);
        }
        
    }

    useEffect(()=>{
        getAllUsers(); 
    },[])

    let onOrgGetAllUsers = (dataObj:any)=>{
        console.log(dataObj);
        if(dataObj) {
            var restData = JSON.parse(dataObj);
            console.log(restData)
            if(restData){
                restData.map((org:any)=>{
                    console.log(org.inspectionOrgId)
                })
            }
            setUsers(restData)
            if(restData){
                var users = [];
                if(Array.isArray(restData)){
                    users = restData;
                }else{
                    users.push(restData);
                    
                }
                setUsers(restData)    
                console.log(users)    
            }

}
        

    }

    let clearUserData = ()=>{
     
        setEmailId("");
        Setusdot("");
        setAddress1("");
        setInstructions("");
        setContactNumber("");
        setContactName("");
        setInspectionOrgName("");
        setCountryObj(null);
    }

    let onLoginError = (err:any)=>{
        console.log(err);
    }

    const handleInputPhone = (e: any) => {
        setContactNumber(e);
    }

    let loadGooglePlaces = () => {
        if (window.google) {
            setScriptLoaded(true);
            getGooglePlaces();
        } else {
            const googleMapScript = loadMapApi();
            if (googleMapScript) {
                googleMapScript.addEventListener('load', function () {
                    setScriptLoaded(true);
                    getGooglePlaces();
                })
            }
        }
    }
    let getGooglePlaces = () => {

        const address = document.getElementById("address") as HTMLInputElement;
        CommonUtil.getPlaceAddressByMap(address,(addr:any)=>{
            //console.log(result);
            setAddress1(addr['rawAddress']);
            setCountryObj({Key: addr['country'], Value: addr['country']});
            setLatitude(addr['lat']);
            setLongitude(addr['lng']);
        });
    }

    let onSwitchCustomer = (event: any)=>{
        Events.dispatchBreadCrumbEvent();
    }

    useEffect(()=>{
        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
    })


    return(
        <>
            <Modal  show={showUserPopup} dialogAs={DraggableComponent} className={'modal-dialog-centered'}>
                <div className="modal-content" style={{"width": "700px","height":"550px"}}>
                <div className="modal-header mt-1" style={{borderBottom:'0px'}}>
                        <h6 className="modal-title darkgrey-text fw-600">{CommonUtil.getMessageText(Constants.CREATE_INSPECTION_COMPANY,"Create Inspection Company")}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseUserPopup} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.COMPANY_NAME,"Inspection Company Name")} <span
                                        className="text-danger"></span></label>
                                    <div className="col-sm-12">
                                        <input ref={insRef} type="text"
                                               className="form-control clsStandardText  clsFormInputControl"
                                               placeholder={CommonUtil.getMessageText(Constants.COMPANY_NAME,"Company Name")} value={inspectionOrgName}
                                               onChange={(e)=>{setInspectionOrgName(e.currentTarget.value);CommonUtil.isMandatory(insRef);}} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.STATUS,"Status")} <span
                                        className="text-danger"></span></label>
                                    <div className="col-sm-12">
                                        <SingleSelectComp dataSource={status} selectedValue={statusObj} handleChange={onStatusChange} value={"key"} label={"value"}
                                                           className={statusClass} width={"100%"} placeholder="Select Status"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CONTACT_NAME,"Contact Name")} <span
                                        className="text-danger"></span></label>
                                    <div className="col-sm-12">
                                        <input type="text"
                                               className="form-control clsStandardText  clsInputControl"
                                               placeholder="Enter Contact Name"  aria-label="Contact Name" value={contactName}
                                               onChange={(e)=>{setContactName(e.currentTarget.value)}} />
                    
                                    </div>
                                </div>
                            </div>
                           

                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.USDOT,"USDOT")} <span
                                        className="text-danger"></span>
                                </label>
                                    <div className="col-sm-12">
                                    <div >
                                    <input ref={usRef} type="text"
                                               className="form-control clsStandardText  clsFormInputControl"
                                               placeholder={CommonUtil.getMessageText(Constants.USDOT,"Enter USDOT")} value={usdot}
                                               onChange={(e)=>{Setusdot(e.currentTarget.value);CommonUtil.isMandatory(usRef);}} />
                                    </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.EMAIL_ID,"Email ID")} <span
                                        className="text-danger"></span></label>
                                    <div className="col-sm-12">
                                    <div >
                                        <input ref={emailRef} type="email"
                                               className="form-control clsStandardText  clsFormInputControl"
                                               placeholder="Enter Email Id" aria-label="Email" value={email}
                                               onChange={(e)=>{setEmailId(e.currentTarget.value)}} />
                                    </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.INSPECTION_TYPE,"Inspection Type")} <span
                                        className="text-danger"></span></label>
                                    <div className="col-sm-12">
                                    <SingleSelectComp  dataSource={inspectionType} selectedValue={insTypeObj} handleChange={onInsTypeChange} value={"key"} label={"value"}
                                                           className={insTypeClass} width={"100%"} placeholder="Select Inspection Type"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-12">
                                <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LOCATION,"Location")} <span
                                        className="text-danger"></span></label>
                                    <div className="col-sm-12">
                                    <div >
                                        <input ref={fnRef} type="text" id={'address'} 
                                            className="form-control clsFormInputControl" placeholder={CommonUtil.getMessageText(Constants.LOCATION, 'Enter Location')}
                                            aria-label="Location"
                                            value={address1}
                                            onChange={(e)=>{setAddress1(e.currentTarget.value)}} 
                                        />
                                    </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.COUNTRY,"Country")} <span
                                        className="text-danger"></span></label>
                                    <div className="col-sm-12">
                                    <SingleSelectComp dataSource={country} selectedValue={countryObj} handleChange={onCountryChange} value={"Key"} label={"Value"}
                                                           className={countryClass} width={"100%"} placeholder="Select Country"/>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-md-6 col-12">
                                <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ACCESS_INSTRUCTIONS,"Access Instructions")} <span
                                        className="text-danger"></span>
                                </label>
                                    <div className="col-sm-12">
                                    
                                        <input type="text"
                                               className="form-control clsStandardText  clsInputControl"
                                               placeholder="Enter Access Instructions"  aria-label="Access Instructions" value={instructions}
                                               onChange={(e)=>{setInstructions(e.currentTarget.value)}} />
                                    </div>
                                  
                                </div>
                            </div> */}

                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PHONE_NUMBER,"Phone Number")} <span
                                        className="text-danger"></span>
                                </label>
                                    <div className="col-sm-12">
                                    <div >
                                    <PhoneInput
                                  country={'us'}
                                  countryCodeEditable={true}
                                  containerClass={phoneClass}
                                  value={contactNumber}
                                  placeholder="Enter Phone Number"
                                  inputClass={`input-phone ${contactNumber.toString().length === 11 ? '' : 'inputMandatory'}`}
                                  onChange={(e) => {
                                    if (contactNumber == '') {
                                      e =  '' + e
                                    }
                                    setContactNumber(e)
                                  }}
                                  disableCountryGuess={true}
                                  disableDropdown={true}
                                  disableSearchIcon={true}
                              />
                                        </div>
                                        </div>
                                </div>
                            </div>
                            <div className="row justify-content-end mt-4">
                            <div className="col-md-6 col-12" style={{textAlign:'right'}}>
                            <button type="button" className="btn btn-success" onClick={onCreateUser}>{CommonUtil.getMessageText(Constants.SAVE,"Save")}</button>&nbsp;&nbsp;
                            <button type="button" className="btn btn-danger"  onClick={onCloseUserPopup}>{CommonUtil.getMessageText(Constants.CANCEL,"Cancel")}</button>
                            </div>
                    </div>
                    </div>
                </div>
                </div>
            </Modal>
            <div ref={divRef} className="container-fluid container-fluid-padding">
                <div className="row fs-7 mt-2">
                        <div className="ag-theme-alpine ag-theme-customeTheme gridHeight" style={{ height: "calc(110vh - 200px)", width: "100%"}}>
                            <InspectionGrid cols={userColumns} rows={users} pageSize={100} addNew={true} showButton={addInfo} tooltip={"Add Company"} name={"Add Company"}
                                    headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={userGridRef1} method={{onClickUserDetails,onClickActiveUser}} addNewRecord={onClickAddUser} />
                        </div>
                </div>
            </div>
            
        </>

    );
}

export default AddInspectionCo;