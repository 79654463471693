import React from 'react'
import './Interchange.css'
import { useState, useEffect } from 'react'
import ExitVisualInspection from './ExitInspectionDetails/ExitVisualInspection'
import ExitLightsInspection from './ExitInspectionDetails/ExitLightsInspection'
import ExitTiresInspection from './ExitInspectionDetails/ExitTiresInspection'
import ExitPicturesInspection from './ExitInspectionDetails/ExitPicturesInspection'
import ExitCargoInspection from './ExitInspectionDetails/ExitCargoInspection'
import ExitReeferInspection from './ExitInspectionDetails/ExitReeferInspection'
import ExitCTPATInspection from './ExitInspectionDetails/ExitCTPATInspection'
import { CommonUtil } from '../../utils/CommonUtil';
import { NetworkManager } from '../../Components/NetworkManager'
import ImageLoader from './ImageLoader'
import SignaturePop from './signaturePop'
import { Constants } from '../Languages/Constants'

const InterchangeExitInspection = () => {

    const [visualStatus, setVisualStatus] = useState(false);
    const [lightsStatus, setLightsStatus] = useState(false);
    const [tiresStatus, setTiresStatus] = useState(false);
    const [cargoStatus, setCargoStatus] = useState(false);
    const [reeferStatus, setReeferStatus] = useState(false);
    const [CTPATStatus, setCTPATStatus] = useState(false);
    const [signature, setSignature] = useState("");
    const [signature2, setSignature2] = useState("");
    const [comments, setComments] = useState("");
    const [signDesc, setSignDesc] = useState("");
    const [isReefer, setIsReefer] = useState(true);

    const [inspectionStatus, setInspectionStatus] = useState(false);
    const [exitLocation, setExitLocation] = useState("");
    const [exitTime, setExitTime] = useState("");
    const [exitInspectionID, setExitInspectionID] = useState("");

    const [tabIndex, setTabIndex] = useState(1);

    const [visualStart, setVisualStart] = useState("");
    const [visualEnd, setVisualEnd] = useState("");

    const [lightsStart, setLightsStart] = useState("");
    const [lightsEnd, setLightsEnd] = useState("");

    const [tiresStart, setTiresStart] = useState("");
    const [tiresEnd, setTiresEnd] = useState("");

    const [pictureStart, setPictureStart] = useState("");
    const [pictureEnd, setPictureEnd] = useState("");

    const [cargoStart, setCargoStart] = useState("");
    const [cargoEnd, setCargoEnd] = useState("");

    const [reeferStart, setReeferStart] = useState("");
    const [reeferEnd, setReeferEnd] = useState("");

    const [ctpatStart, setCtpatStart] = useState("");
    const [ctpatEnd, setCtpatEnd] = useState("");

    const [overallExitStartTime, setoverallExitStartTime] = useState("");
    const [overallExitEndTime, setoverallExitEndTime] = useState("");

    useEffect(() => {
        getVisualInspectionDetails();
    }, [])

    let getVisualInspectionDetails = () => {

        var qparam = CommonUtil.getInterchangeDocumentId()
        var orgUrl = `api/fetchInspectionDetails/?docId=${qparam}&inspectionStatus=EXIT`
        NetworkManager.sendJsonRequest(orgUrl, "", "", "GET", true, onInspectionDetails, onLoginError);

    }



    let onInspectionDetails = (dataObj: any) => {
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            console.log(restData)

            setoverallExitStartTime(restData.overallExitStartTIme);
            setoverallExitEndTime(restData.overallExitEndTime)

            setVisualStart(restData.visualInspection?.startDate)
            setVisualEnd(restData.visualInspection?.endDate)

            setLightsStart(restData.lightInspecton?.startDate)
            setLightsEnd(restData.lightInspecton?.endDate)

            setTiresStart(restData.tiresInspection?.startDate)
            setTiresEnd(restData.tiresInspection?.endDate)

            setPictureStart(restData.picturesInspection?.startDate)
            setPictureEnd(restData.picturesInspection?.endDate)

            setCargoStart(restData.cargoInspection?.startDate)
            setCargoEnd(restData.cargoInspection?.endDate)

            setReeferStart(restData.reeferInspection?.startDate)
            setReeferEnd(restData.reeferInspection?.endDate)

            setCtpatStart(restData.ctpatInspection?.startDate)
            setCtpatEnd(restData.ctpatInspection?.endDate)

            setExitInspectionID(restData.eventCode)
            setExitLocation(restData.location)
            setExitTime(restData.time)
            let desc = `Company Name: ${restData.inspectionCompanyName}, Inspector Name: ${restData.inspectorName}`;
            // setSignDesc(desc) V20-2548
            setSignDesc("Signature")

            setComments(restData?.comments);
            setIsReefer((restData.isReefer !== 'Y' ? false : true))
            restData?.visualInspection?.taskDetailsAttachment?.map((status: any) => {
                if (String(status.status).toUpperCase() == Constants.NO || String(status.status).toUpperCase() == Constants.FAIL) {
                    setVisualStatus(true);
                    setInspectionStatus(true);
                }
            })
            restData?.lightInspecton?.taskDetailsAttachment?.map((status: any) => {
                if (String(status.status).toUpperCase() == Constants.NO || String(status.status).toUpperCase() == Constants.FAIL) {
                    setLightsStatus(true);
                    setInspectionStatus(true);
                }
            })
            restData?.cargoInspection?.taskDetails?.map((status: any) => {
                if (String(status.status).toUpperCase() == Constants.NO || String(status.status).toUpperCase() == Constants.FAIL) {
                    setCargoStatus(true);
                    setInspectionStatus(true);
                }
            })
            restData?.reeferInspection?.taskDetails?.map((status: any) => {
                if (String(status.status).toUpperCase() == Constants.NO || String(status.status).toUpperCase() == Constants.FAIL) {
                    setReeferStatus(true);
                    setInspectionStatus(true);
                }
            })
            restData?.ctpatInspection?.taskDetails?.map((status: any) => {
                if (String(status.status).toUpperCase() == Constants.NO || String(status.status).toUpperCase() == Constants.FAIL) {
                    setCTPATStatus(true);
                    setInspectionStatus(true);
                }
                // if(status?.name === "signatureAttachmentFullFilePath"){
                //     setSignature(status.status)
                // }
            })
            if (restData?.signatureFilePath) {
                setSignature(restData?.signatureFilePath)
            }
            if (restData?.signatureFilePath2) {
                setSignature2(restData?.signatureFilePath2)
            }
            restData?.tiresInspection?.tyres?.map((status: any) => {
                if (status?.condition != "Good" || status?.tyreDetails?.condition != "Good") {
                    setTiresStatus(true);
                    setInspectionStatus(true);
                }
            })
        }

    }

    let onLoginError = (err: any) => {
        console.log(err);
    }

    let inspectionBtnStatus: boolean = false;
    console.log(visualStatus, lightsStatus, tiresStatus, cargoStatus, CTPATStatus);
    if (!visualStatus && !lightsStatus && !tiresStatus && !cargoStatus && !CTPATStatus) {
        inspectionBtnStatus = true
        if (isReefer) {
            if (reeferStatus)
                inspectionBtnStatus = true
            else
                inspectionBtnStatus = false
        }
    }
    const receiptPdfDownload = async () => {
        var myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/json')
        if (localStorage.getItem('token')) {
            var token = 'Bearer ' + localStorage.getItem('token')
            myHeaders.append('Authorization', token);
        }
        await fetch(NetworkManager.serverPath + `api/InspectionDocuments/getIndividualPdf?docId=${CommonUtil.getInterchangeDocumentId()}&status=EXIT`, { method: 'GET', headers: myHeaders })
            .then((response: any) => {
                response.blob().then((blob: any) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `receiptTrailer_${CommonUtil.getInterchangeDocumentId()}.pdf`;
                    a.click();
                });

            }).catch(error => console.log('error', error))
    }

    return (
        <>
            <div className='containData'>
                <div className="admin-wrapper container-fluid">
                    <div className="container sec-wrapper py-3 px-3">
                        <div className="inspection-header mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="title">Receipt Inspection Document <strong># {CommonUtil.getDocumentCode()}</strong></div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <button type="button" className="btn btn-danger btn-lg" onClick={receiptPdfDownload} style={{ height: '30px', marginRight: "30px", fontWeight: "500" }}>PDF Download</button>
                                    <SignaturePop signature={[signature, signature2]} description={"Receipt Inspection"} title={signDesc} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6 col-lg-12 col-md-12">
                                <div className="sec-wrapper-one table-responsive borderless-data py-3 px-3">
                                    <table className="table table-borderless mb-0">

                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>Inspection Id</p>{exitInspectionID}
                                                </td>
                                                <td>
                                                    <p>Location</p>{exitLocation}
                                                </td>
                                                <td>
                                                    <p>Inspection Status</p>{inspectionBtnStatus != true ? <span className="badge bg-danger-light">Submitted with failures</span> : <span className="badge bg-success-light">Submitted without failures</span>}
                                                </td>
                                                <td>
                                                    <p>Start Time</p>{overallExitStartTime}
                                                </td>
                                                <td>
                                                    <p>End Time</p>{overallExitEndTime}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="col-xl-5 col-lg-12 col-md-12 ">
                                <div className="sec-wrapper-one table-responsive borderless-data py-3 px-3">
                                    <table className="table table-borderless mb-0">
                                        <tbody>
                                            {tabIndex === 1 ? (<tr>
                                                <td><p className="mt-2 text-dark">Visual Inspection :</p></td>
                                                <td><p>Start Date & Time</p>{visualStart}</td>
                                                <td><p>End Date & Time</p>{visualEnd}</td>
                                            </tr>) : ""}
                                            {tabIndex === 2 ? (<tr>
                                                <td><p className="mt-2 text-dark">Lights Inspection :</p></td>
                                                <td><p>Start Date & Time</p>{lightsStart}</td>
                                                <td><p>End Date & Time</p>{lightsEnd}</td>
                                            </tr>) : ""}
                                            {tabIndex === 3 ? (<tr>
                                                <td><p className="mt-2 text-dark">Tires Inspection :</p></td>
                                                <td><p>Start Date & Time</p>{tiresStart}</td>
                                                <td><p>End Date & Time</p>{tiresEnd}</td>
                                            </tr>) : ""}
                                            {tabIndex === 4 ? (<tr>
                                                <td><p className="mt-2 text-dark">Pictures Inspection :</p></td>
                                                <td><p>Start Date & Time</p>{pictureStart}</td>
                                                <td><p>End Date & Time</p>{pictureEnd}</td>
                                            </tr>) : ""}
                                            {tabIndex === 5 ? (<tr>
                                                <td><p className="mt-2 text-dark">Cargo Inspection :</p></td>
                                                <td><p>Start Date & Time</p>{cargoStart}</td>
                                                <td><p>End Date & Time</p>{cargoEnd}</td>
                                            </tr>) : ""}
                                            {tabIndex === 6 ? (<tr>
                                                <td><p className="mt-2 text-dark">Reefer Inspection :</p></td>
                                                <td><p>Start Date & Time</p>{reeferStart}</td>
                                                <td><p>End Date & Time</p>{reeferEnd}</td>
                                            </tr>) : ""}
                                            {tabIndex === 7 ? (<tr>
                                                <td><p className="mt-2 text-dark">CTPAT Inspection :</p></td>
                                                <td><p>Start Date & Time</p>{ctpatStart}</td>
                                                <td><p>End Date & Time</p>{ctpatEnd}</td>
                                            </tr>) : ""}
                                        </tbody>
                                    </table>
                                </div>
                            </div>



                            <div className="responsive-tabs mt-5">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation" onClick={(e: any) => setTabIndex(1)}>
                                        <button className="nav-link active" id="visuval-tab" data-bs-toggle="tab"
                                            data-bs-target="#visuval-tab-pane" type="button" role="tab"
                                            aria-controls="visuval-tab-pane" aria-selected="true">{visualStatus ? <i className="fa-solid fa-circle-xmark text-danger"></i> : <i className="fa-solid fa-circle-check text-success"></i>} Visual Inspection</button>
                                    </li>
                                    <li className="nav-item" role="presentation" onClick={(e: any) => setTabIndex(2)}>
                                        <button className="nav-link" id="lights-tab" data-bs-toggle="tab"
                                            data-bs-target="#lights-tab-pane" type="button" role="tab"
                                            aria-controls="lights-tab-pane" aria-selected="false">{lightsStatus ? <i className="fa-solid fa-circle-xmark text-danger"></i> : <i className="fa-solid fa-circle-check text-success"></i>} Lights</button>
                                    </li>
                                    <li className="nav-item" role="presentation" onClick={(e: any) => setTabIndex(3)}>
                                        <button className="nav-link" id="tires-tab" data-bs-toggle="tab"
                                            data-bs-target="#tires-tab-pane" type="button" role="tab" aria-controls="tires-tab-pane"
                                            aria-selected="false">{tiresStatus ? <i className="fa-solid fa-circle-xmark text-danger"></i> : <i className="fa-solid fa-circle-check text-success"></i>} Tires</button>

                                    </li>
                                    <li className="nav-item" role="presentation" onClick={(e: any) => setTabIndex(4)}>
                                        <button className="nav-link" id="pictures-tab" data-bs-toggle="tab"
                                            data-bs-target="#pictures-tab-pane" type="button" role="tab"
                                            aria-controls="pictures-tab-pane" aria-selected="false"><i
                                                className="fa-solid fa-circle-check text-success"></i> Pictures</button>
                                    </li>
                                    {/* <li className="nav-item" role="presentation" onClick={(e:any)=>setTabIndex(5)}>
                            <button className="nav-link" id="cargo-tab" data-bs-toggle="tab"
                                data-bs-target="#cargo-tab-pane" type="button" role="tab" aria-controls="cargo-tab-pane"
                                aria-selected="false">{cargoStatus?<i className="fa-solid fa-circle-xmark text-danger"></i>:<i className="fa-solid fa-circle-check text-success"></i>}
                                Cargo</button>
                        </li> */}
                                    {isReefer ? <li className="nav-item" role="presentation" onClick={(e: any) => setTabIndex(6)}>
                                        <button className="nav-link" id="reefer-tab" data-bs-toggle="tab"
                                            data-bs-target="#reefer-tab-pane" type="button" role="tab"
                                            aria-controls="reefer-tab-pane" aria-selected="false"> {reeferStatus ? <i className="fa-solid fa-circle-xmark text-danger"></i> : <i className="fa-solid fa-circle-check text-success"></i>} Reefer</button>
                                    </li> : ""}
                                    <li className="nav-item" role="presentation" onClick={(e: any) => setTabIndex(7)}>
                                        <button className="nav-link" id="ctpat-tab" data-bs-toggle="tab"
                                            data-bs-target="#ctpat-tab-pane" type="button" role="tab" aria-controls="ctpat-tab-pane"
                                            aria-selected="false">{CTPATStatus ? <i className="fa-solid fa-circle-xmark text-danger"></i> : <i className="fa-solid fa-circle-check text-success"></i>} CTPAT
                                            Confirmation</button>
                                    </li>
                                    <li className="nav-item" role="presentation" onClick={(e: any) => setTabIndex(8)}>
                                        <button className="nav-link" id="comments-tab" data-bs-toggle="tab"
                                            // data-bs-target="#ctpat-tab-pane" type="button" role="tab" aria-controls="ctpat-tab-pane"
                                            aria-selected="false">
                                            {comments ? <i className="fa-solid fa-circle-xmark text-danger"></i> : <i className="fa-solid fa-circle-check text-success"></i>} Comments</button>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className={`tab-pane fade ${(tabIndex === 1 ? "show active" : "")}`} id="visuval-tab-pane" role="tabpanel"
                                        aria-labelledby="visuval-tab">
                                        {tabIndex === 1 ? <ExitVisualInspection /> : ""}
                                    </div>
                                    <div className={`tab-pane fade ${(tabIndex === 2 ? "show active" : "")}`} id="lights-tab-pane" role="tabpanel" aria-labelledby="lights-tab"
                                    >
                                        {tabIndex === 2 ? <ExitLightsInspection /> : ""}
                                    </div>
                                    <div className={`tab-pane fade ${(tabIndex === 3 ? "show active" : "")}`} id="tires-tab-pane" role="tabpanel" aria-labelledby="tires-tab"
                                    >
                                        {tabIndex === 3 ? <ExitTiresInspection /> : ""}
                                    </div>
                                    <div className={`tab-pane fade ${(tabIndex === 4 ? "show active" : "")}`} id="pictures-tab-pane" role="tabpanel" aria-labelledby="pictures-tab" >
                                        {tabIndex === 4 ? <ExitPicturesInspection /> : ""}
                                    </div>
                                    <div className={`tab-pane fade ${(tabIndex === 5 ? "show active" : "")}`} id="cargo-tab-pane" role="tabpanel" aria-labelledby="cargo-tab" >
                                        {tabIndex === 5 ? <ExitCargoInspection /> : ""}
                                    </div>
                                    <div className={`tab-pane fade ${(tabIndex === 6 ? "show active" : "")}`} id="reefer-tab-pane" role="tabpanel"
                                        aria-labelledby="reefer-tab" >
                                        {tabIndex === 6 ? <ExitReeferInspection /> : ""}
                                    </div>
                                    <div className={`tab-pane fade ${(tabIndex === 7 ? "show active" : "")}`} id="ctpat-tab-pane" role="tabpanel"
                                        aria-labelledby="ctpat-tab" >
                                        {tabIndex === 7 ? <ExitCTPATInspection /> : ""}
                                    </div>
                                    <div className={`tab-pane fade ${(tabIndex === 8 ? "show active" : "")}`} id="comments-tab-pane" role="tabpanel"
                                        aria-labelledby="ctpat-tab" >
                                        {tabIndex === 8 ? <textarea style={{ width: "100%" }} disabled>{comments}</textarea> : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {

                }
            </div>

        </>
    )
}

export default InterchangeExitInspection