import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import AGGrid from "./AGGrid";
import { NetworkManager } from "./NetworkManager";
import { CommonUtil } from "../utils/CommonUtil";

function Costestimatehistory({update,insuranceAmount,currency,lastHubodometerReading,lastEngineHourReading}:any) {
  const [modalOpen, setModalOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  // const [columns, setColumns] = useState([]);
  const [reservationList, setReservationList] = useState([]);
  const [details, setDetails] = useState({});

  let onClickEdit = () => {
    setModalOpen(true);
  };
  let onClickClose = () => {
    setModalOpen(false);
  };

  

  const columns: any = [
    {
      headerName: "Details",
      field: "extensionId",
      hide:true,
      cellRenderer:(params:any)=>{
        const onClickItem = (e:any)=>{ 
            getCostestimatehistoryDetails(params.data.extensionId);         
        }
        return (
          <div className={"cellRenderHeader"}>
            <a><span onClick={onClickItem} className={"cellRender"}>{"Details"}</span></a>
        </div>
        )
      }
    },
    {
      headerName: "Hubodometer Reading",
      field: "hubometerReading",
    },
    {
      headerName: "Engine Hour Reading",
      field: "engineHoursReading",
    },
    {
      headerName: "Estimated Engine Hours per day",
      field: "estimatedEngineHoursPerDay",
    },
    {
      headerName: "Estimated Distance per day",
      field: "estimatedDistancePerDay",
    },
    {
      headerName: "Start Date",
      field: "startDate",
    },
    {
      headerName: "End Date",
      field: "endDate",
    },
    {
      headerName: "Creation Date",
      field: "creationDate",
    },
    {
      headerName: "Created By",
      field: "createdBy",
    },
    {
      headerName: "Last Updated Date",
      field: "lastUpdateDate",
    },
    {
      headerName: "Last Updated By",
      field: "lastUpdatedBy",
    },
  ];

  const getHistory = ()=>{
    if(CommonUtil.SELECT_RESERVATION_ID){
      let apiPath: string =
          "api/reservation/AllCostestimateHistory?reservationId=" +
          CommonUtil.SELECT_RESERVATION_ID;

        NetworkManager.sendJsonRequest(
          apiPath,
          "",
          "",
          "GET",
          true,
          (response: any) => {
            var restData = CommonUtil.parseData(response);
            if (restData) {
              console.log(restData.length);
              setReservationList(restData);
              try{                
                if(lastHubodometerReading){                                   
                  lastHubodometerReading(restData[restData.length-1].hubometerReading)
                }
              }catch(e){}                
              try{                
                if(lastEngineHourReading){                                   
                  lastEngineHourReading(restData[restData.length-1].engineHoursReading)
                }
              }catch(e){} 

              
            }
          },
          (err: any) => {
            console.log(err);
          }
        );
      }
  }

  const getCostestimatehistoryDetails = (extentionID:number) =>{
    let apiPath: string =
    `api/reservation/costestimateHistory?reservationId=${CommonUtil.SELECT_RESERVATION_ID}&extensionId=${extentionID}`

    NetworkManager.sendJsonRequest(
      apiPath,
      "",
      "",
      "GET",
      true,
      (response: any) => {
        var restData = CommonUtil.parseData(response);
        if (restData) {
          // setReservationList(restData);
          // setShowDetails(true);
          setModalOpen(true)
          setDetails(restData);
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  useEffect(() => {    
      getHistory();
  }, [update,CommonUtil.SELECT_RESERVATION_ID]);

  return (
    <>
      <Modal show={modalOpen} size="lg">
        <div
          className="modal-content"
          // style={{ width: '1000px', height: "600px" }}
        >
          <div className="modal-header">
            <h6>History Details</h6>
          </div>
          <div className="modal-body">
            <div className="row">
              <div
                className="ag-theme-alpine gridHeight"
                style={{ height:"450px", width: "100%" }}
              >
                 <>
                <ExtensionDetails details={details} insuranceAmount={insuranceAmount} currency={currency}/>
                </>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-dark"
              onClick={onClickClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <div className="ag-theme-alpine gridHeight"
                                 style={{height: 'calc(100vh - 550px)', width: '100%', paddingLeft: '8px', paddingRight: '5px'}} >                                  
          <AGGrid id="ownerReservationReportList" cols={columns}
                  rows={reservationList} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                    addNew={false}  />
      </div>
    </>
  );
}


const ExtensionDetails =({details,insuranceAmount,currency}:any)=>{
  const {distancePrice,
    durationPrice,
    enginePrice,
    extraDays,
    noOfDays,
    ownerServiceFee} = details;
  return (
    <div className="col-sm-12">
   <h6></h6>
   <table className=" w-100 general-three-box" >
    
      <tbody>
        <tr>
            <td className="p-2"><img src="../../images/DailyRate.png" width="50px"/></td>
            <td className="p-2"><label className="m-0">Distance Price</label></td>
            <td className="p-2 text-right">
               <h6>{distancePrice}</h6>
            </td>
         </tr> 
         <tr>
            <td className="p-2"><img src="../../images/DailyRate.png" width="50px"/></td>
            <td className="p-2"><label className="m-0">Duration Price</label></td>
            <td className="p-2 text-right">
               <h6>{durationPrice}</h6>
            </td>
         </tr>        
         
        <tr>
            <td className="p-2"><img src="../../images/DailyRate.png" width="50px"/></td>
            <td className="p-2"><label className="m-0">Engine </label></td>
            <td className="p-2 text-right">
               <h6>{enginePrice}</h6>
            </td>
         </tr> 
         <tr>
            <td className="p-2"><img src="../../images/DailyRate.png" width="50px"/></td>
            <td className="p-2"><label className="m-0">Extra Days</label></td>
            <td className="p-2 text-right">
               <h6>{extraDays}</h6>
            </td>
         </tr>        
         
          <tr>
            <td className="p-2"><img src="../../images/DailyRate.png" width="50px"/></td>
            <td className="p-2"><label className="m-0">Insurance Amount</label></td>
            <td className="p-2 text-right">
               <h6>{noOfDays * insuranceAmount}</h6>
            </td>
         </tr> 
         <tr>
            <td className="p-2"><img src="../../images/DailyRate.png" width="50px"/></td>
            <td className="p-2"><label className="m-0">Admin Fee</label></td>
            <td className="p-2 text-right">
               <h6>{String(currency).toUpperCase().indexOf("USD") !==-1 ? 3.75 : 4.75}</h6>
            </td>
         </tr> 
         <tr>
            <td className="p-2"><img src="../../images/DailyRate.png" width="50px"/></td>
            <td className="p-2"><label className="m-0">owner Service Fee</label></td>
            <td className="p-2 text-right">
               <h6>{ownerServiceFee}</h6>
            </td>
         </tr>        
         
      </tbody>
   </table>

   
</div>
  )
}

export default Costestimatehistory;
