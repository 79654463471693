import React from "react";

const ReservationEquipCellRenderer = (props:any)=>{
    const onClickLink = ()=>{
        if(props.context){
            //localStorage.setItem("userId","krishna");
            localStorage.setItem("ViewTab","true");
            let siteOrigin:string = window.location.origin;
            siteOrigin = siteOrigin+"/?requestor=equipview&equipid="+props.data.equipmentId+"&token="+ localStorage.getItem("token");;
            var newTab = document.createElement('a');
            newTab.href = siteOrigin;
            newTab.setAttribute('target', '_blank');
            newTab.click();

            /*let siteOrigin:string = window.location.origin;
            siteOrigin = siteOrigin+"/?requestor=equipview";
            window.location.assign(siteOrigin);*/
            //props.context.onClickLink(props.data);

            //props.context.onClickLink(props.data);
        }
    }
    return(
        <div>
            <span >{props.data.unitNumber?props.data.unitNumber:props.data.genericEquipmentName}</span>
        </div>

    )
}

export default ReservationEquipCellRenderer;