import React, {useEffect, useRef, useState} from "react";
import AGGrid from "../../Components/AGGrid";
import OrgCellRenderer from "../Organization/OrgCellRenderer";
import useStateWithCallback from "use-state-with-callback";
import {IAGrid} from "../../Components/IInterfaces";
import DraggableComponent from "../../Components/DraggableComponent";
import {CommonUtil} from "../../utils/CommonUtil";
import {Modal} from "react-bootstrap";
import BusyLoader from "../../Components/BusyLoader";
import {NetworkManager} from "../../Components/NetworkManager";
import $ from "jquery";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";
import ToastManager from "../../Components/ToastManager";
import equipDocumentRender from "../Organization/equipDocumentRender";
import equipDocumentStatus from "../Organization/equipDocumentStatusr";
import Confirm from "../../Components/confirm";
import { Constants } from "../Languages/Constants";
import { DragNDrop } from "../../utils/DragDrop";

var fileType:string = "";
var imagePath:string = "";
var equiImages:any = [];
var equipData:any = null;
var attchIdx:number = 0;
var visibility:string = "All";
const EquipmentDocuments = (props:any)=>{

    const [eqpColumnDefs] = useState([
        { field: 'documentName', headerName:CommonUtil.getMessageText(Constants.DOC_NAME,"Document Name"),resizable:true,width:100,flex:1,sortable:true,filter:'agTextColumnFilter',
            cellRenderer: equipDocumentRender},
        { field: 'visibility', headerName:CommonUtil.getMessageText(Constants.VISIBILITY,"Visibility"),resizable:true,width:100,flex:1,sortable:true,filter:'agTextColumnFilter'},
        { field: 'status', headerName:CommonUtil.getMessageText(Constants.STATUS,"Status"),resizable:true,width:100,flex:1,sortable:true,filter:'agTextColumnFilter', cellRenderer: equipDocumentStatus},
    ]);

    const [equipDocs,setEquipData] = useStateWithCallback<any>([],()=>{});
    const gridRef = useRef<IAGrid>(null);
    const [showDocPopup,setShowDocPopup] = useState(false);

    const filedocref = useRef<HTMLInputElement>(null);
    const dropFileObj = React.useRef<any>(null);

    const [fileNmae,setFileName] = useState("");

    const docRef = useRef<HTMLInputElement>(null);
    const [docuName,setDocuName] = useState("");

    const prvRef = useRef<HTMLInputElement>(null);
    const [chkPrivate,setChkPrivate] = useState(true);

    const renterRef = useRef<HTMLInputElement>(null);
    const [chkRenters,setChkRenters] = useState(false);

    const publicRef = useRef<HTMLInputElement>(null);
    const [chkPublic,setChkPublic] = useState(false);
    const [docAvail, setDocAvail] = useState<boolean>(false);

    const [equipImagesPath,setEquipImagesPath] = useState<any>([]);
    const documentsUploadDivRef = useRef(null);

    let uncheckVisibility = ()=>{
        setChkPrivate(false);
        setChkRenters(false);
        setChkPublic(false);
        $("#lnkPrivate").removeClass("active");
        $("#lnkRenters").removeClass("active");
        $("#lnkPublic").removeClass("active");
    }
    let onChangePrivate = (linkId:string) => {
        uncheckVisibility();
        if(linkId == "lnkPrivate"){
            setChkPrivate(true);
            $("#lnkPrivate").addClass("active");
        }else if(linkId == "lnkRenters"){
            setChkRenters(true);
            $("#lnkRenters").addClass("active");
        }else if(linkId == "lnkPublic"){
            setChkPublic(true);
            $("#lnkPublic").addClass("active");
        }
    }

    const handleDragEnter = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const handleDragLeave = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragOver = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDropUploads = (e:any)=>{
        e.preventDefault();
        e.stopPropagation();
        fileType = CommonUtil.EQUIP_DOCS;//documents
        const { files } = e.dataTransfer
        if (files && files.length) {
            dropFileObj.current = files[0];
            setDocAvail(true)
        }
    }

    let onClickDocumentPopup = ()=>{
        setDocuName("");
        setShowDocPopup(true);
        onChangePrivate("lnkPrivate");
        setTimeout(()=>{
            DragNDrop({drop:documentsUploadDivRef, handleDragOver, handleDrop:handleDropUploads, handleDragEnter, handleDragLeave});
        }, 1000)
    }

    let onClickOrg = ()=>{

    }

    let onCloseDocPopup = ()=>{
        setShowDocPopup(false);
        setDocuName('');
        dropFileObj.current = null
        setDocAvail(false)
    }

    let onClickDocBrowse = (fType:string)=>{
        let isDoc:boolean = CommonUtil.isMandatory(docRef);
        if(isDoc){
            fileType = fType;
            if(filedocref.current){
                filedocref.current.value = "";
                // fileref.current.onclick();
                $("#inputDOC").click();
            }
            setFileName("");
        }

    }
    const onSelectDocFiles = (e: any) => {
        if (e && e.target && e.target.files[0]) {
            setDocAvail(true)
            dropFileObj.current = e.target.files[0];
            setTimeout(()=>{
                e.target.value = '';
            }, 1000)
        }
    }
    let onUploadImage = (dataObj:any)=>{
        if(dataObj){
            if(dataObj.statusCode){
                BusyLoader.hideLoader();
                var errMsg = (StatusCodeUtil.getCodeMessage(dataObj.statusCode));
                ToastManager.showToast(errMsg,"Error");
            }else{
                // var strUploadPath = dataObj;
                // if(filedocref.current &&  filedocref.current.files){
                //     const formData = new FormData();
                //     var myHeaders = new Headers();
                //     var file = filedocref.current.files[0];
                //     var requestOptions:any = {method: 'PUT', headers: myHeaders, body: file, redirect: 'follow'};
                //     fetch(strUploadPath, requestOptions)
                //         .then(response => response.text())
                //         .then(result => {
                //             //BusyLoader.hideLoader();
                //         })
                //         .catch(error => {
                //             //  BusyLoader.hideLoader();
                //             console.log('error', error);
                //         });
                // }
                 var strUploadPath = dataObj;
                const formData = new FormData();
                    var myHeaders = new Headers();
                    var file:File = dropFileObj.current;
                    var requestOptions: any = {method: 'PUT', headers: myHeaders, body: file, redirect: 'follow'};
                    fetch(strUploadPath, requestOptions)
                        .then((response) => response.text())
                        .then((result) => {
                            //BusyLoader.hideLoader();
                            if(props?.specificForReservation){
                                uploadReaservationDocs();
                            }else{
                                uploadEquipDocs();
                            }                            
                        })
                        .catch((error) => {
                            //  BusyLoader.hideLoader();
                            console.log('error', error)
                    })
            }
        }
    }

    let uploadEquipDocs = ()=>{
        if(dropFileObj.current){
            let reqObj:any = {};
            let obj:any = {};
            if(fileType == CommonUtil.EQUIP_DOCS){
                obj.documentName = docuName;
            }
            obj.documentType = fileType;
            obj.fullFilePath = imagePath;
            obj.saasOrgId = localStorage.getItem("saasorgId");
            if(fileType == CommonUtil.EQUIP_IMAGES || fileType == CommonUtil.EQUIP_DAMAGES){
                obj.visibility = CommonUtil.PUBLIC_EQUIP;
            }else{
                obj.visibility = chkPrivate?"Private":(chkPublic?"Public":"Renter");
            }

            obj.entityId = CommonUtil.SELECT_EQUIP_ID;
            reqObj = obj;

            //reqObj.attachments = [obj];

            let attachmentUrl:string = "api/equipment/attachment?saasOrgId="+CommonUtil.getSaasOrgId();

            //var qParam:string = "logoPath=images/organizations/"+fileref.current.files[0].name+"&saasOrgId="+localStorage.getItem("saasorgId");
            NetworkManager.sendJsonRequest(attachmentUrl,reqObj,"","PUT",false,onUpdateEquipImageSuccess,onLoginError);
        }
    }
    let uploadReaservationDocs = ()=>{
        if(dropFileObj.current){
            let reqObj:any = {};
            let obj:any = {};
            if(fileType == CommonUtil.EQUIP_DOCS){
                obj.documentName = docuName;
            }
            obj.documentType = `'ReservationDoc-'${CommonUtil.SELECT_RESERVATION_CODE}`;
            obj.fullFilePath = imagePath;
            obj.saasOrgId = localStorage.getItem("saasorgId");            
            obj.visibility = CommonUtil.PUBLIC_EQUIP;            

            obj.entityId = CommonUtil.SELECT_EQUIP_ID;
            reqObj = obj;

            //reqObj.attachments = [obj];

            let attachmentUrl:string = "api/equipment/attachment?saasOrgId="+CommonUtil.getSaasOrgId();

            //var qParam:string = "logoPath=images/organizations/"+fileref.current.files[0].name+"&saasOrgId="+localStorage.getItem("saasorgId");
            NetworkManager.sendJsonRequest(attachmentUrl,reqObj,"","PUT",false,onUpdateEquipImageSuccess,onLoginError);
        }
    }
    let onUpdateEquipImageSuccess = (dataObj:any)=>{
        BusyLoader.hideLoader();
        setShowDocPopup(false);
        setDocAvail(false);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if(restData){
                ToastManager.showToast(CommonUtil.getMessageText(Constants.DOCS_UPLOAD_SUCCESS,"Document Uploaded Successfully"),"Info");
                showEquipmentDetails();
            }
        }
        //console.log(dataObj);
        //let qParam:string = "objectPath="+imagePath;
       // NetworkManager.sendJsonRequest("api/session/downloadUrl","",qParam,"GET",false,onDownloadSuccess,onLoginError)
    }
    let showEquipmentDetails = ()=>{
        attchIdx = 0;
        equiImages = [];
        if (CommonUtil.SELECT_EQUIP_ID) {
            let equipmentAPI: string = 'api/equipment/' + CommonUtil.SELECT_EQUIP_ID;
            let obj: any = {}
            NetworkManager.sendJsonRequest(equipmentAPI, '', '', 'GET', true, onGetEquipmentDetails, onLoginError);
        }
    }
    let onGetEquipmentDetails = (dataObj: any) => {
        //console.log(dataObj)
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                equipData = restData;
                getEquipDocuments();
            }
        }
    }
    let onDownloadSuccess = (data:any)=>{
        let obj:any = {};
        obj.Type = fileType;
        obj.Path = data;
        equiImages.push(obj);

        props.getEquipData();
        setTimeout(()=>{
            //console.log(equiImages);
            //setEquipImagesPath(equiImages);
            //setPage(Math.random()*100000);
            setFileName("");
            BusyLoader.hideLoader();
        },5000);

    }

    let onLoginError = (dataObj:any)=>{
        console.log(dataObj);
    }
    useEffect(()=>{
        //console.log(props);
        onClickVisibility("btnAll","All");
        equipData = props.equipData;




        showEquipmentDetails();
        //getEquipDocuments();
    },[]);

    let getEquipDocuments = ()=>{
        setEquipData([]);
        if(equipData && equipData.attachments){
            let attchments = [];
            if(Array.isArray(equipData.attachments)){
                attchments = equipData.attachments;
            }else{
                attchments.push(equipData.attachments);
            }

            if(attchments.length>0){
                let docs:any = [];
                attchments.forEach((docItem:any,idx:number)=>{
                    if(docItem.documentType == CommonUtil.EQUIP_DOCS){
                        if(visibility == "All"){
                            docs.push(docItem);
                        }else if(docItem.visibility == visibility){
                            docs.push(docItem);
                        }
                    }
                    if(docItem.documentType === `'ReservationDoc-'${CommonUtil.SELECT_RESERVATION_CODE}` && props.specificForReservation){
                        docs.push(docItem);
                    }
                });

                setEquipData(docs);
            }

        }
    }

    let onClickVisibility = (btnId:string,visi:string)=>{
        visibility = visi;
        getEquipDocuments();
        deSelectButtons();
        selectButton(btnId);
    }

    let deSelectButtons = ()=>{
        let buttons:any = ['btnAll','btnPrivate','btnPublic','btnRenter'];
        buttons.forEach((btnId:any,idx:number)=>{
            $("#"+btnId).removeClass("btn-success");
            if(! $("#"+btnId).hasClass("btn-warning")){
                $("#"+btnId).addClass("btn-warning")
            }
        })
    }

    let selectButton = (btnId:string)=>{
        $("#"+btnId).removeClass("btn-warning")
       if(! $("#"+btnId).hasClass("btn-success")){
           $("#"+btnId).addClass("btn-success")
       }
    }
    let onClickRemoveDoc = (data:any)=>{
        console.log(data);
    }

    let onDeleteEquipmentDoc = (dataObj:any)=>{
        console.log(dataObj);
        if(dataObj == "success"){
            ToastManager.showToast(CommonUtil.getMessageText(Constants.DOCS_DELETE_SUCCESS,"Document Deleted Successfully"),"Info");
            showEquipmentDetails();
        }else{
            ToastManager.showToast(CommonUtil.getMessageText(Constants.UNABLE_TO_DELETE_DOCS,"Unable to delete the doc"),"Error");
        }
    }

    let onDocDownload = (fullPath:string)=>{
        let qParam:string = "objectPath="+fullPath;
        NetworkManager.sendJsonRequest("api/session/downloadUrl","",qParam,"GET",true,onDownloadFileSuccess,onLoginError)
    }

    let onDownloadFileSuccess = (dataObj:any)=>{
        console.log(dataObj);
        let link = document.createElement("a");
        link.download = "download";
        link.href = dataObj;
        link.target = "_blank"
        link.click();
    }

    let onDocRemove = (attachId:string)=>{
        Confirm.show(CommonUtil.getMessageText(Constants.CONFIRM,"Confirm"), CommonUtil.getMessageText(Constants.DO_YOU_WANT_REMOVE,"Do you want remove document?"), [{label:"Yes", callback:function(){
            if(attachId){
                    let removeAPI:string = "api/equipment/attachment/"+attachId+"?saasOrgId="+localStorage.getItem("saasorgId");
                    console.log(removeAPI);
                    NetworkManager.sendJsonRequest(removeAPI,"","","DELETE",true,onDeleteEquipmentDoc,onLoginError);
                }
            }}, {label:"No"}]);
    }
const saveUploadDocs = () => {
        if(!docuName.trim()) return ToastManager.showToast('Please enter document name.', "Error");
        if(dropFileObj.current === null || dropFileObj.current === '') return  ToastManager.showToast('Please select document.', "Error");;
        if(dropFileObj.current){
        let fileName = dropFileObj.current.name
            setFileName(fileName)
            BusyLoader.showLoader();
            // var qParam:string = "objectPath=documents/organizations/"+CommonUtil.getSelectedOrgId()+"/"+fileName;
            if (fileType == CommonUtil.EQUIP_DOCS) {
                imagePath = 'documents/equipment/' + CommonUtil.getSelectedOrgId() + '/' + fileName + '-' + new Date().getTime()
            } else {
                imagePath = 'images/equipment/' + CommonUtil.getSelectedOrgId() + '/' + fileName + '-' + new Date().getTime()
            }

            //equiImages.push(imagePath);
            var qParam: string = 'objectPath=' + imagePath //images/equipment/"+CommonUtil.getSelectedOrgId()+"/"+fileName;;
            NetworkManager.sendJsonRequest('api/session/uploadUrl', '', qParam, 'GET', false, onUploadImage, onLoginError)
        }
    }

    const saveUploadDocsForReservation = () => {
        if(!docuName.trim()) return ToastManager.showToast('Please enter document name.', "Error");
        if(dropFileObj.current === null || dropFileObj.current === '') return  ToastManager.showToast('Please select document.', "Error");;
        if(dropFileObj.current){
        let fileName = dropFileObj.current.name
            setFileName(fileName)
            BusyLoader.showLoader();
            // var qParam:string = "objectPath=documents/organizations/"+CommonUtil.getSelectedOrgId()+"/"+fileName;
            if (fileType == CommonUtil.EQUIP_DOCS) {                
                imagePath = `documents/reservation/'${CommonUtil.getSelectedOrgId()}/${fileName}/${CommonUtil.SELECT_RESERVATION_CODE}-${new Date().getTime()}`
            } else {
                imagePath = `images/reservation/'${CommonUtil.getSelectedOrgId()}/${fileName}/${CommonUtil.SELECT_RESERVATION_CODE}-${new Date().getTime()}`;
            }

            //equiImages.push(imagePath);
            var qParam: string = 'objectPath=' + imagePath //images/reservation/
            NetworkManager.sendJsonRequest('api/session/uploadUrl', '', qParam, 'GET', false, onUploadImage, onLoginError)
        }
    }

      const onChangeDocName = (e : any) => {
        setDocuName(e.target.value);
        // CommonUtil.isMandatory(docRef);
    }
       const removeSelectedDoc = () => {
        dropFileObj.current = '';
        setDocAvail(false)
    }
    return(
        <>
            <input type="file" ref={filedocref} className="custom-file-doc-input" multiple={false} id="inputDOC" accept="application/pdf"
                   style={{"display":"none"}} onChange={(e)=>{onSelectDocFiles(e)}}></input>
                   <Modal show={showDocPopup} dialogAs={DraggableComponent} >
                <input type="file" className="custom-file-doc-input" multiple={false}
                id="inputDOC" accept="application/pdf" style={{display: 'none'}}  onChange={(e) => {onSelectDocFiles(e)}}></input>
                <div className="modal-content" style={{width: '550px', height: '500px'}}>
                    <div className="modal-header">
                        <h6 className="modal-title darkgrey-text fw-600">{CommonUtil.getMessageText(Constants.NEW_DOCUMENT,'New Document')}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseDocPopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>{CommonUtil.getMessageText(Constants.DOC_NAME,'Document Name')} </label>
                            <input type="text" className="form-control clsStandardText clsFormInputControl"
                                   placeholder="Document" value={docuName} ref={docRef}
                                   onChange={onChangeDocName} />
                        </div>
                        <div className="form-group" style={{display:props?.specificForReservation === true ? 'none':''}}>
                            <label>{CommonUtil.getMessageText(Constants.VISIBILITY,'Visibility')}</label>
                            <ul className="nav nav-tabs nav-justified" role="tablist" style={{height:"40px"}}>
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" id={"lnkPrivate"}  role="tab" onClick={()=>onChangePrivate('lnkPrivate')}>
                                        <i className="fa fa-lock"></i> {CommonUtil.getMessageText(Constants.PRIVATE,'Private')}</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link " data-toggle="tab"  id={"lnkRenters"} role="tab" onClick={()=>onChangePrivate('lnkRenters')}>
                                        <i className="fa fa-users"></i> {CommonUtil.getMessageText(Constants.RENTERS,'Renters')}</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab"  id={"lnkPublic"} role="tab" onClick={()=>onChangePrivate('lnkPublic')}>
                                        <i className="fa fa-globe"></i> {CommonUtil.getMessageText(Constants.PUBLIC,'Public')}</a>
                                </li>
                            </ul>                            
                        </div>
                        <label>{CommonUtil.getMessageText(Constants.UPLOAD,'Upload')}</label>
                        {docAvail && dropFileObj.current ? (
                        <div className="boxed">
                            <div className="row">
                                <div className="col-sm-12 col-12 text-center">
                                    <a style={{color: 'blue', paddingTop: '5px',zIndex:99}}>
                                        <img src="../../images/document.png" className="upload-thumbnail" />
                                        <i className=" remove-btn fa fa-remove upload-thumbnail-remove"
                                         onClick={removeSelectedDoc} ></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        ):(
                        <div className="boxed" ref={documentsUploadDivRef} style={{cursor:"pointer"}} onClick={()=>{onClickDocBrowse(CommonUtil.EQUIP_DOCS)}}>
                            <div className="row">
                                <div className="col-sm-12 col-12 text-center">
                                    <form action="" className="dropzone">
                                        <img src="../../images/Documents-Upload.png" className="mb-3" />
                                        <p className="text-center"><strong>{CommonUtil.getMessageText(Constants.DRAG_DRAP_FILES_BROWSE,'Drag & drop your documents here or browse documents')}</strong></p>
                                        <div className="fallback">
                                            <input name="file" type="file" style={{display:"none"}} multiple={true} />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        )}
                        <div style={{display:"none"}} className="row fs-7">
                            <div className="col-md-12">
                                <span className="grey-text css-16ihq8s">Document Name</span>
                                <span className="clsFormMandatory"></span>
                                {/* <input  type="text" className="form-control clsStandardText  clsFormInputControl"
                                    placeholder="Document" aria-label="First Name" value={docuName}
                                    onChange={(e) => {setDocuName(e.currentTarget.value);CommonUtil.isMandatory(docRef)}} /> */}
                            </div>
                        </div>
                        <div style={{display:"none"}} className="row fs-7 clsFormStandardTopMargin">
                            <span className="grey-text css-16ihq8s">Visibility</span>
                            <span className="clsFormMandatory"></span>
                            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input ref={prvRef} type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off"
                                     checked={chkPrivate} />
                                <label className="btn btn-outline-primary" htmlFor="btnradio1">
                                    Private
                                </label>
                                <input ref={renterRef} type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off"
                                     checked={chkRenters}/>
                                <label className="btn btn-outline-primary" htmlFor="btnradio2">
                                    Renters
                                </label>

                                <input ref={publicRef} type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off"
                                    checked={chkPublic}
                                />
                                <label className="btn btn-outline-primary" htmlFor="btnradio3">
                                    Public
                                </label>
                            </div>
                        </div>
                        <div style={{display:"none"}} className="row fs-7 clsFormStandardTopMargin">
                            <span className="grey-text css-16ihq8s">Upload File</span>
                            <span className="clsFormMandatory"></span>
                            <div className="col-md-12 upload-file-box">
                                <div>
                                  <span className="grey-text css-16ihq8s">
                                    {CommonUtil.getMessageText(Constants.DRAG_DRAP_FILES_BROWSE,'Drag and Drop files here')}
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OR
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <button className="btn btn-default browse" onClick={() => {onClickDocBrowse(CommonUtil.EQUIP_DOCS)}}>
                                        {CommonUtil.getMessageText(Constants.BROWSE_FILE,'Browse Files')}
                                    </button>
                                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal" onClick={onCloseDocPopup}>
                            {CommonUtil.getMessageText(Constants.CLOSE,'Cancel')}
                        </button>
                        <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={()=>{
                            if(props?.specificForReservation === true){
                                saveUploadDocsForReservation();
                            }else{
                                saveUploadDocs();
                            }
                            }}>
                            {CommonUtil.getMessageText(Constants.SAVE,'Save')}
                        </button>
                    </div>
                </div>
            </Modal>
            {/* <Modal  show={showDocPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{"width": "600px","height":"500px"}}>
                    <div className="modal-header">
                        <h6 className="modal-title darkgrey-text fw-600">{"New Document"}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseDocPopup} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>Document Name </label>
                            <input ref={docRef} type="text"
                                   className="form-control clsStandardText  clsFormInputControl"
                                   placeholder="Document"  aria-label="First Name" value={docuName}
                                   onChange={(e)=>{setDocuName(e.currentTarget.value);CommonUtil.isMandatory(docRef);}} />
                        </div>
                        <div className="form-group">
                            <label>Visibility</label>
                            <ul className="nav nav-tabs nav-justified" role="tablist" style={{height:"40px"}}>
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab"  id={"lnkPrivate"}  role="tab" onClick={()=>onChangePrivate('lnkPrivate')}>
                                        <i className="fa fa-lock"></i> Private</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link " data-toggle="tab"  id={"lnkRenters"} role="tab" onClick={()=>onChangePrivate('lnkRenters')}>
                                        <i className="fa fa-users"></i> Renters</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" id={"lnkPublic"} role="tab" onClick={()=>onChangePrivate('lnkPublic')}>
                                        <i className="fa fa-globe"></i> Public</a>
                                </li>
                            </ul>
                        </div>
                        <label>Upload</label>
                        <div className="boxed" onClick={()=>{onClickDocBrowse(CommonUtil.EQUIP_DOCS)}}>
                            <div className="row">
                                <div className="col-sm-12 col-12 text-center">
                                    <form action="" className="dropzone">
                                        <img src="../../images/Documents-Upload.png" className="mb-3" />
                                            <p className="text-center"><strong>Browse Documents</strong></p>
                                                <div className="fallback">
                                                    <input name="file" type="file" style={{display:"none"}} multiple={true} />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal"
                                onClick={onCloseDocPopup}>Cancel</button>
                    </div>
                </div>

            </Modal> */}

            <div className="container">
                <section>
                    <div className="row">
                        <div className="col-sm-6 col-12 text-right mb-3 d-block d-sm-none">
                            <button id={"btnAddNewDoc"} data-toggle="modal" data-target=".bs-example-modal-center-new-document"
                                    type="button" className="btn btn-warning waves-effect waves-light" style={{display:props.addNewDocument === false?"none":""}} >
                                Add New Document
                            </button>
                        </div>
                        <div className="col-sm-6 col-12">
                            <button id={"btnAll"} type="button" className="btn btn-success waves-effect waves-light  mb-3"
                                    onClick={()=>onClickVisibility('btnAll','All')}>{CommonUtil.getMessageText(Constants.ALL,"All")}</button> &nbsp;
                            <button id={"btnPrivate"} type="button" className="btn btn-warning waves-effect waves-light  mb-3"
                                    onClick={()=>onClickVisibility('btnPrivate','Private')}>{CommonUtil.getMessageText(Constants.PRIVATE,"Private")}</button> &nbsp;
                            <button id={"btnRenter"} type="button" className="btn btn-warning waves-effect waves-light  mb-3"
                                    onClick={()=>onClickVisibility('btnRenter','Renter')}>{CommonUtil.getMessageText(Constants.RENTERS,"Renters")}</button> &nbsp;
                            <button id={"btnPublic"} type="button" className="btn btn-warning waves-effect waves-light  mb-3"
                                    onClick={()=>onClickVisibility('btnPublic','Public')}>{CommonUtil.getMessageText(Constants.PUBLIC,"Public")}</button> &nbsp;

                        </div>
                        <div className="col-sm-6 col-12 text-right  d-none d-sm-block">
                            <button data-toggle="modal" data-target=".bs-example-modal-center-new-document"
                                    type="submit" className="btn btn-warning waves-effect waves-light  mb-3" style={{display:props.addNewDocument == false?"none":""}}
                                    onClick={onClickDocumentPopup}>
                                Add New Document
                            </button>

                        </div>

                        <div className="col-sm-12 col-12">
                            <table className="history-table table-striped table-hover table-nostyle">
                                <tbody>
                                {
                                    equipDocs.map((item: any, idx: number) => {
                                        let list =  (
                                                        <tr className={"doc-row-height"}>
                                                            <td style={{cursor:"pointer"}} onClick={()=>onDocDownload(item.fullFilePath)}><img src="../../images/document.png" alt="" />{item.documentName}</td>
                                                            <td className="document-private">
                                                                <i className="fa-solid fa-lock "></i> {item.visibility}
                                                            </td>
                                                            <td className="history-icons">
                                                                <i style={{display:"none"}} className="fa fa-download icon-green" onClick={()=>onDocDownload(item.fullFilePath)}></i>
                                                                &nbsp;
                                                                <i className="fa fa-remove icon-red" style={{display:props.addNewDocument == false?"none":""}} onClick={()=>onDocRemove(item.attachementId)}></i>
                                                            </td>
                                                         </tr>
                                                    )
                                        return list

                                })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                <section className="bottom" style={{display:"none"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-right">
                                <button type="button" className="btn  btn-outline-dark"
                                        data-dismiss="modal">Discard Changes
                                </button>
                                <button type="submit"
                                        className="btn btn-success">Save
                                </button>


                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default EquipmentDocuments;
