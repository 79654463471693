import React, {useEffect, useRef, useState} from "react";
import AGGrid from "../../Components/AGGrid";
import {CommonUtil} from "../../utils/CommonUtil";
import {Constants} from "../Languages/Constants";
import EquipCellRenderer from "../Organization/EquipCellRenderer";
import useStateWithCallback from "use-state-with-callback";
import {IAGrid} from "../../Components/IInterfaces";
import DraggableComponent from "../../Components/DraggableComponent";
import {Modal} from "react-bootstrap";
import SingleSelectComp from "../../Components/SingleSelectComp";
import {NetworkManager} from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import Confirm from "../../Components/confirm";
import $ from 'jquery'
import {Events} from "../Languages/Events";

var insuranceRateId = "";
var operation = "";
var taxPer = 0;
var rate = 0;

const insurPolacyRateRender = (propData:any) => {
    let onClickLink = ()=>{
        if(propData.context){
            propData.context.onClickLink(propData.data);
        }
    }
    return(
        <div className={"cellRenderHeader"}>
            <a><span onClick={onClickLink} className={"cellRender"}>{propData.data.policy?propData.data.policy:""}</span></a>
        </div>
    )
}

const InsurancePlanRateTable = ()=>{

    const [insurColumnDefs] = useState([
        {field: 'policy', headerName: CommonUtil.getMessageText(Constants.INSUR_POLICY_ID,'Policy Id'), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter',cellRenderer:insurPolacyRateRender},
        {field: 'insurancePlanType', headerName: CommonUtil.getMessageText(Constants.INSURANCE_PLAN_TYPE,'Insurance Plan Type'), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter'},
        {field: 'limit', headerName: CommonUtil.getMessageText(Constants.LIMIT,'Limit'), resizable: true, width: 150,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'deductible', headerName: CommonUtil.getMessageText(Constants.DEDUCTABLE,'Deductible'), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'estimatedValueLower', headerName: CommonUtil.getMessageText(Constants.ESTIMATED_LOW_VALUE,'Estimated low value'), resizable: true, width: 150, sortable: true,  filter: 'agNumberColumnFilter'},
        {field: 'estimatedValueUpper', headerName: CommonUtil.getMessageText(Constants.ESTIMATED_HIGH_VALUE,'Estimated high value'), resizable: true, width: 150, sortable: true,  filter: 'agNumberColumnFilter'},
        {field: 'actualRatePerDay', headerName: CommonUtil.getMessageText(Constants.RATE_PER_DAY,'Rate per day'), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'taxAmount', headerName: CommonUtil.getMessageText(Constants.TAX_AMOUNT,'Tax Amount'), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'ratePerDay', headerName: CommonUtil.getMessageText(Constants.ACTUAL_RATE_PER_DAY,'Actual Rate Per Day'), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},

    ]);

    const [policyClass, setPolicyClass] = useState('mandatory');
    const [equipClass, setEquipClass] = useState('mandatory');

    const policyRef = useRef<HTMLInputElement>(null);
    const insurNameRef = useRef<HTMLInputElement>(null);
    const deductRef = useRef<HTMLInputElement>(null);
    const limitRef = useRef<HTMLInputElement>(null);
    const modelYearRef = useRef<HTMLInputElement>(null);
    const declareRef = useRef<HTMLInputElement>(null);
    const estLowValueRef = useRef<HTMLInputElement>(null);
    const estHighValueRef = useRef<HTMLInputElement>(null);
    const rateRef = useRef<HTMLInputElement>(null);

    const [selEquipType,setSelEquipType] = useState<any>(null);
    const [policyId,setPolicyId] = useState<any>(null);
    const [insuranceName,setInsuranceName] = useState("");
    const [deductable,setDeductable] = useState("");
    const [limit,setLimit] = useState("");
    const [description,setDescription] = useState("");
    const [polacies,setPolacies] = useState<any>([]);
    const [modalYear,setModalYear] = useState("");
    const [declareValue,setDeclareValue] = useState("");
    const [estLowValue,setEstLowValue] = useState("");
    const [estHighValue,setEstHighValue] = useState("");
    const [perdayRate,setPerDayRate] = useState("");
    const [taxPercent, setTaxPercent] = useState('');
    const [acrRatePerDay, setAcrRatePerDay] = useState('');


    const gridRef = useRef<IAGrid>(null);
    const [showInsurPlanPopup,setShowInsurPlanPopup] = useState(false);
    const [insurData, setInsurData] = useStateWithCallback<any>([], () => {
        setTimeout(()=>{
            if(gridRef.current){
                gridRef.current.columnFittoSize();
            }
        },1000);
    })

    let onClickAddInsuranceRateTable = ()=>{
        resetForm();
        insuranceRateId = "";
        operation = CommonUtil.CREATE_MODE;

        setShowInsurPlanPopup(true);
        setTimeout(()=>{
            $("#btnDelete").hide();
        },100);

    }
    let handleCloseInsurPlan = ()=>{
        setShowInsurPlanPopup(false);
    }
    let resetForm = ()=>{
        setPolicyId(null);
        setSelEquipType(null);
        setDeclareValue("");
        setLimit("");
        setDeductable("");
        setModalYear("");
        setPerDayRate("");
        setEstLowValue("");
        setEstHighValue("");
        setAcrRatePerDay("");
        setTaxPercent("");
    }
    let onClickLink = (data: any) => {
        resetForm();
        operation = CommonUtil.UPDATE_MODE;
        console.log(data);
        insuranceRateId = data.insuranceRateId;
        let qParams: string = "";
        getInsuranceRateDetails();
    }

    let getInsuranceRateDetails = ()=>{
        let polacyAPI: string = 'api/insurance/getRatePlan?insuranceRateId='+insuranceRateId;
        NetworkManager.sendJsonRequest(polacyAPI, '', '', 'GET', true, onGetPolicyRateDetails, onLoginError);
    }

    let onGetPolicyRateDetails = (dataObj:any)=>{
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                setModalYear(restData.modelYear);
                setDeductable(restData.deductible);
                setLimit(restData.limit);
                setModalYear(restData.modelYear);
                setPerDayRate(restData.actualRatePerDay?restData.actualRatePerDay:restData.ratePerDay);
                setAcrRatePerDay(restData.ratePerDay);
                setDeclareValue(restData.declaredValue);
                setEstHighValue(restData.estimatedValueUpper);
                setEstLowValue(restData.estimatedValueLower);
                setPolicyId({policyId:restData.policy,id:restData.policyId});
                setSelEquipType({value:restData.equipmentType,label:restData.equipmentType});
                setTaxPercent(restData.taxPercentage);
                taxPer = restData.taxPercentage;
                $("#btnDelete").show();
                setShowInsurPlanPopup(true);
            }
        }
    }
    let onChangeModalYear = (e: any)=>{
        setModalYear(e.target.value)
        CommonUtil.isMandatory(modelYearRef);
    }
    let onChangeDeclareValue = (e:any)=>{
        setDeclareValue(e.target.value)
        CommonUtil.isMandatory(declareRef);
    }

    let onChangeEstLowValue = (e:any)=>{
        setEstLowValue(e.target.value)
        CommonUtil.isMandatory(estLowValueRef);
    }
    let onChangeEstHighValue = (e:any)=>{
        setEstHighValue(e.target.value)
        CommonUtil.isMandatory(estHighValueRef);
    }
    let onChangeperdayRate = (e:any)=>{
        rate = e.target.value;
        setPerDayRate(e.target.value)
        CommonUtil.isMandatory(rateRef);
        calculateActualRate();
    }
    let onChangeLimit = (e:any)=>{
        setLimit(e.target.value)
        CommonUtil.isMandatory(limitRef);
    }
    let onChangeDesc = (e:any)=>{
        setDescription(e.target.value);
    }

    let handleDeleteInsurPlan = ()=>{
        Confirm.show(CommonUtil.getMessageText(Constants.CONFIRM,'Confirm'), CommonUtil.getMessageText(Constants.DO_YOU_WANT_REMOVE_RATE,'Do you want remove plan rate?'), [{label: 'Yes', callback: function () {
                let polacyDelAPI: string = 'api/insurance/deleteRatePlan?insuranceRateId='+insuranceRateId;
                NetworkManager.sendJsonRequest(polacyDelAPI, '', '', 'DELETE', true, onDeletePolicy, onLoginError);
            },
        },
            {label: 'No'},
        ])
    }

    let onDeletePolicy = (dataObj:any)=>{
        if (dataObj == "Rate Plan Deleted Successfully") {
            ToastManager.showToast("Policy Rate Deleted Successfully", 'Info');
            setShowInsurPlanPopup(false);
            getPlanRates();
        }else{
            if (dataObj) {
                var restData = CommonUtil.parseData(dataObj)
                if (restData) {
                    ToastManager.showToast("Policy Rate Deleted Successfully", 'Info');
                    setShowInsurPlanPopup(false);
                    getPlanRates();
                }
            }
        }
    }
    let handleSaveInsurace = ()=>{
        (policyId) ? setPolicyClass('select-box-valid') : setPolicyClass('select-box-invalid');

        //let isModalYear: boolean = CommonUtil.isMandatory(modelYearRef);
       // let isDeclareRate: boolean = CommonUtil.isMandatory(declareRef);
       // let isDeclareRate: boolean = CommonUtil.isMandatory(declareRef);
        let isEstLowValue: boolean = CommonUtil.isMandatory(estLowValueRef);
        let isEstHighValue: boolean = CommonUtil.isMandatory(estHighValueRef);
        let isRatePerDay: boolean = CommonUtil.isMandatory(rateRef);

        if(policyId  && isEstLowValue && isEstHighValue && isRatePerDay){
            let reqObj:any = {};
            reqObj.policyId = policyId.id;
           // reqObj.equipmentType = selEquipType.value;
            //reqObj.modelYear = modalYear;
            reqObj.ratePerDay = perdayRate;
            reqObj.estimatedValueLower = estLowValue;
            reqObj.estimatedValueUpper = estHighValue;
            reqObj.declaredValue = declareValue;

            let insurAPI: string = 'api/insurance/createRatePlan';
            let method:string = "POST";
            if(operation == CommonUtil.UPDATE_MODE){
                insurAPI= 'api/insurance/updateRatePlan?insuranceRateId='+insuranceRateId;
                method = "POST";
            }
            NetworkManager.sendJsonRequest(insurAPI, reqObj, '', method, true, onCreateRatePlan, onLoginError);
        }
    }
    let onCreateRatePlan = (dataObj:any)=>{
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                let strmessage:string = "Insurance Rate Card Created Successfully";
                if(insuranceRateId){
                    strmessage = "Insurance Rate Card Updated Successfully";
                    setShowInsurPlanPopup(false);
                }else{
                    insuranceRateId = restData.insuranceRateId;

                }
                ToastManager.showToast(strmessage, 'Info');
                setShowInsurPlanPopup(false);
                getPlanRates();
            }
        }
    }
    let handleCloseInsurance = ()=>{

    }

    useEffect(()=>{
        insuranceRateId = "";
        rate = 0;
        taxPer = 0;
        setInsurData([]);
        getInsurancePolacies();
        getPlanRates();
        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        return () => {
            document.removeEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        }
    },[]);

    let onSwitchCustomer = ()=>{
        Events.dispatchBreadCrumbEvent();
    }

    let getPlanRates = ()=>{
        setInsurData([]);
        let ratePlanAPI: string = 'api/insurance/allRatePlan';
        let obj: any = {};
        NetworkManager.sendJsonRequest(ratePlanAPI, '', '', 'GET', true, onGetAllRatePlans, onLoginError);
    }

    let onGetAllRatePlans = (dataObj:any)=>{
        //console.log(dataObj);
        let polacies = [];
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                if (Array.isArray(restData)) {
                    polacies = restData
                } else {
                    polacies.push(restData)
                }

                polacies.forEach((item:any,idx:number)=>{
                    if(!item.actualRatePerDay){
                        item.actualRatePerDay = item.ratePerDay;
                    }
                });

            }
        }
        const result = polacies.filter((item)=>{return item.insuranceStatus == CommonUtil.ACTIVE});
        setInsurData(polacies);
    }

    let getInsurancePolacies = () => {
        let equipAllAPI: string = 'api/insurance/allPolicy';
        let obj: any = {};
        NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGetAllPlacies, onLoginError);
    }
    let onGetAllPlacies = (dataObj:any)=>{
        //console.log(dataObj);
        let polacies = []
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                if (Array.isArray(restData)) {
                    polacies = restData
                } else {
                    polacies.push(restData)
                }

               // setPolacies(polacies);

            }
        }

        const result = polacies.filter((item)=>{return item.insuranceStatus == CommonUtil.ACTIVE});
        setPolacies(result);
    }

    let onChangePolicy = (data: any) => {
        setPolicyId(data);
        (data) ? setPolicyClass('select-box-valid') : setPolicyClass('select-box-invalid');
        //(data) ? setFloorClass('select-box-valid') : setFloorClass('select-box-invalid');
        getPolicyDetails(data);
    }

    let getPolicyDetails = (data:any)=>{
        setDeductable("");
        setLimit("");
        if(data){
            let polacyAPI: string = 'api/insurance/?policyId='+data.id;
            NetworkManager.sendJsonRequest(polacyAPI, '', '', 'GET', true, onGetPolicyDetails, onLoginError);
        }
    }

    let onGetPolicyDetails = (dataObj:any)=>{
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                setDeductable(restData.deductible);
                setLimit(restData.limit);
                taxPer = restData.taxPercentage;
                setTaxPercent(restData.taxPercentage?restData.taxPercentage:"");
                calculateActualRate();
            }
        }
    }

    let calculateActualRate = ()=>{
        setAcrRatePerDay("");
        if(taxPer && rate){
            let txAmount = Number(rate)*(Number(taxPer)/100);
            let total = Number(rate)+txAmount;
            if(total){
                setAcrRatePerDay(total.toFixed(2));
            }
        }
    }

    let onChangeEquipType = (data: any) => {
        setSelEquipType(data);
        (data) ? setEquipClass('select-box-valid') : setEquipClass('select-box-invalid');
        //(data) ? setFloorClass('select-box-valid') : setFloorClass('select-box-invalid');
    }

    let onDevAvailShowPopup = () => {
        setTimeout(function () {
            setPopupCenter('devAvailPopup', 'devAvailContentPopup')
        }, 100)
    }
    let setPopupCenter = (popId: string, cId: string) => {
        let windowHeight = window.innerHeight
        let windowWidth = window.innerWidth
        //windowHeight = windowHeight - 170

        if (cId) {
            let popupTop = windowHeight - 650;
            popupTop = popupTop / 2
            let popupLeft = windowWidth - 800;
            popupLeft = popupLeft / 2
            //if(popupTop<50){
            //popupTop = 10
            //}
            $('#' + popId).css({ margin: '10px', top: popupTop, left: popupLeft })
        }
    }


    let onLoginError = (err: any) => {
        console.log(err)
    }

    return (
        <>
            <Modal show={showInsurPlanPopup} centered dialogAs={DraggableComponent} id="devAvailPopup" onShow={onDevAvailShowPopup}>
                <div className="modal-content" id="devAvailContentPopup" style={{ "width": "650px", height: "550px" }}>
                    <div className="modal-header">
                        <h6 className="modal-title mt-0 fw-bold text-center black-text">{CommonUtil.getMessageText(Constants.RATE_TABLE, 'Insurance Rate Table')}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={handleCloseInsurPlan} style={{marginLeft: "0px"}}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{overflowY:"auto"}}>
                        <div className="row">
                            <div className="col-md-6 col-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.INSUR_POLICY_ID, 'Policy Id')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <SingleSelectComp dataSource={polacies} selectedValue={policyId} width={"100%"} value={"policyId"} label={"policyId"}
                                                          handleChange={onChangePolicy} className={policyClass} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.RATE_PER_DAY, 'Rate per day')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text"  className="form-control clsFormInputControl" ref={rateRef} disabled={operation === CommonUtil.UPDATE_MODE}
                                               placeholder={CommonUtil.getMessageText(Constants.RATE_PER_DAY, 'Rate per day')} value={perdayRate}
                                               onChange={onChangeperdayRate} maxLength={10} onKeyPress={CommonUtil.allowNumbersDecimal}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6 col-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.TAX_PERCENTAGE, 'Tax Percentage')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text"  className="form-control clsFormInputControl" disabled={true}
                                               placeholder={CommonUtil.getMessageText(Constants.TAX_PERCENTAGE, 'Tax Percentage')} value={taxPercent}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.ACTUAL_RATE_PER_DAY, 'Actual Rate Per Day')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text"  className="form-control clsFormInputControl" disabled={true}
                                               placeholder={CommonUtil.getMessageText(Constants.ACTUAL_RATE_PER_DAY, 'Actual Rate Per Day')} value={acrRatePerDay}

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.DEDUCTABLE, 'Deductible')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text"  className="form-control clsFormInputControl" disabled={true}
                                               placeholder={CommonUtil.getMessageText(Constants.DEDUCTABLE, 'Deductible')} value={deductable}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.LIMIT, 'Limit')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text"  className="form-control clsFormInputControl" disabled={true}
                                               placeholder={CommonUtil.getMessageText(Constants.LIMIT, 'Limit')} value={limit}

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-md-6 col-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.ESTIMATED_LOW_VALUE, 'Estimated low value')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text"  className="form-control clsFormInputControl" ref={estLowValueRef}
                                               placeholder={CommonUtil.getMessageText(Constants.ESTIMATED_LOW_VALUE, 'Estimated low value')} value={estLowValue}
                                               onChange={onChangeEstLowValue} maxLength={10} onKeyPress={CommonUtil.allowNumbersDecimal}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.ESTIMATED_HIGH_VALUE, 'Estimated high value')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text"  className="form-control clsFormInputControl" ref={estHighValueRef}
                                               placeholder={CommonUtil.getMessageText(Constants.ESTIMATED_HIGH_VALUE, 'Estimated high value')} value={estHighValue}
                                               onChange={onChangeEstHighValue} maxLength={10} onKeyPress={CommonUtil.allowNumbersDecimal}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row">

                        </div>
                    </div>


                    <div className="modal-footer" style={{padding:"5px"}}>
                        <button type="button" className="btn btn-outline-dark"
                                data-dismiss="modal" onClick={handleCloseInsurPlan}>
                            Close
                        </button>

                        <button type="button" id={"btnDelete"} className="btn btn-warning"
                                data-dismiss="modal" onClick={handleDeleteInsurPlan}>
                            Delete
                        </button>

                        <button type="submit" className="btn btn-success"
                                onClick={handleSaveInsurace}>
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
            <div className="container-fluid container-fluid-padding" style={{paddingTop: '1px'}}>
                <div className="row"></div>
                <div className="row fs-7 pt-3">
                    <div className="ag-theme-alpine gridHeight"
                         style={{height: 'calc(100vh - 120px)', width: '100%', paddingLeft: '8px', paddingRight: '5px'}} >
                        <AGGrid id="OrgInsurPlanRateGrid" cols={insurColumnDefs}
                                rows={insurData} pageSize={500} headerHeight={30} rowHeight={40}
                                ref={gridRef} addNew={true} tooltip={CommonUtil.getMessageText(Constants.CREATE_INSURANCE_RATE_TABLE,'Create Rate Table')} addNewRecord={onClickAddInsuranceRateTable}
                                method={{onClickLink}} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default InsurancePlanRateTable;
