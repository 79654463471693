import { useReleaseInspectorContext } from "../../../Components/ReleaseContext";
import React, { useEffect, useRef, useState } from "react";
import BusyLoader from "../../../Components/BusyLoader";
import { CommonUtil } from "../../../utils/CommonUtil";
import { NetworkManager } from "../../../Components/NetworkManager";
import $ from "jquery";
import { StatusCodeUtil } from "../../../utils/StatusCodeUtil";
import ToastManager from "../../../Components/ToastManager";
import { Button } from "react-bootstrap";
import AttachmentsViewer from "./AttachmentsViewer";

const NewEntryPicturesInsp = () => {
  const { state, dispatch } = useReleaseInspectorContext();
  const initState: any = {
    damageType: "",
  };
  const [formData, setFormData] = useState<any>(initState);
  const pictureInspection: any = state?.masterData?.picturesInspection
    ?.positionOfTrailer
    ? [...state?.masterData?.picturesInspection?.positionOfTrailer]
    : [];

  useEffect(() => {
    if (
      state?.picturesInspection?.positionOfTrailer?.filter(
        (items: any) => items?.images?.length === 0
      ).length &&
      state?.picturesInspection?.positionOfTrailer?.filter(
        (items: any) => items.description === ""
      )?.length
    ) {
      dispatch({
        type: "picturesInspection",
        payload: {
          isCompleted: false,
        },
      });
    } else {
      dispatch({
        type: "picturesInspection",
        payload: {
          isCompleted: false,
        },
      });
    }
  }, [state?.picturesInspection?.positionOfTrailer]);

  const onSubmitHandler = () => {
    if (
      +state?.picturesInspection?.positionOfTrailer?.filter(
        (items: any) => items.description
      )?.length === +state?.picturesInspection?.positionOfTrailer?.length &&
      +state?.picturesInspection?.positionOfTrailer?.filter(
        (items: any) => items?.images
      ).length === +state?.picturesInspection?.positionOfTrailer?.length
    ) {
      dispatch({ type: "picturesInspection", payload: { isCompleted: true } });
      ToastManager.showToast(
        "Successfully saved picture inspection",
        "Success"
      );
    } else {
      ToastManager.showToast(
        "Please upload pictures and add description",
        "Error"
      );
    }
  };

  return (
    <div className="section">
      <div className="row picture-details">
        {pictureInspection?.map((item: any, index: any) => {
          return <NewEntryPicturesInspItem key={item.taskId} data={item} />;
        })}
      </div>
      <div className="d-flex justify-content-end mb-2">
        <Button className="btn" type="button" onClick={() => onSubmitHandler()}>
          Save
        </Button>
      </div>
    </div>
  );
};

const NewEntryPicturesInspItem = ({ data }: any) => {
  const { state, dispatch } = useReleaseInspectorContext();
  const fileref = useRef<HTMLInputElement>(null);
  let qPath = CommonUtil.getInterchangeDocumentId();
  let fileType: string = "";
  let imagePath: string = "";
  let images: any = [];
  const onSelectFiles = (e: any) => {
    if (e && e.target && e.target.files[0]) {
      var file = e.target.files[0];
      let fileName = e.target.files[0].name;
      BusyLoader.showLoader();
      let imagePath =
        `visualInspection/attachments/docId_${qPath}_${state?.eventId}${data?.taskId}${fileName}` +
        "-" +
        new Date().getTime();

      let qParam: string = "objectPath=" + imagePath;
      NetworkManager.sendJsonRequest(
        "api/session/uploadUrl",
        "",
        qParam,
        "GET",
        false,
        (dataObj: any) => {
          if (dataObj) {
            if (dataObj.statusCode) {
              BusyLoader.hideLoader();
              var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode);
              ToastManager.showToast(errMsg, "Error");
            } else {
              var strUploadPath = dataObj;
              const formData = new FormData();
              var myHeaders = new Headers();
              var requestOptions: any = {
                method: "PUT",
                headers: myHeaders,
                body: file,
                redirect: "follow",
              };
              fetch(strUploadPath, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                  BusyLoader.hideLoader();
                  dispatch({
                    type: "picturesInspection",
                    payload: {
                      taskId: state?.taskID,
                      position: state?.position,
                      taskFullName: state?.position,
                      images: {
                        fullFilePath: imagePath,
                      },
                    },
                  });

                  if (fileref.current) {
                    fileref.current.value = "";
                    fileref.current.type = "text";
                    fileref.current.type = "file";
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                });
            }
          }
        },
        onLoginError
      );
    }
  };

  const updateDiscription = (value: string, position: string, id: any) => {
    dispatch({
      type: "picturesInspection",
      payload: {
        taskId: id,
        position: position,
        taskFullName: state?.position,
        description: value,
      },
    });
    dispatch({
      type: "picturesInspection",
      payload: {
        isCompleted: false,
      },
    });
  };

  const updateDamageType = (value: string, position: string, id: any) => {
    dispatch({
      type: "picturesInspection",
      payload: {
        taskId: id,
        position: position,
        taskFullName: state?.position,
        damageType: value,
      },
    });
    dispatch({
      type: "picturesInspection",
      payload: {
        isCompleted: false,
      },
    });
  };
  const onLoginError = (err: any) => {
    console.log("Something went wrong");
  };
  const onAddPictures = (cId: string, fType: string, position: string) => {
    dispatch({ type: "setTaskId", payload: cId });
    dispatch({ type: "setPosition", payload: position });
    fileType = fType;
    if (fileref.current) {
      // fileref.current.onclick();
      $("#inputGroupFile4").click();
      fileref.current.value = "";
    }
  };

  return (
    <div className="col-lg-6 mb-1">
      <div className="card">
        <div className="card-body">
          <div className="upload-image">
            <div className="title d-inline-flex">
              <span>{data?.position}</span>
              <div
                style={{
                  fontSize: "0.7rem",
                  textAlign: "right",
                  position: "absolute",
                  right: "30px",
                }}
              >
                Attach File
              </div>
            </div>
            <div
              className="upload d-flex"
              onClick={() =>
                onAddPictures(data?.taskId, "Releases", data?.position)
              }
            >
              <label className="upload-area">
                {/* <input type="file" /> */}
                <span className="upload-button">
                  <i className="fa-solid fa-plus"></i>
                </span>
              </label>
            </div>
          </div>
          <div className="col-lg-12 mt-2">
            <label>Add Damage Type</label>
            <div className="gap-2">
              <input
                type="radio"
                name={data?.position}
                className="m-2"
                id={data?.position + 1}
                onChange={(e) =>
                  updateDamageType("B", data?.position, data?.taskId)
                }
                checked={
                  state?.picturesInspection?.positionOfTrailer?.filter(
                    (items: any) => items.position === data.position
                  )[0]?.damageType === "B"
                    ? true
                    : false
                }
                required
              />
              <label className="pr-3" htmlFor={data?.position + 1}>
                "B" Bruise
              </label>
              <input
                type="radio"
                name={data?.position}
                className="m-2"
                id={data?.position + 2}
                onChange={(e) =>
                  updateDamageType("C", data?.position, data?.taskId)
                }
                checked={
                  state?.picturesInspection?.positionOfTrailer?.filter(
                    (items: any) => items.position === data.position
                  )[0]?.damageType === "C"
                    ? true
                    : false
                }
                required
              />
              <label className="pr-3" htmlFor={data?.position + 2}>
                "C" Cut
              </label>
              <input
                type="radio"
                name={data?.position}
                className="m-2"
                id={data?.position + 3}
                onChange={(e) =>
                  updateDamageType("H", data?.position, data?.taskId)
                }
                checked={
                  state?.picturesInspection?.positionOfTrailer?.filter(
                    (items: any) => items.position === data.position
                  )[0]?.damageType === "H"
                    ? true
                    : false
                }
                required
              />
              <label className="p-1" htmlFor={data?.position + 3}>
                "H" Hole
              </label>
            </div>
          </div>
          <div className="d-flex flex-row">
            {state?.picturesInspection?.positionOfTrailer?.filter(
              (item: any) => item.position === data?.position
            )[0]?.images?.length ? (
              <div
                style={{
                  textDecoration: "underline",
                  color: "blue",
                  padding: "4px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  borderRadius: "3px",
                  marginRight: "5px",
                  height: "25px",
                  position: "relative",
                  top: "25px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() =>
                  AttachmentsViewer.show({
                    attachmentUploads:
                      state?.picturesInspection?.positionOfTrailer?.filter(
                        (item: any) => item.position === data?.position
                      )[0]?.images,
                    attachmentDamageDescription:
                      state?.picturesInspection?.positionOfTrailer?.filter(
                        (item: any) => item.position === data?.position
                      )[0].description,
                    isDeleteButton: true,
                    resetData: "picturesInspection",
                  })
                }
              >
                {
                  state?.picturesInspection?.positionOfTrailer?.filter(
                    (item: any) => item.position === data?.position
                  )[0]?.images?.length
                }
              </div>
            ) : null}

            <div className="attach">
              <span className="attachments">Attachments</span>
              {state?.picturesInspection?.positionOfTrailer?.filter(
                (item: any) => item.position === data?.position
              )[0]?.images?.length ? (
                <textarea
                  className="w-100 ml-3 mt-2"
                  placeholder="Add Description"
                  onChange={(e) =>
                    updateDiscription(
                      e.target.value,
                      data?.position,
                      data?.taskId
                    )
                  }
                  value={
                    state?.picturesInspection?.positionOfTrailer?.filter(
                      (item: any) => item.position === data?.position
                    )[0].description
                  }
                ></textarea>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <input
        type="file"
        ref={fileref}
        className="custom-file-doc-input"
        multiple={false}
        id="inputGroupFile4"
        accept="image/*;pdf/*"
        style={{ display: "none" }}
        onChange={(e) => {
          onSelectFiles(e);
        }}
      />
    </div>
  );
};

export default NewEntryPicturesInsp;
