import React from "react";
import {CommonUtil} from "../../utils/CommonUtil";

const DeleteNetwork = (props: any) => {
    const onClickBlock = () => {
        if (props.context) {
            props.context.deleteDotNetwork(props.data);
        }
    }
    return (
        <div className={"cellRenderHeader"}>
            <button className="btn btn-success in-active-status" onClick={onClickBlock}>{"Remove"}</button>
        </div>

    )
}

export default DeleteNetwork;