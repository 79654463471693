
import React, {useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle, Ref} from 'react'
import {AgGridReact} from 'ag-grid-react'
import DataTable from 'react-data-table-component'
import AssignedOrganizationsToolbar from './AssignedOrganizationsToolbar'
import { IAGrid,IGridToolBox } from '../../Components/IInterfaces'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

var gridAPI: any = {}
var gridColumnApi: any = {}

var strAgGrid = ''
var gridName = 'AgGrid';

const AssignedOrganizationsGrid = forwardRef((props: any, ref: Ref<IAGrid>) => {

    const gridRef = useRef<IGridToolBox>(null)
    const [gAPI, setGridAPI] = React.useState<any>({})

    const defColValues = useMemo(
        () => ({}), [],
    )

    const getRowStyle = (params: any) => {
        if (params.node.rowIndex % 2 === 0) {
            return {background: 'white'}
        }
    }

    useEffect(() => {
        //console.log(props);
    }, [])

    useImperativeHandle(ref, () => ({SayHi, columnFittoSize, getSelectedRows, getGridAPI}))

    /*const onGridReady = (params: any) => {
        //console.log(params);
        // params = params;
        strAgGrid = props.id + gridName
        gridAPI = params.api
        gridColumnApi = params.columnApi
        gridAPI.sizeColumnsToFit()
        gridColumnApi.autoSizeColumns()
        setGridAPI(params.api)
    }*/

    const onGridReady = (params:any)=>{
        //console.log(params);
        // params = params;
        strAgGrid = props.id+strAgGrid;
        strAgGrid = props.id+gridName;
        gridAPI = params.api;
        gridColumnApi = params.columnApi;
        setGridAPI(params.api);

        
    }

    function SayHi() {
        //console.log(gridAPI);
        setGridAPI(gridAPI)
        // setGridAPI(params.api);
    }

    const helloTest = () => {
        //  console.log(gridAPI);
    }

    const onFilterChange = (params: any) => {
        // console.log(params);
        if (gridRef.current) {
            gridRef.current.passColumnFilter(params)
        }
    }
    const getSelectedRows = (): any => {
        if (gridAPI) {
            const selectedData = gridAPI.getSelectedRows()
            return selectedData
        }
        return null
    }

    const getGridAPI = (): any => {
        return gridAPI
    }

    const oViewChanged = (params: any) => {
        // console.log(params);
    }

    const onColumnResized = (params: any) => {
        // console.log(params.columnApi.columnModel.getColumnDefs());
        updateColumnDef(params.columnApi.columnModel.getColumnDefs())
    }
    const onColumnSort = (params: any) => {
        updateColumnDef(params.columnApi.columnModel.getColumnDefs())
    }

    const onColumnVisible = (params: any) => {
        updateColumnDef(params.columnApi.columnModel.getColumnDefs())
    }
    const onColumnMove = (params: any) => {
        updateColumnDef(params.columnApi.columnModel.getColumnDefs())
    }

    const columnFittoSize = () => {
        //console.log(gridAPI);
        if (gridAPI && gridAPI.sizeColumnsToFit) {
            gridAPI.sizeColumnsToFit()
            gridColumnApi.autoSizeColumns()
        }
    }

    let updateColumnDef = (colDef: any) => {
        localStorage[strAgGrid] = JSON.stringify(colDef)
    }

    let exportCSV = () => {
        if (gridAPI) {
            gridAPI.exportDataAsCsv()
        }
    }

    let onGridClear = () => {
        localStorage[strAgGrid] = null
        if (gridAPI) {
            //console.log(props.cols);
            props.cols.forEach((col: any, idx: number) => {
                if (!col.sort) {
                    col.sort = null
                }
                if (!col.hide) {
                    col.hide = null
                }
            })
            gridAPI.setColumnDefs(props.cols)
            var columnDefs = gridAPI.getColumnDefs()
            updateColumnDef(columnDefs)
            // gridAPI.suppressFocusAfterRefresh = true;
        }
    }


    let addRecord = () => {
        props.addNewRecord()
    }


    let selectChange = (e:any) =>{
        props.onSelectChange(e)
    }


    return (
        <React.Fragment>
            <div className={'gridHeight'}>
                <AssignedOrganizationsToolbar ref={gridRef} rowData={props.rows} pageSize={props.pageSize}
                    columnDefs={props.cols} api={gAPI} onClear={onGridClear} exportCSV={exportCSV}
                    onClickAdd={addRecord} addNew={props.addNew ? true : false} tip={props.tooltip} onhandleSelectChange = {selectChange} name={props.name} dropdown = {props.dropdown} placeholder = {props.placeholder}
                />
                <AgGridReact className="mt-2" animateRows={true} enableFillHandle={true} onFilterChanged={onFilterChange}
                    defaultColDef={defColValues} context={props.method} rowData={props.rows}
                    columnDefs={props.cols} getRowStyle={props.getRowStyle ? props.getRowStyle : getRowStyle}
                    rowHeight={props.rowHeight} headerHeight={props.headerHeight} enableCharts={false}
                    pagination={false} paginationPageSize={props.pageSize} suppressMovableColumns={false}
                    onGridReady={onGridReady} onViewportChanged={oViewChanged} onColumnResized={onColumnResized}
                    onSortChanged={onColumnSort} onColumnVisible={onColumnVisible} onColumnMoved={onColumnMove}
                    rowSelection={props.rowSelection ? props.rowSelection : 'single'}
                    isRowSelectable={props.isRowSelectable} suppressRowClickSelection={props.suppressRowClickSelection ? true : false}
                    onFirstDataRendered={props.onFirstDataRendered} onRowSelected={props.onRowSelected?props.onRowSelected: null}
                />
            </div>
            
        </React.Fragment>
    )
})

export default AssignedOrganizationsGrid
