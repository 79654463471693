import React from "react";

const CreateEquipAvail = ()=>{
    return(
            <>
                <div className="page-content-wrapper">
                    <div className="container">
                        <br />
                        <section>
                            <h5 style={{textAlign:"center"}}>New Repositioning</h5>
                            <p style={{textAlign: "center"}}>Availabilities will be automatically reallocated if a reservation is made on a subset of the dates</p>
                            <div className="row">
                                <div className="col-sm-6 col-6">
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label">Starts at:</label>
                                        <div className="col-sm-12">
                                            <div className="date-i">
                                                <input type="date" className="form-control" id="date" required placeholder="5" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6   col-6">
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label">Ends at:</label>
                                        <div className="col-sm-12">
                                            <div className="date-i">
                                                <input type="date" className="form-control " id="date" required placeholder="5"/>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6   col-6">
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label">Number of available equipment for
                                            this period</label>
                                        <div className="col-sm-12">
                                            <input type="text" className="form-control" required placeholder="5"/>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-sm-6   col-6">
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label">Number of days allowed for
                                            repositioning</label>
                                        <div className="col-sm-12">
                                            <input type="text" className="form-control" required placeholder="3"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-12">
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label">Load Type</label>
                                        <div className="col-sm-12">
                                            <select className="custom-select" style={{width:"100%"}}>
                                                <option selected>Load Out</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <h6 className="mt-4">Location</h6>
                            <div className="row">
                                <div className="col-sm-9 col-12">
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label">Departure Parking Lot</label>
                                        <div className="col-sm-12 col-12">
                                            <select className="custom-select">
                                                <option selected>2637 Airport Road, San Jose, California, 26372</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-6"></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-9 col-12">
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label">Arrival Parking Lot</label>
                                        <div className="col-sm-12 ">
                                            <select className="custom-select">
                                                <option selected>2637 Nice Road, Alpharetta, Virginia, 26372</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-3 col-6"></div>
                            </div>
                            <h6 className="mt-4">Pricing</h6>
                            <div className="row">
                                <div className="col-sm-12 col-12">
                                    <div className="form-group row">
                                        <div className="col-sm-12 col-12 checkbox">
                                            <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                            <label htmlFor="vehicle1" style={{display:"inline"}}>
                                                Allow renter to make an offer for this availability
                                            </label>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-12">
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label">Currency</label>
                                        <div className="col-sm-12 col-12">
                                            <select className="custom-select" style={{width:"100%"}}>
                                                <option selected>USD</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-sm-6 col-12 col-md-6 ">
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-12 col-md-12 col-form-label">Paid Price to
                                            Carrier for Repositioning:</label>
                                        <div className="col-sm-6 col-12 col-md-6">
                                            <input type="text" placeholder="USD 2168.00" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-12 col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-12 col-md-12 col-form-label">Additional Price
                                            per Excess Days</label>

                                        <div className="col-sm-6 col-12 col-md-12">

                                            <input type="text" placeholder="USD 2168.00" /> / DAY
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-12 ">
                                        <div className="new-repositioning">
                                            <div className="form-group row">
                                                <div id="repositioning" className="col-sm-1 col-2">

                                                    <i className="fa fa-tag" style={{fontSize:"24px"}}></i>


                                                </div>
                                                <div className=" col-sm-11 col-10">
                                                    <p>These are the rates that will apply for this rental. vHub will
                                                        charge a fee in addition to these rates. Please refer to our
                                                        Payment Terms for full details.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-sm-12 col-12">
                                        <div className="form-group row">

                                            <div className="col-sm-12 col-12 checkbox">
                                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                <label  style={{display:"inline"}}> I have filled my tax exemptions OR I don’t have tax exemptions
                                                </label>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </>
    );
}

export default CreateEquipAvail;