import React, {useEffect, useRef, useState} from "react";
import AGGrid from "../../Components/AGGrid";
import useStateWithCallback from "use-state-with-callback";
import EquipDamageRenderer from "../Organization/EquipDamageRenderer";
import {IAGrid} from "../../Components/IInterfaces";
import {CommonUtil} from "../../utils/CommonUtil";
import {NetworkManager} from "../../Components/NetworkManager";
import DetectDevice from "../../utils/DetectDevice";
import MGrid from "../../Components/MGrid";
import {Constants} from "../Languages/Constants";

const EquipmentHistory = (props:any)=>{
    const { isMobile } = DetectDevice();
    const [equipHistory,setEquipHistory] = useStateWithCallback<any>([],()=>{});

    const [eqpColumnDefs] = useState([
        {field: 'changedField', headerName: CommonUtil.getMessageText(Constants.FIELD,"Field"), resizable: true, width: 250,flex: 1,sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'fromValue', headerName: CommonUtil.getMessageText(Constants.FROM_VALUE,"From Value"), resizable: true, width: 150, flex: 1,sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'toValue',headerName: CommonUtil.getMessageText(Constants.TO_VALUE,"To Value"),resizable: true,width: 150,flex: 1,sortable: true,filter: 'agNumberColumnFilter'},
        {field: 'createdBy', headerName: CommonUtil.getMessageText(Constants.PERFOMED_BY,"Performed By"), resizable: true, width: 150, flex: 1,sortable: true, filter: 'agTextColumnFilter'},
        {field: 'creationDateString', headerName: CommonUtil.getMessageText(Constants.PERFOMED_DATE,"Performed Date"), tooltipField: 'creationDate',flex: 1,resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter',tooltip: (params:any) => 'Address: ' + params.value},
    ]);

    const gridRef = useRef<IAGrid>(null);

    useEffect(()=>{
        //console.log(props);
        if(CommonUtil.SELECT_EQUIP_ID){
            let historyAPI:string = "api/equipment/"+CommonUtil.SELECT_EQUIP_ID+"/history";
           // let obj:any = {};
            NetworkManager.sendJsonRequest(historyAPI,"","","GET",true,onGetEquipmentHistory,onLoginError);
        }
    },[])

    let onGetEquipmentHistory = (dataObj:any)=>{
        console.log(dataObj);
        let hisArray = [];
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                if(Array.isArray(restData)){
                    hisArray = restData;
                }else{
                    hisArray.push(restData);
                }
            }
        }
        setEquipHistory(hisArray);
    }

    let onLoginError = (dataObj:any)=>{
        console.log(dataObj);
    }

    return(
        <>
            <div className="container">
               <section>
                   <div className="row" style={isMobile ? {} : {paddingTop:"10px"}}>
                       {
                        isMobile ? (
                            <MGrid id="OrgEquipHistoryGrid" cols={eqpColumnDefs} rows={equipHistory} pageSize={10}
                                   headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                   ref={gridRef} addNew={false} />
                        ) : (
                            <div className="ag-theme-alpine gridHeight"
                            style={{height: 'calc(100vh - 250px)', width: '100%', paddingLeft: '0px', paddingRight: '0px'}} >
                           <AGGrid id="OrgEquipHistoryGrid" cols={eqpColumnDefs} rows={equipHistory} pageSize={500}
                                   headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                   ref={gridRef} addNew={false} />
                       </div>
                        )
                       }

                   </div>
               </section>
            </div>
        </>
    );
}

export default EquipmentHistory;
