import React from 'react'
import { useState,useEffect } from 'react';
import { NetworkManager } from '../../Components/NetworkManager';

const ImageLoader = ({path,findImg,index,notfoundImage=null}:any) => {
    const [src,setSrc] = useState(path);
    const [requestCounter,setRequestCounter] = useState(0)

    const successImage = (res: any) => { 
      console.log("here")
      setSrc(res);
    }
    const errorCallback = (err: any) => { 
      console.log("our")
      setSrc("");
    }
    const loadImage = ()=>{
      if(path){
        var qParam: string = "objectPath=" + path;
        NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', true, successImage,errorCallback );   
      }
    }
    useEffect(()=>{
      loadImage();  
    },[path]);
    if(notfoundImage && !(!!path && !!src )){
      return <><div>No Image</div><i className="fa-thin fa-notdef" style={{fontSize:"120px"}}></i></>
    }
  
    return <img src={src} alt={'not found'} onClick={()=>{findImg(index)}} className="img-fluid" style={{maxHeight:notfoundImage ? "170px" : "300px"}} onError={(e:any)=>{
      // e.target.src = src;
      if(requestCounter < 1){
        loadImage();
        setRequestCounter(requestCounter+1);
      }
    }}/>
}

export default ImageLoader