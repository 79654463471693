import React, {Component} from 'react';
import Loading from 'react-loading';

var self
export default class BusyLoader extends Component {

    componentWillMount() {
        this.setState({
            busyIcon: false,
            color: "red",
            width: "50px",
            height: "50px"
        });
        self = this;
    }

    static showLoader() {
        try {
            self.setState({
                busyIcon: true
            });
        } catch (e) {
            console.log(e.massage);
        }
    }

    static hideLoader() {
        self.setState({
            busyIcon: false
        });
    }

    render() {
        var absStyle = {
            position: "absolute",
            top: "50%",
            // left: "50%",
            marginTop: "-50px",
            // marginLeft: "-100px",
            zIndex: 2017
        }
        return (<div className="overlay" style={{
            background: "rgb(226 233 233 / 40%)",
            justifyContent:'center',
            // position: 'absolute',
            width: "100%",
            // height: (document.body.scrollHeight - 10),
            height: (window.innerHeight - 10),
            zIndex: 99999,
            display: this.state.busyIcon ? "flex" : "none"
        }}>
            <div style={absStyle}>
                <Loading type='spin' color={this.state.color} width={this.state.width} height={this.state.height}/>
            </div>
        </div>);
    }
}