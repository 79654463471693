import React, {useEffect, useRef, useState} from "react";
import {CommonUtil} from "../../utils/CommonUtil";
import EquipmentAvail from "./EquipmentAvail";
import {NetworkManager} from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";
import EquipmentPrices from "./EquipmentPrices";
import EquipmentDocuments from "./EquipmentDocuments";
import EquipmentDamages from "./EquipmentDamages";

import $ from "jquery";
import BusyLoader from "../../Components/BusyLoader";
import {useNavigate} from "react-router-dom";
import EquipmentReservation from "./EquipmentReservation";
import EquipmentHistory from "./EquipmentHistory";
import ReservationDetails from "./ReservationDetails";
import ReserveActivation from "./ReserveActivation";
import ReserveDeActivation from "./ReserveDeActivation";
import ReserveClaims from "./ReserveClaims";
import DraggableComponent from "../../Components/DraggableComponent";
import SingleSelectComp from "../../Components/SingleSelectComp";
import {Modal} from "react-bootstrap";
import ReservationHistory from "./ReservationHistory";
import {Constants} from "../Languages/Constants";
import PhoneInput from "react-phone-input-2";
import StarRating from "../../Components/Rating";
import { reAdjust, leftClickScroll, rightClickScroll} from "../../utils/ScrollHandler";
import DetectDevice from "../../utils/DetectDevice";
import Confirm from "../../Components/confirm";
import EquipGalleryModal from "./EquipGalleryModal";
import AGGrid from "../../Components/AGGrid";
import EquipAvailTypeRenderer from "../Organization/EquipAvailTypeRenderer";
import EquipCellRenderer from "../Organization/EquipCellRenderer";
import {IAGrid} from "../../Components/IInterfaces";
import ReservationView from "./ReservationView";
import RegistrationHeader from "../Login/RegistrationHeader";
import EquipmentHeader from "../Login/EquipmentHeader";

var equiImages:any = [];
var attchIdx:number = 0;
var attachmentList: any = [];
var policyId = "";
var reservationStatus:string = "";
var assignEquipId = "";
const LoadBoardReservationView = ()=>{
    const {isMobile} = DetectDevice();
    const [isLogin,setIsLogin] = useState(false);
    const [page,setPage] = useState(0);
    //const[equipImagesPath,setEquipmentImagesPath] = useState([]);

    useEffect(()=>{
        if(CommonUtil.getSelectedOrgId()){
            setIsLogin(true);
        }
    },[]);

    let onLoginSuccess = ()=>{
        console.log("login success");
        localStorage.setItem("reservationview","true");
        setIsLogin(true);
        setPage(Math.random()*1000000);
    }

    return(
        <>
            {
                CommonUtil.getSelectedOrgId()?
                    <EquipmentHeader isLogin={"false"} isSearchDB={"false"} isSignUp={"false"} isLogout={"true"} />:
                    <EquipmentHeader isPLogin={"true"} isSearchDB={"false"} isSignUp={"false"} isLoginPopup={"true"} onLoginSuccess={onLoginSuccess} />
            }

            <div style={{paddingTop:"40px"}}>
                <ReservationView isLogin={isLogin} />
            </div>

        </>
    )
}

export default LoadBoardReservationView;
