import React, { useRef } from "react";
import CarouselComponent, { InnerImage } from "../CarouselComponent";
import "../Interchange.css";
import { useState, useEffect } from "react";
import { NetworkManager } from "../../../Components/NetworkManager";
import { CommonUtil } from "../../../utils/CommonUtil";
import ReactImageGallery from "../ReactImageGallery";
import ImageLoader from "../ImageLoader";
import { Constants } from "../../Languages/Constants";
import InspectionGrid from "../../InspectionGrid/InspectionGrid";
import NewReleaseCellRender from "../EntryInspectionDetails/NewReleaseCellRender";
import AttachmentsCellRenderer from "./AttachmentsCellRenderer";

const UpdateCommentsAndSignatureInsp = () => {
  const [ctpatDetails, setCtpatDetails] = useState<any>([]);
  const [userColumns, setUserColumns] = useState<any>([]);
  const [releases, setReleases] = useState<any>([]);
  const [selectedEvents, setSelectedEvents] = useState<any>("");

  const getCTPATInspection = () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var orgUrl = `api/webInspectionDetails/getCTPATInspection?docId=${qparam}`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onCTPATInspection,
      onLoginError
    );
  };

  useEffect(() => {
    getCTPATInspection();
  }, []);

  let onCTPATInspection = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      console.log(restData);

      //   setVisualDetails(restData[0].taskDetailsAttachment);

      let rowData: any = [];

      for (let i = 0; i < restData.length; i++) {
        const inspectionData = restData[i];
        const groupName: string = inspectionData.eventCode;
        const entryStartDate: string = inspectionData.creationDate;
        const exitStartDate: string = inspectionData.exitStartDate;
        let obj: any = {};
        obj["groupName"] = groupName;
        obj["date"] = entryStartDate;
        obj["sign1"] = inspectionData.signature;
        obj["sign2"] = inspectionData.driverSignature;
        obj[`comment`] = inspectionData.comments;

        rowData.push(obj);
      }

      setCtpatDetails(rowData);
    }
  };

  let onLoginError = (err: any) => {
    console.log(err);
  };

  let Capitalize = (str: any) => {
    str = String(str);
    let strText = str.charAt(0).toUpperCase() + str.slice(1);

    return strText.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
  };

  const updateSelction = (id: any, checked: boolean) => {
    let checkboxCheck: any = [];
    try {
      checkboxCheck = selectedEvents ? selectedEvents.split(",") : [];
      if (checkboxCheck.indexOf(String(id)) != -1) {
        if (!checked) {
          checkboxCheck.splice(checkboxCheck.indexOf(String(id)), 1);
        }
      } else {
        if (checked) {
          checkboxCheck.push(String(id));
        }
      }
    } catch (e) {
      checkboxCheck = [];
    }

    setSelectedEvents(checkboxCheck.join(","));
  };

  //   console.log(visualDetails);
  //   console.log(selectedEvents);
  let checkboxCheck: any = [];
  try {
    checkboxCheck = selectedEvents.split(",");
  } catch (e) {
    checkboxCheck = [];
  }
  console.log(ctpatDetails, "CTPAT");

  return (
    <div className="containData">
      <div className="row">
        {releases?.map((ele: any) => {
          return (
            <div className="col-2">
              <label className="form-check-label">
                {String(ele.eventCode)}{" "}
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={checkboxCheck.indexOf(String(ele.eventId)) != -1}
                  onClick={(e: any) => {
                    updateSelction(ele.eventId, e.target.checked);
                  }}
                />
              </label>
            </div>
          );
        })}
      </div>

      <div className="row mt-2">
        <div
          className="ag-theme-alpine ag-theme-customeTheme gridHeight pt-3"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "row",
            paddingLeft: "8px",
            paddingRight: "5px",
          }}
        >
          {ctpatDetails?.map((ele: any) => (
            <div style={{ width: "300px" }}>
              <div className="mb-2">
                <b>{ele.groupName}</b>
                {` Date: ${ele.date}`}
              </div>
              <textarea style={{ width: "100%" }} disabled className="mb-2">
                {ele.comment}
              </textarea>
              <div>
                <b>Signature</b>
              </div>
              <div style={{minHeight:"250px"}}>
              {ele.sign1 && (
                <div className="mb-2" >
                  <ImageLoader path={ele.sign1 || ""} />
                </div>
              )}
              </div>
               <div className="mt-2 mb-2">
                <b>Driver Signature</b>
              </div>
              <div style={{minHeight:"250px"}}>
              {ele.sign2 && (
                <div className="mb-2" >
                  <ImageLoader path={ele.sign2 || ""} />
                </div>
              )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UpdateCommentsAndSignatureInsp;
