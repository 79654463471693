import "./Interchange.css";
import { useState, useEffect } from "react";
import EntryCargoInspection from "./EntryInspectionDetails/EntryCargoInspection";
import { CommonUtil } from "../../utils/CommonUtil";
import { NetworkManager } from "../../Components/NetworkManager";
import AddNewReleaseDetailsModal from "./AddNewReleaseDetailsModal";
import {
  ReleaseInspectionProvider,
  useReleaseInspectorContext,
} from "../../Components/ReleaseContext";
import UpdateVisualInspection from "./NewEntries/UpdateVisualInspection";
import AddNewDetailsModal from "./NewEntries/AddNewDetailsModal";
import UpdateLightInspection from "./NewEntries/UpdateLightInspection";
import UpdateCTPATInspection from "./NewEntries/UpdateCTPATInspection";
import ToastManager from "../../Components/ToastManager";
import UpdatePictureInspection from "./NewEntries/UpdatePicturesInspection";
import UpdateTyreInspection from "./NewEntries/UpdateTyreInspection";
import AttachmentsViewer from "./NewEntries/AttachmentsViewer";
import UpdateCommentsAndSignatureInsp from "./NewEntries/UpdateCommentsAndSignatureInsp";
import UpdateRefeerInspection from "./NewEntries/UpdateRefeerInspection";

let tabIdx = 0;
const InterchangeEntryInspectionReleases = () => {
  const [visualStatus, setVisualStatus] = useState(false);
  const [lightsStatus, setLightsStatus] = useState(false);
  const [tiresStatus, setTiresStatus] = useState(false);
  const [cargoStatus, setCargoStatus] = useState(false);
  const [reeferStatus, setReeferStatus] = useState(false);
  const [CTPATStatus, setCTPATStatus] = useState(false);
  const [signature, setSignature] = useState("");
  const [signature2, setSignature2] = useState("");
  const [signDesc, setSignDesc] = useState("");
  const [comments, setComments] = useState("");
  const [isReefer, setIsReefer] = useState(false);

  const [fromDetails, setFromDetails] = useState<any>(false);

  const [tabIndex, setTabIndex] = useState(7);

  const [inspectionData, setInspectionData] = useState<any>({});
  const [showNewRelease, setShowNewRelease] = useState<any>(false);
  const [newRelease, setNewRelease] = useState<any>(false);
  const [addNewItem, setAddNewItem] = useState<any>(1);
  const [show, setShow] = useState<any>(false);
  const [eventList, setEventList] = useState([]);
  const [refStatus, setRefStatus] = useState<any>({});
  const [selectedEvent, setselectedEvent] = useState({});

  const [eventID, setEventID] = useState("");
  const { state, dispatch } = useReleaseInspectorContext();

  const categoryName = [
    "visualInspection",
    "lightInspecton",
    "tiresInspection",
    "picturesInspection",
    "cargoInspection",
    "reeferInspection",
    "ctpatInspection",
  ];

  const closeModalWindow = () => {
    setShow(false);
  };
  const onOpenNewRelease = () => {
    setShowNewRelease(true);
  };

  const openSecondModal = () => {
    setShow(true);
  };

  const onCloseNewRelease = (val: any) => {
    setEventID(val?.eventId);
    dispatch({ type: "setEventId", payload: val?.eventId });
    setShowNewRelease(false);
  };

  //Closing model
  const closeModal = () => {
    setShowNewRelease(false);
    //getAllEventCodes();
  };
  const onSubmitDocument = () => {
    const payload = { ...state };
    delete payload.masterData;
    !state?.reeferInspectionRquired && delete payload.reeferInspection;
    let usrUrl = `api/webInspectionDetails/saveInspectionEventDetails?eventId=${state?.eventId}`;
    NetworkManager.sendJsonRequest(
      usrUrl,
      payload,
      "",
      "POST",
      true,
      onSuccessSubmit,
      onLoginError
    );
  };

  const onLoginError = () => {
    ToastManager.showToast("Someting went wrong! Please Try again.", "Error");
  };
  const onSuccessSubmit = (dataObj: any) => {
    //setShowNewRelease(false);
    //getVisualInspectionDetails();
    dispatch({ type: "isOpen", payload: false });
    let resp = JSON.parse(dataObj);
    if (resp?.statusCode === 500 || resp?.status === 404) {
      ToastManager.showToast("Something went wrong please try again", "Error");
    } else {
      tabIdx = tabIndex;
      setTabIndex(7);
      getAllEventCodes();
      dispatch({ type: "clearData" });
      setEventID("");
      dispatch({ type: "setEventId", payload: null });
      closeModalWindow();
      ToastManager.showToast("Successfully added Inspection", "Success");
      //getGeneralDocDetails();
    }
  };

  const OnGetEventCodes = (dataObj: any) => {
    try {
      if (dataObj) {
        let restData: any = JSON.parse(dataObj);
        setEventList(restData);
        setTabIndex(tabIdx == 0 ? 7 : tabIdx);
      }
    } catch (e) {}
  };
  const onCloseNewDetails = (event: any) => {
    dispatch({ type: "OPEN_SHOWDETAILS_POPUP", payload: false });
  };

  let getAllEventCodes = () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var orgUrl = `api/webInspectionDetails/getEvents?docId=${qparam}`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      OnGetEventCodes,
      onLoginError
    );
  };

  let getGeneralDocDetails = () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var orgUrl = `api/webInspectionDetails/getGeneralDocDetails?docId=${qparam}`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onGetGeneralDocDetails,
      onLoginError
    );
  };

  const onGetGeneralDocDetails = (dataObj: any) => {
    try {
      if (dataObj) {
        let restData: any = JSON.parse(dataObj);
        setRefStatus(restData);
        dispatch({ type: "isReferRequired", payload: restData?.isReefer });
      }
    } catch (e) {}
  };
  let inspectionBtnStatus: boolean = false;
  if (
    !visualStatus &&
    !lightsStatus &&
    !tiresStatus &&
    !cargoStatus &&
    !CTPATStatus
  ) {
    inspectionBtnStatus = true;
    if (isReefer) {
      if (reeferStatus) inspectionBtnStatus = true;
      else inspectionBtnStatus = false;
    }
  }

  const releasePdfDownload = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    if (localStorage.getItem("token")) {
      var token = "Bearer " + localStorage.getItem("token");
      myHeaders.append("Authorization", token);
    }
    await fetch(
      NetworkManager.serverPath +
        `api/InspectionDocuments/getIndividualPdf?docId=${CommonUtil.getInterchangeDocumentId()}&status=ENTRY`,
      { method: "GET", headers: myHeaders }
    )
      .then((response: any) => {
        response.blob().then((blob: any) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `releaseTrailer_${CommonUtil.getInterchangeDocumentId()}.pdf`;
          a.click();
        });
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getAllEventCodes();
    getGeneralDocDetails();
  }, []);

  useEffect(() => {
    if (state?.opendetailsPopup) {
      let eventObj = {};
      try {
        eventObj = eventList.filter(
          (ele: any) =>
            ele.inspectionEvents.eventCode === state?.opendetailsPopup
        )[0];
      } catch (e) {}
      setselectedEvent(eventObj);
      setNewRelease(true);
    } else {
      setNewRelease(false);
    }
  }, [state?.opendetailsPopup]);
  const getPdfDocumentDetails = async () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    if (localStorage.getItem("token")) {
      var token = "Bearer " + localStorage.getItem("token");
      myHeaders.append("Authorization", token);
    }
    await fetch(
      NetworkManager.serverPath +
        `api/InspectionDocuments/getMultiReleasePdf?docId=${qparam}&status=ENTRY`,
      { method: "GET", headers: myHeaders }
    )
      .then((response: any) => {
        response.blob().then((blob: any) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `MultiRelease-${CommonUtil.getInterchangeDocumentId()}.pdf`;
          a.click();
        });
      })
      .catch((error) => console.log("error", error));
  };

  const handlePdfDownload = () => {
    getPdfDocumentDetails();
  };

  return (
    <div className="containData">
      <div className="admin-wrapper container-fluid">
        <div className="container sec-wrapper py-3 px-3">
          <div className="inspection-header mb-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="title">
                Release Inspection Document{" "}
                <strong># {CommonUtil.getDocumentCode()}</strong>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                {/* <SignaturePop signature={[signature, signature2]} description={"Release Inspection"} title={signDesc} /> */}
                <button
                  type="button"
                  className="btn btn-success btn-lg"
                  onClick={handlePdfDownload}
                  style={{ height: "30px", fontWeight: "500" ,display: CommonUtil.getDocStatus() === "FAILED"? "none" : ""}}
                >
                  PDF Download
                </button>
                &nbsp;&nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-success btn-lg"
                  onClick={onOpenNewRelease}
                  style={{
                    height: "30px",
                    fontWeight: "500",
                    display:
                      refStatus?.isReceiptDone ||
                      CommonUtil.getDocStatus() === "FORCED_COMPLETE" || CommonUtil.getDocStatus() === "FAILED"
                        ? "none"
                        : "",
                  }}
                >
                  Add New Release
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="responsive-tabs mt-5">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li
                  className="nav-item"
                  role="presentation"
                  onClick={(e: any) => setTabIndex(7)}
                >
                  <button
                    className="nav-link active"
                    id="ctpat-tab"
                    data-bs-toggle="tab"
                    // data-bs-target="#ctpat-tab-pane" type="button" role="tab" aria-controls="ctpat-tab-pane"
                    aria-selected="false"
                  >
                    {CTPATStatus ? (
                      <i className="fa-solid fa-circle-xmark text-danger"></i>
                    ) : (
                      <i className="fa-solid fa-circle-check text-success"></i>
                    )}{" "}
                    CTPAT Confirmation
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={(e: any) => setTabIndex(4)}
                >
                  <button
                    className="nav-link"
                    id="pictures-tab"
                    data-bs-toggle="tab"
                    // data-bs-target="#pictures-tab-pane" type="button" role="tab"
                    aria-controls="pictures-tab-pane"
                    aria-selected="false"
                  >
                    <i className="fa-solid fa-circle-check text-success"></i>{" "}
                    Pictures
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={(e: any) => setTabIndex(1)}
                >
                  <button
                    className="nav-link "
                    id="visuval-tab"
                    data-bs-toggle="tab"
                    // data-bs-target="#visuval-tab-pane" type="button" role="tab"
                    aria-controls="visuval-tab-pane"
                    aria-selected="true"
                  >
                    {visualStatus ? (
                      <i className="fa-solid fa-circle-xmark text-danger"></i>
                    ) : (
                      <i className="fa-solid fa-circle-check text-success"></i>
                    )}{" "}
                    Visual Inspection
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={(e: any) => setTabIndex(2)}
                >
                  <button
                    className="nav-link"
                    id="lights-tab"
                    data-bs-toggle="tab"
                    // data-bs-target="#lights-tab-pane" type="button" role="tab"
                    aria-controls="lights-tab-pane"
                    aria-selected="false"
                  >
                    {lightsStatus ? (
                      <i className="fa-solid fa-circle-xmark text-danger"></i>
                    ) : (
                      <i className="fa-solid fa-circle-check text-success"></i>
                    )}{" "}
                    Lights
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={(e: any) => setTabIndex(3)}
                >
                  <button
                    className="nav-link"
                    id="tires-tab"
                    data-bs-toggle="tab"
                    // data-bs-target="#tires-tab-pane" type="button" role="tab" aria-controls="tires-tab-pane"
                    aria-selected="false"
                  >
                    {tiresStatus ? (
                      <i className="fa-solid fa-circle-xmark text-danger"></i>
                    ) : (
                      <i className="fa-solid fa-circle-check text-success"></i>
                    )}
                    Tires
                  </button>
                </li>
                
                {/* <li className="nav-item" role="presentation" onClick={(e:any)=>setTabIndex(5)}>
                            <button className="nav-link" id="cargo-tab" data-bs-toggle="tab"
                                // data-bs-target="#cargo-tab-pane" type="button" role="tab" aria-controls="cargo-tab-pane"
                                aria-selected="false">
                                    {cargoStatus?<i className="fa-solid fa-circle-xmark text-danger"></i>:<i className="fa-solid fa-circle-check text-success"></i>}
                                Cargo</button>
                        </li> */}
                {refStatus?.isReefer ? (
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={(e: any) => setTabIndex(6)}
                  >
                    <button
                      className="nav-link"
                      id="reefer-tab"
                      data-bs-toggle="tab"
                      // data-bs-target="#reefer-tab-pane" type="button" role="tab"
                      aria-controls="reefer-tab-pane"
                      aria-selected="false"
                    >
                      {reeferStatus ? (
                        <i className="fa-solid fa-circle-xmark text-danger"></i>
                      ) : (
                        <i className="fa-solid fa-circle-check text-success"></i>
                      )}{" "}
                      Reefer
                    </button>
                  </li>
                ) : (
                  ""
                )}
                
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={(e: any) => setTabIndex(8)}
                >
                  <button
                    className="nav-link"
                    id="comments-tab"
                    data-bs-toggle="tab"
                    // data-bs-target="#ctpat-tab-pane" type="button" role="tab" aria-controls="ctpat-tab-pane"
                    aria-selected="false"
                  >
                    {comments ? (
                      <i className="fa-solid fa-circle-xmark text-danger"></i>
                    ) : (
                      <i className="fa-solid fa-circle-check text-success"></i>
                    )}{" "}
                    Comments
                  </button>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  className={`tab-pane fade ${
                    tabIndex === 1 ? "show active" : ""
                  }`}
                  id="visuval-tab-pane"
                  role="tabpanel"
                  aria-labelledby="visuval-tab"
                >
                  {tabIndex === 1 ? <UpdateVisualInspection /> : ""}
                </div>
                <div
                  className={`tab-pane fade ${
                    tabIndex === 2 ? "show active" : ""
                  }`}
                  id="lights-tab-pane"
                  role="tabpanel"
                  aria-labelledby="lights-tab"
                >
                  {tabIndex === 2 ? <UpdateLightInspection /> : ""}
                </div>
                <div
                  className={`tab-pane fade ${
                    tabIndex === 3 ? "show active" : ""
                  }`}
                  id="tires-tab-pane"
                  role="tabpanel"
                  aria-labelledby="tires-tab"
                >
                  {tabIndex === 3 ? <UpdateTyreInspection /> : ""}
                </div>
                <div
                  className={`tab-pane fade ${
                    tabIndex === 4 ? "show active" : ""
                  }`}
                  id="pictures-tab-pane"
                  role="tabpanel"
                  aria-labelledby="pictures-tab"
                >
                  {tabIndex === 4 ? <UpdatePictureInspection /> : ""}
                </div>
                <div
                  className={`tab-pane fade ${
                    tabIndex === 5 ? "show active" : ""
                  }`}
                  id="cargo-tab-pane"
                  role="tabpanel"
                  aria-labelledby="cargo-tab"
                >
                  {tabIndex === 5 ? <EntryCargoInspection /> : ""}
                </div>
                <div
                  className={`tab-pane fade ${
                    tabIndex === 6 ? "show active" : ""
                  }`}
                  id="reefer-tab-pane"
                  role="tabpanel"
                  aria-labelledby="reefer-tab"
                >
                  {tabIndex === 6 ? <UpdateRefeerInspection /> : ""}
                </div>
                <div
                  className={`tab-pane fade ${
                    tabIndex === 7 ? "show active" : ""
                  }`}
                  id="ctpat-tab-pane"
                  role="tabpanel"
                  aria-labelledby="ctpat-tab"
                >
                  {tabIndex === 7 ? <UpdateCTPATInspection /> : ""}
                </div>
                <div
                  className={`tab-pane fade ${
                    tabIndex === 8 ? "show active" : ""
                  }`}
                  id="comments-tab-pane"
                  role="tabpanel"
                  aria-labelledby="ctpat-tab"
                >
                  {tabIndex === 8 ? (
                    // <textarea style={{ width: "100%" }} disabled>
                    //   {comments}
                    // </textarea>
                    <UpdateCommentsAndSignatureInsp />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddNewReleaseDetailsModal
        showNewRelease={showNewRelease}
        onCloseNewRelease={onCloseNewRelease}
        data={inspectionData?.taskDetailsAttachment}
        onSubmitDocument={onSubmitDocument}
        closeModal={closeModal}
        closeModalWindow={closeModalWindow}
        show={show}
        openSecondModal={openSecondModal}
      />
      <AddNewDetailsModal
        showNewDetails={newRelease}
        onCloseNewDetails={onCloseNewDetails}
        data={selectedEvent}
      />
      <AttachmentsViewer />
    </div>
  );
};

export default InterchangeEntryInspectionReleases;
