import React, { useRef } from 'react'
import './Interchange.css'
import { Link } from 'react-router-dom'
import { CommonUtil } from '../../utils/CommonUtil'
import { useState,useEffect } from 'react'
import { NetworkManager } from '../../Components/NetworkManager'
import { Constants } from '../Languages/Constants'
import { Modal } from 'react-bootstrap'
import DraggableComponent from '../../Components/DraggableComponent'
import CDTextarea from '../Equipments/CDTextarea'
import SingleSelectComp from '../../Components/SingleSelectComp'
import AddClaimModal from './AddClaimModal'


const InterchangeDocumentDetails = () => {

const [expectedEntryTime, setExpectedEntryTime] = useState("");
const [expectedExitTime,setExpectedExitTime] = useState("");
const [entryLocation, setEntryLocation] = useState("");
const [exitLocation,setExitLocation] = useState("");
const [carrierDot, setCarrierDot] = useState("");
const [trailerNumber,setTrailerNumber] = useState("");
const [licenseNo,setLicenseNo] = useState("");
const [vinNumber,setVinNumber] = useState("");
const [modalYear,setModalYear] = useState("");
const [modal,setModal] = useState("");
const [make,setMake] = useState("");
const [eventStatus, setEventStatus] = useState("");
const [createdBy,setCreatedBy] = useState("")
const [createdDate,setCreatedDate] = useState("")
const [updatedBy,setUpdatedBy] = useState("")
const [updatedDate,setUpdatedDate] = useState("");
const [reference,setReference] = useState("");
const [ownerContact,setOwnerContact] = useState("")
const [ownerPhone,setOwnerPhone] = useState("")
const [assestType,setAssestType] = useState("")
const [inspectionOrgName,setInspectionOrgName] = useState("")
  const [inspectionStatus, setInspectionStatus] = useState("") 
  const [carrierName, setCarrierName] = useState("")
  const [loadStatus, setLoadStatus] = useState("")
  const [refNumber, setRefNumber] = useState("")
  const [manifestNo, setManifestNo] = useState("")
  const [teBondNo, setTeBondNo] = useState("")
  const [truckNo, setTruckNo] = useState("")
  const [sealNo, setSealNo] = useState("")
  const [brokerName,setBrokerName]=useState("")

const [showEntry,setShowEntry] = useState(false);
  const [showExit, setShowExit] = useState(false);
  const [openAddClaim, setOpenAddClaim] = useState(false);

let getDocumentDetails = () =>{
  var orgUrl = "api/InspectionDocuments/"+CommonUtil.getInterchangeDocumentId();
  NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onGetDocumentDetails,onLoginError);

}

let onGetDocumentDetails = (dataObj:any)=>{
  if(dataObj){
    var restData = JSON.parse(dataObj);
    console.log(restData)
    setExpectedEntryTime(restData.inspectionDocument.expectedEntryTime)
    setEntryLocation(restData.expectedEntryLocation.rawAddress)
    setExitLocation(restData.expectedExitLocation.rawAddress)
    setCarrierDot(restData.inspectionDocument.carrierDot)
    setTrailerNumber(restData.equipment.unitNumber === null?`${restData.equipment.genericEquipmentName}`:`${restData.equipment.unitNumber}`)
    setVinNumber(restData.equipment.vin)
    setLicenseNo(restData.equipment.licensePlateNumber)
    setModalYear(restData.equipment.manufacturingYear)
    setModal(restData.equipment.model)
    setMake(restData.equipment.brand)
    setEventStatus(restData.inspectionDocument.status)
    setCreatedBy(restData.inspectionDocument.createdBy)
    setCreatedDate(restData.inspectionDocument.creationDate)
    setUpdatedBy(restData.inspectionDocument.lastUpdatedBy)
    setUpdatedDate(restData.inspectionDocument.lastUpdateDate)
    setReference(restData.inspectionDocument.referenceNo)
    setOwnerContact(restData.inspectionDocument.ownerCompanyContactName)
    setOwnerPhone(restData.inspectionDocument.ownerContactPhone)
    setExpectedExitTime(restData.inspectionDocument.expectedExitTime)
    setInspectionStatus(restData.inspectionDocument.status)
    setInspectionOrgName(restData.inspectionOrgName)
    setCarrierName(restData.inspectionDocument.carrierName)
    setTruckNo(restData.inspectionDocument.truckNumber)
    setRefNumber(restData.inspectionDocument.referenceNo)
    setTeBondNo(restData.inspectionDocument.teBondNumber)
    setManifestNo(restData.inspectionDocument.manifestNumber)
    setLoadStatus(restData.inspectionDocument.isLoaded)
    setSealNo(restData.inspectionDocument.sealNumber)
    setBrokerName(restData.inspectionDocument.brokerName)
    if(restData.equipment.assetTypeId == 1){
      setAssestType("Trailer")
    }else{
      setAssestType("Truck")
    }
    if(restData.inspectionDocument.status === "SCHEDULED"){
      setShowEntry(false);
      setShowExit(false);
    }else if(restData.inspectionDocument.status === "ENTRY"){
      setShowEntry(true);
      setShowExit(false);
    }else if(restData.inspectionDocument.status === "EXIT_INITIATED"){
      setShowEntry(true);
      setShowExit(false);
    }else if(restData.inspectionDocument.status === "EXIT"){
      setShowEntry(true);
      setShowExit(true);
    } else if(restData.inspectionDocument.status==="FORCED_COMPLETE") {
      setShowEntry(true);
      setShowExit(true);
    } else if(restData.inspectionDocument.status==="FAILED") {
      setShowEntry(true);
      setShowExit(false);
    }

    CommonUtil.setDocStatus(restData?.inspectionDocument?.status)

  }
}

let onLoginError = (error:any)=>{
console.log(error);
}

useEffect(()=>{
  getDocumentDetails()
}, [])
  
  let onClickAddClaim = () => {
    setOpenAddClaim(true)
  }

  let onCloseDocPopup = () => {
    setOpenAddClaim(false)
  }
 


  return (
    <div className='containData'>
  <div className="container sec-wrapper py-3 px-3">
      <div className="card-details">
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-sm-6 col-sm-12">
            <div className="card m-b-0">
              <div className="card-header">
              Order Type
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <p>Inspection Company</p>{inspectionOrgName}
                </li>
                <li className="list-group-item">
                  <p>Inspection Type</p>Interchange
                </li>
                <li className="list-group-item">
                  <p>Document Status</p><span className="badge bg-danger-light">{eventStatus}</span>
                </li>
                {/* <li className="list-group-item">
                  <p>Reference #</p>{reference}
                </li> */}
                {/* <li className="list-group-item">
                  <p>Owner Contact</p>{ownerContact}
                </li>
                <li className="list-group-item">
                  <p>Contact Phone</p>{ownerPhone}
                </li> */}
                <li className="list-group-item">
                  <p>Creation Date</p>{createdDate}
                </li>
                <li className="list-group-item">
                  <p>Created By</p>{createdBy}
                </li>
              </ul>
            </div>
          </div>

          <div className="col-xl-3 col-lg-4 col-sm-6 col-sm-12">
            <div className="card m-b-0">
              <div className="card-header">
                Document Details
              </div>
              <ul className="list-group list-group-flush">         
                <li className="list-group-item">
                  <p>Truck Number</p>{truckNo}
                  </li>
                  <li className="list-group-item">
                  <p>Broker Name</p>{brokerName}
                </li>
                {/* <li className="list-group-item">
                  <p>Reference Number</p>{refNumber}
                </li> */}
                <li className="list-group-item">
                  <p>Load Status</p>{loadStatus}
                </li>
                <li className="list-group-item">
                  <p>Equipment Carrier</p>{carrierName}
                </li>
                <li className="list-group-item">
                  <p>Manifest Number</p>{manifestNo}
                  </li>
                  <li className="list-group-item">
                  <p>Seal Number</p>{sealNo}
                  </li>
                  <li className="list-group-item">
                  <p>T&E Bond Number</p>{teBondNo}
                </li>
              </ul>
            </div>
          </div>

          <div className="col-xl-3 col-lg-4 col-sm-6 col-sm-12">
            <div className="card m-b-0">
              <div className="card-header">
                Equipment
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <p>Unit Number #</p>{trailerNumber}
                </li>
                <li className="list-group-item">
                  <p>VIN #</p>{vinNumber}
                </li>
                <li className="list-group-item">
                  <p>License Plate Number #</p>{licenseNo}
                </li>
                <li className="list-group-item">
                  <p>Model Year</p>{modalYear}
                </li>
                <li className="list-group-item">
                  <p>Make</p>{make}
                </li>
                {/* <li className="list-group-item">
                  <p>Model</p>{modal}
                </li> */}
                <li className="list-group-item">
                  <p>Asset Type</p>{assestType}
                </li>
                <li className="list-group-item">
                  <p>Possessing Truck #</p>N/A
                </li>
              </ul>
              </div>
              <div className="row">
        <div className="col-sm-12 mt-4 text-center">
          <button
            data-toggle="modal"
            data-target=".bs-example-modal-center-new-claim"
            type="button"
            className="btn btn-warning waves-effect"
            data-dismiss="modal"
            onClick={onClickAddClaim}
          >
            {CommonUtil.getMessageText(Constants.ADD_CLAIM, "Add Claim")}
          </button>
        </div>
      </div>
          </div>

          <div className="col-xl-3 col-lg-4 col-sm-6 col-sm-12">
            <div className="card action-green mb-4">
              {showEntry?(<>
                {/* Changed Path entryInspection to  entryInspectionReleases*/}
                <Link to="/menu/InterchangeApplication/interchangeDocumentDetails/entryInspectionReleases"> 
                <div className="card-header d-flex align-items-center justify-content-between">
                  <div className="float-start"> Release Inspection</div>
                  <div className="text-end"><i className="fa-solid fa-caret-right"></i></div>
                </div>
              </Link></>):(<>
                <Link to="#">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <div className="float-start"> Release Inspection</div>
                  <div className="text-end"><i className="fa-solid fa-caret-right"></i></div>
                </div>
              </Link></>)}
              
              <ul className="list-group list-group-flush">
              <li className="list-group-item">
                  <p>Release Date</p>{showEntry?CommonUtil.getDateInterchange([expectedEntryTime]):"-----"}
                </li>
                <li className="list-group-item">
                  <p>Interchange Location</p>{showEntry?entryLocation:"-----"}
                </li>
                <li className="list-group-item">
                  <p>Inspection Status</p>{eventStatus === "FAILED" ?<span className="badge bg-danger-light">{showEntry?"FAILED":"-----"}</span> :<span className="badge bg-success-light">{showEntry?"ENTRY":"-----"}</span>}
                </li>
                <li className="list-group-item">
                  <p>Submitted</p>{showEntry?"Yes":"-----"}
                </li>
              </ul>
            </div>

            <div className="card action-red m-b-0">
              {showExit?(
              <>
              <Link to="/menu/InterchangeApplication/interchangeDocumentDetails/exitInspection">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <div className="">Receipt Inspection</div>
                  <div className="text-end"><i className="fa-solid fa-caret-right"></i></div>
                </div>
              </Link>
              </>):(
              <>
              <Link to="#">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <div className="">Receipt Inspection</div>
                  <div className="text-end"><i className="fa-solid fa-caret-right"></i></div>
                </div>
              </Link>
              </>)}
              
              <ul className="list-group list-group-flush">
              <li className="list-group-item">
                  <p>Receipt Date</p>{showExit?CommonUtil.getDateInterchange([expectedExitTime]):"-----"}
                </li>
                <li className="list-group-item">
                  <p>Interchange Location</p>{showExit?exitLocation:"-----"}
                </li>
                <li className="list-group-item">
                  <p>Inspection Status</p><span className="badge bg-danger-light">{showExit?inspectionStatus:"-----"}</span>
                </li>
                <li className="list-group-item">
                  <p>Submitted</p>{showExit?"Yes":"-----"}
                </li>
              </ul>
            </div>

          </div>


         
        </div>
      </div>
      </div>
      <AddClaimModal show={openAddClaim} handleClose={onCloseDocPopup} />
    </div>
    
  )
}

export default InterchangeDocumentDetails