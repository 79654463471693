import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';


import "../node_modules/@fortawesome/fontawesome-free/css/all.css";

import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";

import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";

import "../node_modules/ag-grid-community/dist/styles/ag-grid.css"; // Core grid CSS, always needed
import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

import 'flatpickr/dist/themes/material_green.css'
import 'react-toastify/dist/ReactToastify.css';

import "./custom.css";
import "./datagrid.css";

//import "./icons.css";
//import "./styles.css";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

  <BrowserRouter basename={window.location.pathname.replace(/(\/[^/]+)$/, "")}>
    <App />
  </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
