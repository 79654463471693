import React, { useRef } from "react";
import CarouselComponent, { InnerImage } from "../CarouselComponent";
import "../Interchange.css";
import { useState, useEffect } from "react";
import { NetworkManager } from "../../../Components/NetworkManager";
import { CommonUtil } from "../../../utils/CommonUtil";
import ReactImageGallery from "../ReactImageGallery";
import ImageLoader from "../ImageLoader";
import { Constants } from "../../Languages/Constants";
import InspectionGrid from "../../InspectionGrid/InspectionGrid";
import NewReleaseCellRender from "../EntryInspectionDetails/NewReleaseCellRender";
import AttachmentsCellRenderer from "./AttachmentsCellRenderer";
import { AttachmentsArea, TopEventSelector } from "./UpdatedVisualCompare";
import SplitterLayout from "react-splitter-layout";
import "react-splitter-layout/lib/index.css";

let orginalData: any = [];
let eventSelected: any = [];
const UpdatedCTPATCompare = () => {
  const [ctpatDetails, setCtpatDetails] = useState<any>([]);
  const [userColumns, setUserColumns] = useState<any>([]);
  const [releases, setReleases] = useState<any>([]);
  const [selectedEvents, setSelectedEvents] = useState<any>(eventSelected);
  const [showCompareAttachments, setShowCompareAttachments] =
    useState<any>(null);
  const gridRef: any = useRef();
  useEffect(() => {
    if (gridRef?.current && !showCompareAttachments) {
      const gridApi: any = gridRef?.current?.getGridAPI();
      gridApi.deselectAll();
    }
  }, [showCompareAttachments, gridRef]);

  const compareCTPATInspection = () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var orgUrl = `api/webInspectionDetails/multiRelease/compareCtpatInspection?docId=${qparam}&eventId=${selectedEvents}`;
    // var orgUrl = `api/webInspectionDetails/multiRelease/compareVisualInspection?docId=${qparam}&eventId=129,130,132`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onCompareCTPATInspection,
      onLoginError
    );
  };

  useEffect(() => {
    // getEventsOnlyForIdAndStatus();
    compareCTPATInspection();
  }, [selectedEvents]);

  let onCompareCTPATInspection = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      console.log(restData);

      //   setVisualDetails(restData[0].taskDetailsAttachment);
      let columns: any = [
        {
          field: "taskFullName",
          headerName: CommonUtil.getMessageText(
            Constants.INSPECTION_ITEM,
            "INSPECTION ITEM"
          ),
          maxWidth: 600,
          sortable: true,
          cellStyle: {
            fontWeight: "600",
            letterSpacing: "0px",
            fontSize: "15px",
            fontFamily: "poppins",
            color: "black",
            paddingTop: "5px",
          },
          headerClass: "header-white",
          filter: "agTextColumnFilter",
        },
      ];
      let rowData: any = [];

      for (let i = 0; i < restData.length; i++) {
        const inspectionData = restData[i][0];
        const groupName: string = inspectionData.entryEventCode;
        inspectionData?.task?.map((ele: any) => {
          let obj: any = { ...ele };
          obj[`same-${groupName}`] = obj.same;
          obj[`entryValue-${groupName}`] = obj.entryValue;
          obj[`entryAttachments-${groupName}`] = obj.entryAttachments;

          const index = rowData.findIndex((item: any) => {
            return item.taskName === obj.taskName;
          });
          if (index === -1) {
            rowData.push(obj);
          } else {
            rowData[index] = { ...rowData[index], ...obj };
          }
        });
        columns.push({
          //   field: restData[i].eventCode,
          //   headerGroupComponent: NewReleaseCellRender,
          //   children: [
          //     {
          field: `same-${groupName}`,
          headerName: groupName,
          resizable: true,
          width: 100,
          sortable: true,
          cellStyle: (params: any) => {
            const status = params.data[params.colDef.field];
            return {
              fontWeight: "400",
              letterSpacing: "0px",
              textAlign: "center",
              fontSize: "20px",
              fontFamily: "poppins",
              color:
                String(params.data[`entryValue-${groupName}`]).toUpperCase() ===
                  Constants.OK ||
                String(params.data[`entryValue-${groupName}`]).toUpperCase() ===
                  Constants.PASS
                  ? "#28a745"
                  : "#dc3545",
              paddingTop: "5px",
              background: !status ? "rgba(242, 170, 176,0.5)" : null,
            };
          },
          headerClass: "header-white",
          filter: "agTextColumnFilter",
          cellRenderer: (params: any) =>
            String(params.data[`entryValue-${groupName}`]).toUpperCase() ===
              Constants.OK ||
            String(params.data[`entryValue-${groupName}`]).toUpperCase() ===
              Constants.PASS ? (
              <i className="fa-solid fa-check"></i>
            ) : (
              <i className="fa-solid fa-xmark"></i>
            ),
        });
      }
      columns.push({
        field: `exitValue`,
        headerName: "Receipt",
        resizable: true,
        width: 100,
        sortable: true,
        cellStyle: (params: any) => {
          const status = params.data[params.colDef.field];
          return {
            fontWeight: "400",
            letterSpacing: "0px",
            textAlign: "center",
            fontSize: "20px",
            fontFamily: "poppins",
            color:
              String(params.data[params.colDef.field]).toUpperCase() ===
              Constants.OK
                ? "#28a745"
                : "#dc3545",
            paddingTop: "5px",
            background: !status ? "rgba(242, 170, 176,0.5)" : null,
          };
        },
        headerClass: "header-white",
        filter: "agTextColumnFilter",
        cellRenderer: (params: any) =>
          String(params.data[params.colDef.field]).toUpperCase() ===
          Constants.OK ? (
            <i className="fa-solid fa-check"></i>
          ) : (
            <i className="fa-solid fa-xmark"></i>
          ),
      });
      setUserColumns(columns);

      setCtpatDetails(rowData);
      orginalData = rowData;
    }
  };

  let onLoginError = (err: any) => {
    console.log(err);
  };

  let Capitalize = (str: any) => {
    str = String(str);
    let strText = str.charAt(0).toUpperCase() + str.slice(1);

    return strText.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
  };

  const updateSelction = (id: any, checked: boolean) => {
    let checkboxCheck: any = [];
    try {
      checkboxCheck = selectedEvents ? selectedEvents.split(",") : [];
      if (checkboxCheck.indexOf(String(id)) != -1) {
        if (!checked) {
          checkboxCheck.splice(checkboxCheck.indexOf(String(id)), 1);
        }
      } else {
        if (checked) {
          checkboxCheck.push(String(id));
        }
      }
    } catch (e) {
      checkboxCheck = [];
    }
    eventSelected = checkboxCheck.join(",");

    setSelectedEvents(eventSelected);
  };
  //   console.log(visualDetails);
  //   console.log(selectedEvents);
  let checkboxCheck: any = [];
  try {
    checkboxCheck = selectedEvents.split(",");
  } catch (e) {
    checkboxCheck = [];
  }
  const enableMismatch = (checked: boolean) => {
    showonlyMismatched(checked ? ctpatDetails : orginalData, checked);
  };

  const showonlyMismatched = (rowData: any, allow = false) => {
    if (!allow) {
      setCtpatDetails(rowData);
    } else {
      let mismatchedData: any = [];
      for (let i = 0; i < rowData.length; i++) {
        const element: any = rowData[i];
        if (element) {
          for (const key in element) {
            if (key.includes("same-") && element[key] === false) {
              mismatchedData.push(element);
              break;
            }
          }
        }
        // mismatchedData.push(element);
      }
      setCtpatDetails(mismatchedData);
    }
  };

  const onRowSelected = (params: any) => {
    if (!params.node.selected) return;
    let attachments: any = [];
    console.log(params.data);

    try {
      for (let item in params.data) {
        let arr: any = item.split("entryAttachments-");

        if (arr.length > 1) {
          attachments.push({
            name: arr[1],
            position: params.data.taskFullName,
            attachments: { ...params.data[item] },
          });
        }
      }
      if (params.data["exitAttachments"]) {
        attachments.push({
          name: "Receipt",
          position: params.data.taskFullName,
          attachments: { ...params.data["exitAttachments"] },
        });
      }
    } catch (e) {
      attachments = [];
    }
    console.log(attachments);
    setShowCompareAttachments(attachments);
  };

  return (
    <div className="containData">
      <TopEventSelector
        releases={releases}
        checkboxCheck={checkboxCheck}
        setReleases={setReleases}
        eventSelected={eventSelected}
        // updateSelction={updateSelction}
        enableMismatch={enableMismatch}
        setSelectedEvents={setSelectedEvents}
        selectedEvents={selectedEvents}
      />
      <hr className="mb-3" style={{ width: "98%" }} />

      <div style={{ position: "relative" }}>
        <SplitterLayout primaryIndex={0}>
          <div className="row">
            <div
              className="ag-theme-alpine ag-theme-customeTheme gridHeight"
              style={{
                height: "calc(100vh - 330px)",
                width: "100%",
                paddingLeft: "8px",
                paddingRight: "5px",
              }}
            >
              <InspectionGrid
                cols={userColumns}
                rows={ctpatDetails}
                pageSize={100}
                addNew={true}
                tooltip={"Add Company"}
                name={"Add Company"}
                headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                hideToolbar={true}
                //onRowSelected={onRowSelected}
                ref={gridRef}
              />
            </div>
          </div>
          {showCompareAttachments && (
            <AttachmentsArea
              setShowCompareAttachments={setShowCompareAttachments}
              showCompareAttachments={showCompareAttachments}
            />
          )}
        </SplitterLayout>
      </div>
    </div>
  );
};

export default UpdatedCTPATCompare;
