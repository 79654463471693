import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Interchange.css";
import { NetworkManager } from "../../Components/NetworkManager";
import Image_not_available from "../../images/Image_not_available.png";

const CarouselComponent = ({
  images,
  callBack,
  imageRomoveHandler,
  isDeleteButton,
}: any) => {
  // let images = [
  //   "images/organizations/Screenshot%202023-04-18%20113056.png",
  //   "signature/Screenshot 2023-04-20 104007.png",
  //   "signature/Screenshot 2023-04-20 104007.png",
  //   "https://via.placeholder.com/500x400?text=4",
  // ];

  const findImg = (index: any) => {
    console.log(index);
    callBack(index);
  };

  return (
    <div>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay
        autoPlaySpeed={2000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 4,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 1500,
              min: 512,
            },
            items: 4,
            partialVisibilityGutter: 40,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {images?.length > 0
          ? images.map((img: any, index: any) => {
              return (
                <>
                  <div
                    className="card m-2"
                    key={img}
                    style={{ minHeight: "100px", minWidth: "85px" }}
                  >
                    <div className="card-img">
                      {isDeleteButton && (
                        <i
                          className=" remove-btn fa fa-remove upload-thumbnail-remove"
                          onClick={() => {
                            imageRomoveHandler(index);
                          }}
                          style={{
                            position: "absolute",
                            right: "-5px",
                            top: "-6px",
                            zIndex: 100,
                          }}
                        ></i>
                      )}

                      <a>
                        <InnerImage
                          path={img}
                          findImg={findImg}
                          index={index}
                        />
                      </a>
                    </div>
                  </div>
                </>
              );
            })
          : []}
      </Carousel>
    </div>
  );
};

export const InnerImage = ({ path, findImg, index }: any) => {
  const [src, setSrc] = useState(path);

  const successImage = (res: any) => {
    console.log("here");
    setSrc(res);
  };
  const errorCallback = (err: any) => {
    console.log("our");
    setSrc("");
  };
  useEffect(() => {
    var qParam: string = "objectPath=" + path;
    NetworkManager.sendJsonRequest(
      "api/session/downloadUrl",
      "",
      qParam,
      "GET",
      true,
      successImage,
      errorCallback
    );
  }, [path]);

  return (
    <img
      src={src}
      alt="No Data Available"
      onError={(event: any) =>
        (event.target.src =
          "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg")
      }
      onClick={() => {
        findImg(index);
      }}
      className="img-fluid"
      style={{ maxHeight: "300px" }}
    />
  );
};

export default CarouselComponent;
