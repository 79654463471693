import React, {useEffect, useState} from "react";
import {NetworkManager} from "../../Components/NetworkManager";
import BusyLoader from "../../Components/BusyLoader";
import {CommonUtil} from "../../utils/CommonUtil";

const EquipDamageRenderer = (props: any) => {

    const [imageSource, setImageSource] = useState("");
    const onClickLink = () => {
        if (props.context) {
            props.context.onClickLink(props.data);
        }
    }
    useEffect(() => {
        console.log(props.data.fullFilePath);
        getDamageImage();
    }, []);

    let getDamageImage = () => {
        let qParam: string = 'objectPath=' + props.data.fullFilePath;
        NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', true, onDownloadSuccess, onLoginError);
    }
    let onDownloadSuccess = (dataObj: any) => {
        BusyLoader.hideLoader();
        props.data.imageSource = dataObj;
        setImageSource(dataObj);
    }

    const onClickDamageImage = ()=>{
        if(props.context){
            //onClickLinkDetails(props.data);
            props.context.onClickLinkDetails(props.data);
        }
    }
    let onLoginError = (err: any) => {
        console.log(err)
    }
    return (
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <img src={imageSource} className="damage-image" style={{margin:"0px",cursor:"pointer"}}
                onClick={onClickDamageImage}/>
                <label style={{display:"none"}}>{props.data.documentName ? props.data.documentName : "-"}</label>
            </div>
        </div>

    )
}

export default EquipDamageRenderer;
