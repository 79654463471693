import React, { useRef } from "react";
import CarouselComponent, { InnerImage } from "../CarouselComponent";
import "../Interchange.css";
import { useState, useEffect } from "react";
import { NetworkManager } from "../../../Components/NetworkManager";
import { CommonUtil } from "../../../utils/CommonUtil";
import ReactImageGallery from "../ReactImageGallery";
import ImageLoader from "../ImageLoader";
import { Constants } from "../../Languages/Constants";
import InspectionGrid from "../../InspectionGrid/InspectionGrid";
import NewReleaseCellRender from "../EntryInspectionDetails/NewReleaseCellRender";
import AttachmentsCellRenderer from "./AttachmentsCellRenderer";

const UpdateTyreInspection = () => {
  const [visualDetails, setVisualDetails] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const [galleryPop, setGalleryPop] = useState(false);
  const [galleryPopStartIndex, setGalleryPopStartIndex] = useState(0);
  const [damageDes, setDamageDes] = useState("");
  const [damageMap, setDamageMap] = useState<any>([]);
  const [fullDamageView, setFullDamageView] = useState<any>([]);
  const [entryDamage, setEntryDamage] = useState("");
  const [showMap, setShowMap] = useState(false);

  let getTiresInspectionDetails = () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var orgUrl = `api/webInspectionDetails/getTiresInspection?docId=${qparam}`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      ongetTiresInspectionDetails,
      onLoginError
    );
  };

  useEffect(() => {
    getTiresInspectionDetails();
  }, []);

  let ongetTiresInspectionDetails = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      console.log(restData);

    //   setVisualDetails(restData[0].taskDetailsAttachment);
      let columns: any = [
        {
          field: "tyreName",
          headerName: CommonUtil.getMessageText(
            Constants.INSPECTION_ITEM,
            "INSPECTION ITEM"
          ),
          maxWidth: 400,
          sortable: true,
          cellStyle: {
            fontWeight: "600",
            letterSpacing: "0px",
            fontSize: "12px",
            fontFamily: "poppins",
            color: "black",
            paddingTop: "5px",
          },
          headerClass: "header-white-bg",
          pinned:"left",
          cellRenderer:(params:any)=>{
            return (
                <div className="flex justify-content-left">
                    <img src="../../../images/tire.svg" className="img-fluid"/>
                    {params.value}
                </div>
            )
          }
        },
      ];
      let rowData:any = [];
      for (let i = 0; i < restData.length; i++) {
        const groupName:string = restData[i].eventCode;
        if(restData[i]?.trailerTyres?.lenght > 0)
        rowData.push({assetType:"Trialer"});
        restData[i].trailerTyres.map((ele:any)=>{
                let obj:any = {...ele};
                obj[`brand-${groupName}`] = obj.brand;
                obj[`condition-${groupName}`] = obj.condition;
                obj[`size-${groupName}`] = obj.size;
                obj[`threadDepth-${groupName}`] = obj.threadDepth;
                obj[`type-${groupName}`] = String(obj.type);
                obj[`attachments-${groupName}`] = obj.attachments;

                const index = rowData.findIndex((item:any)=>{
                    return item.tyreName === obj.tyreName;                
                }) 
                if(index === -1){
                    rowData.push(obj);
                }else{
                    rowData[index] = {...rowData[index],...obj};
                }
            })
            if(restData[i]?.truckTyres?.lenght > 0)
                rowData.push({assetType:"Truck"});
        restData[i].truckTyres.map((ele:any)=>{
            let obj:any = {...ele};
            obj[`brand-${groupName}`] = obj.brand;
            obj[`condition-${groupName}`] = obj.condition;
            obj[`size-${groupName}`] = obj.size;
            obj[`threadDepth-${groupName}`] = obj.threadDepth;
            obj[`type-${groupName}`] = String(obj.type);
            obj[`attachments-${groupName}`] = obj.attachments;

            const index = rowData.findIndex((item:any)=>{
                return item.tyreName === obj.tyreName;                
            }) 
            if(index === -1){
                rowData.push(obj);
            }else{
                rowData[index] = {...rowData[index],...obj};
            }
        })
        columns.push({
          field: restData[i].eventCode,
          date: restData[i].creationDate,
          headerGroupComponent: NewReleaseCellRender,
          children: [            
            {
              field: `brand-${groupName}`,
              headerName: "Brand",
              resizable: true,
              width: 100,
              sortable: true,
              cellStyle: {
                fontWeight: "400",
                letterSpacing: "0px",
                fontSize: "12px",
                fontFamily: "poppins",
                color: "black",
                paddingTop: "5px",
              },
              headerClass:  "header-white-bg",
              
            },
            {
              field: `condition-${groupName}`,
              headerName: "Condition",
              resizable: true,
              width: 100,
              sortable: true,
              cellStyle: {
                fontWeight: "400",
                letterSpacing: "0px",
                fontSize: "12px",
                fontFamily: "poppins",
                color: "black",
                paddingTop: "5px",
              },
              headerClass:  "header-white-bg",
              
            },
            {
              field: `size-${groupName}`,
              headerName: "Size",
              resizable: true,
              width: 100,
              sortable: true,
              cellStyle: {
                fontWeight: "400",
                letterSpacing: "0px",
                fontSize: "12px",
                fontFamily: "poppins",
                color: "black",
                paddingTop: "5px",
              },
              headerClass:  "header-white-bg",
              
            },
            {
              field: `threadDepth-${groupName}`,
              headerName: "Thread Depth",
              resizable: true,
              width: 100,
              sortable: true,
              cellStyle: {
                fontWeight: "400",
                letterSpacing: "0px",
                fontSize: "12px",
                fontFamily: "poppins",
                color: "black",
                paddingTop: "5px",
              },
              headerClass:  "header-white-bg",              
            },
            {
              field: `type-${groupName}`,
              headerName: "Type",
              resizable: true,
              width: 100,
              sortable: true,
              cellStyle: {
                fontWeight: "400",
                letterSpacing: "0px",
                fontSize: "12px",
                fontFamily: "poppins",
                color: "black",
                paddingTop: "5px",
              },
              headerClass:  "header-white-bg",
              valueGetter:(params:any)=>String(params.data[params.colDef.field]) !== "true"? "Original Tyre" : "Recap Tyre"
            },
            {
                field: `attachments-${groupName}`,
                headerName: "Attachments",
                resizable: true,
                width: 100,
                sortable: true,
                cellStyle: {
                  fontWeight: "400",
                  letterSpacing: "0px",
                  fontSize: "12px",
                  fontFamily: "poppins",
                  color: "black",
                  paddingTop: "5px",
                  borderRight: "1px solid #d0d3d7"
                },
                headerClass:  "header-white-bg",
                
                cellRenderer: AttachmentsCellRenderer,
              },
          ],
        });
      }
      setUserColumns(columns);

      setVisualDetails(rowData);
    }
  };

  let onLoginError = (err: any) => {
    console.log(err);
  };

  const handleData = (name: any, damageMap: any, attached: any, des: any) => {
    setShowMap(true);
    setEntryDamage(name);
    setFullDamageView(damageMap);
    setDamageMap(attached);
    setDamageDes(des);
  };

  let Capitalize = (str: any) => {
    str = String(str);
    let strText = str.charAt(0).toUpperCase() + str.slice(1);

    return strText.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
  };

  let attachImages: any = [];
  try {
    attachImages = attachments.attachmentUploads.map(
      (ele: any) => ele.fullFilePath
    );
    console.log(attachImages);
  } catch (e) {
    attachImages = [];
  }

  const [userColumns, setUserColumns] = useState<any>([]);

  console.log(visualDetails);
  console.log(userColumns);
  return (
    <div className="containData">
      <div className="row">
        <div
          className="ag-theme-alpine ag-theme-customeTheme gridHeight"
          style={{
            height: "calc(100vh - 330px)",
            width: "100%",
            paddingLeft: "8px",
            paddingRight: "5px",
          }}
        >
          <InspectionGrid
            cols={userColumns}
            rows={visualDetails}
            pageSize={100}
            addNew={true}
            tooltip={"Add Company"}
            name={"Add Company"}
            headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
            rowHeight={50}
            hideToolbar={true}
          />
        </div>
        
      </div>
    </div>
  );
};

export default UpdateTyreInspection;
