import React from 'react'
import { Link } from 'react-router-dom';

const InterchangeRenderer = (props: any) => {
    const onClickUser = () => {
        if (props.context) {
            props.context.onClickUserDetails(props.data);
        }
    }
  return (
    <div>
        <div className={"inspectionRenderHeader"}>
            <a><span onClick={onClickUser} className={"cellRender"} style={{fontSize:'13px'}}>{props.data.inspectionDocumentCode}</span></a>
        </div>
    </div>
  )
}

export default InterchangeRenderer;