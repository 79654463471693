import React, { useState, useRef } from "react";
import { useReleaseInspectorContext } from "../../../Components/ReleaseContext";
import { CommonUtil } from "../../../utils/CommonUtil";
import $ from "jquery";
import BusyLoader from "../../../Components/BusyLoader";
import { NetworkManager } from "../../../Components/NetworkManager";
import { StatusCodeUtil } from "../../../utils/StatusCodeUtil";
import ToastManager from "../../../Components/ToastManager";
import AttachmentsViewer from "./AttachmentsViewer";

const NewEntryCTPATValues = ({ data, onAddPictures, loadStatus }: any) => {
  const { state, dispatch } = useReleaseInspectorContext();
  let qPath = CommonUtil.getInterchangeDocumentId();

  let imagePath: string = "";
  let taskItem: any = {
    taskId: data.taskId,
    taskStatus: "",
    taskNote: "",
  };
  let attachments: any = {
    attachmentUploads: [],
    attachmentDamageDescription: null,
  };
  try {
    const item = state?.ctpatInspection?.task?.filter(
      (ele: any) => ele.taskId === data.taskId
    )[0];
    taskItem = {
      taskId: item.taskId,
      taskStatus: item?.taskStatus,
      taskNote: item?.taskNote,
    };
  } catch (e) {
    taskItem = {
      taskId: data.taskId,
      taskStatus: "",
      taskNote: "",
    };
  }

  try {
    const item = state?.ctpatInspection?.task?.filter(
      (ele: any) => ele.taskId === data.taskId
    )[0];
    attachments = {
      attachmentUploads: { fullFilePath: item?.attachments.attachmentUploads },
      attachmentDamageDescription: item?.attachents.attachmentDamageDescription,
    };
  } catch (e) {
    attachments = {
      attachmentUploads: [],
      attachmentDamageDescription: "",
    };
  }

  const handleChange = (value: any) => {
    dispatch({
      type: "ctpatInspection",
      payload: {
        taskId: value.taskId,
        taskStatus: value.taskStatus,
        taskNote: value.taskNote,
      },
    });

    dispatch({
      type: "ctpatInspection",
      payload: {
        isCompleted: false,
      },
    });
  };

  const selectionNote = (
    <input
      className="textarea"
      type="text"
      value={taskItem?.taskNote}
      onChange={(e) =>
        handleChange({
          ...taskItem,
          taskNote: e.target.value,
          taskId: data?.taskId,
        })
      }
      name={data?.taskNote}
    />
  );

  return (
    <>
      <tr>
        <td>{data?.taskName}</td>
        <td>
          <div className="d-flex text-center" style={{ marginLeft: "10px" }}>
            <div>
              <input
                className="radio-size"
                //required
                type="radio"
                name={data?.taskId}
                value={taskItem?.taskStatus}
                checked={taskItem?.taskStatus === "ok"}
                onChange={(e) =>
                  handleChange({
                    ...taskItem,
                    taskStatus: "ok",
                    taskId: data?.taskId,
                  })
                }
              />
            </div>
            <div>
              <input
                className="radio-size"
                //required
                name={data?.taskId}
                type="radio"
                value={taskItem?.taskStatus}
                checked={taskItem?.taskStatus === "fail"}
                onChange={(e) =>
                  handleChange({
                    ...taskItem,
                    taskStatus: "fail",
                    taskId: data?.taskId,
                  })
                }
                style={{ marginLeft: "45px" }}
              />
            </div>
          </div>
        </td>
        <td>{selectionNote}</td>
      </tr>
    </>
  );
};

export default NewEntryCTPATValues;
