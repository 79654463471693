import React from "react";
import {CommonUtil} from "../../utils/CommonUtil";

const OrgBlockStatusRenderer = (props: any) => {
    const onClickBlock = () => {
        if (props.context) {
            props.context.onClickBlockOrgDetails(props.data);
        }
    }
    return (
        <div className={"cellRenderHeader"}>
            <button className="btn btn-success in-active-status" onClick={onClickBlock}>{"Blocked"}</button>
        </div>

    )
}

export default OrgBlockStatusRenderer;