import React from "react";
import {CommonUtil} from "../../utils/CommonUtil";

const OrgUserStatusRenderer = (props:any)=>{
    const onClickUser = ()=>{
        if(props.context){
            props.context.onClickActiveUser(props.data);
        }
    }

    const onClickResend = ()=>{
        if(props.context){
            props.context.onClickResend(props.data);
        }
    }


    return(
        <div className={"cellRenderHeader"} style={{marginTop:props.isMobile ? '2px' : "-3px"}}>
            {/* <a><span onClick={onClickUser} className={"cellRender"}>{props.value}</span></a>*/}
            {
                (props.value == CommonUtil.ACTIVE)?<button className="btn btn-success active-status" onClick={onClickUser}>{(props.value)}</button>:
                    <button className="btn btn-success in-active-status" onClick={onClickUser} style={{margin:'2px'}} >{(props.value)}</button>
            }
            {
                (props.value == CommonUtil.INVITED)?<button className="btn btn-success in-active-status" style={{margin:"2px"}} onClick={onClickResend}>{"Resend"}</button>:
                    ""
            }

        </div>

    )
}

export default OrgUserStatusRenderer;