import { useEffect, useState } from "react";
import ReactImageGallery from "./ReactImageGallery";

const SignaturePop = ({signature,description,title}:any)=>{

    const [galleryPop,setGalleryPop] = useState(false)

    useEffect(()=>{


    },[signature])

    return (
        <>
        <ReactImageGallery  attachedImages={signature} showPop={galleryPop} handleClose={()=>{setGalleryPop(false)}} description={description} title={title}/>
        <div className="text-end">
            <button type="button" className="btn btn-success btn-lg" onClick={()=>{setGalleryPop(true)}}>
                {/* <img src="../../../images/pdfcopy.svg"/> */}
                Show Signature
            </button>
        </div>
        </>
    )

}

export default SignaturePop;