import React from 'react'
import CarouselComponent from '../CarouselComponent'
import '../Interchange.css'
import { useState,useEffect } from 'react'
import { NetworkManager } from '../../../Components/NetworkManager'
import { CommonUtil } from '../../../utils/CommonUtil'
import ReactImageGallery from '../ReactImageGallery'
import { Constants } from '../../Languages/Constants'


const EntryCTPATInspection = () => {

    const [ctpatDetails,setCtpatDetails] = useState<any>([])
    const [attachments,setAttachments] = useState<any>([]) 
    const [galleryPop,setGalleryPop] = useState(false);
    const [galleryPopStartIndex,setGalleryPopStartIndex] = useState(0);

    const [damageDes,setDamageDes] = useState("")


    let getCtpatInspectionDetails = () =>{

        var qparam = CommonUtil.getInterchangeDocumentId()
        var orgUrl = `api/fetchInspectionDetails/?docId=${qparam}&inspectionStatus=ENTRY`
        NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onGetCtpatInspectionDetails,onLoginError);
      }

      useEffect(()=>{
        getCtpatInspectionDetails();
      },[])

      let onGetCtpatInspectionDetails = (dataObj:any)=>{
        if(dataObj){
            var restData = JSON.parse(dataObj);
            console.log(restData)
            setDamageDes(restData?.ctpatInspection?.attachments?.attachmentDamageDescription)
            if(restData.ctpatInspection.taskDetailsAttachment){
                var ctpat = [];
            if(Array.isArray(restData.ctpatInspection.taskDetailsAttachment)){
                ctpat = restData.ctpatInspection.taskDetailsAttachment;
            }else{
                ctpat.push(restData.ctpatInspection.taskDetailsAttachment); 
            }
            ctpat = ctpat.filter((item:any)=>item.name !=="signatureAttachmentFullFilePath")
            setCtpatDetails(ctpat)
            setAttachments(restData?.ctpatInspection?.attachments);
        }
        }
      }
      let onLoginError = (err:any)=>{
        console.log(err)
  }
  console.log(ctpatDetails);

  let Capitalize = (str:any)=>{
    str = String(str);
    let strText = str.charAt(0).toUpperCase() + str.slice(1);
    
    return strText.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")
    
    }
    let attachImages:any = [];
        try{
            attachImages = attachments.attachmentUploads.map((ele:any)=> ele.fullFilePath)
            console.log(attachImages)
        }catch(e){
            attachImages = [];
        }

  return (
    <div>
        <div className="row">
                            <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12">
                                <div className="inner-table-data table-responsive mb-2">
                                    <table className="table table-bordered">
                                    <thead>
                                            <tr>
                                                <th scope="col">INSPECTION ITEM</th>
                                                <th scope="col">PASS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {ctpatDetails.map((data:any)=>{
                                                return(
                                                <>
                                                <tr>    
                                                <td>{Capitalize(data.name)}</td>
                                                <td className={`${String(data.status).toUpperCase() === Constants.FAIL?"text-danger text-uppercase":"text-success text-uppercase"}`}>{(String(data.status).toUpperCase() !==Constants.FAIL && String(data.status).toUpperCase() !== Constants.OK) ? data.status : (String(data.status).toUpperCase() === Constants.FAIL?"NO":"YES")}</td>
                                                {(data.note)?<td>{data.note}</td>:''}                                                
                                                </tr>
                                                </>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 text-center">

                                </div>
                                </div>
    </div>
  )
}

export default EntryCTPATInspection