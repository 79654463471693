import React, {useEffect, useState} from "react";
import {CommonUtil} from "../../utils/CommonUtil";
import useStateWithCallback from "use-state-with-callback";
import {NetworkManager} from "../../Components/NetworkManager";
import BusyLoader from "../../Components/BusyLoader";
import StarRating from "../../Components/Rating";
import EquipGalleryModal from "./EquipGalleryModal";
import {Constants} from "../Languages/Constants";

var equipData:any = null;
var reservationData:any = null;
var attachmentList:any = [];
var attchIdx:number = 0;
var equiImages:any = [];

const ReserveActivation = (props:any)=>{

    const [equipDocs,setEquipData] = useStateWithCallback<any>([],()=>{});
    const [imageSrc,setImageSrc] = useState("");
    const [hodometerSrc,setHodometerSrc] = useState("");
    const [reeferEngineSrc,setReeferEngineSrc] = useState("");
    const [licenseNumber,setLicenseNumber] = useState("");
    const [hodometerNumber,setHdodmeterNumber] = useState("");
    const [reeferEngineHours,setReeferEngineHours] = useState("");
    const [activationDate,setActivationDate] = useState("");
    const [equipImagesPath, setEquipImagesPath] = useState<any>([]);
    const [activationAddress,setActivationAddress] = useState("");

    const [activationRating,setActivationRating] = useState("");
    const [deActivationRating,setDeActivationRating] = useState("");

    const [imageModalShow,setImageMoalShow] = useState(false);
    const [imageIdx,setImageIdx] = useState(0);
    const [gallary,setGallary] = useState([]);
    const [imagePath,setImagePath] = useState("");
    const [modalTitle,setModalTile] = useState("");
    const [vinNumber,setVINNumber] = useState("");

    const [podSrc,setPODSrc] = useState("");

    useEffect(()=>{
        equipData = props.equipData;
        reservationData = props.reservationData;
        console.log(equipData);
        console.log(reservationData);
        showEquipmentDetails();
        showReservationDetails();
    },[]);


    let showReservationDetails = ()=>{
        if(reservationData){
            setLicenseNumber(reservationData.activationLpn?reservationData.activationLpn:"");
            setHdodmeterNumber(reservationData.activationOdometer?reservationData.activationOdometer:"");
            setReeferEngineHours(reservationData.activationEngineHours?reservationData.activationEngineHours:"");
            setVINNumber(reservationData.activationUnitVin?reservationData.activationUnitVin:"");
            setActivationDate(reservationData.activationDate);
            setActivationAddress(reservationData.activationAddress);
            let aMap:string = `https://maps.google.com/maps?q=${reservationData.activationLatitude},${reservationData.activationLongitude}&hl=en&z=14&amp&output=embed`;
            //setDepartureMap(aMap);
            let idIframe = document.getElementById('activationiFRAME') as HTMLIFrameElement;
            //console.log(aMap);
            idIframe.setAttribute('src', aMap);

            setActivationRating(reservationData.activationRating);
            setDeActivationRating(reservationData.deactivationRating);
        }
    }
    let showEquipmentDetails = ()=>{
        setEquipData([]);
        equiImages = [];
        let docs:any = [];
        attachmentList = [];
        attchIdx = 0;
        if(equipData && equipData.attachments){
            let attchments = [];
            if(Array.isArray(equipData.attachments)){
                attchments = equipData.attachments;
            }else{
                attchments.push(equipData.attachments);
            }

            if(attchments.length>0){
                attchments.forEach((docItem:any,idx:number)=>{
                    if(docItem.attachmentCategory == CommonUtil.RESERVATION_ACTIVATION){
                        if(docItem.documentType == CommonUtil.LICENSE_IMAGES && reservationData.reservationId == docItem.reservationId){
                            setLicenseNumber(docItem.attachmentDescription?docItem.attachmentDescription:"");
                            if(docItem.fullFilePath){
                                //console.log(docItem);
                                let qParam:string = "objectPath="+docItem.fullFilePath;
                                NetworkManager.sendJsonRequest("api/session/downloadUrl","",qParam,"GET",true,onDownloadSuccess,onLoginError);
                            }
                        }else if(docItem.documentType == CommonUtil.EQUIP_POD && reservationData.reservationId == docItem.reservationId){
                            if(docItem.fullFilePath){
                                //console.log(docItem);
                                //setLicenseNumber(docItem.attachmentDescription);
                                let qParam:string = "objectPath="+docItem.fullFilePath;
                                NetworkManager.sendJsonRequest("api/session/downloadUrl","",qParam,"GET",true,onDownloadPODSuccess,onLoginError);
                            }
                        }else if(docItem.documentType == CommonUtil.EQUIP_HODOMETER && reservationData.reservationId == docItem.reservationId){
                            setHdodmeterNumber(docItem.attachmentDescription?docItem.attachmentDescription:"");
                            //console.log(docItem.fullFilePath);
                            if(docItem.fullFilePath){
                               // console.log(docItem);
                                let qParam:string = "objectPath="+docItem.fullFilePath;
                                NetworkManager.sendJsonRequest("api/session/downloadUrl","",qParam,"GET",true,onDownloadHodoMeterSuccess,onLoginError);
                            }
                        }else if(docItem.documentType == CommonUtil.EQUIP_REEFER_TYPE && reservationData.reservationId == docItem.reservationId){
                            //console.log(docItem.fullFilePath);
                            setReeferEngineHours(docItem.attachmentDescription?docItem.attachmentDescription:"");
                            if(docItem.fullFilePath){
                                // console.log(docItem);
                                let qParam:string = "objectPath="+docItem.fullFilePath;
                                NetworkManager.sendJsonRequest("api/session/downloadUrl","",qParam,"GET",true,onDownloadReeferSuccess,onLoginError);
                            }
                        }else if(docItem.documentType == CommonUtil.EQUIP_DAMAGES && reservationData.reservationId == docItem.reservationId){
                            docs.push(docItem);
                            attachmentList.push(docItem)
                        }
                    }

                });

                setEquipData(docs);
            }

        }
        console.log(attachmentList);
        if (attachmentList.length > 0) {
            BusyLoader.showLoader()
            showAttachments()
        }
    }

    let showAttachments = () => {
        if (attachmentList.length > attchIdx) {
            let qParam: string = 'objectPath=' + attachmentList[attchIdx].fullFilePath
            NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', false, (dataObj: any) => {
                    let obj: any = {}
                    obj.Type = attachmentList[attchIdx].documentType
                    obj.Path = dataObj
                    obj.attachementId = attachmentList[attchIdx].attachementId;
                    obj.fullFilePath = attachmentList[attchIdx].fullFilePath;
                    obj.description = attachmentList[attchIdx].attachmentDescription;
                    equiImages.push(obj)
                    attchIdx = attchIdx + 1
                    showAttachments()
                },
                onLoginError,
            )
        } else {
            BusyLoader.hideLoader()
            setEquipImagesPath(equiImages)
        }
    }

    let onDownloadSuccess = (data:any)=>{
        //console.log(data);
        setImageSrc(data);
    }
    let onDownloadPODSuccess = (data:any)=>{
        //console.log(data);
        setPODSrc(data);
    }

    let onDownloadHodoMeterSuccess = (data:any)=>{
        setHodometerSrc(data);
    }

    let onDownloadReeferSuccess = (data:any)=>{
        setReeferEngineSrc(data);
    }

    let onClickDamageImage = (mTile:string,images:any,imgPath:string,idx:number)=>{
        if(images.length == 0){
            setImagePath(imgPath);
        }else{
            setImageIdx(idx);
        }
        setModalTile(mTile);
        setGallary(images);

        setImageMoalShow(true);
    }

    let onCloseImagePopup = ()=>{
        setImageMoalShow(false);
    }
    let onLoginError = (err:any)=>{
        console.log(err);
    }


    return(
        <>
            <EquipGalleryModal modalTile={modalTitle} modalShow={imageModalShow} onCloseImagePopup={onCloseImagePopup} images={gallary} idx={imageIdx}
                               imagePath={imagePath}/>

            <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4">
                    <section className="h-100">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <h6>{CommonUtil.getMessageText(Constants.LICENSE_PLATE,"License Plate")}</h6>
                                    <div className="col-sm-6">
                                        <h6>{licenseNumber}</h6>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        {imageSrc?<img src={imageSrc} className="upload-thumbnail" style={{marginTop:"0px",cursor:"pointer"}}
                                                       onClick={()=>{onClickDamageImage(CommonUtil.getMessageText(Constants.LICENSE_PLATE,"License Plate"),[],imageSrc,0)}} />:""}
                                        {/*<img src="../../images/License-Plate.png" alt="" style={{width: "150px"}} />*/}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </section>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4">
                    <section className="h-100">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h6>{CommonUtil.getMessageText(Constants.HODOMETER,"Hubodometer")}</h6>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6>{hodometerNumber} {CommonUtil.getMessageText(Constants.MILES,"miles")}</h6>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        {hodometerSrc?<img src={hodometerSrc} className="upload-thumbnail" style={{marginTop:"0px",cursor:"pointer"}}
                                                           onClick={()=>{onClickDamageImage(CommonUtil.getMessageText(Constants.HODOMETER,"Hubodometer"),[],hodometerSrc,0)}}/>:""}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </section>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4  mb-4">
                    <section className="h-100">
                        <div className="row">
                            <div className="col-sm-12">


                                <div className="row">
                                    <div className="col-sm-6">
                                        <h6>{CommonUtil.getMessageText(Constants.ENGINE_HOURS,"Engine Hours")}</h6>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6>{reeferEngineHours} {CommonUtil.getMessageText(Constants.HOURS,"Hours")}</h6>

                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        {reeferEngineSrc?<img src={reeferEngineSrc} className="upload-thumbnail" style={{marginTop:"0px",cursor:"pointer"}}
                                                              onClick={()=>{onClickDamageImage(CommonUtil.getMessageText(Constants.ENGINE_HOURS,"Engine Hours"),[],reeferEngineSrc,0)}}/>:""}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4">
                    <section className="h-100">
                        <div className="row">
                            <div className="col-sm-12">
                                <h6>{CommonUtil.getMessageText(Constants.PROFF_OF_DELIVERY,"Proof of Delivery")} </h6>
                                <div className="col-sm-12" style={{marginTop:"30px"}}>
                                    {podSrc?<img src={podSrc} className="upload-thumbnail" style={{cursor:"pointer"}}
                                                 onClick={()=>{onClickDamageImage(CommonUtil.getMessageText(Constants.PROFF_OF_DELIVERY,"Proof of Delivery"),[],podSrc,0)}}/> :""}
                                </div>


                            </div>
                        </div>
                    </section>
                </div>
                <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-2 mb-4">
                    <section className="h-100">
                        <div className="row">
                            <div className="col-sm-12">
                                <h6>{CommonUtil.getMessageText(Constants.CHRONOLOGY,"Chronology")}</h6>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <span className="details-head">{CommonUtil.getMessageText(Constants.DEVICE_TIME,"Device Time")}</span>
                                        <label className="m-0">{activationDate}</label>
                                    </div>
                                    <div className="col-sm-6" style={{display:"none"}}>
                                        <span className="details-head">Synchronized At</span><label className="m-0">June
                                        5th, 2021 | 22:23 IST</label>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </section>

                </div>
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <section className="h-100">
                        <div className="row">
                            <div className="col-sm-12">
                                <h6>{CommonUtil.getMessageText(Constants.VIN_OR_TRUCK_NO,"VIN or Truck Number")}</h6>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label className="m-0">{vinNumber}</label>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </section>

                </div>
                <div className="col-sm-6 col-lg-4 col-md-4 col-xl-3 mb-4">
                    <section className="h-100">
                        <div className="row">
                            <div className="col-sm-12">
                                <h6>{CommonUtil.getMessageText(Constants.REVIEW,"Review")}</h6>
                                <div className="rating row" >
                                    <StarRating value={Math.round(Number(activationRating))} />{activationRating}  {CommonUtil.getMessageText(Constants.RATINGS,"Ratings")}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <section>
                <h6>{CommonUtil.getMessageText(Constants.DAMAGES,"Damages")}</h6>
                <div className="row">
                    {equipImagesPath.map((item: any, idx: number) => {
                        //if (item && item.Type == CommonUtil.EQUIP_IMAGES) {
                        var list = (
                            <div className="col-md-6 flex" style={{marginTop:"10px"}} >
                                <div className="col-md-4">
                                    <img src={item.Path} className="upload-thumbnail" style={{margin:"0px",cursor:"pointer"}}
                                         onClick={()=>{
                                             //console.log(equipImagesPath,item.Path,idx);
                                             onClickDamageImage(CommonUtil.getMessageText(Constants.DAMAGES,"Damages"),equipImagesPath,item.Path,idx);
                                         }}/>
                                </div>
                                <div className="col-md-8">
                                    <span className="details-head">{item.description}</span>
                                </div>
                            </div>
                        )
                        return list
                        //}
                    })}
                </div>
            </section>
            <section className="p-0">
                <div className="row m-0">
                    <div className="col-sm-4 col-12 p-4">
                        <h6>{CommonUtil.getMessageText(Constants.LOCATION,"Location")}</h6>
                        <span className="details-head">{CommonUtil.getMessageText(Constants.DRIVERS_POSITION,"Driver's Position")}</span><label>{activationAddress}</label>
                    </div>
                    <div className="col-sm-8 col-12 p-0">
                        {/*<iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d184693.82271653027!2d-79.83990028425029!3d43.672276945510944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3760d44c33c5%3A0x59e9c8bf2149d577!2sEtobicoke%2C%20Toronto%2C%20ON%2C%20Canada!5e0!3m2!1sen!2sin!4v1664435425442!5m2!1sen!2sin"
                            width="100%" height="250" style={{border:"0", margin:"0px auto -7px"}} allowFullScreen
                            loading="lazy" referrerPolicy="no-referrer-when-downgrade">

                        </iframe>*/}
                        <iframe width="100%" height="450" id={"activationiFRAME"} loading="lazy" allowFullScreen referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReserveActivation;
