import React from 'react';

const Authentication = () => {
    return (
        <>
            <div className="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 right-content p-4 bg-white">
                <div className="row">
                    <div className="col-12">
                        <h1>Authentication Overview</h1>
                    </div>
                    <div className="col-12">

                        <p>vHub's APIs allow authentication with API Key. API key can be generated from the screen Customer Profile  General Configuration Tab.</p>
                        <p>This API Key has to be sent as a header parameter with a name api-key.  Below is the cURL example of sending the API Key</p>

                        <p>Using an API key, which is added to the URL using the api-key header:</p>
                        <blockquote>
                            <pre>➜ ~ curl -H "api-key: 01d75bb0************sq" https://apidev.2vhubapp.com:8080/</pre>
                        </blockquote>

                        <p>This API Key has to be sent with each and every request. One company can have only one API Key and the same can be regenrated from UI Screen. API Key regeneration makes old API Key as invalid.</p>
                    </div>


                </div>
            </div>
        </>
    )
}

export default Authentication;