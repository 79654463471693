import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import { CommonUtil } from "../../utils/CommonUtil";
import BusyLoader from "../../Components/BusyLoader";
import { NetworkManager } from "../../Components/NetworkManager";
import $ from "jquery";
import SingleSelectComp from "../../Components/SingleSelectComp";
import { Constants } from "../Languages/Constants";
import ToastManager from "../../Components/ToastManager";
import { StatusCodeUtil } from "../../utils/StatusCodeUtil";
import Confirm from "../../Components/confirm";
import { DragNDrop } from "../../utils/DragDrop";
import EquipGalleryModal from "./EquipGalleryModal";
import CDTextarea from "./CDTextarea";

var claimId: string = "";
var fileType: string = "Images";
var imagePath: string = "";
var totalCliamAttachments: any = [];
var attchIdx: number = 0;
var messageId: string = "";
var totalMsgAttachments = [];
var claimMessageItem: any = null;

let CLAIMS: string = "CLAIMS";
let MESSAGES: string = "MESSAGES";

let IMG: string = "Img";
let PDF: string = "PDF";

const ReserveClaims = (props: any) => {
  const fileref = useRef<HTMLInputElement>(null);
  const filedocref = useRef<HTMLInputElement>(null);
  const [showDocPopup, setShowDocPopup] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [disLength] = useState(1000);

  const [fileNmae, setFileName] = useState("");
  const [claimObj, setClaimObj] = useState<any>(null);
  const [statusObj, setStatusObj] = useState<any>(null);
  const countryRef = useRef<HTMLSelectElement>(null);
  const provinceRef = useRef<HTMLSelectElement>(null);
  const claimReasonRef = useRef<HTMLInputElement>(null);
  const descRef = useRef<HTMLTextAreaElement>(null);
  const rdescRef = useRef<HTMLTextAreaElement>(null);
  const claimAmountRef = useRef<HTMLInputElement>(null);
  const taxAmountRef = useRef<HTMLInputElement>(null);

  const [claimReason, setClaimReason] = useState("");
  const [description, setDescription] = useState("");
  const [rdescription, rRetDescription] = useState("");

  const [claimAmount, setClaimAmount] = useState("");
  const [taxAmount, setTaxAmount] = useState("");
  const [recipient, setRecipient] = useState<any>("");
  const [recipientDisabled, setRecipientDisabled] = useState<any>(false);

  const [countryObj, setCountryObj] = useState<any>(null);
  const [stateObj, setStateObj] = useState<any>(null);

  const [states, setStates] = useState<any>(CommonUtil.canadaProvinces);
  const [claims, setClaims] = useState<any>([]);
  const [equipImagesPath, setEquipImagesPath] = useState<any>([]);
  const [claimData, setClaimData] = useState<any>([])
  const claimDivRef = useRef<HTMLDivElement>(null);
  const dropFileObj = React.useRef<any>(null);

  const [claimStatusClass, setClaimStatusClass] = useState(
    CommonUtil.DROPDOWN_MANDATORY_CLASS
  );

  const [imageIdx, setImageIdx] = useState(0);
  const [gallary, setGallary] = useState([]);
  const [imageEquipPath, setEquipImagePath] = useState("");
  const [modalTitle, setModalTile] = useState("");

  const [imageModalShow, setImageMoalShow] = useState(false);

  let onSelectDocFiles = (e: any) => {
    if (e && e.target && e.target.files[0]) {
      let fileName = e.target.files[0].name;
      console.log(fileName);
    }
  };

  useEffect(() => {
    //console.log(props);
    getClaims();
    localStorage.setItem("claimsView", "");
  }, []);

  useEffect(() => {
    console.log(CommonUtil.getSaasOrgId() , props?.reservationData.saasOrgId, "IDS")
    if (props?.reservationData && showDocPopup) {
      if (CommonUtil.getSaasOrgId() == props?.reservationData.saasOrgId) {
        setRecipient({ Key: "renter", Value: "Renter" });
        setRecipientDisabled(true);
      } else if (
        props?.reservationData.renterOrgId == CommonUtil.getSelectedOrgId()
      ) {
        setRecipient({ Key: "owner", Value: "Owner" });
        setRecipientDisabled(true);
      }
    }
  }, [props?.reservationData, showDocPopup]);

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    fileType = CommonUtil.EQUIP_CLAIMS; //'GST506';
    const { files } = e.dataTransfer;
    if (files && files.length) {
      dropFileObj.current = files[0];
      onSelectDrop(files[0]);
    }
  };

  let onSelectDrop = (e: any) => {
    //console.log(e);
    if (e && e.name) {
      let fileName = e.name;
      setFileName(fileName);
      BusyLoader.showLoader();

      if (fileType == CommonUtil.EQUIP_CLAIMS) {
        imagePath =
          "images/claims/" +
          claimId +
          "/" +
          fileName +
          "-" +
          new Date().getTime();
      } else {
        imagePath =
          "images/claimsMsg/" +
          claimId +
          "/" +
          fileName +
          "-" +
          new Date().getTime();
      }

      let qParam: string = "objectPath=" + imagePath;
      NetworkManager.sendJsonRequest(
        "api/session/uploadUrl",
        "",
        qParam,
        "GET",
        false,
        onUploadSuccessDrop,
        onLoginError
      );
    }
  };
  let onUploadSuccessDrop = (dataObj: any) => {
    if (dataObj) {
      if (dataObj.statusCode) {
        BusyLoader.hideLoader();
        var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode);
        ToastManager.showToast(errMsg, "Error");
      } else {
        var strUploadPath = dataObj;
        if (dropFileObj.current) {
          const formData = new FormData();
          var myHeaders = new Headers();
          var file = dropFileObj.current;
          var requestOptions: any = {
            method: "PUT",
            headers: myHeaders,
            body: file,
            redirect: "follow",
          };
          fetch(strUploadPath, requestOptions)
            .then((response) => response.text())
            .then((result) => {
              //BusyLoader.hideLoader();
              uploadClaimImagesDrop();
            })
            .catch((error) => {
              //  BusyLoader.hideLoader();
              console.log("error", error);
            });
        }
      }
    }
  };
  let uploadClaimImagesDrop = () => {
    if (dropFileObj.current) {
      let reqObj: any = {};
      let obj: any = {};

      obj.documentName = dropFileObj.current.name;
      obj.documentType = fileType;
      obj.fullFilePath = imagePath;
      obj.saasOrgId = CommonUtil.getSaasOrgId();
      obj.claimId = claimId;
      obj.reservationId = CommonUtil.SELECT_RESERVATION_ID;
      obj.reservationCode = "";
      obj.entityId = CommonUtil.SELECT_EQUIP_ID;
      obj.claimMessageId = messageId;
      obj.visibility = "public";
      reqObj.attachments = [obj];
      let arr: any = [];
      arr.push(obj);

      let attachmentUrl: string =
        "api/equipment/attachments?saasOrgId=" + CommonUtil.getSaasOrgId();

      //var qParam:string = "logoPath=images/organizations/"+fileref.current.files[0].name+"&saasOrgId="+localStorage.getItem("saasorgId");
      NetworkManager.sendJsonRequest(
        attachmentUrl,
        arr,
        "",
        "PUT",
        false,
        onUpdateEquipImageSuccess,
        onLoginError
      );
    }
  };

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  let onClickAddClaim = () => {
    claimId = "";
    setClaimAmount("");
    setTaxAmount("");
    setClaimReason("");
    setClaimObj("");
    setCountryObj("");
    setStateObj("");
    setDescription("");
    setShowDocPopup(true);
  };
  let onCloseDocPopup = () => {
    setShowDocPopup(false);
  };

  let onCloseResponsePopup = () => {
    setShowResponsePopup(false);
  };
  let onClickDocBrowse = (fType: string) => {
    setFileName("");
  };

  let onChangeClaim = (dataObj: any) => {
    setClaimObj(dataObj);
  };
  let onCountryChange = (data: any) => {
    setCountryObj(data);
    setStateObj(null);
    if (data) {
      if (data.Key == CommonUtil.CANADA) {
        setStates(CommonUtil.canadaProvinces);
      } else {
        setStates(CommonUtil.usStates);
      }
    } else {
      setCountryObj(null);
      setStates(CommonUtil.canadaProvinces);
    }
  };
  let onStateChange = (data: object) => {
    setStateObj(data);
    //(data) ? setProvinceClass('select-box-valid') : setProvinceClass('select-box-invalid');
  };

  let onChangeStatus = (data: any) => {
    setStatusObj(data);
    data
      ? setClaimStatusClass("select-box-valid")
      : setClaimStatusClass("select-box-invalid");
  };

  let getClaims = () => {
    let claimAPI: string =
      "api/claim/reservation/" +
      CommonUtil.SELECT_RESERVATION_ID +
      "?saasOrgId=" +
      CommonUtil.getSaasOrgId();
    NetworkManager.sendJsonRequest(
      claimAPI,
      "",
      "",
      "GET",
      true,
      onGetClaimList,
      onLoginError
    );
  };

  let onGetClaimList = (dataObj: any) => {
    // console.log(dataObj);
    let claims = [];
    attchIdx = 0;
    totalCliamAttachments = [];
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        if (Array.isArray(restData)) {
          claims = restData;
        } else {
          claims.push(restData);
        }
      }
    }
    claims.forEach((cItem: any, idx: number) => {
      let attchments = [];
      let cMessages = [];
      let cMsgAttaches: any = [];

      if (cItem && cItem.claimAttachments) {
        if (Array.isArray(cItem.claimAttachments)) {
          attchments = cItem.claimAttachments;
        } else {
          attchments.push(cItem.claimAttachments);
        }
      }
      attchments.forEach((aItem: any, aIdx: number) => {
        aItem.claimId = cItem.claimId;
        aItem.CAT = CLAIMS;
        totalCliamAttachments.push(aItem);
      });

      if (cItem && cItem.claimMessages) {
        if (Array.isArray(cItem.claimMessages)) {
          cMessages = cItem.claimMessages;
        } else {
          cMessages.push(cItem.claimMessages);
        }
      }

      cMessages.forEach((cmItem: any, aIdx: number) => {
        if (cmItem.messageAttachemnts) {
          if (Array.isArray(cmItem.messageAttachemnts)) {
            cMsgAttaches = cmItem.messageAttachemnts;
          } else {
            cMsgAttaches.push(cmItem.messageAttachemnts);
          }
        }

        cMsgAttaches.forEach((caItem: any, caIdx: number) => {
          caItem.claimMessageId = cmItem.claimMessageId;
          caItem.CAT = MESSAGES;
          totalCliamAttachments.push(caItem);
        });
      });

      /**/
    });
    //console.log(totalCliamAttachments);
    setClaims(claims);
    if (totalCliamAttachments.length > 0) {
      BusyLoader.showLoader();
      showAttachments();
    }
    // DragNDrop({drop:claimDivRef, handleDragOver, handleDrop, handleDragEnter, handleDragLeave})
  };

  let showAttachments = () => {
    if (totalCliamAttachments.length > attchIdx) {
      let qParam: string =
        "objectPath=" + totalCliamAttachments[attchIdx].fullFilePath;
      NetworkManager.sendJsonRequest(
        "api/session/downloadUrl",
        "",
        qParam,
        "GET",
        false,
        (dataObj: any) => {
          let docType = "";
          let docName = totalCliamAttachments[attchIdx].documentName;
          docName = docName.toLowerCase();
          if (
            docName.indexOf(".png") >= 0 ||
            docName.indexOf(".jpg") >= 0 ||
            docName.indexOf(".jpeg") >= 0 ||
            docName.indexOf(".gif") >= 0
          ) {
            docType = IMG;
          } else {
            docType = PDF;
          }
          let obj: any = {};
          obj.Type = totalCliamAttachments[attchIdx].documentType;
          obj.docName = totalCliamAttachments[attchIdx].documentName;
          obj.docType = docType;
          obj.Path = dataObj;
          obj.attachementId = totalCliamAttachments[attchIdx].attachementId;
          totalCliamAttachments[attchIdx].docType = docType;
          totalCliamAttachments[attchIdx].Path = dataObj;
          //equiImages.push(obj)
          attchIdx = attchIdx + 1;
          showAttachments();
        },
        onLoginError
      );
    } else {
      BusyLoader.hideLoader();
      setEquipImagesPath(totalCliamAttachments);
      console.log(totalCliamAttachments);
    }
  };

  let onClickSubmit = () => {
    console.log("SUN");
    let restObj: any = {};
    restObj.city = "";
    restObj.claimAmount = claimAmount;
    restObj.claimDateString = CommonUtil.getReservationDateString(new Date());
    restObj.claimDescription = description;
    restObj.claimReason = claimReason;
    restObj.claimType = claimObj ? claimObj.key : "";
    restObj.country = countryObj ? countryObj.Key : "";
    restObj.city = stateObj ? stateObj.Key : "";
    restObj.reservationCode = "";
    restObj.reservationId = CommonUtil.SELECT_RESERVATION_ID;
    restObj.saasOrgId = CommonUtil.getSaasOrgId();
    restObj.taxAmount = taxAmount;
    if (recipientDisabled) restObj.recipient = recipient?.Key;
    else {
      restObj.recipient = recipient?.Key === "owner" ? "renter" : "owner";
    }

    let method: string = "POST";
    let claimAPI: string = "api/claim/";
    if (claimId) {
      claimAPI = "api/claim/" + claimId;
      method = "PUT";
    }

    console.log(recipient?.Key, "KEY");

    if (!recipient?.Key) {
      return;
    }

    let isClaim: boolean = CommonUtil.isMandatory(claimReasonRef);
    let isDesc: boolean = CommonUtil.isMandatory(descRef);

    console.log(isClaim, isDesc);

    // let claimAPI:string = "api/claim/";//+CommonUtil.SELECT_EQUIP_AVAIL_ID+"?saasOrgId="+CommonUtil.getSaasOrgId();
    if (isClaim && isDesc) {
      NetworkManager.sendJsonRequest(
        claimAPI,
        restObj,
        "",
        method,
        true,
        onSaveClaims,
        onLoginError
      );
    }
  };

  let onSaveClaims = (dataObj: any) => {
    console.log(dataObj, "RES");
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        onCloseDocPopup();
        ToastManager.showToast("Claim created Successfully", "Info");
        getClaims();
      }
    }
  };

  let onEditResponse = (item: any) => {
    console.log(item);
    claimMessageItem = item;
    setStatusObj({ key: item.responseStatus, value: item.responseStatus });
    rRetDescription(item.responseMessage);
    setShowResponsePopup(true);

    // claimId = cId;
    // messageId = msgId;

    //let claimAPI:string = "api/claim/reservation/"+messageId;
    // NetworkManager.sendJsonRequest(claimAPI,'',"","GET",true,onGetResponseMessage,onLoginError);
  };

  let onGetResponseMessage = (dataObj: any) => {
    console.log(dataObj);
  };

  let onClickDeleteClaim = (cid: string) => {
    claimId = cid;
    if (claimId) {
      Confirm.show("Confirm", "Do you want delete claim?", [
        {
          label: "Yes",
          callback: function () {
            let removeAPI: string = "api/claim/" + claimId;
            //console.log(removeAPI)
            NetworkManager.sendJsonRequest(
              removeAPI,
              "",
              "",
              "DELETE",
              true,
              onDeleteClaim,
              onLoginError
            );
          },
        },
        { label: "No" },
      ]);
    }
  };

  let onDeleteClaim = (dataObj: any) => {
    if (dataObj == "success") {
      setShowDocPopup(false);
      ToastManager.showToast("Claim deleted successfully", "Info");
      getClaims();
    } else {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
      }
    }
  };

  let onAddResponse = (cId: string) => {
    claimMessageItem = null;
    claimId = cId;
    setStatusObj(null);
    rRetDescription("");
    setShowResponsePopup(true);
  };

  let onClickSubmitResponse = () => {
    let restObj: any = {};
    restObj.claimId = claimId;
    restObj.responseStatus = statusObj ? statusObj.key : "";
    restObj.responseMessage = rdescription;
    restObj.reservationCode = "";
    restObj.reservationId = CommonUtil.SELECT_RESERVATION_ID;
    restObj.saasOrgId = CommonUtil.getSaasOrgId();

    if (!claimMessageItem) {
      let claimAPI: string = "api/claim/message/"; //+CommonUtil.SELECT_EQUIP_AVAIL_ID+"?saasOrgId="+CommonUtil.getSaasOrgId();
      NetworkManager.sendJsonRequest(
        claimAPI,
        restObj,
        "",
        "POST",
        true,
        onSaveClaimsResponse,
        onLoginError
      );
    }
  };

  let onClickDeleteClaimMessage = () => {};

  let onSaveClaimsResponse = (dataObj: any) => {
    console.log(dataObj);
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        setShowResponsePopup(false);
        ToastManager.showToast(
          "Response to claims are added successfully",
          "Info"
        );
        getClaims();
      }
    }
  };

  let onAddPictures = (cId: string, fType: string) => {
    claimId = cId;
    messageId = "";
    fileType = fType;
    if (fileref.current) {
      fileref.current.value = "";
      // fileref.current.onclick();
      $("#inputGroupFile").click();
    }
    setFileName("");
  };

  let onAddCliamMessagePictures = (cId: string, mId: string, fType: string) => {
    claimId = cId;
    messageId = mId;
    fileType = fType;
    if (fileref.current) {
      fileref.current.value = "";
      // fileref.current.onclick();
      $("#inputGroupFile").click();
    }
    setFileName("");
  };
  let onSelectFiles = (e: any) => {
    // console.log(e)
    if (e && e.target && e.target.files[0]) {
      let fileName = e.target.files[0].name;
      setFileName(fileName);
      BusyLoader.showLoader();
      if (fileType == CommonUtil.EQUIP_CLAIMS) {
        imagePath =
          "images/claims/" +
          claimId +
          "/" +
          fileName +
          "-" +
          new Date().getTime();
      } else {
        imagePath =
          "images/claimsMsg/" +
          claimId +
          "/" +
          fileName +
          "-" +
          new Date().getTime();
      }

      var qParam: string = "objectPath=" + imagePath; //images/equipment/"+CommonUtil.getSelectedOrgId()+"/"+fileName;;
      NetworkManager.sendJsonRequest(
        "api/session/uploadUrl",
        "",
        qParam,
        "GET",
        false,
        onUploadImage,
        onLoginError
      );
    }
  };
  let onUploadImage = (dataObj: any) => {
    if (dataObj) {
      if (dataObj.statusCode) {
        BusyLoader.hideLoader();
        var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode);
        ToastManager.showToast(errMsg, "Error");
      } else {
        var strUploadPath = dataObj;
        if (fileref.current && fileref.current.files) {
          const formData = new FormData();
          var myHeaders = new Headers();
          var file = fileref.current.files[0];
          var requestOptions: any = {
            method: "PUT",
            headers: myHeaders,
            body: file,
            redirect: "follow",
          };
          fetch(strUploadPath, requestOptions)
            .then((response) => response.text())
            .then((result) => {
              //BusyLoader.hideLoader();
              uploadClaimImages();
            })
            .catch((error) => {
              //  BusyLoader.hideLoader();
              console.log("error", error);
            });
        }
      }
    }
  };
  let uploadClaimImages = () => {
    if (fileref.current && fileref.current.files && fileref.current.files[0]) {
      let reqObj: any = {};
      let obj: any = {};

      if (fileref.current && fileref.current.files) {
        obj.documentName = fileref.current.files[0].name;
      }
      obj.documentType = fileType;
      obj.fullFilePath = imagePath;
      obj.saasOrgId = CommonUtil.getSaasOrgId();
      obj.claimId = claimId;
      obj.reservationId = CommonUtil.SELECT_RESERVATION_ID;
      obj.reservationCode = "";
      obj.entityId = CommonUtil.SELECT_EQUIP_ID;
      obj.claimMessageId = messageId;
      obj.visibility = "public";
      reqObj.attachments = [obj];
      let arr: any = [];
      arr.push(obj);

      let attachmentUrl: string =
        "api/equipment/attachments?saasOrgId=" + CommonUtil.getSaasOrgId();

      //var qParam:string = "logoPath=images/organizations/"+fileref.current.files[0].name+"&saasOrgId="+localStorage.getItem("saasorgId");
      NetworkManager.sendJsonRequest(
        attachmentUrl,
        arr,
        "",
        "PUT",
        false,
        onUpdateEquipImageSuccess,
        onLoginError
      );
    }
  };
  let onUpdateEquipImageSuccess = (dataObj: any) => {
    //console.log(dataObj);
    BusyLoader.hideLoader();
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        getClaims();
      }
    }
  };

  let onEditClaims = (cId: string) => {
    claimId = cId;
    let claimAPI: string = "api/claim/" + claimId; //+CommonUtil.SELECT_EQUIP_AVAIL_ID+"?saasOrgId="+CommonUtil.getSaasOrgId();
    NetworkManager.sendJsonRequest(
      claimAPI,
      "",
      "",
      "GET",
      true,
      onGetClaimData,
      onLoginError
    );
  };

  console.log(claimData.claimedByOrgId, "DTR");
  

  let onGetClaimData = (dataObj: any) => {
    console.log(dataObj);
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        setShowDocPopup(true);
        setClaimData(restData);
        setClaimReason(restData.claimReason);
        setDescription(restData.claimDescription);
        setClaimObj({ key: restData.claimType, value: restData.claimType });
        setCountryObj({ Key: restData.country, Value: restData.country });

        onCountryChange({ Key: restData.country, Value: restData.country });
        setClaimAmount(restData.claimAmount);
        setTaxAmount(restData.taxAmount);
        setStateObj({ Key: restData.city, Value: restData.city });

        if (restData.recipient) {
          if (restData.recipient === "owner")
            setRecipient({ Key: "owner", Value: "Owner" });
          else if (restData.recipient === "renter")
            setRecipient({ Key: "renter", Value: "Renter" });
          setRecipientDisabled(true);
        }
      }
    }
  };

  let imageDownload = (imagePath: string): any => {
    let qParam: string = "objectPath=" + imagePath;
    //return qParam;
    NetworkManager.sendJsonRequest(
      "api/session/downloadUrl",
      "",
      qParam,
      "GET",
      false,
      (dataObj: any) => {
        return "dataObj";
      },
      onLoginError
    );
  };
  let onClickRemoveImage = (attachId: string) => {
    //console.log(attachId)
    Confirm.show("Confirm", "Do you want remove document?", [
      {
        label: "Yes",
        callback: function () {
          if (attachId) {
            //fileAttachmentId = attachId;
            let removeAPI: string =
              "api/equipment/attachment/" +
              attachId +
              "?saasOrgId=" +
              CommonUtil.getSaasOrgId();
            //console.log(removeAPI)
            NetworkManager.sendJsonRequest(
              removeAPI,
              "",
              "",
              "DELETE",
              true,
              onDeleteEquipmentImage,
              onLoginError
            );
          }
        },
      },
      { label: "No" },
    ]);
  };
  let onDeleteEquipmentImage = (dataObj: any) => {
    //console.log(dataObj)
    if (dataObj == "success") {
      ToastManager.showToast("Removed Successfully", "Info");
      // removeSelectedImage(fileAttachmentId);
    } else {
      ToastManager.showToast("Error", "Error");
    }

    getClaims();
  };

  let onClickEquipImage = (
    mTile: string,
    images: any,
    imgPath: string,
    idx: number,
    catagory?: string,
    msgId?: string
  ) => {
    // item && item.Type == CommonUtil.EQUIP_DRIVER_SIDE
    // setGallary(images);
    if (images.length == 0) {
      setEquipImagePath(imgPath);
    } else {
      setImageIdx(idx);
    }
    let equipImages: any = [];

    images.forEach((item: any, eIdx: number) => {
      if (item && item.docType == IMG && item.CAT == catagory) {
        if (catagory == CLAIMS && msgId == item.claimId) {
          equipImages.push(item);
        } else if (catagory == MESSAGES && msgId == item.claimMessageId) {
          equipImages.push(item);
        }
      }
    });
    for (let i = 0; i < equipImages.length; i++) {
      if (equipImages[i].Path == imgPath) {
        idx = i;
        break;
      }
    }
    setModalTile(mTile);
    setGallary(equipImages);
    setImageIdx(idx);

    setImageMoalShow(true);
  };

  let onDownloadFP2506Doc = (filePath: string) => {
    let link = document.createElement("a");
    link.download = "download";
    link.href = filePath;
    link.target = "_blank";
    link.click();
  };

  let onDownloadFP2506Success = (dataObj: any) => {
    //console.log(dataObj);
  };

  let onCloseImagePopup = () => {
    setImageMoalShow(false);
  };

  let onLoginError = (err: any) => {
    console.log(err);
  };

  return (
    <>
      <input
        type="file"
        ref={fileref}
        className="custom-file-doc-input"
        multiple={false}
        id="inputGroupFile"
        accept="image/*;pdf/*"
        style={{ display: "none" }}
        onChange={(e) => {
          onSelectFiles(e);
        }}
      ></input>

      <EquipGalleryModal
        modalTile={modalTitle}
        modalShow={imageModalShow}
        onCloseImagePopup={onCloseImagePopup}
        images={gallary}
        idx={imageIdx}
        imagePath={imageEquipPath}
      />

      <Modal show={showDocPopup} dialogAs={DraggableComponent}>
        <div
          className="modal-content"
          style={{ width: "500px", height: "600px" }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {CommonUtil.getMessageText(Constants.ADD_CLAIM, "Add Claim")}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              style={{ marginLeft: "0px" }}
              onClick={onCloseDocPopup}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ overflow: "auto" }}>
            <p>
              {CommonUtil.getMessageText(
                Constants.ENTER_CLAIM_AMOUNT,
                "Please Enter claim amount including tax"
              )}
            </p>
            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(
                  Constants.CLAIM_REASON,
                  "Claim Reason"
                )}
              </label>
              <input
                type="text"
                ref={claimReasonRef}
                value={claimReason}
                className="form-control clsStandardText  clsFormInputControl"
                placeholder="Claim Reason"
                onChange={(e) => {
                  CommonUtil.isMandatory(claimReasonRef);
                  setClaimReason(e.currentTarget.value);
                }}
              />
            </div>
            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(
                  Constants.DESCRIPTION,
                  "Description"
                )}
              </label>
              <CDTextarea
                maxLength={disLength}
                ref={descRef}
                value={description}
                placeholder={CommonUtil.getMessageText(
                  Constants.ENTER_DESCRIPTION,
                  "Enter Description"
                )}
                rows={4}
                onChange={(e: any) => {
                  CommonUtil.isMandatory(descRef);
                  setDescription(e.currentTarget.value);
                }}
              />
            </div>
            <div className={`form-group ${recipientDisabled ? "d-none" : ""}`}>
              <label>
                {CommonUtil.getMessageText(
                  Constants.ON_BEHALF_OF,
                  "This transaction is performed on behalf of"
                )}
              </label>
              <SingleSelectComp
                dataSource={[
                  { Key: "", Value: "Select" },
                  { Key: "owner", Value: "Owner" },
                  { Key: "renter", Value: "Renter" },
                ]}
                selectedValue={recipient}
                handleChange={(e: any) => {
                  setRecipient(e ? e : "");
                }}
                className={
                  recipient?.Key ? "select-box-valid" : "select-box-invalid"
                }
                disabled={recipientDisabled}
                value={"Key"}
                label={"Value"}
                width={"100%"}
              />
            </div>
            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(Constants.CLAIM_TYPE, "Claim Type")}
              </label>
              <SingleSelectComp
                dataSource={CommonUtil.CLAIM_TYPES}
                selectedValue={claimObj}
                handleChange={onChangeClaim}
                width={"100%"}
                value={"key"}
                label={"value"}
              />
            </div>
            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(Constants.COUNTRY, "Country")}
              </label>
              <SingleSelectComp
                ref={countryRef}
                dataSource={CommonUtil.countryList}
                selectedValue={countryObj}
                handleChange={onCountryChange}
                value={"Key"}
                label={"Value"}
                width={"100%"}
              />
            </div>
            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(Constants.STATE, "State")}
              </label>
              <SingleSelectComp
                ref={provinceRef}
                dataSource={states}
                selectedValue={stateObj}
                handleChange={onStateChange}
                value={"Key"}
                label={"Value"}
                width={"100%"}
              />
            </div>
            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(
                  Constants.CLAIM_AMOUNT,
                  "Claim Amount"
                )}
              </label>
              <input
                type="text"
                ref={claimAmountRef}
                value={claimAmount}
                className="form-control"
                required
                placeholder="Claim Amount"
                onKeyPress={CommonUtil.allowNumbersDecimal}
                onChange={(e) => {
                  CommonUtil.handleNumbersDecimalInput({
                    event: e,
                    ref: null,
                    setState: setClaimAmount,
                  });
                }}
              />
            </div>
            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(Constants.TAX_AMOUNT, "Tax Amount")}
              </label>
              <input
                type="number"
                ref={taxAmountRef}
                value={taxAmount}
                onKeyPress={CommonUtil.allowNumbersDecimal}
                onChange={(e) => {
                  CommonUtil.handleNumbersDecimalInput({
                    event: e,
                    ref: null,
                    setState: setTaxAmount,
                  });
                }}
                className="form-control"
                required
                placeholder="Tax Amount"
              />
            </div>

            <label style={{ display: "none" }}>Add Pictures</label>
            <div
              style={{ display: "none" }}
              className="boxed"
              onClick={() => {
                onClickDocBrowse(CommonUtil.EQUIP_DOCS);
              }}
            >
              <div className="row">
                <div className="col-sm-12 col-12 text-center">
                  <form action="" className="dropzone">
                    <img
                      src="../../images/Documents-Upload.png"
                      className="mb-3"
                    />
                    <p className="text-center">
                      <strong>
                        Drag & Drop your Photos here or{" "}
                        <a href="#">Browse Photos</a>
                      </strong>
                    </p>
                    <div className="fallback">
                      <input
                        name="file"
                        type="file"
                        style={{ display: "none" }}
                        multiple={true}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer clsModalPermissionsFooter">
            <button
              type="button"
              className="btn btn-outline-dark"
              data-bs-dismiss="modal"
              onClick={onCloseDocPopup}
            >
              {CommonUtil.getMessageText(Constants.CLOSE, "Cancel")}
            </button>

            {/* V20-2687--------------------V20-2687 */}
            {CommonUtil.getSaasOrgId() == claimData?.claimedByOrgId || CommonUtil.getSaasOrgId() == 1 ? (<span>
                {claimId ? (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => {
                      onClickDeleteClaim(claimId);
                    }}
                  >
                    {CommonUtil.getMessageText(Constants.DELETE, "Delete")}
                  </button>
                ) : (
                  ""
                )}</span>):("")
            }
            <button
              type="submit"
              className="btn btn-success"
              onClick={onClickSubmit}
            >
              {CommonUtil.getMessageText(Constants.SUBMIT, "Submit")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal show={showResponsePopup} dialogAs={DraggableComponent}>
        <div
          className="modal-content"
          style={{ width: "500px", height: "500px" }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {CommonUtil.getMessageText(
                Constants.RESPOND_TO_CLAIM,
                "Respond to Claim"
              )}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              style={{ marginLeft: "0px" }}
              onClick={onCloseResponsePopup}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ overflow: "auto" }}>
            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(Constants.STATUS, "Status")}
              </label>
              <SingleSelectComp
                dataSource={CommonUtil.CLAIM_STATUES}
                selectedValue={statusObj}
                handleChange={onChangeStatus}
                width={"100%"}
                value={"key"}
                label={"value"}
                className={claimStatusClass}
              />
            </div>
            <div className="form-group">
              <label>
                {CommonUtil.getMessageText(
                  Constants.DESCRIPTION,
                  "Description"
                )}
              </label>
              <CDTextarea
                maxLength={disLength}
                ref={rdescRef}
                value={rdescription}
                placeholder={CommonUtil.getMessageText(
                  Constants.ENTER_DESCRIPTION,
                  "Enter Description"
                )}
                rows={4}
                onChange={(e: any) => {
                  rRetDescription(e.currentTarget.value);
                  CommonUtil.isMandatory(rdescRef);
                }}
              />
            </div>

            <label style={{ display: "none" }}>Add Pictures</label>
            <div
              style={{ display: "none" }}
              className="boxed"
              onClick={() => {
                onClickDocBrowse(CommonUtil.EQUIP_DOCS);
              }}
            >
              <div className="row">
                <div className="col-sm-12 col-12 text-center">
                  <form action="" className="dropzone">
                    <img
                      src="../../images/Documents-Upload.png"
                      className="mb-3"
                    />
                    <p className="text-center">
                      <strong>
                        Drag & Drop your Photos here or{" "}
                        <a href="#">Browse Photos</a>
                      </strong>
                    </p>
                    <div className="fallback">
                      <input
                        name="file"
                        type="file"
                        style={{ display: "none" }}
                        multiple={true}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer clsModalPermissionsFooter">
            <button
              type="button"
              className="btn btn-outline-dark"
              data-bs-dismiss="modal"
              onClick={onCloseResponsePopup}
            >
              {CommonUtil.getMessageText(Constants.CLOSE, "Cancel")}
            </button>
            {claimMessageItem ? (
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => {
                  onClickDeleteClaimMessage();
                }}
              >
                {CommonUtil.getMessageText(Constants.DELETE, "Delete")}
              </button>
            ) : (
              ""
            )}
            <button
              type="submit"
              className="btn btn-success"
              onClick={onClickSubmitResponse}
            >
              {CommonUtil.getMessageText(Constants.SUBMIT, "Submit")}
            </button>
          </div>
        </div>
      </Modal>
      <div className="row">
        <div className="col-sm-12 text-right mt-2">
          <button
            data-toggle="modal"
            data-target=".bs-example-modal-center-new-claim"
            type="button"
            className="btn btn-warning waves-effect"
            data-dismiss="modal"
            onClick={onClickAddClaim}
          >
            {CommonUtil.getMessageText(Constants.ADD_CLAIM, "Add Claim")}
          </button>
        </div>
      </div>
      <br />
      {claims.map((item: any, idx: number) => {
        let list = (
          <section>
            <div className="row">
              <div className="col-sm-6">
                <div style={{ paddingBottom: "5px" }}>
                  <span> 
                    {CommonUtil.getMessageText(Constants.CLAIMRAISEDBY, "Claim Raised by")}{": "}
                    <b>{item.claimedByOrg}</b>
                  </span><br/>
                  {/* <label style={{ fontSize: "16px", marginBottom: "0px" }}>
                    {CommonUtil.getSelectedOrgName()}
                  </label>{" "} */}
                  <span> 
                    {CommonUtil.getMessageText(Constants.CLAIM, "Claim")}:{" "}
                    <b>USD ${item.claimAmount}</b>{" "}{/* V20-2694--------------------V20-2694 */}<br/>
                    {CommonUtil.getMessageText(Constants.REASON, "Reason")}{":  "}
                    <b>{item.claimReason}</b>
                  </span>
                </div>
                <div style={{ paddingBottom: "5px" }}>
                  <span>
                    {CommonUtil.getMessageText(Constants.TYPES, "Types")}:{" "}
                    <b>{item.claimType}</b>
                  </span>{" "}
                  &nbsp;
                  <span>
                    {CommonUtil.getMessageText(Constants.LOCATION, "Location")}:{" "}
                    <b>
                      {item.city} , {item.country}
                    </b>
                  </span>{" "}
                  &nbsp;
                </div>
                <div style={{ paddingBottom: "5px" }}>
                  <span>
                    {CommonUtil.getMessageText(
                      Constants.TAX_AMOUNT,
                      "Tax Amount"
                    )}
                    : <b>USD $ {item.taxAmount}</b>
                  </span>{" "}
                  &nbsp;
                </div>

                <div style={{ paddingBottom: "10px" }}>
                  <span>
                    {CommonUtil.getMessageText(Constants.ADDED_ON, "Added on")}:{" "}
                    <b> {item.claimDateString}</b>
                  </span>{" "}
                  &nbsp;
                </div>
                <div>
                  <label style={{ marginBottom: "0px" }}>
                    {CommonUtil.getMessageText(
                      Constants.PROFF_PICTURES,
                      "Proof Pictures"
                    )}
                    :
                  </label>
                </div>
              </div>
              <div className="col-sm-5">
                <div>
                  <span>
                    {CommonUtil.getMessageText(Constants.STATUS, "Status")}:{" "}
                    <b> {item.claimStatus}</b>
                  </span>
                  &nbsp;
                </div>
              </div>
              <div className="col-sm-1 text-sm-right">
                <button
                  type="button"
                  className="btn btn-warning waves-effect"
                  data-dismiss="modal"
                  disabled = {CommonUtil.getSaasOrgId() != item?.claimedByOrgId && CommonUtil.getSaasOrgId() != 1 }
                  onClick={() => {
                    onEditClaims(item.claimId);
                  }}
                >
                  {CommonUtil.getMessageText(Constants.EDIT, "Edit")}
                </button>
              </div>
            </div>
            <div className="row">
              <div
                className="col-sm-2"
                id="claimImages"
                ref={claimDivRef}
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "0px",
                }}
              >
                <div
                  className="col-sm-12 cus-input-file-text financial-browse-button"
                  onClick={() => {
                    onAddPictures(item.claimId, CommonUtil.EQUIP_CLAIMS);
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-outline-primary financial-button"
                  >
                    {CommonUtil.getMessageText(
                      Constants.BROWSE_FILE,
                      "Browse File"
                    )}
                  </button>
                </div>
                <button
                  type="button"
                  className="btn btn-warning waves-effect"
                  style={{ display: "none" }}
                  data-dismiss="modal"
                  onClick={() => {
                    onAddPictures(item.claimId, CommonUtil.EQUIP_CLAIMS);
                  }}
                >
                  {CommonUtil.getMessageText(Constants.ADD, "Add")}
                </button>
              </div>
              <div
                id={"equipImages"}
                className="upload-border col-md-10"
                style={{ height: "110px" }}
              >
                <div
                  style={{
                    display: "flex",
                    overflow: "auto",
                    overflowY: "hidden",
                  }}
                >
                  {equipImagesPath.map((citem: any, cidx: number) => {
                    if (item && item.claimId == citem.claimId) {
                      var list = (
                        <div
                          className={"upload-image-item"}
                          style={{ height: "110px" }}
                          key={cidx}
                        >
                          <a
                            style={{
                              color: "blue",
                              paddingTop: "5px",
                              zIndex: 99,
                            }}
                          >
                            <i
                              className=" remove-btn fa fa-remove upload-thumbnail-remove"
                              onClick={() => {
                                onClickRemoveImage(citem.attachementId);
                              }}
                            ></i>
                          </a>
                          {citem.docType == IMG ? (
                            <img
                              src={citem.Path}
                              className="upload-thumbnail"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                onClickEquipImage(
                                  "Claims",
                                  equipImagesPath,
                                  citem.Path,
                                  cidx,
                                  CLAIMS,
                                  citem.claimId
                                );
                              }}
                            />
                          ) : (
                            <img
                              src="../../images/document.png"
                              className="upload-thumbnail"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                onDownloadFP2506Doc(citem.Path);
                              }}
                            />
                          )}
                        </div>
                      );
                      return list;
                    }
                  })}
                </div>
              </div>
            </div>
            <div className="row"></div>
            <hr className="mt-1" />
            <div className="row">
              <div className="col-sm-8">
                <h6>
                  {CommonUtil.getMessageText(Constants.RESPONSES, "Responses")}
                </h6>
                <span className="details-head"></span>
              </div>

              <div className="col-sm-4 text-right mt-2">
                <button
                  data-toggle="modal"
                  data-target=".bs-example-modal-center-new-claim"
                  type="button"
                  className="btn btn-warning waves-effect"
                  data-dismiss="modal"
                  onClick={() => {
                    onAddResponse(item.claimId);
                  }}
                >
                  {CommonUtil.getMessageText(
                    Constants.ADD_RESPONSE,
                    "Add Response"
                  )}
                </button>
              </div>
            </div>
            <hr className="mt-3" />
            {item.claimMessages &&
              item.claimMessages.map((mItem: any, mIdx: number) => {
                let list1 = (
                  <div className="row">
                    <div className="row" style={{ paddingBottom: "10px" }}>
                      <div className="col-sm-8">
                        <span>
                          {CommonUtil.getMessageText(
                            Constants.STATUS,
                            "Status"
                          )}
                          : <b>{mItem.responseStatus}</b>
                        </span>{" "}
                        &nbsp;
                        <br />
                        <span>
                          {CommonUtil.getMessageText(
                            Constants.DESCRIPTION,
                            "Description"
                          )}
                          : <b>{mItem.responseMessage}</b>
                        </span>
                      </div>
                      <div
                        className="col-sm-4 text-sm-right mt-2"
                        style={{ display: "none" }}
                      >
                        <button
                          type="button"
                          data-toggle="modal"
                          data-target=".bs-example-modal-center-add-response"
                          className="btn btn-warning waves-effect"
                          data-dismiss="modal"
                          onClick={() => {
                            onEditResponse(mItem);
                          }}
                        >
                          {CommonUtil.getMessageText(
                            Constants.EDIT_RESPONSE,
                            "Edit Response"
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <label style={{ marginBottom: "0px" }}>
                        {CommonUtil.getMessageText(
                          Constants.PROFF_PICTURES,
                          "Proof Pictures"
                        )}
                        :
                      </label>
                    </div>
                    <div className="row fs-7 padding-left-right">
                      <div
                        className="col-sm-2 cus-input-file-text financial-browse-button"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "0px",
                          marginTop: "30px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-outline-primary financial-button"
                          onClick={() => {
                            onAddCliamMessagePictures(
                              item.claimId,
                              mItem.claimMessageId,
                              CommonUtil.EQUIP_RESPONSES
                            );
                          }}
                        >
                          {CommonUtil.getMessageText(
                            Constants.BROWSE_FILE,
                            "Browse File"
                          )}
                        </button>
                        <button
                          type="button"
                          style={{ display: "none" }}
                          className="btn btn-warning waves-effect"
                          data-dismiss="modal"
                          onClick={() => {
                            onAddCliamMessagePictures(
                              item.claimId,
                              mItem.claimMessageId,
                              CommonUtil.EQUIP_RESPONSES
                            );
                          }}
                        >
                          {CommonUtil.getMessageText(Constants.ADD, "Add")}
                        </button>
                      </div>
                      <div
                        id={"equipImages"}
                        className="upload-border col-md-10"
                        style={{ height: "110px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            overflow: "auto",
                            overflowY: "hidden",
                          }}
                        >
                          {equipImagesPath.map((maitem: any, maidx: number) => {
                            if (
                              maitem &&
                              maitem.claimMessageId == mItem.claimMessageId
                            ) {
                              var list = (
                                <div
                                  className={"upload-image-item"}
                                  style={{ height: "110px" }}
                                  key={maidx}
                                >
                                  <a
                                    style={{
                                      color: "blue",
                                      paddingTop: "5px",
                                      zIndex: 99,
                                    }}
                                  >
                                    <i
                                      className=" remove-btn fa fa-remove upload-thumbnail-remove"
                                      onClick={() => {
                                        onClickRemoveImage(
                                          maitem.attachementId
                                        );
                                      }}
                                    ></i>
                                  </a>
                                  {maitem.docType == IMG ? (
                                    <img
                                      src={maitem.Path}
                                      className="upload-thumbnail"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        onClickEquipImage(
                                          "Claims",
                                          equipImagesPath,
                                          maitem.Path,
                                          maidx,
                                          MESSAGES,
                                          mItem.claimMessageId
                                        );
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src="../../images/document.png"
                                      className="upload-thumbnail"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        onDownloadFP2506Doc(maitem.Path);
                                      }}
                                    />
                                  )}
                                </div>
                              );
                              return list;
                            }
                          })}
                        </div>
                      </div>
                    </div>
                    <hr className="mt-3" />
                  </div>
                );
                return list1;
              })}
          </section>
        );
        return list;
      })}

      <section style={{ display: "none" }}>
        <div className="row">
          <div className="col-sm-6">
            <h6>Tall Grass Freight Company</h6>
            <span className="details-head">Reason</span>
            <label>Tire Blow Out</label>
            <span className="details-head">Added on</span>
            <label>31st May 2022</label>
            <span className="details-head">Proof Pictures</span>
            <div className="row">
              <div className="col-sm-12 col-12">
                <div className="popup-gallery damage-details-photos">
                  <a className="d-inline-block">
                    <div className="img-fluid d-block mx-auto">
                      <img
                        src="assets/images/small/general-details-type-(1).png"
                        alt=""
                      />
                    </div>
                  </a>
                  <a className="d-inline-block">
                    <div className="img-fluid d-block mx-auto">
                      <img
                        src="assets/images/small/general-details-type-(2).png"
                        alt=""
                      />
                    </div>
                  </a>
                  <a className="d-inline-block">
                    <div className="img-fluid d-block mx-auto">
                      <img
                        src="assets/images/small/general-details-type-(3).png"
                        alt=""
                      />
                    </div>
                  </a>
                  <a className="d-inline-block">
                    <div className="img-fluid d-block mx-auto">
                      <img
                        src="assets/images/small/general-details-type-(4).png"
                        alt=""
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 text-sm-right mt-3">
            <button
              type="button"
              className="btn btn-warning waves-effect"
              data-dismiss="modal"
            >
              Edit Claim
            </button>
          </div>

          <div className="col-sm-3 text-sm-right mt-3">
            <h6>Claim Amount</h6>
            <h6>$682.10</h6>
            <button
              type="button"
              className="btn btn-warning waves-effect"
              data-dismiss="modal"
            >
              Pending
            </button>
          </div>
        </div>
        <hr className="mt-3" />
        <div className="row">
          <div className="col-sm-8">
            <h6>Activity</h6>
            <span className="details-head">No Response Yet.</span>
          </div>
          <div className="col-sm-4 text-sm-right mt-2">
            <button
              type="button"
              data-toggle="modal"
              data-target=".bs-example-modal-center-add-response"
              className="btn btn-warning waves-effect"
              data-dismiss="modal"
            >
              Add Response
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReserveClaims;
