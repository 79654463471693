import "../Interchange.css";
import { useState, useEffect, useRef } from "react";
import { NetworkManager } from "../../../Components/NetworkManager";
import { CommonUtil } from "../../../utils/CommonUtil";
import { Constants } from "../../Languages/Constants";
import InspectionGrid from "../../InspectionGrid/InspectionGrid";
import SplitterLayout from "react-splitter-layout";
import "react-splitter-layout/lib/index.css";
import { PictureComponent } from "../EntryInspectionDetails/EntryPicturesInspection";

let orginalData: any = [];
let eventSelected: any = [];
const UpdatedVisualCompare = () => {
  const [visualDetails, setVisualDetails] = useState<any>([]);
  const [userColumns, setUserColumns] = useState<any>([]);
  const [releases, setReleases] = useState<any>([]);
  const [selectedEvents, setSelectedEvents] = useState<any>(eventSelected);
  const [showCompareAttachments, setShowCompareAttachments] =
    useState<any>(null);
  const gridRef:any=useRef();
  useEffect(()=>{

    if(gridRef?.current && !showCompareAttachments){
      const gridApi:any = gridRef?.current?.getGridAPI();
      gridApi.deselectAll()
    }
    
  },[showCompareAttachments,gridRef])

  const compareVisualInspection = () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var orgUrl = `api/webInspectionDetails/multiRelease/compareVisualInspection?docId=${qparam}&eventId=${selectedEvents}`;
    // var orgUrl = `api/webInspectionDetails/multiRelease/compareVisualInspection?docId=${qparam}&eventId=129,130,132`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onCompareVisualInspection,
      onLoginError
    );
  };
  useEffect(() => {
    // getEventsOnlyForIdAndStatus();
    compareVisualInspection();
  }, [selectedEvents]);

  let onCompareVisualInspection = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      let columns: any = [
        {
          field: "taskFullName",
          headerName: CommonUtil.getMessageText(
            Constants.INSPECTION_ITEM,
            "INSPECTION ITEM"
          ),
          maxWidth: 600,
          sortable: true,
          cellStyle: {
            fontWeight: "600",
            letterSpacing: "0px",
            fontSize: "15px",
            fontFamily: "poppins",
            color: "black",
            paddingTop: "5px",
          },
          headerClass: "header-white",
          filter: "agTextColumnFilter",
        },
      ];
      let rowData: any = [];

      for (let i = 0; i < restData.length; i++) {
        const inspectionData = restData[i][0];
        const groupName: string = inspectionData.entryEventCode;
        inspectionData?.task?.map((ele: any) => {
          let obj: any = { ...ele };
          obj[`same-${groupName}`] = obj.same;
          obj[`entryValue-${groupName}`] = obj.entryValue;
          obj[`entryAttachments-${groupName}`] = obj.entryAttachments;

          const index = rowData.findIndex((item: any) => {
            return item.taskName === obj.taskName;
          });
          if (index === -1) {
            rowData.push(obj);
          } else {
            rowData[index] = { ...rowData[index], ...obj };
          }
        });
        columns.push({
          field: `same-${groupName}`,
          headerName: groupName,
          resizable: true,
          width: 100,
          sortable: true,
          cellStyle: (params: any) => {
            const status = params.data[params.colDef.field];
            return {
              fontWeight: "400",
              letterSpacing: "0px",
              textAlign: "center",
              fontSize: "20px",
              fontFamily: "poppins",
              color:
                String(params.data[`entryValue-${groupName}`]).toUpperCase() ===
                  Constants.OK ||
                String(params.data[`entryValue-${groupName}`]).toUpperCase() ===
                  Constants.PASS
                  ? "#28a745"
                  : "#dc3545",
              paddingTop: "5px",
              background: !status ? "rgba(242, 170, 176,0.5)" : null,
            };
          },
          headerClass: "header-white",
          filter: "agTextColumnFilter",
          cellRenderer: (params: any) =>
            String(params.data[`entryValue-${groupName}`]).toUpperCase() ===
              Constants.OK ||
            String(params.data[`entryValue-${groupName}`]).toUpperCase() ===
              Constants.PASS ? (
              <i className="fa-solid fa-check"></i>
            ) : (
              <i className="fa-solid fa-xmark"></i>
            ),
        });
      }
      columns.push({
        field: `exitValue`,
        headerName: "Receipt",
        resizable: true,
        width: 100,
        sortable: true,
        cellStyle: (params: any) => {
          const status = params.data[params.colDef.field];
          return {
            fontWeight: "400",
            letterSpacing: "0px",
            textAlign: "center",
            fontSize: "20px",
            fontFamily: "poppins",
            color:
              String(params.data[params.colDef.field]).toUpperCase() ===
              Constants.OK
                ? "#28a745"
                : "#dc3545",
            paddingTop: "5px",
            background: !status ? "rgba(242, 170, 176,0.5)" : null,
          };
        },
        headerClass: "header-white",
        filter: "agTextColumnFilter",
        cellRenderer: (params: any) =>
          String(params.data[params.colDef.field]).toUpperCase() ===
          Constants.OK ? (
            <i className="fa-solid fa-check"></i>
          ) : (
            <i className="fa-solid fa-xmark"></i>
          ),
      });
      setUserColumns(columns);

      setVisualDetails(rowData);
      console.log(rowData);

      orginalData = rowData;
      // showonlyMismatched(rowData);
    }
  };

  let onLoginError = (err: any) => {
    console.log(err);
  };

  const showonlyMismatched = (rowData: any, allow = false) => {
    if (!allow) {
      setVisualDetails(rowData);
    } else {
      let mismatchedData: any = [];
      for (let i = 0; i < rowData.length; i++) {
        const element: any = rowData[i];
        if (element) {
          for (const key in element) {
            if (key.includes("same-") && element[key] === false) {
              mismatchedData.push(element);
              break;
            }
          }
        }
        // mismatchedData.push(element);
      }
      setVisualDetails(mismatchedData);
    }
  };

  let Capitalize = (str: any) => {
    str = String(str);
    let strText = str.charAt(0).toUpperCase() + str.slice(1);

    return strText.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
  };

  let checkboxCheck: any = [];
  try {
    checkboxCheck = selectedEvents.split(",");
  } catch (e) {
    checkboxCheck = [];
  }

  const enableMismatch = (checked: boolean) => {
    showonlyMismatched(checked ? visualDetails : orginalData, checked);
  };

  const onRowSelected = (params: any) => {
    if (!params.node.selected)
      return;
    let attachments: any = [];
    console.log(params.data);
    
    try {
      for (let item in params.data) {
        let arr: any = item.split("entryAttachments-");
        
        if (arr.length > 1) {          
          attachments.push({ name: arr[1],position:params.data.taskFullName, attachments: params.data[item] ? JSON.parse(JSON.stringify(params.data[item]))  : {}});
        }
      }
      if (params.data["exitAttachments"]) {
        attachments.push({ name: "Receipt",position:params.data.taskFullName, attachments:{...params.data["exitAttachments"] }});
      }
    } catch (e) {
      attachments = [];
    }
    console.log(attachments);
    setShowCompareAttachments(attachments);
  };

  return (
    <div className="w-100">
      <style>
        {`
        
        .splitter-layout > .layout-splitter{
          height:"100vh";
        }

        `}
      </style>
      <TopEventSelector
        releases={releases}
        checkboxCheck={checkboxCheck}
        setReleases={setReleases}
        setSelectedEvents={setSelectedEvents}
        eventSelected={eventSelected}
        // updateSelction={updateSelction}
        enableMismatch={enableMismatch}
        selectedEvents={selectedEvents}
      />

      <hr className="mb-3" style={{ width: "98%" }} />
      <div style={{position:"relative"}}>
        <SplitterLayout primaryIndex={0}>
          <div className="row">
            <div
              className="ag-theme-alpine ag-theme-customeTheme gridHeight"
              style={{
                height: "calc(100vh - 330px)",
                width: "100%",
                paddingLeft: "8px",
                paddingRight: "5px",
              }}
            >
              <InspectionGrid
                cols={userColumns}
                rows={visualDetails}
                pageSize={100}
                addNew={true}
                tooltip={"Add Company"}
                name={"Add Company"}
                headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                hideToolbar={true}
                onRowSelected={onRowSelected}
                ref={gridRef}
              />
            </div>
          </div>
          {showCompareAttachments && (
            <AttachmentsArea setShowCompareAttachments={setShowCompareAttachments} showCompareAttachments={showCompareAttachments}/>
          )}
        </SplitterLayout>
      </div>
    </div>
  );
};

export default UpdatedVisualCompare;

export const AttachmentsArea = ({setShowCompareAttachments,showCompareAttachments}:any)=>{
  return (
    <div
        style={{
          height: "calc(100vh - 330px)",
        }}
      >
        <button
          type="button"
          className="btn-close2"
          onClick={(e) => setShowCompareAttachments(null)}
        >
            <span className="icon-cross"></span>
            <span className="visually-hidden">Close</span>
        </button>
        <CompareAttachments list={showCompareAttachments} />
      </div>
  )
}

export const CompareAttachments = ({ list }: any) => {

  const onlyWithAttachments:any = list?.filter((ele:any)=>{    
    if(ele?.attachments?.attachmentUploads?.length === 1 && ele?.attachments?.attachmentUploads[0]?.fullFilePath === "")
      return false
    else
    return true
  })

  return (
    <div
      className="row"
      style={{
        height: `${window.innerHeight - 330}px`,
        overflowY: "auto",
        display: "flex",
        justifyContent: "space-evenly",
      }}
    >
      {
      onlyWithAttachments?.length == 0 ? <div style={{ width: "250px",alignSelf:"center" }}><b>There are no images attached.</b></div>:      
      onlyWithAttachments?.map((ele: any) => {
        let temp:any = {...ele};
        let data:any = {
          images:temp?.attachments?.attachmentUploads ? [...temp?.attachments?.attachmentUploads] :[],
          description:temp?.attachments?.attachmentDamageDescription || "",
          position:temp?.position ||''
        }        
        // if(data?.images?.length === 1 && data?.images[0]?.fullFilePath === "")
        //   return null
        return (
          <div style={{ width: "250px" }} key={temp.name}>
            <div style={{ height: "35px" }}>
              <b>{temp.name}</b>             
            </div>
            <PictureComponent key={data} data={data} classStyle="col-xs-12 mb-2" cardStyle={{height:"150px"}} notfoundImage={true} />
          </div>
        );
      })}
    </div>
  );
};

export const TopEventSelector = ({
  releases,
  checkboxCheck,
  setReleases,
  // updateSelction,
  enableMismatch = null,
  eventSelected,
  selectedEvents,
  setSelectedEvents,
}: any) => {
  useEffect(() => {
    getEventsOnlyForIdAndStatus();
    // compareVisualInspection();
  }, []);

  const [mismatchCheck, setMismatchCheck] = useState(false);
  const getEventsOnlyForIdAndStatus = () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var orgUrl = `api/webInspectionDetails/getEventsOnlyForIdAndStatus?docId=${qparam}`;
    // var orgUrl = `api/webInspectionDetails/multiRelease/compareVisualInspection?docId=${qparam}&eventId=129,130,132`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onEventsOnlyForIdAndStatus,
      onLoginError
    );
  };

  const updateSelction = (id: any, checked: boolean) => {
    let checkboxCheck: any = [];
    try {
      checkboxCheck = selectedEvents ? selectedEvents.split(",") : [];
      if (checkboxCheck.indexOf(String(id)) != -1) {
        if (!checked) {
          checkboxCheck.splice(checkboxCheck.indexOf(String(id)), 1);
        }
      } else {
        if (checked) {
          checkboxCheck.push(String(id));
        }
      }
    } catch (e) {
      checkboxCheck = [];
    }

    eventSelected = checkboxCheck.join(",");
    CommonUtil.setSelectedEventForCompare(eventSelected);
    setSelectedEvents(eventSelected);
  };

  const onEventsOnlyForIdAndStatus = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      if (restData?.length > 1) {
        restData.splice(restData.length - 1, 1);
      }
      setReleases(restData);
      if (restData?.length > 0) {
        const savedEvents: any = CommonUtil.getSelectedEventForCompare();
        if (!savedEvents) {
          setSelectedEvents(`${restData[0].eventId}`);
          // eventSelected = `${restData[0].eventId}`;
        } else {
          setSelectedEvents(savedEvents);
        }
        //setSelectedEvents(`${restData[0].eventId}`);
        // eventSelected = `${restData[0].eventId}`;
        // compareVisualInspection()
      }
    }
  };
  let onLoginError = (err: any) => {
    console.log(err);
  };
  return (
    <>
      <div className="d-flex justify-content-end align-items-center w-50">
        {releases?.map((ele: any) => {
          return (
            <div className="d-flex m-2">
              <label
                className="form-check-label pr-2"
                style={{ fontSize: "10px" }}
              >
                {String(ele.eventCode)}
              </label>
              <input
                type="checkbox"
                className="form-check-input"
                checked={checkboxCheck.indexOf(String(ele.eventId)) != -1}
                onClick={(e: any) => {
                  setMismatchCheck(false);
                  updateSelction(ele.eventId, e.target.checked);
                }}
              />
            </div>
          );
        })}
      </div>
      {enableMismatch && (
        <div className="d-flex justify-content-end pr-2">
          <label className="form-check-label pr-3" style={{ fontSize: "10px" }}>
            {"Show mismatch only"}
          </label>
          <input
            type="checkbox"
            className="form-check-input ml-3"
            checked={mismatchCheck}
            onClick={(event: any) => {
              setMismatchCheck(event.target.checked);
              enableMismatch(event.target.checked);
            }}
          />
        </div>
      )}
    </>
  );
};
