import React, { useEffect } from 'react'
import { useState, useRef } from 'react';
import useStateWithCallback from "use-state-with-callback";
import { IAGrid } from '../../Components/IInterfaces';
import { CommonUtil } from '../../utils/CommonUtil';
import { Constants } from '../Languages/Constants';
import InspectionRenderer from '../InspectionGrid/InspectionRenderer';
import InterchangeDocumentStatusRenderer from './InterchangeDocumentStatusRenderer';
import { NetworkManager } from '../../Components/NetworkManager';
import InspectionGrid from '../InspectionGrid/InspectionGrid';
import { useNavigate } from 'react-router';
import DownloadCSVFileRenderer from './DownloadCSVFileRenderer';
import CSVErrorsRenderer from './CSVErrorsRenderer';

const CSVFiles = () => {

    const [users, setUsers] = useStateWithCallback([], () => {
        if (userGridRef1.current) {
            userGridRef1.current.columnFittoSize();
        }
    });
    const userGridRef1 = useRef<IAGrid>(null);

    // const [users] = useState([
    //     { fileName: "File123", updatedDate: "2024-01-30", }
    //  ] )

    const [userColumns] = useState([
        { field: 'fileName', headerName: CommonUtil.getMessageText(Constants.FILE_NAME, 'File Name'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '12px', 'fontFamily': 'poppins', 'color': 'black', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter' },
        { field: 'lastModifiedDate', headerName: CommonUtil.getMessageText(Constants.FILE_UPDATED_DATE, 'File Updated Date'), resizable: true, width: 100, flex: 1, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agDateColumnFilter' },
        { field: 'download', headerName: CommonUtil.getMessageText(Constants.DOWNLOAD, 'Download'), resizable: true, flex: 1, width: 100, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter', cellRenderer: DownloadCSVFileRenderer },
        { headerName: CommonUtil.getMessageText(Constants.ERRORS, 'Error(if any error found)'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter', cellRenderer: CSVErrorsRenderer },
    ]);

    let navigate = useNavigate();

    let onClickUserDetails = (userData: any) => {
        console.log(userData)
        localStorage.setItem("documentId", userData.documentId);
        navigate("/menu/InterchangeApplication/interchangeDocumentDetails");
    }

    let getAllCSVFiles = () => {
        var csvUrl = `api/fetchInspectionDetails/extractedFiles?saasOrgId=${CommonUtil.getSaasOrgId()}`
        NetworkManager.sendJsonRequest(csvUrl, "", "", "GET", true, onGetAllCSVFiles, onLoginError);
    }

    useEffect(() => {
        getAllCSVFiles();
    }, []);

    let onGetAllCSVFiles = (dataObj: any) => {
        console.log(dataObj);
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            console.log(restData)
            if (restData) {
                restData.map((org: any) => {
                    console.log(org.documentId)
                })
            }
            setUsers(restData)
            if (restData) {
                var users = [];
                if (Array.isArray(restData)) {
                    users = restData;
                } else {
                    users.push(restData);

                }
                let ascendingUsers: any = [...restData].sort((a, b) => {
                    return b.documentId - a.documentId
                })
                setUsers(ascendingUsers)
                console.log(users)
            }
        }
    }
    let onLoginError = (err: any) => {
        console.log(err);
    }

    return (
        <div className="container-fluid container-fluid-padding mt-3" style={{ backgroundColor: 'white' }}>
            <div className="row fs-7 pt-1">
                <div className="ag-theme-alpine ag-theme-customeTheme gridHeight" style={{ height: "calc(100vh - 330px)", width: "100%", paddingLeft: "8px", paddingRight: "5px" }}>
                    <InspectionGrid cols={userColumns} rows={users} pageSize={100} addNew={true} tooltip={"Add Company"} name={"Add Company"}
                        headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={userGridRef1} method={{ onClickUserDetails }} />
                </div>
            </div>
        </div>
    )
}

export default CSVFiles