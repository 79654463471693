export class Events{

    static CREATE_EQUIPMENT = "Create Equipment";
    static SAVE_EQUIPMENT = "Save Equipment";
    static HIDE_AVAILABILITY = "Hide Availability";
    static SHOW_AVAILABILITY = "Show Availability";
    static EDIT_EQUIPMENT = "Edit Equipment";
    static EQUIPMENTS = "Equipments";
    static ADMINISTRATION = "Administration";
    static FAVORITIES = "Favorities";
    static EQUIPMENTS_VIEW = "Equipment View";
    static CONFIRM_RESERVATION = "Confirm Reservation";
    static SEARCH_DASHBOARD = "Search Dashboard";
    static AVAILABILITIES = "Availabilities";
    static LANGUAGE_CHANGE_EVENT = "languageChangeEvent";
    static SWITCH_CUSTOMER_EVENT = "SwitchCustomerEvent";
    static EQUIPMENT_EVENT = "EquipmentEvent";
    static BREAD_CRUMB_EVENT = "BreadCrumbEVent";
    static FAVORITE_EVENT = "FavoriteEVent";
    static RESERVATION_EVENT = "ReservationEVent";
    static OFFER_EVENT = "OfferEVent";


    static OWNER_RESERVATION = "Owner Reservations";
    static RENTER_RESERVATIONS = "Renter Reservations";

    static OWNER_OFFERS = "Owner Offers";
    static RENTER_OFFERS = "Renter Offers";

    static PROCESS_RESERVATION_EVENT = "ProcessReservationEvent";
    //static CREATE_EQUIPMENT = "ProcessReservationEvent";

    static dispatchBreadCrumbEvent() {
        const event = new CustomEvent(Events.BREAD_CRUMB_EVENT, {detail: {}});
        document.dispatchEvent(event);
    }

    static dispatchFavoriteEVent(){
        const event = new CustomEvent(Events.FAVORITE_EVENT, {detail: {}});
        document.dispatchEvent(event);
    }
    static dispatchReservationEVent(){
        const event = new CustomEvent(Events.RESERVATION_EVENT, {detail: {}});
        document.dispatchEvent(event);
    }
    static dispatchOfferEVent(){
        const event = new CustomEvent(Events.OFFER_EVENT, {detail: {}});
        document.dispatchEvent(event);
    }
    static dispatchChangeLanguage(){
        const event = new CustomEvent(Events.LANGUAGE_CHANGE_EVENT, {detail: {}});
        document.dispatchEvent(event);
    }

    static dispatchLoadProcessReservation(){
        const event = new CustomEvent(Events.PROCESS_RESERVATION_EVENT, {detail: {}});
        document.dispatchEvent(event);
    }

    static dispatchCreateEquipment(){
        const event = new CustomEvent(Events.CREATE_EQUIPMENT, {detail: {}});
        document.dispatchEvent(event);
    }

    static dispatchSaveEquipment(){
        const event = new CustomEvent(Events.SAVE_EQUIPMENT, {detail: {}});
        document.dispatchEvent(event);
    }

    static dispatchHideAvailabilityButton(){
        const event = new CustomEvent(Events.HIDE_AVAILABILITY, {detail: {}});
        document.dispatchEvent(event);
    }
    static dispatchShowAvailabilityButton(){
        const event = new CustomEvent(Events.SHOW_AVAILABILITY, {detail: {}});
        document.dispatchEvent(event);
    }




}