import React, {useEffect, useRef, useState} from 'react';
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import {MapData} from '../Components/MapData';

//import './Map.scss';

interface IMap extends google.maps.MapOptions {
    mapType: google.maps.MapTypeId;
    mapTypeControl?: boolean;
    setDistanceInKm: React.Dispatch<React.SetStateAction<number>>;
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle?: (map: google.maps.Map) => void;
}

interface IMarker {
    address: string;
    latitude: number;
    longitude: number;
}

type GoogleLatLng = google.maps.LatLng;
type GoogleMap = google.maps.Map;
type GoogleMarker = google.maps.Marker;
type GooglePolyline = google.maps.Polyline;
var assets: any = [];
var flag: boolean = false;

const Map: React.FC<IMap> = ({
                                 mapType, mapTypeControl = false, setDistanceInKm, onClick,
                                 onIdle
                             }) => {

    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<GoogleMap>();
    const [marker, setMarker] = useState<IMarker>();
    const [homeMarker, setHomeMarker] = useState<GoogleMarker>();
    const [googleMarkers, setGoogleMarkers] = useState<GoogleMarker[]>([]);
    const [listenerIdArray, setListenerIdArray] = useState<any[]>([]);
    const [LastLineHook, setLastLineHook] = useState<GooglePolyline>();

    const startMap = (): void => {
        if (!map) {
            defaultMapStart();
        } else {
            getEquipmentJsonToObject();
            // defaultMapStart();
            //const homeLocation = new google.maps.LatLng(17.3850, 78.4867);
            //setHomeMarker(addHomeMarker(homeLocation));
        }
    };
    useEffect(() => {
        startMap();
        if (map) {
            map.addListener("idle", () => {
                // map.unbind("tilesloaded");
                //console.log("map loaded");
                if (!flag) {
                    flag = true;
                    setMapStyle();
                    const homeLocation = new google.maps.LatLng(17.3850, 78.4867);
                    setHomeMarker(addHomeMarker(homeLocation));
                }

            })
        }
    }, [map, onClick, onIdle]);

    let setMapStyle = () => {
        var styles = {
            default: [],
            minimum: [
                {
                    featureType: "poi",
                    elementType: "labels.text",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
                {
                    featureType: "poi.business",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
                {
                    featureType: "transit",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
            ],
        };
        if (map) {
            map.setOptions({styles: styles["minimum"]});
        }

        getEquipmentJsonToObject();
    }

    let getEquipmentJsonToObject = () => {
        console.log(MapData.getMapData());
        assets = MapData.getMapData();
        // loaderAsset();
        fetch("/vehicles.json")
            .then(response => response.json())
            .then(data => {
                assets = data;
                console.log(assets);
                loaderAsset();
            });

        /* fetch("vehicles.json")
            .then(response => response.json())
            .then((jsonData) => {
                // jsonData is parsed json object received from url
                console.log(jsonData)
            })
            .catch((error) => {
                // handle your errors here
                console.log(error)
            })*/

    }

    let loaderAsset = () => {
        //console.log(assets);
        var myLatlng: any = null;
        // marker:GoogleMarker = null;
        var latlngbounds = new google.maps.LatLngBounds();
        var markers: any = [];
        const image = "/images/NewIcons/a30000.png";//"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";
        var infowindowEquipment = null;
        for (let i = 0; i < assets.length; i++) {
            myLatlng = new google.maps.LatLng(assets[i].latitude, assets[i].longitude);
            let marker: GoogleMarker = new google.maps.Marker({
                position: myLatlng,
                map: map,
                title: assets[i].placeName,
                icon: image
            });
            //marker.setIcon('images/moving.png');
            markers.push(marker);
            latlngbounds.extend(myLatlng);
            //var position2 = { lat: Number(lat1), lng: Number(long1) };
            marker.addListener('click', () => {
                console.log(assets[i].placeName);
                infowindowEquipment = new google.maps.InfoWindow({
                    minWidth: 320,
                    maxWidth: 400,
                });
                infowindowEquipment.setContent(assets[i].placeName);
                infowindowEquipment.open(map, marker);
                infowindowEquipment.setPosition(marker.getPosition());
            });
        }

        // const markerCluster = new MarkerClusterer({ map, markers });


        var mapCenter = latlngbounds.getCenter();
        if (latlngbounds && assets.length > 0) {
            if (map) {
                map.fitBounds(latlngbounds);
            }
        }
    }

    const defaultMapStart = (): void => {
        const defaultAddress = new google.maps.LatLng(17.3850, 78.4867);
        initMap(17, defaultAddress);
    };

    /*const initEventListener = ():void => {
        if (map) {
            google.maps.event.addListener(map, 'click', function(e) {
                coordinateToAddress(e.latLng);
            })
        }
    };*/
    // useEffect(initEventListener, [map]);

    /*const coordinateToAddress = async (coordinate: GoogleLatLng) => {
        const geocoder = new google.maps.Geocoder();
        await geocoder.geocode({ location: coordinate}, function (results, status) {
            if (status === 'OK') {
                setMarker({
                    address: results[0].formatted_address,
                    latitude: coordinate.lat(),
                    longitude: coordinate.lng()
                })
            }
        });
    };*/

    /* useEffect(() => {
         if (marker) {
             addMarker(new google.maps.LatLng(marker.latitude, marker.longitude));
         }
     }, [marker]);*/

    /* const addMarker = (location: GoogleLatLng): void => {
         const marker:GoogleMarker = new google.maps.Marker({
             position: location,
             map: map,
             icon: getIconAttributes('#000000')
         });

         setGoogleMarkers(googleMarkers => [...googleMarkers, marker]);

         const listenerId = marker.addListener('click', () => {
             const homePos = homeMarker?.getPosition();
             const markerPos = marker.getPosition();
             if (homePos && markerPos) {
                 const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(homePos, markerPos);
                 setDistanceInKm(Math.round(distanceInMeters / 1000));

                 if (LastLineHook) {
                     LastLineHook.setMap(null);
                 }

                 const line = new google.maps.Polyline({
                     path: [
                         { lat: homePos.lat(), lng: homePos.lng()},
                         { lat: markerPos.lat(), lng: markerPos.lng()},
                     ],
                     icons: [
                         {
                             icon: {
                                 path: google.maps.SymbolPath.FORWARD_OPEN_ARROW
                             },
                             offset: "100%"
                         }
                     ],
                     map: map,
                 });

                 setLastLineHook(line);
             }
         });

         setListenerIdArray(listenerIdArray => [...listenerIdArray, listenerId]);
     };*/

    /*useEffect(() => {
        listenerIdArray.forEach((listenerId) => {
           google.maps.event.removeListener(listenerId);
        });

        setListenerIdArray([]);
        setGoogleMarkers([]);
        googleMarkers.forEach((googleMarker) => {
            const markerPosition = googleMarker.getPosition();
            if (markerPosition) {
                addMarker(markerPosition);
            }
        });
    }, [LastLineHook]);*/

    const addHomeMarker = (location: GoogleLatLng): GoogleMarker => {
        const homeMarkerConst: GoogleMarker = new google.maps.Marker({
            position: location,
            map: map,
            icon: 'images/moving.png'

        });

        homeMarkerConst.addListener('click', () => {
            if (map) {
                map.panTo(location);
                map.setZoom(6);
            }
        });

        return homeMarkerConst;
    };

    const getIconAttributes = (iconColor: string) => {
        return {
            path: 'M11.0639 15.3003L26.3642 2.47559e-05L41.6646 15.3003L26.3638 51.3639L11.0639 15.3003 M22,17.5a4.5,4.5 0 1,0 9,0a4.5,4.5 0 1,0 -9,0Z',
            fillColor: iconColor,
            fillOpacity: 0.8,
            strokeColor: 'pink',
            strokeWeight: 2,
            anchor: new google.maps.Point(30, 50)
        };
    };

    const initMap = (zoomLevel: number, address: GoogleLatLng): void => {
        if (ref.current) {
            setMap(
                new google.maps.Map(ref.current, {
                    zoom: zoomLevel,
                    center: address,
                    mapTypeControl: mapTypeControl,
                    gestureHandling: 'greedy',
                    streetViewControl: true,
                    zoomControl: true,
                    fullscreenControl: true,
                    mapTypeId: mapType,
                    draggableCursor: 'pointer',
                    fullscreenControlOptions: {
                        position: google.maps.ControlPosition.TOP_RIGHT,
                    },
                    mapTypeControlOptions: {
                        position: google.maps.ControlPosition.BOTTOM_RIGHT,
                    },
                })
            );
        }
    };

    return (
        <div className="map-container">
            <div ref={ref} className="map-container__map" style={{"height": "90vh"}}></div>
        </div>
    );
};

export default Map;
