import {useRef, useState} from 'react';
import AGGrid from '../../../../Components/AGGrid';
import {IAGrid} from '../../../../Components/IInterfaces';
import ParkingRender from "../ParkingRender";
import {CommonUtil} from "../../../../utils/CommonUtil";
import DetectDevice from '../../../../utils/DetectDevice';
import MGrid from '../../../../Components/MGrid';
import { Constants } from '../../../Languages/Constants';
import noActionButton from '../noActionButton';
import TwicRequiredButton from "../TwicRequiredButton";

const ParkingLotList = ({handleShow, setParking, parkingList, setAddress, setAddress1, onClickEditParking}: any) => {

    const userGridRef = useRef<IAGrid>(null);
    const { isMobile } = DetectDevice();
    const onClickNewParking = (data: any) => {
        handleShow();
    }

    const [parkingColumns] = useState([
        {
            field: 'parkingLotName',
            headerName: CommonUtil.getMessageText(Constants.PARKING_LOT ,'Parking Lot Name'),
            resizable: true,
            width: 200,
            sortable: true,
            tooltipField:'parkingLotName',
            cellRenderer: ParkingRender,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'address',
            headerName: CommonUtil.getMessageText(Constants.LOCATION, 'Location'),
            resizable: true,
            width: 300,
            sortable: true,
            tooltipField:'address',
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'phoneNumber',
            headerName: CommonUtil.getMessageText(Constants.CONTACT_NO,'Contact Number'),
            resizable: true,
            width: 200,
            sortable: true,
            tooltipField:'phoneNumber',
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'email',
            headerName: CommonUtil.getMessageText(Constants.EMAIL, 'Email'),
            resizable: true,
            width: 200,
            sortable: true,
            tooltipField:'email',
            filter: 'agNumberColumnFilter'
        },

        {
            field: 'isDummy',
            headerName: "Is Dummy",
            resizable: true,
            width: 100,
            sortable: true,

            filter: 'agNumberColumnFilter'
        },

        {
            field: 'hourlyRate',
            headerName: CommonUtil.getMessageText(Constants.HOURLY_RATE, 'Hourly Rate'),
            resizable: true,
            width: 150,
            sortable: true,
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'dailyRate',
            headerName: CommonUtil.getMessageText(Constants.DAILY_RATE, 'Daily Rate'),
            resizable: true,
            width: 150,
            sortable: true,
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'weeklyRate',
            headerName: CommonUtil.getMessageText(Constants.WEEKLY_RATE, 'Weekly Rate'),
            resizable: true,
            width: 150,
            sortable: true,
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'monthlyRate',
            headerName: CommonUtil.getMessageText(Constants.MONTHLY_RATE,'Monthly Rate'),
            resizable: true,
            width: 150,
            sortable: true,
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'twicAccessRequired',
            headerName: CommonUtil.getMessageText(Constants.TWIC_REQUIRE,'Twic Required'),
            resizable: true,
            width: 150,
            sortable: true,
            cellRenderer: TwicRequiredButton,
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'status',
            headerName: CommonUtil.getMessageText(Constants.STATUS, 'Status'),
            resizable: true,
            width: 150,
            sortable: true,
            cellRenderer: noActionButton,
            filter: 'agNumberColumnFilter'

        },
    ]);

    return (
            <div className="row fs-7 pt-3">
                {
                    isMobile ? (
                            <MGrid id="driverList" cols={parkingColumns} rows={parkingList} pageSize={10} addNew={true}
                            tooltip={"Add Parking"}
                            headerHeight={35} rowHeight={40} ref={userGridRef} method={{onClickEditParking}}
                            addNewRecord={onClickNewParking}/>
                     ) : (
                            <div className="ag-theme-alpine gridHeight"
                            style={{height: CommonUtil.isRootUser() ? 'calc(100vh -  170px)' : 'calc(100vh -  120px)' , width: "100%", paddingLeft: "8px", paddingRight: "5px"}}>
                            <AGGrid id="driverList" cols={parkingColumns} rows={parkingList} pageSize={500} addNew={true}
                            tooltip={CommonUtil.getMessageText(Constants.ADD_PARKING, 'Add Parking')}
                            headerHeight={35} rowHeight={40} ref={userGridRef} method={{onClickEditParking}}
                            addNewRecord={onClickNewParking}/>
                            </div>
                    )
                }
            </div>
    );
}

export default ParkingLotList;
