import React, {useEffect, useRef, useState} from "react";
import AGGrid from "../../Components/AGGrid";
import {IAGrid} from "../../Components/IInterfaces";
import useStateWithCallback from "use-state-with-callback";
import OrganizationDetails from "./OrganizationDetails";
import OrgCellRenderer from "./OrgCellRenderer";
import {CommonUtil} from "../../utils/CommonUtil";
import {NetworkManager} from "../../Components/NetworkManager";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";
import ToastManager from "../../Components/ToastManager";
import {useNavigate} from "react-router-dom";
import SingleSelectComp from "../../Components/SingleSelectComp";
import orgUserStatucRenderer from "./OrgUserStatucRenderer";
import {Modal} from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import {subscribe, unsubscribe} from "../Login/CustomEvents";
import {Events} from "../Languages/Events";
import {Constants} from "../Languages/Constants";
import DetectDevice from "../../utils/DetectDevice";
import MGrid from "../../Components/MGrid";

var selectedOrg: any = {};
const OrganizationList = () => {
    const { isMobile } = DetectDevice();
    const [isRoot, setRoot] = useState(false);
    const [isCustomer, setCustomer] = useState(false);
    const [orgData, setOrgData] = useState(null);

    const orgListRef = useRef<HTMLDivElement>(null);
    const orgDetailsRef = useRef<HTMLDivElement>(null);
    const gridRef = useRef<IAGrid>(null);
    const [selOrg, setSelOrg] = useState(null);
    const [statues, setStatues] = useState(CommonUtil.statuses);
    const [statusObj, setStatusObj] = useState<any>(null);
    const [userStatus, setUserStatus] = useState("");

    const [showUserStatusPopup, setShowUerStatusPopup] = useState(false);
    const [page,setPage] = useState("");

    const navigate = useNavigate();

    const [rowData, setRowData] = useStateWithCallback([], () => {
        if (gridRef.current) {
            gridRef.current.columnFittoSize();
        }
    });
    const [columnDefs] = useState([
        {field: 'orgName', headerName: CommonUtil.getMessageText(Constants.ORG_NAME,'Organization'), resizable: true,
            width: 100,
            flex: 1,
            sortable: true,
            tooltip: (params: any) => params.value,
            cellRenderer: OrgCellRenderer,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'organizationType',
            headerName: CommonUtil.getMessageText(Constants.ORG_TYPE,'Organization Type'),
            resizable: true,
            width: 100,
            flex: 1,
            sortable: true,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'transportCommissionNumber',
            headerName: CommonUtil.getMessageText(Constants.DOT_NUMBER,'DOT Number'),
            resizable: true,
            width: 100,
            flex: 1,
            sortable: true,
            filter: 'agTextColumnFilter'
        },
        {
            field: 'ownerStatus',
            headerName: CommonUtil.getMessageText(Constants.OWNER_STATUS,'Owner Status'),
            resizable: true,
            width: 100,
            flex: 1,
            sortable: true,
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'renterStatus',
            headerName: CommonUtil.getMessageText(Constants.RENTER_STATUS,'Renter Status'),
            resizable: true,
            width: 100,
            flex: 1,
            sortable: true,
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'city',
            headerName: 'City',
            resizable: true,
            width: 100,
            sortable: true,
            flex: 1,
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'province',
            headerName: CommonUtil.getMessageText(Constants.PROVINCE_STATE,'Province/State'),
            resizable: true,
            width: 100,
            flex: 1,
            sortable: true,
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'country',
            headerName: CommonUtil.getMessageText(Constants.COUNTRY,'Country'),
            resizable: true,
            width: 100,
            flex: 1,
            sortable: true,
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'status',
            headerName: CommonUtil.getMessageText(Constants.STATUS,'Status'),
            resizable: true,
            width: 100,
            flex: 1,
            sortable: true,
            filter: 'agNumberColumnFilter',
            cellRenderer: orgUserStatucRenderer
        }
    ])
    useEffect(() => {
        showAllOrganizations();
        document.addEventListener(Events.LANGUAGE_CHANGE_EVENT, onLanguageChange);
        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        return () => {
            document.removeEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
            document.removeEventListener(Events.LANGUAGE_CHANGE_EVENT, onLanguageChange);
        }
    }, []);

    let onLanguageChange = ()=>{
        setPage(""+Math.random()*100000);
    }

    let onSwitchCustomer = ()=>{
        /*if(CommonUtil.isRootUser()){
            //navigate("orgList1");
        }else{
            navigate("orgList1");
           Events.dispatchBreadCrumbEvent();
        }*/
        Events.dispatchBreadCrumbEvent();
        //unsubscribe(Events.SWITCH_CUSTOMER_EVENT,()=>{onSwitchCustomer1()});
        //navigate("/menu");
    }

    let showAllOrganizations = () => {
        if (CommonUtil.isRootUser()) {
            setRoot(true);
            getAllOrgs();
        } else {
            setCustomer(true);
        }
    }

    let getAllOrgs = () => {
        NetworkManager.sendJsonRequest("api/organization/all", "", "", "GET", true, onGetAllOrgs, onLoginError);
    }

    let onGetAllOrgs = (data: any) => {
        //console.log(data);
        if (data) {
            var restData = CommonUtil.parseData(data);
            if (restData) {
                let orgs = [];
                if (Array.isArray(restData.organizations)) {
                    orgs = restData.organizations;
                } else {
                    orgs.push(restData.organizations);
                }
                setRowData(orgs);
            }
        }
    }
    let onLoginError = (err: any) => {
        console.log(err);
    }
    const onClickOrg = (data: any) => {
        localStorage.setItem("saasorgId", data.orgId);
       // navigate("orgDetails");
        setOrgData(data);
        if (data.root) {
            navigate("orgDetails");
        } else {
            navigate("/menu/custProfiles");
        }
    }
    let onClickActiveUser = (data: any) => {
        selectedOrg = data;
        setUserStatus(data.status);
        setShowUerStatusPopup(true);
    }
    let onOrgChange = (data: any) => {
        setSelOrg(data);
    }
    let onStatusChange = (data: any) => {
        setStatusObj(data);
    }
    let onClickSearch = () => {
        //console.log(selOrg, statusObj);
    }
    let onCloseUserStausPopup = () => {
        setShowUerStatusPopup(false);
    }
    let onClickActivateOrg = () => {
       // console.log(selectedOrg);
        if (selectedOrg) {
            let status: string = (selectedOrg.status == CommonUtil.ACTIVE ? CommonUtil.IN_ACTIVE : CommonUtil.ACTIVE);
            let changeStatusAPI: string = "api/organization/changeOrganizationStatus?saasOrgId=" + selectedOrg.orgId + "&orgStatus=" + status;
            NetworkManager.sendJsonRequest(changeStatusAPI, "", "", "PUT", true, onChangeOrgStatus, onLoginError);
        }
    }
    let onChangeOrgStatus = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let strOrgStatus = CommonUtil.getMessageText(Constants.ORG_STATUS_UPDATED,'Organization Status Updated Successfully');
                ToastManager.showToast(strOrgStatus, "Info");
                setShowUerStatusPopup(false);
                onCloseUserStausPopup();
                getAllOrgs();
            }
        }
    }
    return (
        <>
            <Modal show={showUserStatusPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{"width": "600px", "height": "200px"}}>
                    <div className="modal-header">
                        <h6 className="modal-title darkgrey-text fw-600">{(userStatus == CommonUtil.ACTIVE) ? CommonUtil.getMessageText(Constants.INACTIVE_ORG,'Inactivate Organization') : CommonUtil.getMessageText(Constants.ACTIVE_ORG,'Activate Organization')}</h6>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"
                                onClick={onCloseUserStausPopup}>
                            <span aria-hidden="true"></span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row fs-7">
                            {
                                (userStatus == CommonUtil.ACTIVE) ?
                                    <span className="dark-text fs-7">{CommonUtil.getMessageText(Constants.PREVENT_ORG,'This action will prevent the Organization from using the vHub Application')}</span> :
                                    <span className="dark-text fs-7">{CommonUtil.getMessageText(Constants.ACCESS_ORG,'This action will allow the Organization to access the vHub Application')}</span>
                            }
                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button" className="btn btn-outline-dark clsBoldText me-2 fw-600" data-bs-dismiss="modal"
                                onClick={onCloseUserStausPopup}>{CommonUtil.getMessageText(Constants.CLOSE,'Cancel')}
                        </button>
                        <button type="button" className="btn btn-success  fw-600"
                                onClick={onClickActivateOrg}>{(userStatus == CommonUtil.ACTIVE) ? CommonUtil.getMessageText(Constants.INACTIVATE,'Inactivate'): CommonUtil.getMessageText(Constants.ACTIVATE,'Activate')}</button>
                    </div>
                </div>

            </Modal>

            {isRoot && (
                <div ref={orgListRef} className="container-fluid container-fluid-padding" style={{paddingTop: "1px"}}>
                    <div className={`row fs-7 ${isMobile ? '':'pt-3'}`}>
                        {
                            isMobile ? (
                                <MGrid id="OrgGrid" cols={columnDefs} rows={rowData} pageSize={10}
                                    headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={gridRef}
                                    method={{onClickOrg, onClickActiveUser}} showHeader={false}/>
                            ) : (
                                <div className="ag-theme-alpine gridHeight"
                             style={{height: "calc(100vh - 120px)", width: "100%"}}>
                            <AGGrid id="OrgGrid" cols={columnDefs} rows={rowData} pageSize={500}
                                    headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={gridRef}
                                    method={{onClickOrg, onClickActiveUser}} showHeader={false}/>
                        </div>
                            )
                        }
                    </div>
                </div>
            )}
        </>
    );
}

export default OrganizationList;
