     import $ from 'jquery';
     
     const scrollBarWidths = 40;
     const widthOfList = function() {
        let itemsWidth = 0;
        $('.list a').each(function() {
            let itemWidth:any = $(this).outerWidth();
            itemsWidth += itemWidth;
        });
        return itemsWidth;
    };

    const widthOfHidden = function(){
            let ww :any =  $('.wrapper-nav').outerWidth();
            ww = 0 - ww;
            let navWrapperWidth : any = $('.wrapper-nav').outerWidth();
            let hw : any = (navWrapperWidth - widthOfList() - getLeftPosi()) - scrollBarWidths;
            let navItem :any = $('.nav-item.nav-link');
            let documentWidth:any = $(document).width();
            let rp = documentWidth - (navItem.last().offset().left + navItem.last().outerWidth() + (scrollBarWidths * 2) );
            if (ww > hw) {
                //return ww;
                return (rp > ww ? rp : ww);
            }
            else {
                //return hw;
                return (rp > hw ? rp : hw);
            }
    };

     const getLeftPosi = function(){
        let ww : any =  $('.wrapper-nav').width();
        ww = 0 - ww;
        let lp = $('.list').position().left;
        if (ww > lp) {
            return ww;
        }
        else {
            return lp;
        }
    };

    const reAdjust = function(){
        // check right pos of last nav item
        let documentWidth:any = $(document).width();
        let lastLeft:any = $('.nav-item.nav-link');
        let lastOuterWidth:any = $('.nav-item.nav-link').last().outerWidth();
        let wrapperOuterWidth:any = $('.wrapper-nav').outerWidth();
        let rp:any = documentWidth -  (lastLeft.last()?.offset()?.left + lastOuterWidth);
        if (wrapperOuterWidth < widthOfList() && (rp < 0)) {
            $('.scroller-right').show().css('display', 'flex');
        }
        else {
            $('.scroller-right').hide();
        }
        
        if (getLeftPosi() < 0) {
            $('.scroller-left').show().css('display', 'flex');
        }
        else {
            $('.scroller-left').hide();
        }
    }

    const rightClickScroll = () => {
        $('.scroller-left').fadeIn('fast');
        $('.scroller-right').fadeOut('fast');
        $('.list').animate({ left: `+=${widthOfHidden()}px` }, 'fast', function() {
            reAdjust();
        });
    }

    const leftClickScroll = () => {
        $('.scroller-right').fadeIn('fast');
        $('.scroller-left').fadeOut('fast');

        $('.list').animate({ left: `-=${getLeftPosi()}px` }, 'fast', function() {
            reAdjust();
        });
    }


    export {leftClickScroll, rightClickScroll, reAdjust}