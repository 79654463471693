import React, { useRef } from "react";
import CarouselComponent, { InnerImage } from "../CarouselComponent";
import "../Interchange.css";
import { useState, useEffect } from "react";
import { NetworkManager } from "../../../Components/NetworkManager";
import { CommonUtil } from "../../../utils/CommonUtil";
import ReactImageGallery from "../ReactImageGallery";
import ImageLoader from "../ImageLoader";
import { Constants } from "../../Languages/Constants";
import InspectionGrid from "../../InspectionGrid/InspectionGrid";
import NewReleaseCellRender from "../EntryInspectionDetails/NewReleaseCellRender";
import AttachmentsCellRenderer from "./AttachmentsCellRenderer";
import { TopEventSelector } from "./UpdatedVisualCompare";
let eventSelected: any = [];
const UpdateCommentsAndSignatureCompare = () => {
  const [refeerDetails, setRefeerDetails] = useState<any>([]);
  const [ctpatDetails, setCtpatDetails] = useState<any>([]);
  const [userColumns, setUserColumns] = useState<any>([]);
  const [releases, setReleases] = useState<any>([]);
  const [selectedEvents, setSelectedEvents] = useState<any>(eventSelected);

  const compareCTPATInspection = () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var orgUrl = `api/webInspectionDetails/multiRelease/compareCtpatInspection?docId=${qparam}&eventId=${selectedEvents}`;
    // var orgUrl = `api/webInspectionDetails/multiRelease/compareVisualInspection?docId=${qparam}&eventId=129,130,132`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onCompareCTPATInspection,
      onLoginError
    );
  };

  const getEventsOnlyForIdAndStatus = () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var orgUrl = `api/webInspectionDetails/getEventsOnlyForIdAndStatus?docId=${qparam}`;
    // var orgUrl = `api/webInspectionDetails/multiRelease/compareVisualInspection?docId=${qparam}&eventId=129,130,132`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onEventsOnlyForIdAndStatus,
      onLoginError
    );
  };

  const onEventsOnlyForIdAndStatus = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      console.log(restData);

      setReleases(restData);
      if (restData?.length > 0) {
        eventSelected.push(restData[0].eventId);
        setSelectedEvents(eventSelected);
        // compareVisualInspection()
      }
    }
  };

  useEffect(() => {
    // getEventsOnlyForIdAndStatus();
    compareCTPATInspection();
  }, [selectedEvents]);

  // useEffect(() => {
  //   getEventsOnlyForIdAndStatus();
  //   // compareVisualInspection();
  // }, []);

  let onCompareCTPATInspection = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      console.log(restData);

      //   setVisualDetails(restData[0].taskDetailsAttachment);

      let rowData: any = [];

      for (let i = 0; i < restData.length; i++) {
        const inspectionData = restData[i]?.filter(
          (ele: any) => ele.categoryName === "SIGNATURE"
        );
        const groupName: string = restData[i][0].entryEventCode;
        const entryStartDate: string = restData[i][0].entryStartDate;
        const exitStartDate: string = restData[i][0].exitStartDate;
        let obj: any = {};
        obj["groupName"] = groupName;
        obj["date"] = entryStartDate;
        inspectionData?.map((ele: any) => {
          obj = { ...obj, ...ele };
          obj[`comment`] = obj.entryComments;
          obj[`sign1`] = obj.entrySignatureFilePath;
          obj[`sign2`] = obj.entrySignatureFilePath2;
        });
        rowData.push(obj);
        if (i === restData.length - 1) {
          try {
            let exitObj: any = { ...obj };
            exitObj["groupName"] = "Receipt";
            exitObj["date"] = exitStartDate;
            exitObj[`comment`] = exitObj.exitComments;
            exitObj[`sign1`] = exitObj.exitSignatureFilePath;
            exitObj[`sign2`] = exitObj.exitSignatureFilePath2;

            rowData.push(exitObj);
          } catch (e) {
            console.log(e);
          }
        }
      }

      setCtpatDetails(rowData);
    }
  };

  let onLoginError = (err: any) => {
    console.log(err);
  };

  let Capitalize = (str: any) => {
    str = String(str);
    let strText = str.charAt(0).toUpperCase() + str.slice(1);

    return strText.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
  };

  const updateSelction = (id: any, checked: boolean) => {
    let checkboxCheck: any = [];
    try {
      checkboxCheck = selectedEvents ? selectedEvents.split(",") : [];
      if (checkboxCheck.indexOf(String(id)) != -1) {
        if (!checked) {
          checkboxCheck.splice(checkboxCheck.indexOf(String(id)), 1);
        }
      } else {
        if (checked) {
          checkboxCheck.push(String(id));
        }
      }
    } catch (e) {
      checkboxCheck = [];
    }
    eventSelected = checkboxCheck.join(",");

    setSelectedEvents(eventSelected);
  };

  let checkboxCheck: any = [];
  try {
    checkboxCheck = selectedEvents.split(",");
  } catch (e) {
    checkboxCheck = [];
  }
  console.log(checkboxCheck);

  return (
    <div className="containData">
      <TopEventSelector
        releases={releases}
        checkboxCheck={checkboxCheck}
        setReleases={setReleases}
        eventSelected={eventSelected}
        // updateSelction={updateSelction}
        setSelectedEvents={setSelectedEvents}
        selectedEvents={selectedEvents}
      />
      <hr className="mb-3" style={{ width: "98%" }} />

      <div className="row">
        <div
          className="ag-theme-alpine ag-theme-customeTheme gridHeight"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "row",
            paddingLeft: "8px",
            paddingRight: "5px",
          }}
        >
          {ctpatDetails?.map((ele: any, i: number) => (
            <div style={{ width: "300px" }} key={ele.groupName}>
              <div className="mb-2">
                <b>{ele.groupName}</b>
                {` Date: ${ele.date}`}
              </div>
              <textarea style={{ width: "100%" }} disabled className="mb-2">
                {ele.comment}
              </textarea>
              <div style={{ minHeight: "250px" }}>
                {ele.sign1 && (
                  <div className="mb-2">
                    <ImageLoader path={ele.sign1 || ""} />
                  </div>
                )}
              </div>
              {ele.sign2 && (
                <div className="mb-2">
                  <ImageLoader path={ele.sign2 || ""} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UpdateCommentsAndSignatureCompare;
