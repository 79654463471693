import React, {useEffect, useRef, useState} from 'react'
import SingleSelectComp from '../../Components/SingleSelectComp'
import AGGrid from '../../Components/AGGrid'
import OrgCellRenderer from '../Organization/OrgCellRenderer'
import orgUserStatucRenderer from '../Organization/OrgUserStatucRenderer'
import {IAGrid} from '../../Components/IInterfaces'
import useStateWithCallback from 'use-state-with-callback'
import {useNavigate} from 'react-router-dom'
import {CommonUtil} from '../../utils/CommonUtil'
import {NetworkManager} from '../../Components/NetworkManager'
import ToastManager from '../../Components/ToastManager'
import {StatusCodeUtil} from '../../utils/StatusCodeUtil'
import {CloseButton, Modal} from 'react-bootstrap'
import DraggableComponent from '../../Components/DraggableComponent'
//import specific from '../../images/specific-equipment.png'
//import generic from '../../images/general-equipment.png'

import dummyData from './dummy.json'
import EquipCellRenderer from "../Organization/EquipCellRenderer";
import ReservationCellRenderer from "../Organization/ReservationCellRenderer";
import EquipAvailTypeRenderer from "../Organization/EquipAvailTypeRenderer";
import DetectDevice from '../../utils/DetectDevice'
import MGrid from '../../Components/MGrid'
import {Constants} from "../Languages/Constants";
import {Events} from "../Languages/Events";

const FavoritiesList = () => {
    const { isMobile } = DetectDevice();
    const [orgData, setOrgData] = useState(null)
    const [equipData, setEquipData] = useStateWithCallback<any>([], () => {
        if(gridRef.current){
            gridRef.current.columnFittoSize();
        }
    })

    const [showEquipPopup, setShowEquipPopup] = useState(false)

    const gridRef = useRef<IAGrid>(null)

    const navigate = useNavigate();
    const [isCompanyVisible,setCompanyVisible] = useState(CommonUtil.isRootUser());

    const [eqpColumnDefs] = useState([
        {field: 'unitNumber', headerName: CommonUtil.getMessageText(Constants.EQUIP_NO_NAME,"Equipment Number / Name"), resizable: true, width: 250, sortable: true,  filter: 'agTextColumnFilter'},
        {field: 'equipmentType', headerName: CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'brand', headerName: CommonUtil.getMessageText(Constants.BRAND,"Brand"), resizable: true, width: 100, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'isGeneric', headerName: CommonUtil.getMessageText(Constants.IS_GENERIC,"Is Generic"), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'listingType', headerName: CommonUtil.getMessageText(Constants.AVAILABILITY_TYPE,"Availability Type"), resizable: true, width: 200, sortable: true,  filter: 'agNumberColumnFilter'},
        {field: 'startDate', headerName: CommonUtil.getMessageText(Constants.AVAILABILITY_FROM_DATE,"Availability From Date"), resizable: true, width: 200, sortable: true,  filter: 'agNumberColumnFilter'},
        {field: 'endDate', headerName: CommonUtil.getMessageText(Constants.AVAILABILITY_TO_DATE,"Availability To Date"), resizable: true, width: 200, sortable: true,  filter: 'agNumberColumnFilter'},
        {field: 'details', headerName: CommonUtil.getMessageText(Constants.AVAILABILITY_DETAILS,"Availability Details"),resizable: true, width: 200,  sortable: true, filter: 'agNumberColumnFilter',cellRenderer: EquipAvailTypeRenderer},
        {field: 'overAllRating', headerName: CommonUtil.getMessageText(Constants.RATING,"Rating"),resizable: true, width: 100,  sortable: true, filter: 'agNumberColumnFilter'},
    ]);

    const [companies, setCompanies] = useState([])
    const [selCompanyObj, setSelCompanyObj] = useState<any>(null)

    const [assetTypes, setAssetTypes] = useState<any>() //CommonUtil.ASSET_TYPES
    const [selAssetObj, setSelAssetObj] = useState<any>(null)

    const [equipTypes, setEquipTypes] = useState<any>([]) //CommonUtil.TRAILER_EQP_TYPES
    const [selEquipObj, setSelEquipObj] = useState<any>(null)

    const [brands, setBrands] = useState<any>([]) //CommonUtil.TRAILER_BRAND
    const [selBrandObj, setSelBransObj] = useState<any>(null)

    const [modals, setModals] = useState<any>([]) //CommonUtil.EQUiP_MODELS
    const [selModalObj, setSetModalObj] = useState<any>(null)

    //const [rowData,setRowData] = useState([]);

    useEffect(() => {
        if(CommonUtil.SCREEN_FROM == "View"){
            //navigate('equipDetails');
            //CommonUtil.SCREEN_FROM = "";
        }else{
            getFavorList()
            if(CommonUtil.isRootUser()){
                CommonUtil.getAllOrgs((data: any) => {
                    setOrgData(data)
                })
            }

            CommonUtil.getAllAssetTypes((data: any) => {
                CommonUtil.ASSET_TYPES = data;
                setAssetTypes(data)
            })
            CommonUtil.getAllModels((data: any) => {
                CommonUtil.EQUiP_MODELS = data;
                setModals(data)
            })
        }
        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        return () => {
            document.removeEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        }

    }, [])
    let onSwitchCustomer = () => {
        /* if (CommonUtil.isRootUser()) {
             CommonUtil.getAllOrgs((data: any) => {
                 setOrgData(data)
             })
         }
         setEquipData([]);
         //setCompanyVisible(CommonUtil.isRootUser());
         if (CommonUtil.isRootUser()) {
             setSelCompanyObj(null);
         } else {
             getAvailabilityList();
         }*/
        Events.dispatchBreadCrumbEvent();
    }



    let getFavorList = () => {
        let qParams: string = 'saasOrgId=' + (selCompanyObj ? selCompanyObj.orgId : '');
        qParams = qParams + '&' + 'model=' + (selModalObj ? selModalObj.key : '');
        qParams = qParams + '&' + 'equipmentTypeId=' + (selEquipObj ? selEquipObj.key : '');
        qParams = qParams + '&' + 'brand=' + (selBrandObj ? selBrandObj.key : '');
        qParams = qParams + '&' + 'assetTypeId=' + (selAssetObj ? selAssetObj.key : '');

        setEquipData([]);
        let equipAllAPI: string = 'api/favorite/all?';// + qParams;
        let obj: any = {};
        NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGetAllFavors, onLoginError);
    }

    let onGetAllFavors = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                let equips = []
                if (Array.isArray(restData)) {
                    equips = restData
                } else {
                    equips.push(restData)
                }
               // props.data.unitNumber?props.data.unitNumber:props.data.genericEquipmentName
                restData.forEach((item:any,idx:number)=>{
                    item.unitNumber = item.unitNumber?item.unitNumber:item.genericEquipmentName;
                });
                setEquipData(equips);
            }
        }
    }

    let onClickLink = (data: any) => {
        CommonUtil.SELECT_EQUIP_ID = data.equipmentId
        // console.log(data);
        navigate('viewreservation');
        //navigate('equippublic');
    }
    let onClickAddEquipment = () => {
        CommonUtil.SELECT_EQUIP_ID = ''
        navigate("viewreservation");
       // setShowEquipPopup(true)
        // navigate("equipDetails");
    }

    let onCompanyChange = (dataObj: any) => {
        setSelCompanyObj(dataObj)
    }

    let onAssetTypeChange = (dataObj: any) => {
        setSelAssetObj(dataObj)
        // getEquipmentTypes(dataObj);
        setSelEquipObj(null);
        setEquipTypes([]);
        setSelBransObj(null);
        setBrands([]);

        if (dataObj) {
            CommonUtil.getEquipmentTypes(dataObj.key, (data: any) => {
                setEquipTypes(data)
            })
            CommonUtil.getBrands(dataObj.key, (data: any) => {
                setBrands(data)
            })
        }
    }

    let onEquipmentTypeChange = (dataObj: any) => {
        setSelEquipObj(dataObj)
    }

    let onModalChange = (dataObj: any) => {
        setSetModalObj(dataObj)
    }

    let onBrandChange = (dataObj: any) => {
        setSelBransObj(dataObj)
    }

    let onCloseEquipPopup = () => {
        setShowEquipPopup(false)
    }

    let onClickGeneric = () => {
        CommonUtil.IS_GENERIC_EQUIP = true
        navigate('equipDetails')
        onCloseEquipPopup()
    }

    let onClickSpecific = () => {
        CommonUtil.IS_GENERIC_EQUIP = false
        navigate('equipDetails')
        onCloseEquipPopup()
    }

    let onLoginError = (err: any) => {
        console.log(err)
    }

    return (
        <>
            <div className="container-fluid container-fluid-padding" style={{paddingTop: '1px'}}>
                <div className={`row ${isMobile ? '' : 'pt-3'}`}>
                    {isMobile ? (
                        <MGrid id="OrgFavourGrid" cols={eqpColumnDefs}
                            rows={equipData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                            ref={gridRef} addNew={false} method={{onClickLink}} />
                    ) : (
                    <div className="ag-theme-alpine gridHeight"
                        style={{height: 'calc(100vh - 110px)', width: '100%'}} >
                        <AGGrid id="OrgFavourGrid" cols={eqpColumnDefs}
                            rows={equipData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                            ref={gridRef} addNew={false} method={{onClickLink}} />
                    </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default FavoritiesList;
