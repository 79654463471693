import React from "react";
import Draggable from "react-draggable";
import {ModalDialog} from "react-bootstrap";

const DraggableComponent = (props: any) => {
    return (
        <Draggable handle=".modal-header">
            <ModalDialog {...props} className={"modal-dialog-centered"}/>
        </Draggable>
    );
}

export default DraggableComponent;
