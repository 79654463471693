import React, { useEffect, useState, useRef } from "react";
import { CommonUtil } from "../../../utils/CommonUtil";
import { Constants } from "ag-grid-community";
import SingleSelectComp from "../../../Components/SingleSelectComp";
import { NetworkManager } from "../../../Components/NetworkManager";
import { Button } from "react-bootstrap";
import BusyLoader from "../../../Components/BusyLoader";
import { useReleaseInspectorContext } from "../../../Components/ReleaseContext";
import { StatusCodeUtil } from "../../../utils/StatusCodeUtil";
import ToastManager from "../../../Components/ToastManager";
import $ from "jquery";
import AttachmentsViewer from "./AttachmentsViewer";

const NewEntryTiresInsp = () => {
  const [assetTypes, setAssetTypes] = useState(CommonUtil.ASSET_TYPES);
  const [brandOther, setBrandOther] = useState("");
  const [assets, setAssets] = useState("");
  const [brands, setBrands] = useState("");
  const [sizes, setSizes] = useState("");
  const [depths, setDepths] = useState("");
  const [varients, setVarients] = useState([
    "1-Left Side Inner",
    "1-Left Side Outer",
    "1-Right Side Inner",
    "1-Right Side Outer",
  ]);
  const { state, dispatch } = useReleaseInspectorContext();
  const qPath = CommonUtil.getInterchangeDocumentId();
  const fileref = useRef<HTMLInputElement>(null);
  const prvRef = useRef(null);
  let fileType: string = "";
  let postionLabel: string = "";

  const initState: any = {
    position: "",
    brand: "",
    otherBrand: "",
    size: "",
    otherSize: "",
    threadDepth: "",
    otherDepth: "",
    condition: "",
    isOriginal: true,
    isRecap: false,
    comments: "",
    attachments: {
      attachmentUploads: [],
    },
  };

  const [formData, setFormData] = useState<any>(initState);

  useEffect(() => {
    if (state?.tyresInspection?.trailer?.length === 0) {
      setFormData(initState);
    }
  }, [state]);

  useEffect(() => {
    getAvailableTyreBrands();
    getAvailableSize();
    getAvailableTyreDepth();
    getTyresSequence();
  }, []);

  const getTyresSequence = () => {
    let api: string = `api/webInspectionDetails/getTyresSequence?docId=${qPath}`;
    NetworkManager.sendJsonRequest(
      api,
      "",
      "",
      "GET",
      true,
      (dataObj: any) => {
        if (dataObj) {
          setVarients(JSON.parse(dataObj).trailer);
          try {
            const trailer = JSON.parse(dataObj).trailer;
            let formDataObj: any = {};
            trailer.map((item: any, index: any) => {
              formDataObj[item] = { ...initState, position: item };
              let stateData = state?.tyresInspection?.trailer?.filter(
                (ele: any) => ele.position === item
              )[0];
              if (stateData) {
                formDataObj[item] = stateData;
              }
            });
            setFormData(formDataObj);
          } catch (e) {
            console.log(e);
          }
        }
      },
      onLoginError
    );
  };

  const getAvailableTyreBrands = () => {
    let api: string = "api/tyreMasterData/brands";
    NetworkManager.sendJsonRequest(
      api,
      "",
      "",
      "GET",
      true,
      (dataObj: any) => {
        if (dataObj) {
          setBrands(JSON.parse(dataObj));
        }
      },
      onLoginError
    );
  };

  const getAvailableSize = () => {
    let api: string = "api/tyreMasterData/sizes";
    NetworkManager.sendJsonRequest(
      api,
      "",
      "",
      "GET",
      true,
      (dataObj: any) => {
        if (dataObj) {
          setSizes(JSON.parse(dataObj));
        }
      },
      onLoginError
    );
  };

  const getAvailableTyreDepth = () => {
    let api: string = "api/tyreMasterData/depths";
    NetworkManager.sendJsonRequest(
      api,
      "",
      "",
      "GET",
      true,
      (dataObj: any) => {
        if (dataObj) {
          setDepths(JSON.parse(dataObj));
        }
      },
      onLoginError
    );
  };
  const onAddPictures = (fType: string, label: string) => {
    fileType = fType;
    postionLabel = label;
    if (fileref.current) {
      fileref.current.value = "";
      $("#inputTyres").click();
    }
  };

  const onLoginError = () => {
    console.log("Error");
  };

  let onSelectFiles = (e: any) => {
    if (e && e.target && e.target.files[0]) {
      var file = e.target.files[0];
      let fileName = e.target.files[0].name;
      BusyLoader.showLoader();
      let imagePath =
        `attachments/tyres/${qPath}/${state?.eventId}/${postionLabel}/${fileName}` +
        "-" +
        new Date().getTime();

      let qParam: string = "objectPath=" + imagePath;
      NetworkManager.sendJsonRequest(
        "api/session/uploadUrl",
        "",
        qParam,
        "GET",
        false,
        (dataObj: any) => {
          if (dataObj) {
            if (dataObj.statusCode) {
              BusyLoader.hideLoader();
              var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode);
              ToastManager.showToast(errMsg, "Error");
            } else {
              var strUploadPath = dataObj;

              // const formData = new FormData();
              var myHeaders = new Headers();
              var requestOptions: any = {
                method: "PUT",
                headers: myHeaders,
                body: file,
                redirect: "follow",
              };
              fetch(strUploadPath, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                  BusyLoader.hideLoader();
                  const prevSt: any = { ...formData[postionLabel] };
                  let attachments: any = { ...prevSt.attachments };
                  if (attachments?.attachmentUploads?.length > 0)
                    attachments?.attachmentUploads?.push({
                      fullFilePath: imagePath,
                    });
                  else
                    attachments.attachmentUploads = [
                      { fullFilePath: imagePath },
                    ];

                  updateFormData("attachments", attachments, postionLabel);
                  // setFormData({
                  //   ...prevSt,
                  //   attachments: attachments,
                  // });
                })
                .catch((error) => {
                  console.log("error", error);
                });
            }
          }
        },
        onLoginError
      );
    }
  };

  const conditionData = [
    {
      condition: "Fair",
      value: "Fair",
    },
    {
      condition: "Good",
      value: "Good",
    },
    {
      condition: "Scrap",
      value: "Scrap",
    },
    {
      condition: "Wear",
      value: "Wear",
    },
  ];

  useEffect(() => {
    let isFilled: any = [];
    if (state?.tyresInspection?.trailer?.length === varients?.length) {
      isFilled = state?.tyresInspection?.trailer?.filter(
        (item: any) => item.attachments.attachmentUploads.length === 0
      );
      if (isFilled.length === 0) {
        dispatch({
          type: "tyresInspection",
          payload: {
            isCompleted: true,
          },
        });
      }
    }
  }, [state?.tyresInspection?.trailer]);

  const updateTiresData = (e: any, position: any, num: number) => {
    e.preventDefault();
    const formDataObj = { ...formData[position] };
    if (
      formDataObj?.brand === "" ||
      formDataObj?.size === "" ||
      formDataObj?.threadDepth === "" ||
      formDataObj?.attachments?.attachmentUploads?.length === 0 ||
      formDataObj?.condition === "" ||
      (formDataObj?.attachments?.attachmentUploads?.length > 0 &&
        formDataObj?.attachments?.attachmentDamageDescription === "")
    ) {
      ToastManager.showToast("Please fill all the required fields", "Error");
    } else {
      const form = {
        ...formDataObj,
      };
      form.position = position;
      dispatch({ type: "tyresInspection", payload: form });
      ToastManager.showToast("Successfully saved tire data", "Success");

      // dispatch({
      //   type: "tyresInspection",
      //   payload: {
      //     isCompleted: num,
      //   },
      // });

      // const isFilled = state?.tyresInspection?.trailer?.filter(
      //   (item: any) => item.attachments.attachmentUploads.length === 0
      // );
      // if (isFilled.length === 0) {
      //   dispatch({
      //     type: "tyresInspection",
      //     payload: {
      //       isCompleted: true,
      //     },
      //   });
      // }
    }
  };

  const updateFormData = (key: any, value: any, name: any) => {
    let formObj = { ...formData };
    formObj[name] = { ...formObj[name] };
    formObj[name][key] = value;
    setFormData({ ...formObj });
  };

  return (
    <div>
      <div
        className="tires"
        style={{
          paddingRight: "12px",
          height: "420px",
          minHeight: "420px",
          overflowY: "scroll",
        }}
      >
        {varients?.map((itemLabel: any, index: any) => {
          let stateData = { ...formData[itemLabel] };
          return (
            <form
              key={index}
              onSubmit={(e: any) => updateTiresData(e, itemLabel, index)}
            >
              <div className="card shadow-sm">
                <div className="card-header">
                  <div className="card-title">
                    {String(itemLabel).toUpperCase()}
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-4 my-2">
                      <label
                        style={{
                          fontSize: "0.7rem",
                          fontWeight: "normal",
                          color: "#666",
                          marginLeft: "5px",
                        }}
                      >
                        Brands
                      </label>
                      <SingleSelectComp
                        dataSource={brands}
                        selectedValue={{ brand: stateData?.brand }}
                        name="brand"
                        handleChange={(e: any) => {
                          updateFormData("brand", e.brand, itemLabel);
                        }}
                        width={"100%"}
                        value={"brand"}
                        label={"brand"}
                        //className={"select-box-invalid"}
                        placeholder={"Select Brand"}
                      />
                    </div>
                    {stateData.brand === "Other" && (
                      <>
                        <div className="col-lg-4 my-2">
                          <label
                            style={{
                              fontSize: "0.7rem",
                              fontWeight: "normal",
                              color: "#666",
                              marginLeft: "5px",
                            }}
                          >
                            Other Brand
                          </label>
                          <input
                            type="text"
                            name="brand"
                            className="form-control clsFormInputControl"
                            onChange={(e: any) =>
                              // setFormData({ ...formData, condition: e.target.value })
                              updateFormData(
                                "otherBrand",
                                e.target.value,
                                itemLabel
                              )
                            }
                            value={stateData?.otherBrand}
                          />
                        </div>
                      </>
                    )}

                    {/* <div className="col-lg-4 my-2">
                      <input
                        type="text"
                        name="brand"
                        className="form-control clsFormInputControl"
                        onChange={(e: any) =>
                          // setFormData({ ...formData, condition: e.target.value })
                          updateFormData("otherBrand", e.target.value, itemLabel)
                        }
                        value={stateData?.otherBrand}
                      />
                    </div> */}

                    <div className="col-lg-4 my-2">
                      <label
                        style={{
                          fontSize: "0.7rem",
                          fontWeight: "normal",
                          color: "#666",
                          marginLeft: "5px",
                        }}
                      >
                        Size
                      </label>
                      <SingleSelectComp
                        dataSource={sizes}
                        name="size"
                        selectedValue={{ size: stateData?.size }}
                        handleChange={(e: any) => {
                          updateFormData("size", e.size, itemLabel);
                        }}
                        width={"100%"}
                        value={"tyreSizeId"}
                        label={"size"}
                        //className={"select-box-valid"}
                        placeholder={"Select Asset Type"}
                      />
                    </div>

                    {stateData.size === "Other" && (
                      <>
                        <div className="col-lg-4 my-2">
                          <label
                            style={{
                              fontSize: "0.7rem",
                              fontWeight: "normal",
                              color: "#666",
                              marginLeft: "5px",
                            }}
                          >
                            Other Size
                          </label>
                          <input
                            type="text"
                            name="othersize"
                            className="form-control clsFormInputControl"
                            onChange={(e: any) =>
                              // setFormData({ ...formData, condition: e.target.value })
                              updateFormData(
                                "otherSize",
                                e.target.value,
                                itemLabel
                              )
                            }
                            value={stateData?.otherSize}
                          />
                        </div>
                      </>
                    )}

                    <div className="col-lg-4 my-2">
                      <label
                        style={{
                          fontSize: "0.7rem",
                          fontWeight: "normal",
                          color: "#666",
                          marginLeft: "5px",
                        }}
                      >
                        Thread Depth
                      </label>
                      <SingleSelectComp
                        dataSource={depths}
                        name="threadDepth"
                        selectedValue={{ tyreDepth: stateData?.threadDepth }}
                        handleChange={(e: any) => {
                          updateFormData("threadDepth", e.tyreDepth, itemLabel);
                        }}
                        width={"100%"}
                        value={"tyreDepth"}
                        label={"tyreDepth"}
                        //className={"select-box-invalid"}
                        placeholder={"Select Asset Type"}
                      />
                    </div>
                    {stateData.threadDepth === "Other" && (
                      <>
                        <div className="col-lg-4 my-2">
                          <label
                            style={{
                              fontSize: "0.7rem",
                              fontWeight: "normal",
                              color: "#666",
                              marginLeft: "5px",
                            }}
                          >
                            Other Depth
                          </label>
                          <input
                            type="text"
                            name="otherDepth"
                            className="form-control clsFormInputControl"
                            onChange={(e: any) =>
                              // setFormData({ ...formData, condition: e.target.value })
                              updateFormData(
                                "otherDepth",
                                e.target.value,
                                itemLabel
                              )
                            }
                            value={stateData?.otherSize}
                          />
                        </div>
                      </>
                    )}

                    <div className="col-lg-4 my-2">
                      <label
                        style={{
                          fontSize: "0.7rem",
                          fontWeight: "normal",
                          color: "#666",
                          marginLeft: "5px",
                        }}
                      >
                        Condition
                      </label>
                      {/* <input
                        type="text"
                        name="condition"
                        className="form-control clsFormInputControl"
                        onChange={(e: any) =>
                          updateFormData("condition", e.target.value, itemLabel)
                        }
                        value={stateData?.condition}
                      /> */}
                      <SingleSelectComp
                        dataSource={conditionData}
                        selectedValue={{ condition: stateData?.condition }}
                        name="condition"
                        handleChange={(e: any) => {
                          updateFormData("condition", e.condition, itemLabel);
                        }}
                        width={"100%"}
                        value={"condition"}
                        label={"condition"}
                        //className={"select-box-invalid"}
                        placeholder={"Tire Condition"}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label
                        style={{
                          fontSize: "0.7rem",
                          fontWeight: "normal",
                          color: "#666",
                          marginLeft: "5px",
                        }}
                      >
                        Comments
                      </label>
                      <textarea
                        onChange={(e: any) => {
                          updateFormData("comments", e.target.value, itemLabel);
                        }}
                        className="form-control"
                        placeholder="Description"
                        rows={6}
                        value={stateData?.comments}
                      ></textarea>
                    </div>
                    <div className="col-lg-4 my-2">
                      <div className="flex-row">
                        <label
                          style={{
                            fontSize: "0.7rem",
                            fontWeight: "normal",
                            color: "#666",
                            marginLeft: "5px",
                          }}
                        >
                          Tire type
                        </label>
                      </div>
                      <div className="d-inline-flex justify-content-between">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e: any) => {
                              let formObj = { ...formData };
                              formObj[itemLabel] = { ...formObj[itemLabel] };
                              formObj[itemLabel]["isOriginal"] =
                                e.target.checked;
                              formObj[itemLabel]["isRecap"] = false;
                              setFormData({ ...formObj });
                            }}
                            name={itemLabel}
                            id={itemLabel + 1}
                            checked={stateData?.isOriginal}
                            //value={JSON.stringify(!stateData?.isRecap)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={itemLabel + 1}
                          >
                            Original
                          </label>
                        </div>{" "}
                        &nbsp;&nbsp;
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            onChange={(e: any) => {
                              let formObj = { ...formData };
                              formObj[itemLabel] = { ...formObj[itemLabel] };
                              formObj[itemLabel]["isRecap"] = e.target.checked;
                              formObj[itemLabel]["isOriginal"] = false;
                              setFormData({ ...formObj });
                            }}
                            type="radio"
                            name={itemLabel}
                            id={itemLabel + 2}
                            checked={stateData?.isRecap}
                            //value={JSON.stringify(!stateData?.isOriginal)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={itemLabel + 2}
                          >
                            Recap
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex card-footer justify-content-between align-items-center">
                  <div className="col-lg-3 attach mt-1">
                    {stateData?.attachments?.attachmentUploads?.length ? (
                      <div
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          padding: "4px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          borderRadius: "3px",
                          marginRight: "5px",
                          height: "25px",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          AttachmentsViewer.show({
                            ...stateData?.attachments,
                            isDeleteButton: true,
                            resetData: "tyresInspection",
                          })
                        }
                      >
                        {stateData?.attachments?.attachmentUploads?.length}
                      </div>
                    ) : null}
                    <span className="attachments">Attachments:</span>
                    &nbsp;
                    <span
                      onClick={() => {
                        onAddPictures(CommonUtil.RELEASES_PICS, itemLabel);
                      }}
                    >
                      <i className="fa-solid fa-cloud-arrow-up"></i>
                    </span>
                  </div>
                  {stateData?.attachments?.attachmentUploads?.length > 0 && (
                    <div className="col-lg-4 my-2">
                      <textarea
                        required
                        onChange={(e: any) => {
                          const prevSt = { ...formData[itemLabel] };
                          let attachments = { ...prevSt.attachments };
                          attachments.attachmentDamageDescription =
                            e?.target?.value;
                          updateFormData("attachments", attachments, itemLabel);
                        }}
                        className="form-control"
                        placeholder="Description"
                        rows={6}
                        value={
                          stateData?.attachments?.attachmentDamageDescription
                        }
                      ></textarea>
                    </div>
                  )}

                  <Button className="btn" type="submit">
                    Save
                  </Button>
                </div>
              </div>
            </form>
          );
        })}
      </div>
      <input
        type="file"
        ref={fileref}
        multiple={false}
        id="inputTyres"
        accept="image/*;pdf/*"
        style={{ display: "none" }}
        onChange={(e) => {
          onSelectFiles(e);
        }}
      />
    </div>
  );
};

export default NewEntryTiresInsp;
