import { Children, useEffect, useState } from "react";
import ReactImageGallery from "../ReactImageGallery";
import CarouselComponent from "../CarouselComponent";
import { Modal } from "react-bootstrap";
import DraggableComponent from "../../../Components/DraggableComponent";
import { useReleaseInspectorContext } from "../../../Components/ReleaseContext";

const AttachmentsViewer = ({ children }: any) => {
  const [attachmentsView, setAttachmentsView] = useState(false);
  const [galleryPop, setGalleryPop] = useState(false);
  const [galleryPopStartIndex, setGalleryPopStartIndex] = useState(false);
  const [attachmentsData, setAttachmentsData] = useState<any>({});
  const { state, dispatch } = useReleaseInspectorContext();

  useEffect(() => {
    console.log('att');
  }, [attachmentsData]);

  const updateAttachments = (data: any) => {
    setAttachmentsData(data);
    setAttachmentsView(true);
  };

  const imageRomoveHandler = (value: any) => {
    dispatch({
      type: attachmentsData?.resetData,
      payload: {
        isCompleted: false,
      },
    });
    attachmentsData?.attachmentUploads?.splice(value, 1);
    dispatch({
      type: "deleteImage",
      payload: {
        category: attachmentsData?.attachmentDetails?.category,
        attachmentUploads: attachmentsData?.attachmentUploads,
      },
    });
  };

  useEffect(() => {
    AttachmentsViewer.register(updateAttachments);
  }, []);

  const onClickEditDriver = () => {
    setAttachmentsView(true);
  };

  const handleClose = () => {
    setAttachmentsView(false);
  };
  const attachments: any = attachmentsData?.attachmentUploads?.map(
    ({ fullFilePath }: any) => fullFilePath
  );
  const attachmentDesc: any = attachmentsData?.attachmentDamageDescription;
  const inspectionItemName: string = attachmentsData?.fullName;
  return (
    <>
      {attachmentsView && (
        <>
          <Modal
            id="attachments-viewer"
            show={attachmentsView}
            onHide={handleClose}
            dialogAs={DraggableComponent}
            centered
            className={"modal-dialog-centered modal-lg"}
            // style={{ width: "400px", marginLeft: "600px" }}
            size="lg"
          >
            <div className="">
              <Modal.Header closeButton>
                <Modal.Title>{"Attachments"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <div className=" text-right">
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={handleClose}
                  ></button>
                </div> */}

                <div className="col-lg-12">
                  <div className="text-center">
                    <div className="gallery-carousel">
                      <h2 className="title-head my-3">
                        {inspectionItemName} Photos
                      </h2>
                      <CarouselComponent
                        images={attachments}
                        imageRomoveHandler={imageRomoveHandler}
                        isDeleteButton={
                          attachmentsData?.isDeleteButton
                            ? attachmentsData?.isDeleteButton
                            : false
                        }
                        callBack={(idx: any) => {
                          setGalleryPop(true);
                          setGalleryPopStartIndex(idx);
                        }}
                      />
                    </div>
                  </div>

                  <div className="gallery-form form">
                    <h2 className="title-head my-4 mb-2 text-center">
                      {inspectionItemName} Description
                    </h2>
                    <div className="form-floating">
                      <div
                        className="card p-3"
                        placeholder="Leave a comment here"
                        id="floatingTextarea2"
                        style={{ minHeight: "100px", height: "auto" }}
                      >
                        {attachmentDesc}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </div>
          </Modal>

          <ReactImageGallery
            startIndex={galleryPopStartIndex}
            attachedImages={attachments}
            showPop={galleryPop}
            handleClose={() => {
              setGalleryPop(false);
            }}
          />
        </>
      )}

      <div className={"cellRenderHeader"}>
        <a>
          <span onClick={onClickEditDriver} className={"cellRender"}>
            {/* {attachments?.length ? <>{attachments?.length} <i className="fa fa-paperclip" aria-hidden="true"></i></> : ""} */}
            {children}
          </span>
        </a>
      </div>
    </>
  );
};

export default AttachmentsViewer;
AttachmentsViewer.setData = (data: any) => {};
AttachmentsViewer.register = (fun: any) => {
  return (AttachmentsViewer.setData = fun);
};
AttachmentsViewer.show = (data: any) => {
  AttachmentsViewer.setData(data);
};
