import React from 'react'
import { CommonUtil } from '../../utils/CommonUtil';

const InspectionCompareRenderer = (props:any) => {
    const onClickCompare = () => {
        if (props.context) {
            props.context.onCompareDocument(props.data);
      }
      CommonUtil.setDocumentCode(props.data.documentCode)
    }
  return (
    <div className={"cellRenderHeader"}>
            <button className="btn btn-danger" onClick={onClickCompare}>{"Compare"}</button>
    </div>
  )
}

export default InspectionCompareRenderer