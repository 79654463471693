import React, {useEffect, useRef, useState} from "react";
import {CommonUtil} from "../../utils/CommonUtil";
import EquipmentAvail from "./EquipmentAvail";
import {NetworkManager} from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";
import EquipmentPrices from "./EquipmentPrices";
import EquipmentDocuments from "./EquipmentDocuments";
import EquipmentDamages from "./EquipmentDamages";

import $ from "jquery";
import BusyLoader from "../../Components/BusyLoader";
import {useNavigate} from "react-router-dom";
import EquipmentReservation from "./EquipmentReservation";
import EquipmentHistory from "./EquipmentHistory";
import {publish} from "../Login/CustomEvents";
import {Events} from "../Languages/Events";
import {Constants} from "../Languages/Constants";
import StarRating from "../../Components/Rating";
import DetectDevice from "../../utils/DetectDevice";
import { reAdjust, leftClickScroll, rightClickScroll} from "../../utils/ScrollHandler";
import EquipGalleryModal from "./EquipGalleryModal";
import {Modal} from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";

var equiImages:any = [];
var attchIdx:number = 0;
var attachmentList: any = [];
const EquipmentView = ()=>{
    const { isMobile } = DetectDevice();
    //const[equipImagesPath,setEquipmentImagesPath] = useState([]);
    const [equipImagesPath, setEquipImagesPath] = useState<any>([])
    const [genInfo,setGenInfo] = useState(true);
    const [availability,setAvailability] = useState(false);
    const [prices,setPrices] = useState(false);
    const [docs,setDocs] = useState(false);
    const [damages,setDamages] = useState(false);
    const [reservations,setReservations] = useState(false);
    const [ehistory,setEHistory] = useState(false);

    const [equipmentData,setEquipmentData] = useState<any>(null);

    const [assetType,setAssetType] = useState("");
    const [equipType,setEquipType] = useState("");
    const [estimatedValue,setEstimatedValue] = useState("");
    const [foodGrade,setFoodGrade] = useState("No");
    const [isEstimatedValue,setIsEstimatedValue] = useState(false);
    const [unitNumber,setUnitNumber] = useState("");
    const [vinNumber,setVinNumber] = useState("");
    const [brand,setBrand] = useState("");
    const [model,setModel] = useState("");
    const [year,setYear] = useState("");
    const [equipName,setEquipName] = useState("");

    const [numOfAxes,setNumOfAxes] = useState("");
    const [trailerLength,setTrailerLength] = useState("");
    const [license,setELicense] = useState("");
    const [expDate,setExpDate] = useState("");

    const [trackingType,setTrackingType] = useState("");

    const [axelSpread,setAxelSpread] = useState("");
    const [tireInflation,setTireInflation] = useState("");
    const [skirts,setSkirts] = useState("");
    const [pintleHook,setPintleHook] = useState("");
    const [hazmat,setHazmat] = useState("");
    const [suspension,setSuspension] = useState("");

    const [rearDoor,setRearDoor] = useState("");
    const [sideDoor,setSideDoor] = useState("");
    const [roof,setRoof] = useState("");
    const [floor,setFloor] = useState("");
    const [floorType,setFloorType] = useState("");
    const [interior,setInterior] = useState("");
    const [logistics,setLogistics] = useState("");
    const [deckBeams,setDeckBeams] = useState("");

    const [liftType,setLiftType] = useState("");
    const [lift,setLift] = useState("");
    const [liftCap,setLiftCap] = useState("");

    const [unitCapacity,setUnitCapacity] = useState("");
    const [unitMake,setUnitMake] = useState("");
    const [unitModal,setUnitModal] = useState("");
    const [unitYear,setUnitYear] = useState("");
    const [multiTemp,setMultiTemp] = useState("");

    const [deActiveInstruction,setDeActive] = useState("");
    const [activeInstruction,setActivative] = useState("");

    const [emails,setEmails] = useState("");
    const [deposit,setDeposit] = useState("");
    const [imagePath,setImagePath] = useState("");
    const [ratingCount,setRatingCount] = useState("");
    const [overAllRate,setOverAllRate] = useState("");

    const [imageIdx,setImageIdx] = useState(0);
    const [gallary,setGallary] = useState([]);
    const [imageEquipPath,setEquipImagePath] = useState("");
    const [modalTitle,setModalTile] = useState("");

    const [imageModalShow,setImageMoalShow] = useState(false);

    const [showEstValuePopup,setSowEstValuePopup] = useState(false);
    const [estimatedEnterValue,setEstimatedEnterValue] = useState("");
    const estValueRef =  useRef<HTMLInputElement>(null);




    const genRef = useRef<HTMLButtonElement>(null);
    const avlRef = useRef<HTMLButtonElement>(null);
    const priceRef = useRef<HTMLButtonElement>(null);
    const docRef = useRef<HTMLButtonElement>(null);
    const damageRef = useRef<HTMLButtonElement>(null);
    const reserveRef = useRef<HTMLButtonElement>(null);
    const historyRef = useRef<HTMLButtonElement>(null);

    const navigate = useNavigate();

    let deSelectButtons = ()=>{
        setGenInfo(false);
        setAvailability(false);
        setPrices(false);
        setDocs(false);
        setDamages(false);
        setReservations(false);
        setEHistory(false);
    }

    let onClickGenInfo = ()=>{
        deSelectButtons();
        setGenInfo(true);
        genRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickAvailability = ()=>{
        deSelectButtons();
        setAvailability(true);
        avlRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickPrice = ()=>{
        deSelectButtons();
        setPrices(true);
        priceRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickDocs = ()=>{
        deSelectButtons();
        setDocs(true);
        docRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickDamages = ()=>{
        deSelectButtons();
        setDamages(true);
        damageRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickReservations = ()=>{
        deSelectButtons();
        setReservations(true);
        reserveRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickHistory = ()=>{
        deSelectButtons();
        setEHistory(true);
        historyRef.current?.classList.add("equi-view-select-btn");
    }

    useEffect(()=>{
        reAdjust();
        //console.log(CommonUtil.SELECT_EQUIP_ID);
        if(localStorage && localStorage.getItem("language") != null){
            CommonUtil.SELECTED_LANGUAGE = localStorage.getItem("language") as string;
        }
        publish(Events.EQUIPMENT_EVENT,{data:Events.EQUIPMENTS_VIEW})
        getEquipmentDetails();
        window.addEventListener('resize',function(e){
            reAdjust();
        })
        return () => {
          window.removeEventListener('resize', function(){
                    reAdjust();
                });
        }

    },[])

    let getEquipmentDetails = ()=>{
        attchIdx = 0;
        equiImages = [];
        CommonUtil.getAllAssetTypes((data: any) => {
            CommonUtil.ASSET_TYPES = data;
           // setAssetTypes(data)
        })
        CommonUtil.getAllModels((data: any) => {
            CommonUtil.EQUiP_MODELS = data;
           // setModals(data)
        })
        if(CommonUtil.SELECT_EQUIP_ID){
            let equipmentAPI:string = "api/equipment/"+CommonUtil.SELECT_EQUIP_ID;
            let obj:any = {};
            NetworkManager.sendJsonRequest(equipmentAPI,"","","GET",true,onGetEquipmentDetails,onLoginError);
        }
    }

    let onGetEquipmentDetails = (dataObj:any)=>{
        console.log(dataObj);
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                    CommonUtil.IS_GENERIC_EQUIP = (restData.isGeneric == "Y"?true:false);
                    setEquipmentData(restData);
                    CommonUtil.SELECT_EQUIPMENT_TYPE = restData.equipmentType;
                    setEquipType(restData.equipmentType?restData.equipmentType:"-");
                    setEquipName(restData.genericEquipmentName?restData.genericEquipmentName:"-");
                    setUnitNumber(restData.unitNumber?restData.unitNumber:"-");
                    setEstimatedValue(restData.estimatedValue?restData.estimatedValue:"-");
                    setIsEstimatedValue(restData.estimatedValue?true:false);
                    setVinNumber(restData.VIN?restData.VIN:"-");
                    setYear(restData.year?restData.year:"-");
                    setModel(restData.model?restData.model:"-");
                    setBrand(restData.brand?restData.brand:"-");
                    setNumOfAxes(restData.numberOfAxles?restData.numberOfAxles:"-");
                    setTrailerLength(restData.lengthOfTrailer?restData.lengthOfTrailer:"-");
                    setELicense(restData.licensePlateNumber?restData.licensePlateNumber:"-");
                    setExpDate(restData.expDate?restData.expDate:"-");
                    setAssetType(restData.assetType?restData.assetType:"-");
                     setTrackingType(restData.trackingType?restData.trackingType:"-");
                    setExpDate(restData.annualMechanicalInspExpDate?restData.annualMechanicalInspExpDate:"-");

                    setActivative(restData.activationInstructions?restData.activationInstructions:"-");
                    setDeActive(restData.deactivationInstructions?restData.deactivationInstructions:"-");

                    setEmails(restData.additionalEmailIds?restData.additionalEmailIds:"-");
                    setDeposit(restData.securityDeposit?restData.securityDeposit:"-");

                    setRatingCount(restData.ratingCount?restData.ratingCount:"");
                    setOverAllRate(restData.overallRating?restData.overallRating:"");
                    if (restData.specifications.general.foodGrade == 'Y') {
                        setFoodGrade("Yes");
                    }else{
                        setFoodGrade("No");
                    }

                    if(restData.equipmentType == "DRY VAN" ){
                        setImagePath("../../images/dryVan.png");
                    }else if(restData.equipmentType == "PLATE DRY VAN" ){
                         setImagePath("../../images/plateDryVan1.png");
                     }

                   // console.log(CommonUtil.getEquipemntImage(restData.equipmentType))
                     setImagePath(CommonUtil.getEquipemntImage(restData.equipmentType));
               // getEquipemntImage

                    if(restData.specifications){
                        if(restData.specifications.general){
                            setAxelSpread(restData.specifications.general.axleSpread?restData.specifications.general.axleSpread:"-");
                            setTireInflation(restData.specifications.general.tireInflationSystem?restData.specifications.general.tireInflationSystem:"-");
                            setSkirts(restData.specifications.general.skirts?restData.specifications.general.skirts:"-");
                            setPintleHook(restData.specifications.general.pintleHook?restData.specifications.general.pintleHook:"-");
                            setHazmat(restData.specifications.general.hazmatPlacardHolder?restData.specifications.general.hazmatPlacardHolder:"-");
                            setSuspension(restData.specifications.general.suspensionType?restData.specifications.general.suspensionType:"-");
                        }
                        if(restData.specifications.trailer){
                            setRearDoor(restData.specifications.trailer.rearDoor?restData.specifications.trailer.rearDoor:"-");
                            setSideDoor(restData.specifications.trailer.sideDoor?restData.specifications.trailer.sideDoor:"-");
                            setRoof(restData.specifications.trailer.roof?restData.specifications.trailer.roof:"-");
                            setFloor(restData.specifications.trailer.floor?restData.specifications.trailer.floor:"-");
                            setFloorType(restData.specifications.trailer.floorType?restData.specifications.trailer.floorType:"-");
                            setInterior(restData.specifications.trailer.interiorFinish?restData.specifications.trailer.interiorFinish:"-");
                            setLogistics(restData.specifications.trailer.logisticPostsDistance?restData.specifications.trailer.logisticPostsDistance:"-");
                            setDeckBeams(restData.specifications.trailer.doubleDeckBeams?restData.specifications.trailer.doubleDeckBeams:"-");
                        }
                        if (restData.specifications.lift) {
                            setLift(restData.specifications.lift.lift);
                            setLiftType(restData.specifications.lift.liftType);
                            setLiftCap(restData.specifications.lift.liftCapacity);
                        }

                        if (restData.specifications.reefer) {
                            setMultiTemp(restData.specifications.reefer.multiTemperature);
                            setUnitModal(restData.specifications.reefer.unitMake);
                            setUnitMake(restData.specifications.reefer.unitModel);
                            setUnitYear(restData.specifications.reefer.unitYear);
                        }
                    }

                if (restData.attachments) {
                    attachmentList = []
                    if (Array.isArray(restData.attachments)) {
                        attachmentList = restData.attachments
                    } else {
                        attachmentList.push(restData.attachments)
                    }
                    if (attachmentList.length > 0) {
                        BusyLoader.showLoader()
                        showAttachments()
                    }
                }
                }
            }
        }

    let showAttachments = () => {
        if (attachmentList.length > attchIdx) {
            let qParam: string = 'objectPath=' + attachmentList[attchIdx].fullFilePath
            NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', false, (dataObj: any) => {
                    let obj: any = {}
                    obj.Type = attachmentList[attchIdx].documentType
                    obj.Path = dataObj
                    obj.attachementId = attachmentList[attchIdx].attachementId
                    equiImages.push(obj)
                    attchIdx = attchIdx + 1
                    showAttachments()
                },
                onLoginError,
            )
        } else {
            BusyLoader.hideLoader()
            setEquipImagesPath(equiImages)
        }
    }



    let onClickTabs = (divTag:string)=>{
        let tabs:any = ['lnkGeneral','lnkAvailability','lnkPricing','lnkDocuments','lnkDamages','lnkReservations','lnkHistory'];
        let tabScreens:any = ['General-Information','Availabilities','Pricing','Documents','Damages','Reservations','History'];

        tabs.forEach((tab:any,idx:number)=>{
            $("#"+tab).removeClass("active");
        });
        tabScreens.forEach((tab:any,idx:number)=>{
            $("#"+tab).removeClass("show active");
            $("#"+tab).addClass("fade");
        });

        $("#"+divTag).addClass("active");
        let tabScreen:string = "General-Information";
        if(divTag == "lnkGeneral"){
            deSelectButtons();
            setGenInfo(true);
            tabScreen = 'General-Information';
        }else if(divTag == "lnkAvailability"){
            deSelectButtons();
           //
            if(!isEstimatedValue){
                setSowEstValuePopup(true);
            }else{
                setAvailability(true);
            }

            tabScreen = 'Availabilities';
        }else if(divTag == "lnkPricing"){
            deSelectButtons();
            setPrices(true);
            tabScreen = 'Pricing';
        }else if(divTag == "lnkDocuments"){
            deSelectButtons();
            setDocs(true);
            tabScreen = 'Documents';
        }else if(divTag == "lnkDamages"){
            deSelectButtons();
            setDamages(true);
            tabScreen = 'Damages';
        }else if(divTag == "lnkReservations"){
            deSelectButtons();
            setReservations(true);
            tabScreen = 'Reservations';
        }else if(divTag == "lnkHistory"){
            deSelectButtons();
            setEHistory(true);
            tabScreen = 'History';
        }

        $("#"+tabScreen).addClass("show active");
    }

    let onClickEditEquip = ()=>{
        CommonUtil.SCREEN_FROM = "View";
        publish(Events.EQUIPMENT_EVENT,{data:Events.EDIT_EQUIPMENT})
       // navigate(-1);
        navigate("editequip");
    }

    let onClickEquipImage = (mTile:string,images:any,imgPath:string,idx:number,catagory?:string)=>{
        // item && item.Type == CommonUtil.EQUIP_DRIVER_SIDE
        // setGallary(images);
        if(images.length == 0){
            setEquipImagePath(imgPath);
        }else{
            setImageIdx(idx);
        }
        let equipImages:any = [];

        images.forEach((item:any,eIdx:number)=>{
            if(item && item.Type == catagory){
                equipImages.push(item)
            }
        });
        for(let i=0;i<equipImages.length;i++){
            if(equipImages[i].Path == imgPath){
                idx =  i;
                break;
            }
        }
        setImageIdx(idx);
        setModalTile(mTile);
        setGallary(equipImages);

        setImageMoalShow(true);
    }

    let onCloseImagePopup = ()=>{
        setImageMoalShow(false);
    }

    let handleEstPopup = ()=>{
        setEstimatedEnterValue("")
        setSowEstValuePopup(false);
        setAvailability(true);
    }

    let onDevAvailShowPopup = () => {
        setTimeout(function () {
            setPopupCenter('devEstPopupEquip', 'devEstContentPopupEquip')
        }, 100)
    }
    let setPopupCenter = (popId: string, cId: string) => {
        let windowHeight = window.innerHeight
        let windowWidth = window.innerWidth
        //windowHeight = windowHeight - 170

        if (cId) {
            let popupTop = windowHeight - 650;
            popupTop = popupTop / 2
            let popupLeft = windowWidth - 400;
            popupLeft = popupLeft / 2
            //if(popupTop<50){
            //popupTop = 10
            //}
            $('#' + popId).css({ margin: '0px', top: popupTop, left: popupLeft })
        }
    }

    let handleEstimatedValue = (e: any) => {
        setEstimatedEnterValue(e.target.value)
         CommonUtil.isMandatory(estValueRef);
    }

    let handleSaveEstimatedValue = ()=>{
        if(estimatedEnterValue){
            let availCraateApi:string = "";
            let method:string = "PUT";
            availCraateApi = "api/equipment/updateEstimatedValue/"+CommonUtil.SELECT_EQUIP_ID+"?estimatedValue="+estimatedEnterValue;
            NetworkManager.sendJsonRequest(availCraateApi,'',"",method,true,onEstimatedValueUpdated,onLoginError);
        }
    }

    let onEstimatedValueUpdated = (dataObj:any)=>{
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                setEstimatedValue(estimatedEnterValue);
                setIsEstimatedValue(true);
                setEstimatedEnterValue("")
                setSowEstValuePopup(false);
                ToastManager.showToast(CommonUtil.getMessageText(Constants.SUCCESSFULLY_UPDATED_EST_VALUE, 'Successfully updated estimated value'), 'Success');
                setAvailability(true);
            }
        }
    }

    let onLoginError = (err:any)=>{
        console.log(err);
    }
    return(
        <>
            <EquipGalleryModal modalTile={modalTitle} modalShow={imageModalShow} onCloseImagePopup={onCloseImagePopup} images={gallary} idx={imageIdx}
                               imagePath={imageEquipPath}/>

            <Modal show={showEstValuePopup} centered dialogAs={DraggableComponent} id="devEstPopupEquip" onShow={onDevAvailShowPopup}>
                <div className="modal-content" id="devEstContentPopupEquip" style={{border:"1px solid green"}}>
                    <Modal.Header className="modal-header" closeButton={false}>
                        <h5 className="modal-title mt-0" style={{fontSize:"15px"}}>{CommonUtil.getMessageText(Constants.EST_EQUIP_VALUE, 'Equipment Estimated Value')}</h5>
                        <button type="button" className="close" data-dismiss="modal" style={{ marginLeft: "0px" }} aria-label="Close"
                                onClick={handleEstPopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.ENTER_ESTIMATED_VALUE, 'Enter Estimated Value')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text" autoFocus={true} className="form-control clsFormInputControl" ref={estValueRef}
                                               placeholder={CommonUtil.getMessageText(Constants.ESTIMATED_VALUE, 'Estimated Value')} value={estimatedEnterValue}
                                               onChange={handleEstimatedValue}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-dark" data-dismiss="modal" onClick={handleEstPopup}>
                            {CommonUtil.getMessageText(Constants.CLOSE, 'Cancel')}
                        </button>
                        <button type="submit" className="btn btn-success" onClick={handleSaveEstimatedValue}>
                            {CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                        </button>
                    </div>
                </div>

            </Modal>


            <div className="page-content-wrapper px-2">
                <div className="container-fluid euipviewpage-header" style={{paddingLeft:"10px",paddingRight:"10px"}}>
                    <div className="profile-bar col-12 p-0 m-0">
                        <div className="shade col-12 p-0 m-0">
                            <div className={`container ${isMobile ? 'p-0 m-0' : ''}`}>
                                <div className="row col-12 p-0 m-0">
                                    <div className="col-sm-12 col-md-12 col-lg-8 col-xl-7 p-0 m-0">
                                        <div className="col-12 row p-0 m-0">
                                            <div className="col-sm-12 col-md-12 col-lg-4 p-0" style={{display:'flex', justifyContent:'center' }}>
                                                <img className="img-fluid" src={imagePath} alt="" />
                                            </div>
                                            <div className={`${isMobile ? 'col text-right' : 'col-md-6 col-lg-4'}`}>

                                                <div className="profile-name"><h6>{equipType}<small
                                                    style={{"display": "block",paddingTop:"10px"}}>{unitNumber}</small></h6></div>
                                                <p>{CommonUtil.getMessageText(Constants.VIN_NO,"VIN Number")} : {vinNumber}</p>
                                                <div className="favourities" style={{display:"none"}}>
                                                    <i className="fa-regular fa-heart"></i> Add To Favourites
                                                </div>
                                                <div className="rating row" style={isMobile ? {justifyContent:'right', paddingRight:'calc(var(--bs-gutter-x) * 0.5)'} : {}}>
                                                    <StarRating value={Math.round(Number(overAllRate))} />{overAllRate} | {ratingCount} {CommonUtil.getMessageText(Constants.RATINGS,"Ratings")}
                                                </div>
                                                <div className="rating" style={{display:"none"}}>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa-solid fa-star-half-stroke"></i> 4.5 | 39 Ratings
                                                </div>
                                            </div>
                                            <div className={`border-left ${isMobile ? 'col text-left' : 'text-center border-right col-md-6 col-lg-4 col-sm-3'}`}>
                                                <p><small style={{"display": "block"}}>{CommonUtil.getMessageText(Constants.BRAND,"Brand")}</small>{brand}</p>
                                                <p><small style={{"display": "block"}}>{CommonUtil.getMessageText(Constants.YEAR,"Year")}</small>{year}</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={`col-sm-12 col-md-12 col-lg-4 col-xl-5 p-0 text-right ${isMobile ? 'd-flex justify-content-center mt-3' : 'equip-view-button-group'}`}>
                                        <button type="button" className="btn btn-light mb-2 " data-dismiss="modal" style={{display:"none"}}>
                                            <i className="fa fa-truck"></i> {CommonUtil.getMessageText(Constants.EQUIP_PUBLIC_PAGE,"Equipment Public Page")}
                                        </button>
                                        <button type="button" className="btn btn-danger  mb-2 " data-dismiss="modal" style={{display:"none"}}>
                                            <i className="fa-solid fa-trash-can"></i> Delete Equipment
                                        </button>
                                        <button type="button" className="btn btn-primary mb-2 edit-equipment-btn" data-dismiss="modal"
                                            onClick={onClickEditEquip}>
                                            <i className="fa-solid fa-pencil"></i> {CommonUtil.getMessageText(Constants.EQUIP_EDIT,"Edit Equipment")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`profile-tabs row px-3`} style={{ justifyContent:'center' }}>
                        <div className="scroller scroller-left float-left" onClick={leftClickScroll} style={{display:'none', width:'fit-content'}}><i className="fa fa-chevron-left" aria-hidden="true"></i></div>
                                <div className="wrapper-nav m-0" id='wrapper-nav'>
                                    <nav className="nav nav-tabs list col-12 px-3" id="myTab" role="tablist">
                                      <a className={`nav-item nav-link active ${isMobile ? '' : 'col'}`} data-toggle="tab"
                                        role="tab" aria-controls="public" aria-expanded="true" id="lnkGeneral"
                                        onClick={()=>onClickTabs('lnkGeneral')}>{CommonUtil.getMessageText(Constants.GEN_INFO,"General Information")}</a>
                                    <a className={`nav-item nav-link ${isMobile ? '' : 'col'}`}  role="tab"
                                       data-toggle="tab" id="lnkAvailability"  onClick={()=>onClickTabs('lnkAvailability')}>{CommonUtil.getMessageText(Constants.AVAILABILITIES,"Availabilities")}</a>
                                    <a className={`nav-item nav-link ${isMobile ? '' : 'col'}`}  role="tab"
                                       data-toggle="tab" id="lnkPricing" onClick={()=>onClickTabs('lnkPricing')}>{CommonUtil.getMessageText(Constants.PRICING,"Pricing")}</a>
                                    <a className={`nav-item nav-link ${isMobile ? '' : 'col'}`}  role="tab"
                                       data-toggle="tab" id="lnkDocuments" onClick={()=>onClickTabs('lnkDocuments')}>{CommonUtil.getMessageText(Constants.DOCUMENTS,"Documents")}</a>
                                    <a className={`nav-item nav-link ${isMobile ? '' : 'col'}`}  role="tab"
                                       data-toggle="tab" id="lnkDamages" onClick={()=>onClickTabs('lnkDamages')}>{CommonUtil.getMessageText(Constants.DAMAGES,"Damages")}</a>
                                    <a className={`nav-item nav-link ${isMobile ? '' : 'col'}`}  role="tab"
                                       data-toggle="tab" id="lnkReservations" onClick={()=>onClickTabs('lnkReservations')}>{CommonUtil.getMessageText(Constants.RESERVATIONS,"Reservations")}</a>
                                    <a className={`nav-item nav-link ${isMobile ? '' : 'col'}`}  role="tab"
                                       data-toggle="tab" id="lnkHistory" onClick={()=>onClickTabs('lnkHistory')}>{CommonUtil.getMessageText(Constants.HISTORY,"History")}</a>
                                    </nav>
                                </div>
                                <div className="scroller scroller-right float-right" onClick={rightClickScroll} style={{display:'none', width:'fit-content'}}><i className="fa fa-chevron-right" aria-hidden="true"></i></div>
                            {/* <div className="wrapper-nav">
                                <nav className="nav nav-tabs list " id="myTab" role="tablist">
                                    <a className="nav-item nav-link active" data-toggle="tab"
                                        role="tab" aria-controls="public" aria-expanded="true" id="lnkGeneral"
                                        onClick={()=>onClickTabs('lnkGeneral')}>{CommonUtil.getMessageText(Constants.GEN_INFO,"General Information")}</a>
                                    <a className="nav-item nav-link"  role="tab"
                                       data-toggle="tab" id="lnkAvailability"  onClick={()=>onClickTabs('lnkAvailability')}>{CommonUtil.getMessageText(Constants.AVAILABILITIES,"Availabilities")}</a>
                                    <a className="nav-item nav-link"  role="tab"
                                       data-toggle="tab" id="lnkPricing" onClick={()=>onClickTabs('lnkPricing')}>{CommonUtil.getMessageText(Constants.PRICING,"Pricing")}</a>
                                    <a className="nav-item nav-link"  role="tab"
                                       data-toggle="tab" id="lnkDocuments" onClick={()=>onClickTabs('lnkDocuments')}>{CommonUtil.getMessageText(Constants.DOCUMENTS,"Documents")}</a>
                                    <a className="nav-item nav-link"  role="tab"
                                       data-toggle="tab" id="lnkDamages" onClick={()=>onClickTabs('lnkDamages')}>{CommonUtil.getMessageText(Constants.DAMAGES,"Damages")}</a>
                                    <a className="nav-item nav-link bottom-none"  role="tab"
                                       data-toggle="tab" id="lnkReservations" onClick={()=>onClickTabs('lnkReservations')}>{CommonUtil.getMessageText(Constants.RESERVATIONS,"Reservations")}</a>
                                    <a className="nav-item nav-link"  role="tab"
                                       data-toggle="tab" id="lnkHistory" onClick={()=>onClickTabs('lnkHistory')}>{CommonUtil.getMessageText(Constants.HISTORY,"History")}</a>

                                </nav>
                            </div> */}
                    </div>
                    <div className="tab-content pb-3" id="myTabContent" style={isMobile ? {width:'100%'} : {}} >
                                <div role="tabpanel" className="tab-pane show active mt-2" id="General-Information"
                                     aria-labelledby="public-tab" aria-expanded="true">
                                    <div className="container">
                                        <section>
                                            <h5>{CommonUtil.getMessageText(Constants.GENERAL_DETAILS,"General Details")}</h5>
                                            <hr />
                                                <div className="row">
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.ASSET_TYPE,"Asset Type")}</span><label>{assetType}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.UNIT_NUMBER,"Unit Number")}</span><label>{unitNumber}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.VIN_NO,"VIN Number")}</span><label>{vinNumber}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type")}</span><label>{equipType}</label></div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.EQUIP_NAME,"Equipment Name")}</span><label>{equipName}</label></div>
                                                    <div className="col-sm-2 col-6"><span
                                                        className="details-head">{CommonUtil.getMessageText(Constants.BRAND,"Brand")}</span><label>{brand}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6"><span
                                                        className="details-head">{CommonUtil.getMessageText(Constants.MODEL,"Model")}</span><label>{model}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6"><span
                                                        className="details-head">{CommonUtil.getMessageText(Constants.YEAR,"Year")}</span><label>{year}</label></div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.NO_OF_AXELS,"Number of Axles")}</span><label>{numOfAxes}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.LENGTH_OF_TRAILER,"Length of Trailer")}</span><label>{trailerLength}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.LICENSE_PLATE_NO,"License Plate Number")}</span><label>{license}</label></div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.ANN_MECH_EXPIRE_DATE,"Annual Mechanical Expiration Date")}</span><label>{expDate}</label></div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.TRACK_TYPE,"Tracking Type")}</span><label>{trackingType}</label></div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.ESTIMATED_VALUE,"Estimated Value")}</span><label>{estimatedValue}</label></div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.FOOD_GRADE,"Food Grade")}</span><label>{foodGrade}</label></div>

                                                </div>
                                        </section>
                                        <section>
                                                <h5>{CommonUtil.getMessageText(Constants.SPECIFICATIONS,"Specifications")}</h5>
                                                <hr />
                                                <h6>{CommonUtil.getMessageText(Constants.SPECIFICATIONS,"Specifications")}</h6>
                                                <div className="row">
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.AXLE_SPREAD,"Axle Spread")}</span><label>{axelSpread}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.TIRE_INF_SYS,"Tire Inflation System")}</span><label>{tireInflation}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6"><span
                                                        className="details-head">{CommonUtil.getMessageText(Constants.SKIRTS,"Skirts")}</span><label>{skirts}</label></div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.PINTLE_HOOK,"Pintle Hook")}</span><label>{pintleHook}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.HAZMAT_PLACARD,"Hazmat Placard")}</span><label>{hazmat}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6">
                                                        <span className="details-head">{CommonUtil.getMessageText(Constants.SUSPENSION,"Suspension")}</span><label>{suspension}</label></div>
                                                </div>
                                                <h6>{CommonUtil.getMessageText(Constants.TRAILERS,"Trailers")}</h6>
                                                <div className="row">
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.REAR_DOOR,"Rear Door")}</span><label>{rearDoor}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.SIDE_DOOR,"Side Door")}</span><label>{sideDoor}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6"><span
                                                        className="details-head">{CommonUtil.getMessageText(Constants.ROOF,"Roof")}</span><label>{roof}</label></div>
                                                    <div className="col-sm-2 col-6"><span
                                                        className="details-head">{CommonUtil.getMessageText(Constants.FLOOR,"Floor")}</span><label>{floor}</label></div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.FLOOR_TYPE,"Floor Type")}</span><label>{floorType}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.INTERIOR_FINISH,"Interior Finish")}</span><label>{interior}</label>
                                                    </div>

                                                    <div className="col-sm-4 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.LOGIS_POST_DIST,"Logistics Posts Distance")}</span><label>{logistics}</label>
                                                    </div>
                                                    <div className="col-sm-4 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.DOUBLE_DECK_BEAMS,"Double Deck Beams")}</span><label>{deckBeams}</label>
                                                    </div>
                                                </div>
                                                <h6>{CommonUtil.getMessageText(Constants.LIFT,"Lift")}</h6>
                                                <div className="row">
                                                    <div className="col-sm-2 col-6"><span
                                                        className="details-head">{CommonUtil.getMessageText(Constants.LIFT,"Lift")}</span><label>{lift}</label></div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.LIFT_TYPE,"Lift Type")}</span><label>{liftType}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.LIFT_CAPACITY,"Lift Capacity")}</span><label>{liftCap}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6">
                                                        </div>
                                                </div>
                                                <h6>{CommonUtil.getMessageText(Constants.TEMP_CONTROL,"Temperature Control")}</h6>
                                                <div className="row">
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.UNIT_MAKE,"Unit Make")}</span><label>{unitMake}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.UNIT_MODEL,"Unit Model")}</span><label>{unitModal}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.UNIT_YEAR,"Unit Year")}</span><label>{unitYear}</label>
                                                    </div>
                                                    <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.MULTI_TEMP,"Multi Temperature")}</span><label>{multiTemp}</label>
                                                    </div>
                                                </div>

                                        </section>
                                        <section style={{display:"none"}}>
                                        <h5>{CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type")}</h5>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-12 col-12">
                                                    <div className="popup-gallery general-details-photos">
                                                        <a className="d-inline-block">
                                                            <div className="img-fluid d-block mx-auto">
                                                                <img src={imagePath} alt="" />
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <h5>{CommonUtil.getMessageText(Constants.EQUIP_PHOTO,"Equipment Photos")}</h5>
                                            <hr />
                                            <div style={{display: 'flex',overflow:"auto"}}>
                                                {equipImagesPath.map((item: any, idx: number) => {
                                                    if (item && item.Type == CommonUtil.EQUIP_IMAGES) {
                                                        var list = (
                                                            <div className={'upload-image-item'} key={idx} style={{margin:"0px",cursor:"pointer"}}>
                                                                <img src={item.Path} className="upload-thumbnail m-3"  onClick={()=>{
                                                                    //console.log(equipImagesPath,item.Path,idx);
                                                                    onClickEquipImage(CommonUtil.getMessageText(Constants.EQUIP_PHOTO,"Equipment Photos"),equipImagesPath,item.Path,idx,CommonUtil.EQUIP_IMAGES);
                                                                }}/>
                                                            </div>
                                                        )
                                                        return list
                                                    }
                                                })}

                                            </div>

                                        </section>
                                        <section style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
                                            <h5>{CommonUtil.getMessageText(Constants.INSTRUCTIONS,"Instructions")}</h5>
                                            <hr />
                                                <div className="row">
                                                    <div className="col-sm-12 col-12">
                                                        <p>{CommonUtil.getMessageText(Constants.INSTRUCTIONS_REQUIRED,"These instructions will be requested of the drivers to apply\n" +
                                                            "                                                            and confirm at the beginning and at the end of the rental.\n" +
                                                            "                                                            These instructions may also assist the driver if needed.")}</p>
                                                        <h6>{CommonUtil.getMessageText(Constants.ACTIVATION_INSTR,"Activation Instructions")}</h6>
                                                        <p>{activeInstruction}</p>
                                                        <h6>{CommonUtil.getMessageText(Constants.DEACTIVATION_INSTR,"Deactivation Instructions")}</h6>
                                                        <p>{deActiveInstruction}</p>
                                                    </div>
                                                </div>
                                        </section>
                                        <section>
                                            <h5>{CommonUtil.getMessageText(Constants.GEN_SETT,"General Settings")}</h5>
                                            <hr />
                                                <div className="row">
                                                    <div className="col-sm-12 col-12">
                                                        <h6>{CommonUtil.getMessageText(Constants.ADDITIONAL_EMAILS,"Additional emails for renting notifications")}</h6>
                                                        <p><a>{emails}</a>
                                                        </p>
                                                        <h6>{CommonUtil.getMessageText(Constants.SECURE_DEPOSIT,"Security Deposit")}</h6>
                                                        <p>{deposit}</p>
                                                    </div>

                                                </div>
                                        </section>

                                    </div>
                                </div>
                                <div className="tab-pane fade  mt-2 mb-2" id="Availabilities" role="tabpanel"
                                     aria-labelledby="group-dropdown2-tab" aria-expanded="false">

                                    {availability &&
                                        <EquipmentAvail equipData={equipmentData} vin={vinNumber} />
                                    }

                                </div>
                                <div className="tab-pane fade   mt-2" id="Pricing" role="tabpanel"
                                     aria-labelledby="group-dropdown2-tab" aria-expanded="false">
                                    {prices &&  <EquipmentPrices equipData={equipmentData} />}


                                </div>

                                <div className="tab-pane fade mt-2" id="Documents" role="tabpanel"
                                     aria-labelledby="group-dropdown2-tab" aria-expanded="false">
                                    {docs &&  <EquipmentDocuments equipData={equipmentData} getEquipData={getEquipmentDetails} />}

                                </div>
                                <div className="tab-pane fade mt-2" id="Damages" role="tabpanel"
                                     aria-labelledby="group-dropdown2-tab" aria-expanded="false">
                                    {damages && <EquipmentDamages equipData={equipmentData} getEquipData={getEquipmentDetails} />}

                                </div>
                                <div className="tab-pane fade mt-2" id="Reservations" role="tabpanel"
                                     aria-labelledby="group-dropdown2-tab" aria-expanded="false">
                                    {reservations && <EquipmentReservation equipData={equipmentData} />}
                                </div>
                                <div className="tab-pane fade mt-2" id="History" role="tabpanel"
                                     aria-labelledby="group-dropdown2-tab" aria-expanded="false">
                                    {ehistory && <EquipmentHistory equipData={equipmentData} />}
                                </div>

                            </div>
                </div>
            </div>

            {/* <div className="container-fluid mt-1 pt-1" style={{display:"none",background:"#f0f0f0"}}>
                <div className="container-fluid equip-view-header">
                    <div className="row fs-7" style={{padding:"5px"}}>
                        <div className="col-md-1">
                            <div className="equip-view-box">

                            </div>
                        </div>
                        <div className="col-md-2">
                            <div style={{display:"flex"}}>
                                <span className="grey-text css-16ihq8s">{assetType}</span>
                                &nbsp;&nbsp;&nbsp;
                                <div style={{display:"flex"}}>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <span className="grey-text css-16ihq8s display_inline">Add to Favorities</span>
                                </div>
                            </div>
                                <span className="grey-text css-16ihq8s">234538447</span>
                                <span className="grey-text css-16ihq8s">VIN Number : {vinNumber}</span>

                            <div>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="grey-text css-16ihq8s display_inline">4.0 | 39 rating</span>
                            </div>
                        </div>
                        <div className="col-md-1" style={{borderLeft:"2px solid #999"}}>
                            <div>
                                 <span className="grey-text css-16ihq8s">Brand</span>
                                 <span className="grey-text css-16ihq8s">{brand}</span>
                            </div>
                            <div style={{paddingTop:"20px"}}>
                                <span className="grey-text css-16ihq8s">Year</span>
                                <span className="grey-text css-16ihq8s">{year}</span>
                            </div>

                        </div>
                        <div className="col-md-6 float-end"></div>
                        <div className="col-md-2 float-end">
                            <div>
                                 <button type="button" className="btn btn-default equip-view-button">Equipment Public Page</button>
                            </div>
                            <div style={{paddingTop:"10px"}}>
                                <button type="button" className="btn btn-default equip-view-button">
                                    <i className="fa fa-trash" aria-hidden="true"></i> &nbsp;
                                    Delete Equipment</button>
                            </div>
                            <div style={{paddingTop:"10px"}}>
                                <button type="button" className="btn btn-default equip-view-button">
                                    <i className="fa-solid fa-pen-to-square" aria-hidden="true"></i> &nbsp;
                                    Edit Equipment</button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row fs-7" style={{paddingTop:"16px"}}>
                        <div className="col clsNoPaddingLeft clsNoPaddingRight" >
                            <button ref={genRef}  className="btn clsTabButton equi-view-select-btn" onClick={onClickGenInfo} >
                                General Information
                            </button>
                        </div>
                        <div className="col clsNoPaddingLeft clsNoPaddingRight" >
                            <button  ref={avlRef} className="btn clsTabButton"  onClick={onClickAvailability} >
                                Availabilities
                            </button>
                        </div>
                        <div className="col clsNoPaddingLeft clsNoPaddingRight">
                            <button  ref={priceRef} className="btn clsTabButton" onClick={onClickPrice}>
                               Pricing
                            </button>
                        </div>
                        <div className="col clsNoPaddingLeft clsNoPaddingRight" >
                            <button ref={docRef}  className="btn clsTabButton" onClick={onClickDocs}>
                               Documents
                            </button>
                        </div>
                        <div className="col clsNoPaddingLeft clsNoPaddingRight" >
                            <button ref={damageRef} className="btn clsTabButton" onClick={onClickDamages}>
                                Damages
                            </button>
                        </div>
                        <div className="col clsNoPaddingLeft clsNoPaddingRight" >
                            <button ref={reserveRef} className="btn clsTabButton" onClick={onClickReservations}>
                                Reservations
                            </button>
                        </div>
                        <div className="col clsNoPaddingLeft clsNoPaddingRight" >
                            <button ref={historyRef} className="btn clsTabButton" onClick={onClickHistory}>
                                History
                            </button>
                        </div>
                    </div>
                </div>
                {
                    genInfo && <div className="container-fluid" style={{width:"80%"}}>
                    <div className="container-fluid" >
                        <div className="container-fluid" style={{background:"#FFF"}}>
                            <div className="row fs-7 mt-4 pt-2 ">
                                <div className="col-md-12">
                                    <h5 className="mb-4">General Details</h5>
                                </div>
                            </div>
                            <div className="row fs-7">
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Asset Type</span>
                                    <span className="grey-text">{assetType}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Unit Number</span>
                                    <span className="grey-text">{unitNumber}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">VIN Number</span>
                                    <span className="grey-text">{vinNumber}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Brand</span>
                                    <span className="grey-text">{brand}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Model</span>
                                    <span className="grey-text">{model}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Year</span>
                                    <span className="grey-text">{year}</span>
                                </div>
                            </div>
                            <div className="row fs-7 pt-5">
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Number of Axies</span>
                                    <span className="grey-text">{numOfAxes}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Length of Trailer</span>
                                    <span className="grey-text">{trailerLength}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">License Plate Number</span>
                                    <span className="grey-text">{license}</span>
                                </div>
                                <div className="col-md-3">
                                    <span className="grey-text css-16ihq8s">Annual Machanical Expiration Date</span>
                                    <span className="grey-text">{expDate}</span>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid" style={{background:"#FFF"}}>
                            <div className="row fs-7 mt-4 pt-2">
                                <div className="col-md-12">
                                    <h5 className="mb-4">Specifications</h5>
                                </div>
                            </div>
                            <div className="row fs-7">
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Axels Spread</span>
                                    <span className="grey-text">{axelSpread}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Tire Inflation System</span>
                                    <span className="grey-text">{tireInflation}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Skirts</span>
                                    <span className="grey-text">{skirts}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Pintle Hook</span>
                                    <span className="grey-text">{pintleHook}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Hazmat Placard</span>
                                    <span className="grey-text">{hazmat}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Suspension</span>
                                    <span className="grey-text">{suspension}</span>
                                </div>
                            </div>
                            <div className="row fs-7 mt-4 pt-2">
                                <div className="col-md-12">
                                    <h5 className="mb-4">Trailers</h5>
                                </div>
                            </div>
                            <div className="row fs-7 clsFormStandardTopMargin">
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Rear Door</span>
                                    <span className="grey-text">{rearDoor}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Side Door</span>
                                    <span className="grey-text">{sideDoor}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Roof</span>
                                    <span className="grey-text">{roof}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Floor</span>
                                    <span className="grey-text">{floor}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Floor Type</span>
                                    <span className="grey-text">{floorType}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Interior Finish</span>
                                    <span className="grey-text">{interior}</span>
                                </div>
                            </div>
                            <div className="row fs-7 clsFormStandardTopMargin">
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Logistics Post Distance</span>
                                    <span className="grey-text">{logistics}</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Double Deck Beams</span>
                                    <span className="grey-text">{deckBeams}</span>
                                </div>
                            </div>
                            <div className="row fs-7 mt-4 pt-2">
                                <div className="col-md-12">
                                    <h5 className="mb-4">Lift</h5>
                                </div>
                            </div>
                            <div className="row fs-7">
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Lift</span>
                                    <span className="grey-text">---</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Lift Type</span>
                                    <span className="grey-text">---</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Unit Capacity</span>
                                    <span className="grey-text">---</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Floor</span>
                                    <span className="grey-text">---</span>
                                </div>
                            </div>
                            <div className="row fs-7 mt-4 pt-2">
                                <div className="col-md-12">
                                    <h5 className="mb-4">Temparature Control</h5>
                                </div>
                            </div>
                            <div className="row fs-7">
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Unit Make</span>
                                    <span className="grey-text">---</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Unit Model</span>
                                    <span className="grey-text">---</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Unit Year</span>
                                    <span className="grey-text">---</span>
                                </div>
                                <div className="col-md-2">
                                    <span className="grey-text css-16ihq8s">Multi Temparature</span>
                                    <span className="grey-text">---</span>
                                </div>
                            </div>
                            <div className="row fs-7 clsFormStandardTopMargin">
                                <div className="col-md-12">
                                    <h5 className="mb-4">Equipment Photos</h5>
                                </div>
                            </div>
                            <div className="row fs-7 clsFormStandardTopMargin">
                                <div className="upload-border mt-3">
                                    <div style={{display:"flex"}}>
                                        {
                                            equipImagesPath.map((item:any,idx:number)=>{
                                                if(item.Type == CommonUtil.EQUIP_IMAGES){
                                                    var list = <div className={"upload-image-item"}>
                                                        <img src={item.Path} className="upload-thumbnail m-3" />

                                                    </div>;
                                                    return list;
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row fs-7 clsFormStandardTopMargin">
                                <div className="col-md-12">
                                    <h5 className="mb-4">Instructions</h5>
                                </div>
                            </div>
                            <div className="row fs-7 clsFormStandardTopMargin">
                                <div className="col-md-12">
                                <span>

                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                    </div>
                </div>
                }
                {
                    availability && <div className="container-fluid" style={{width:"80%"}}>
                        <EquipmentAvail equipData={equipmentData} vin={vinNumber} />
                    </div>
                }
                {
                    prices && <div className="container-fluid" style={{width:"80%"}}>
                        <EquipmentPrices equipData={equipmentData} vin={vinNumber} />
                    </div>
                }
                {
                    docs && <div className="container-fluid" style={{width:"80%"}}>
                        <EquipmentDocuments equipData={equipmentData} />
                    </div>
                }
                {
                    damages && <div className="container-fluid" style={{width:"80%"}}>
                        <EquipmentDamages />
                    </div>
                }
                {
                    reservations && <div className="container-fluid" style={{width:"80%"}}>
                        <h1>Reservation Tab</h1>
                    </div>
                }
                {
                    ehistory && <div className="container-fluid" style={{width:"80%"}}>
                        <h1>History Tab</h1>
                    </div>
                }


            </div>*/}
        </>
    )
}

export default EquipmentView;
