import React from "react";
import {Modal} from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import { CommonUtil } from "../../utils/CommonUtil";
import { Constants } from "../Languages/Constants";

const AvailabilityModal = ({
                               modalShow,
                               onCloseAvailPopup,
                               onClickRenter,
                               onClickReposition

                           }: any) => {
    return (<>
        <Modal show={modalShow} dialogAs={DraggableComponent}>
            <div className="modal-content" style={{height: '450px'}}>
                <div className="modal-header">
                    <h6 className="modal-title mt-0 fw-bold text-center black-text" style={{width: '97%', marginLeft: '3%'}}>{CommonUtil.getMessageText(Constants.NEW_AVAILABILITY,"New Availability")}</h6>
                    {/* <button type="button" className="close" data-dismiss="modal" style={{ width:'3%', height:'10%' }} aria-label="Close" onClick={onCloseEquipPopup} /> */}
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseAvailPopup}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body color-black my-2" style={{paddingTop: '10px'}}>
                    <div className="row fs-7 px-6">
                        <div className="col-md-6 text-center">
                            <div className="col-md-12 generic-equip p-3" style={{height: '289px'}} onClick={onClickRenter}>
                                <img src={"../../images/new-availability-location-1.png"} style={{width: '110px'}}/>
                                <p style={{marginTop: 0, marginBottom: '1rem'}}>
                                    <strong>{CommonUtil.getMessageText(Constants.RENTAL,"Rental")} </strong>{' '}
                                </p>
                                <p style={{marginTop: 0, marginBottom: '1rem'}}>
                                    {CommonUtil.getMessageText(Constants.MULTIPLE_SIMILAR_EQUIPS," Display multiple similar equipment in a single listing to\n" +
                                        "                                        simplify the management of their availabilites.")}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 text-center">
                            <div className="col-md-12 specific-equip p-3" style={{height: '289px'}} onClick={onClickReposition}>
                                <img src={"../../images/new-availability-location-2.png"} style={{width: '110px'}}/>
                                <p style={{marginTop: 0, marginBottom: '1rem'}}>
                                    <strong>{CommonUtil.getMessageText(Constants.REPOSITIONING,"Repositiong")}</strong>{' '}
                                </p>
                                <p style={{marginTop: 0, marginBottom: '1rem'}}>
                                    {CommonUtil.getMessageText(Constants.SPECIFIC_EQUIPS," This specific equipment \n" +
                                        "                                        Will be the one made available \n" +
                                        "                                        for the renters.")}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="modal-footer clsModalPermissionsFootr">
                    <button type="button" className="btn btn-outline-dark btn-sm"
                            style={{backgroundColor: '#00CFB4', color: '#fff'}}
                            data-bs-dismiss="modal" onClick={onCloseAvailPopup}>
                        {CommonUtil.getMessageText(Constants.CLOSE,"Cancel")}
                    </button>
                </div>
            </div>
        </Modal>
    </>)
}
export default AvailabilityModal;
