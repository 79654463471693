import React, {useEffect, useRef, useState} from "react";
import {CommonUtil} from "../../utils/CommonUtil";
import useStateWithCallback from "use-state-with-callback";
import AGGrid from "../../Components/AGGrid";
import EquipCellRenderer from "../Organization/EquipCellRenderer";
import {IAGrid} from "../../Components/IInterfaces";
import EquipDamageRenderer from "../Organization/EquipDamageRenderer";
import DetectDevice from "../../utils/DetectDevice";
import MGrid from "../../Components/MGrid";
import {Constants} from "../Languages/Constants";
import EquipGalleryModal from "./EquipGalleryModal";

const EquipmentDamages = (props:any)=>{
    const { isMobile } = DetectDevice();
    const [equipDocs,setEquipData] = useStateWithCallback<any>([],()=>{});

    const [imageModalShow,setImageMoalShow] = useState(false);
    const [imageIdx,setImageIdx] = useState(0);
    const [gallary,setGallary] = useState([]);
    const [imagePath,setImagePath] = useState("");
    const [modalTitle,setModalTile] = useState("");

    const [eqpColumnDefs] = useState([
        {field: 'unitNumber', headerName: CommonUtil.getMessageText(Constants.DAMAGE,"Damage"), resizable: true, width: 200, sortable: true, cellRenderer: EquipDamageRenderer, filter: 'agTextColumnFilter'},
        {field: 'reservationCode', headerName: CommonUtil.getMessageText(Constants.RESERVATION_CODE,"Reservation Code"), resizable: true, width: 150, flex: 1,sortable: true, filter: 'agTextColumnFilter'},
        {field: 'attachmentDescription', headerName: CommonUtil.getMessageText(Constants.DESTINATION,"Description"), resizable: true, width: 150, flex: 1,sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'attachmentCategory', headerName: CommonUtil.getMessageText(Constants.CONTEXT,"Context"), resizable: true, width: 150, flex: 1,sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'creationDate', headerName: CommonUtil.getMessageText(Constants.DATE,"Date"), resizable: true, width: 150, flex: 1, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'attachmentStatus',headerName: CommonUtil.getMessageText(Constants.STATUS,"Status"),resizable: true,width: 150,flex: 1,sortable: true,filter: 'agNumberColumnFilter'},
    ]);

    const gridRef = useRef<IAGrid>(null);

    useEffect(()=>{
        //console.log(props);
        getEquipDamages();
    },[]);

    let getEquipDamages = ()=>{
        if(props.equipData && props.equipData.attachments){
            let attchments = [];
            if(Array.isArray(props.equipData.attachments)){
                attchments = props.equipData.attachments;
            }else{
                attchments.push(props.equipData.attachments);
            }

            if(attchments.length>0){
                let docs:any = [];
                attchments.forEach((docItem:any,idx:number)=>{
                    if(docItem.documentType == CommonUtil.EQUIP_DAMAGES){
                        docs.push(docItem);
                    }
                });
                //console.log(docs);
                setEquipData(docs);
            }

        }
    }

    let onClickLinkDetails = (data:any)=>{
        console.log(data);
        if(data.imageSource){
            setImagePath(data.imageSource);
            setImageIdx(0);
            setModalTile(CommonUtil.getMessageText(Constants.DAMAGES,"Damages"));
            setGallary([]);

            setImageMoalShow(true);
        }

    }

    let onCloseImagePopup = ()=>{
        setImageMoalShow(false);
        //setImagePath("");
    }

    let onClickUploadDamage = ()=>{

    }

    return(
        <>
            <EquipGalleryModal modalTile={modalTitle} modalShow={imageModalShow} onCloseImagePopup={onCloseImagePopup} images={gallary} idx={imageIdx}
                               imagePath={imagePath}/>
            <div className="container">
                <section>
                    <div className="row" style={{display:"none"}}>
                        <div className="col-sm-6 col-12">
                            <button type="submit" className="btn btn-success waves-effect waves-light">All</button>
                            &nbsp;
                            <button type="submit" className="btn btn-warning waves-effect waves-light">Reported</button>
                            &nbsp;
                            <button type="submit" className="btn btn-warning waves-effect waves-light ">Repaired</button>
                        </div>
                    </div>
                    <div className="row" style={isMobile ? {}: {paddingTop:"10px"}}>
                        {
                            isMobile ? (
                            <MGrid id="OrgEquipDamagesGrid1" cols={eqpColumnDefs}
                                    rows={equipDocs} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                                    rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                    ref={gridRef} addNew={false}
                            />
                            ) : (
                                <div className="ag-theme-alpine gridHeight"
                             style={{height: 'calc(100vh - 250px)', width: '100%', paddingLeft: '0px', paddingRight: '0px'}} >
                            <AGGrid id="OrgEquipDamagesGrid1" cols={eqpColumnDefs}
                                    rows={equipDocs} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                                    rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                    ref={gridRef} addNew={false} method={{onClickLinkDetails}}
                            />
                        </div>
                            )
                        }
                    </div>




                </section>
            </div>
        </>
    );
}

export default EquipmentDamages;
