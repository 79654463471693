import $ from "jquery";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NetworkManager } from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import { CommonUtil } from "../../utils/CommonUtil";
import { StatusCodeUtil } from "../../utils/StatusCodeUtil";
import { Constants } from "../Languages/Constants";
import RegistrationHeader from "./RegistrationHeader";



const RegistrationPage = () => {

    const navigate = useNavigate();

    const chkProfile = useRef<HTMLInputElement>(null);
    const chkAuthorize = useRef<HTMLInputElement>(null);


    const fnRef = useRef<HTMLInputElement>(null);
    const lnRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const contactNoRef = useRef<HTMLInputElement>(null);
    var sourceRef = useRef<HTMLInputElement>(null);
    var passRef = useRef<HTMLInputElement>(null);
    var cPassRef = useRef<HTMLInputElement>(null);


    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmailId] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [sourceData, setSourceData] = useState(CommonUtil.sourceData);
    const [sourceState, setSourceState] = useState(true);
    const [department, setDepartment] = useState("");
    const [password, setPassword] = useState("");
    const [cPass, setCPass] = useState("");
    const [invitationCode, setInvitationCode] = useState("");
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const [terms, setTerms] = useState("none");
    const [searchParams] = useSearchParams();
    //const [terms,setTerms] = useState("none");

    var token: any = "";
    useEffect(() => {
        // loadGooglePlaces();
        //console.log(NetworkManager.serverPath);
        if (NetworkManager.serverPath) {
            getTokenUrl();
        } else {
            getServerPath();
        }


    }, []);

    let getServerPath = () => {
        $.ajax({
            url: "server.xml",
            beforeSend: function () {
            },
            success: function (data, statusCode, jqXHR) {
                var xmlResponse = $(data).find('Server');
                for (var i = 0; i < xmlResponse.length; i++) {
                    var item = xmlResponse[i];
                    let routeUrl = $(item).find("URL").text();

                    console.log(routeUrl);
                    NetworkManager.serverPath = routeUrl;

                    getTokenUrl();

                }
            },
            error: function () {
            }
        });
    }

    let getTokenUrl = () => {
        const params = new URLSearchParams(window.location.search) // id=123
        token = params.get('token');
        console.log(token);
        // token = "020100bc-615c-4a7b-8336-d8769e9e11d0";
        var inviteUrl: string = "api/session/organizationInvitation?invitationCode=" + token;
        NetworkManager.sendJsonRequest(inviteUrl, "", "", "GET", false, onGetInfo, onLoginError);
    }

    let onGetInfo = (dataObj: any) => {
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);//JSON.parse(dataObj);
            if (restData) {
                setFirstName(restData.firstName);
                setLastName(restData.lastName);
                setDepartment(restData.department);
                setEmailId(restData.loginId);
                setContactNumber(restData.contactNumber);
                setInvitationCode(restData.invitationCode);
            }
        }
    }

    let onClickCreateProfile = () => {
        // window.location.assign("http://vhubui.s3-website-us-west-2.amazonaws.com/menu")
        // loginUser();
        /*let siteOrigin:string = window.location.origin;
        // window.location.assign(siteOrigin);
        localStorage.setItem("userLogin","true");
        siteOrigin = siteOrigin+"/menu";
        window.location.assign(siteOrigin);*/

        if (password != cPass) {
            let msg: string = CommonUtil.getMessageText(Constants.PASSWORD_MIS_MATCH, "Password and confirm password does not match");
            let eMsg: string = CommonUtil.getMessageText(Constants.ERROR, "Error");
            ToastManager.showToast(msg, eMsg);
            return;
        }
        (chkProfile.current?.checked) ? setTerms("none") : setTerms("block");
        if ((chkProfile.current?.checked) && invitationCode && password && cPass && (password == cPass)) {
            var obj: any = {};
            obj.invitationCode = invitationCode;
            obj.password = password;
            obj.userName = email;
            obj.sendPromotionalMails = ((chkAuthorize.current?.checked) ? "Y" : "N");
            obj.termsConditionsAccepted = ((chkProfile.current?.checked) ? "Y" : "N");
            NetworkManager.sendJsonRequest("api/session/registerUser", obj, "", "POST", true, onOrgCreateSuccess, onLoginError);
        }
    }

    let onOrgCreateSuccess = (data: any) => {
        // console.log(data);
        if (data) {
            var restData = CommonUtil.parseData(data);
            if (restData) {
                loginUser();
            }
        }
    }
    let loginUser = () => {
        var restObj: any = {};//"userName": "fn2l32n3@yahoo.com","password": "admin1234"};
        restObj.userName = CommonUtil.trim(email);
        restObj.password = CommonUtil.trim(password);
        NetworkManager.sendJsonRequest("api/auth/login", restObj, "", "POST", true, (dataObj: any) => {
            if (dataObj) {
                var restData = JSON.parse(dataObj);
                if (restData) {
                    if (restData.statusCode) {
                        //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    } else {
                        // console.log(restData);
                        localStorage.setItem("user", email);
                        localStorage.setItem("userLogin", "true");
                        localStorage.setItem("token", restData.token);
                        localStorage.setItem("refreshToken", restData.refreshToken);
                        localStorage.setItem("orgId", restData.saasOrgId);
                        localStorage.setItem("saasorgId", restData.saasOrgId);
                        localStorage.setItem("orgName", restData.orgName);
                        localStorage.setItem("userId", restData.userId);
                        localStorage.setItem("root", restData.root);
                        localStorage.setItem("ViewTab", "false");
                        localStorage.setItem("ViewDB", "false");

                        gotoLoginPage();
                    }
                }
            }

        }, onLoginError);
    }
    let gotoLoginPage = () => {
        ToastManager.showToast("User Activated Successfully", "Info");
        // navigate("/");
        setTimeout(() => {
            let siteOrigin: string = window.location.origin;
            // window.location.assign(siteOrigin);
            siteOrigin = siteOrigin + "/?requestor=menu";//+"/menu";
            window.location.assign(siteOrigin);
        });
    }


    let onLoginError = (err: any) => {
        console.log(err);
    }

    return (
        <div style={{ overflow: "hidden" }}>
            <div className="row">
                <div className="row">
                    <RegistrationHeader />
                </div>
                <div className={"row"}>
                    <div className="page-content-wrapper page-margin-top">
                        <div className="container" style={{ width: "80%" }}>
                            <section className="mt-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{CommonUtil.getMessageText(Constants.FIRST_NAME, "First Name")}</label> <span className="clsFormMandatory"></span>
                                            <input ref={fnRef} type="text" className="form-control clsStandardText"
                                                placeholder="Enter First Name" aria-label="First Name" value={firstName}
                                                disabled={true} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{CommonUtil.getMessageText(Constants.LAST_NAME, "Last Name")}</label> <span className="clsFormMandatory"></span>
                                            <input ref={lnRef} type="text" className="form-control clsStandardText"
                                                placeholder="Enter Last Name" aria-label="Last Name" value={lastName}
                                                disabled={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{CommonUtil.getMessageText(Constants.CONTACT_NO, "Mobile Number")}</label> <span className="clsFormMandatory"></span>
                                            <input ref={contactNoRef} type="text" className="form-control clsStandardText"
                                                placeholder="Enter Number" aria-label="Contact Number" value={contactNumber} disabled={true} />
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{CommonUtil.getMessageText(Constants.DEPARTMENT, "Department")}</label> <span className="clsFormMandatory"></span>
                                            <input ref={emailRef} type="text" className="form-control clsStandardText"
                                                placeholder="Department" aria-label="Email ID" value={department} disabled={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >{CommonUtil.getMessageText(Constants.EMAIL_ID, "Email ID")}</label> <span className="clsFormMandatory"></span>
                                            <input ref={emailRef} type="text" className="form-control clsStandardText"
                                                placeholder="Enter Email" aria-label="Email ID" value={email} disabled={true} />
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ paddingTop: "30px" }}>
                                        <label className="grey-text css-16ihq8s">{CommonUtil.getMessageText(Constants.LOG_APP, "This will be username for logging into the application")}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        {/* <div className="input-group input-group-lg">
                                             <div className="input-group-prepend">
                                               <span className="input-group-text"><i className="fa fa-user"></i></span>
                                             </div>
                                           <input type="text" className="form-control" placeholder="Username" />
                                        </div> */}
                                        <div className="form-group">
                                            <label>{CommonUtil.getMessageText(Constants.PASS, "Password")}</label> <span className="clsFormMandatory"></span>
                                            <div className="input-group" >
                                                <input ref={passRef} type={showPassword ? 'text' : 'password'} className="form-control clsStandardText clsFormInputControl"
                                                    placeholder={CommonUtil.getMessageText(Constants.PASS, "Password")} value={password} onChange={(e) => {
                                                        setPassword(e.currentTarget.value); CommonUtil.isMandatory(passRef);
                                                    }} />
                                                {/* <div className="input-group-prepend"> */}
                                                <span className="input-group-text" onClick={() => { setShowPassword(!showPassword) }} style={{ cursor: 'pointer' }} >{showPassword ? <i className="fa-solid fa-eye-slash" style={{ color: '#3B71CA' }}></i> : <i className="fa fa-eye" style={{ color: '#3B71CA' }}></i>}</span>
                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <span className="grey-text css-16ihq8s">{CommonUtil.getMessageText(Constants.CONFIRM_PASSWORD, "Confirm Password")}</span> <span className="clsFormMandatory"></span>
                                            <input ref={cPassRef} type="password" className="form-control clsStandardText clsFormInputControl"
                                                placeholder={CommonUtil.getMessageText(Constants.CONFIRM_PASSWORD, "Confirm Password")} value={cPass} onChange={(e) => {
                                                    setCPass(e.currentTarget.value); CommonUtil.isMandatory(cPassRef);
                                                }} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{ marginTop: "30px" }}>
                                    <div className="col-md-12 flex">
                                        <input ref={chkAuthorize} type="checkbox" className="checkBox" id="chkProfile" name="profile" value="profile"
                                            onChange={(e) => { e.currentTarget.checked ? setTerms("none") : setTerms("none") }} />
                                        <label className="clsControlLabel labelpadding" htmlFor="vehicle1"> {CommonUtil.getMessageText(Constants.V_HUB_AUTHOR_MAIL, "I authorize vHub to send me promotional mails")}</label>
                                    </div>
                                    <div className="col-md-12 flex">
                                        <input ref={chkProfile} type="checkbox" className="checkBox" id="chkProfile" name="profile" value="profile"
                                            onChange={(e) => { e.currentTarget.checked ? setTerms("none") : setTerms("none") }} />
                                        &nbsp;&nbsp;
                                        <label>{CommonUtil.getMessageText(Constants.BY_CLICK, "By clicking ")} “{CommonUtil.getMessageText(Constants.CREATE_PROFILE, "Create Profile")}”, {CommonUtil.getMessageText(Constants.I_ACCEPT_THE, "I accept the")}{' '}
                                            <a href="https://www.vhubapp.com/terms-conditions-home" target={"_blank"}>
                                                <u>{CommonUtil.getMessageText(Constants.TERMS_CONDITIONS, "Terms & Conditions")}</u>
                                            </a> and <a href="https://www.vhubapp.com/privacy-policy" target={"_blank"}>
                                                <u>{CommonUtil.getMessageText(Constants.PRIVACY_POLICY, "Privacy Policy")}</u>
                                            </a> {CommonUtil.getMessageText(Constants.OF_VHUB, "of vHub")}
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-8 flex" style={{ paddingTop: "10px", display: (terms == "none") ? "none" : "block" }}>
                                        <label style={{ color: "red", fontSize: "14px" }}>You must accept the vHub Terms of Service</label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 clsAlignRight" >
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" onClick={onClickCreateProfile}>{CommonUtil.getMessageText(Constants.SUBMIT, "Submit")}</button>
                                    </div>
                                    <div className="col-md-2"></div>
                                </div>

                            </section>
                        </div>
                    </div>
                </div>
            </div>
            {/*<LoginHeader />*/}
            {/*<div className="container-fluid" style={{display:"none",background:"#f0f0f0",overflow:"hidden",height:"100vh",padding:"0px"}} >
                <div style={{width:"100%",background:"#FFF",padding:"0px"}} className="container-fluid">
                    <div className="row fs-7 topbar-breadcrumbs" style={{paddingLeft:"3px",paddingTop:"7px"}}>
                        <br />
                        <span>{CommonUtil.getMessageText(Constants.USER_REGISTER_FORM,"User Registration Form")}</span>
                    </div>
                </div >
                <div className="container-fluid" style={{width:"1000px",background:"#FFF",paddingRight:"0px",paddingLeft:"40px",paddingBottom:"40px"}}>
                    <div  className="container-fluid">
                        <div className="row fs-7" style={{display:"none"}}>
                            <nav className="navbar fixed-top bg-white">
                                <div className="container-fluid">
                                    <a className="navbar-brand"  target="_blank">
                                        <img src="../../logo.png"  />
                                    </a>
                                    <div className="navbar-nav ms-md-auto pe-0">
                                        <p className=" grey-text fs-7" style={{marginTop: "0.3125rem",marginBottom: "0.3125rem;"}}></p>
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <div className="row fs-7" style={{marginTop:"15px",paddingTop:"20px"}}>
                        </div>
                    </div>
                    <div  className="container-fluid" style={{paddingTop:"20px"}}>
                    </div>
                    <div  className="container-fluid" >
                        <div className="row fs-7">
                            <div className="col-md-6">
                                <label>{CommonUtil.getMessageText(Constants.FIRST_NAME,"First Name")}</label> <span className="clsFormMandatory"></span>
                                <input ref={fnRef} type="text" className="form-control clsStandardText"
                                       placeholder="Enter First Name" aria-label="First Name" value={firstName}
                                       disabled={true} />
                            </div>
                            <div className="col-md-6">
                                <label>{CommonUtil.getMessageText(Constants.LAST_NAME,"Last Name")}</label> <span className="clsFormMandatory"></span>
                                <input ref={lnRef} type="text" className="form-control clsStandardText"
                                       placeholder="Enter Last Name" aria-label="Last Name" value={lastName}
                                       disabled={true}/>
                            </div>

                        </div>
                        <div className="row fs-7 clsFormStandardTopMargin">
                            <div className="col-md-6">
                                <label>{CommonUtil.getMessageText(Constants.CONTACT_NO,"Mobile Number")}</label> <span className="clsFormMandatory"></span>
                                <input ref={contactNoRef} type="text" className="form-control clsStandardText"
                                       placeholder="Enter Number" aria-label="Contact Number" value={contactNumber} disabled={true}/>
                            </div>


                            <div className="col-md-6">
                                <label>{CommonUtil.getMessageText(Constants.DEPARTMENT,"Department")}</label> <span className="clsFormMandatory"></span>
                                <input ref={emailRef} type="text" className="form-control clsStandardText"
                                       placeholder="Department" aria-label="Email ID" value={department} disabled={true} />
                            </div>
                        </div>
                        <div className="row fs-7 clsFormStandardTopMargin">
                            <div className="col-md-6">
                                <label >{CommonUtil.getMessageText(Constants.EMAIL_ID,"Email ID")}</label> <span className="clsFormMandatory"></span>
                                <input ref={emailRef} type="text" className="form-control clsStandardText"
                                       placeholder="Enter Email" aria-label="Email ID" value={email} disabled={true}/>
                            </div>
                            <div className="col-md-6" style={{paddingTop:"30px"}}>
                                <label className="grey-text css-16ihq8s">{CommonUtil.getMessageText(Constants.LOG_APP,"This will be username for logging into the application")}</label>
                            </div>
                        </div>
                        <div className="row fs-7 clsFormStandardTopMargin">
                            <div className="col-md-6">
                                <label>{CommonUtil.getMessageText(Constants.PASS,"Password")}</label> <span className="clsFormMandatory"></span>
                                <input ref={passRef} type="password" className="form-control clsStandardText clsFormInputControl"
                                       placeholder={CommonUtil.getMessageText(Constants.PASS,"Password")} value={password} onChange={(e)=>{
                                    setPassword(e.currentTarget.value);CommonUtil.isMandatory(passRef);}} />
                            </div>
                        </div>
                        <div className="row fs-7 clsFormStandardTopMargin">
                            <div className="col-md-6">
                                <span className="grey-text css-16ihq8s">{CommonUtil.getMessageText(Constants.CONFIRM_PASSWORD,"Confirm Password")}</span> <span className="clsFormMandatory"></span>
                                <input ref={cPassRef} type="password" className="form-control clsStandardText clsFormInputControl"
                                       placeholder={CommonUtil.getMessageText(Constants.CONFIRM_PASSWORD,"Confirm Password")} value={cPass}  onChange={(e)=>{
                                    setCPass(e.currentTarget.value);CommonUtil.isMandatory(cPassRef);}} />
                            </div>
                        </div>

                        <div className="row fs-7 clsFormStandardTopMargin" style={{marginTop:"30px"}}>
                            <div className="col-md-12 flex">
                                <input ref={chkAuthorize} type="checkbox" className="checkBox" id="chkProfile" name="profile" value="profile"
                                       onChange={(e)=>{e.currentTarget.checked?setTerms("none"):setTerms("none")}}/>
                                <label className="clsControlLabel labelpadding" htmlFor="vehicle1"> {CommonUtil.getMessageText(Constants.V_HUB_AUTHOR_MAIL,"I authorize vHub to send me promotional mails")}</label>
                            </div>
                            <div className="col-md-12 flex clsFormStandardTopMargin">
                                <input ref={chkProfile} type="checkbox" className="checkBox" id="chkProfile" name="profile" value="profile"
                                       onChange={(e)=>{e.currentTarget.checked?setTerms("none"):setTerms("none")}}/>
                                &nbsp;&nbsp;
                                <label>{CommonUtil.getMessageText(Constants.BY_CLICK,"By clicking ")} “{CommonUtil.getMessageText(Constants.CREATE_PROFILE,"Create Profile")}”, {CommonUtil.getMessageText(Constants.I_ACCEPT_THE,"I accept the")}{' '}
                                    <a href="https://www.vhubapp.com/terms-conditions-home" target={"_blank"}>
                                        <u>{CommonUtil.getMessageText(Constants.TERMS_CONDITIONS,"Terms & Conditions")}</u>
                                    </a> and <a href="https://www.vhubapp.com/privacy-policy" target={"_blank"}>
                                                <u>{CommonUtil.getMessageText(Constants.PRIVACY_POLICY,"Privacy Policy")}</u>
                                            </a> {CommonUtil.getMessageText(Constants.OF_VHUB,"of vHub")}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-8 flex" style={{paddingTop:"10px",display:(terms == "none")?"none":"block"}}>
                            <label style={{color:"red",fontSize:"14px"}}>You must accept the vHub Terms of Service</label>
                        </div>
                        <div className="row fs-7 clsFormStandardBtnRowMargin">
                            <div className="col-md-12 clsAlignRight" >
                                <button type="submit" className="btn btn-primary waves-effect waves-light" onClick={onClickCreateProfile}>{CommonUtil.getMessageText(Constants.SUBMIT,"Submit")}</button>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                </div>

            </div>*/}
        </div>
    )
}

export default RegistrationPage;
