import React, {useEffect, useRef, useState} from "react";
import useStateWithCallback from "use-state-with-callback";
import {IAGrid} from "../../Components/IInterfaces";
import {CommonUtil} from "../../utils/CommonUtil";
import Flatpickr from "react-flatpickr";
import {useNavigate} from "react-router-dom";
import EquipCellRenderer from "../Organization/EquipCellRenderer";
import EquipAvailTypeRenderer from "../Organization/EquipAvailTypeRenderer";
import {NetworkManager} from "../../Components/NetworkManager";
import SingleSelectComp from "../../Components/SingleSelectComp";
import AGGrid from "../../Components/AGGrid";
import EquipPublicCellRenderer from "../Organization/EquipPublicCellRenderer";
import DetectDevice from "../../utils/DetectDevice";
import MGrid from "../../Components/MGrid";

const SearchDashboard = (props:any)=>{
    const { isMobile } = DetectDevice();
    const [orgData, setOrgData] = useState(null)
    const [equipData, setEquipData] = useStateWithCallback<any>([], () => {})

    const [showEquipPopup, setShowEquipPopup] = useState(false);
    const [showAvailPopup,setShowAvailPopup] = useState(false);

    const gridRef = useRef<IAGrid>(null);

    const chkAllowRef = useRef<HTMLInputElement>(null);
    const priceRef = useRef<HTMLInputElement>(null);

    const [currencies,setCurrencies] = useState(CommonUtil.DEF_CURRENCY);
    const [currencyObj,setCurrencyObj] = useState<any>(null);
    //const [noOfDays,setNoOfDays] = useState("");

    const stDateRef = useRef<Flatpickr>(null);
    const endDateRef = useRef<Flatpickr>(null);

    const [assetTypes, setAssetTypes] = useState<any>(); //CommonUtil.ASSET_TYPES
    const [selAssetObj, setSelAssetObj] = useState<any>(null);

    const [companies, setCompanies] = useState([]);
    const [selCompanyObj, setSelCompanyObj] = useState<any>(null);

    const [equipTypes, setEquipTypes] = useState<any>([]); //CommonUtil.TRAILER_EQP_TYPES
    const [selEquipObj, setSelEquipObj] = useState<any>(null);

    const navigate = useNavigate()

    const [eqpColumnDefs] = useState([
        {field: 'unitNumber', headerName: 'Unit Number/Equipment Name', resizable: true, width: 220, sortable: true, cellRenderer: EquipPublicCellRenderer, filter: 'agTextColumnFilter'},
        {field: 'assetType', headerName: 'Asset Type', resizable: true, width: 150, sortable: true, filter: 'agTextColumnFilter'},
        {field: 'equipmentType', headerName: 'Equipment Type', resizable: true, width: 150,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'isGeneric', headerName: 'Is Generic', resizable: true, width: 150,  sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'brand', headerName: 'Brand', resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'model', headerName: 'Model', resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'listingType', headerName: 'Availability Type', resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'startDate', headerName: 'Availability From Date',resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'endDate', headerName: 'Availability To Date', resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'damagesCount', headerName: 'Availability Details', resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        {field: 'listingStatus',headerName: 'Status',resizable: true,width: 150,sortable: true,filter: 'agNumberColumnFilter'},
    ])

    useEffect(() => {
        getEquipmentList();
        if(CommonUtil.isRootUser()){
            CommonUtil.getAllOrgs((data: any) => {
                setOrgData(data)
            })
        }
        CommonUtil.getAllAssetTypes((data: any) => {
            CommonUtil.ASSET_TYPES = data
            setAssetTypes(data)
        })

    }, [])

    let getEquipmentList = () => {
        let orgId:any = "";
        if(CommonUtil.isRootUser()){
            orgId = selCompanyObj ? selCompanyObj.orgId : '';
        }else{
            if(localStorage.getItem("saasorgId")){
                orgId = localStorage.getItem("saasorgId");
            }
        }
        let qParams: string = 'saasOrgId=' + orgId;
        qParams = qParams + '&' + 'assetTypeId=' + (selAssetObj ? selAssetObj.key : '')
        qParams = qParams + '&' + 'equipmentTypeId=' + (selEquipObj ? selEquipObj.key : '')

        setEquipData([])
        let equipAllAPI: string = 'api/availability/all?' + qParams
        let obj: any = {}
        NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGetAllEquipments, onLoginError);
    }

    let onGetAllEquipments = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                var equips = []
                if (Array.isArray(restData)) {
                    equips = restData
                } else {
                    equips.push(restData)
                }
                setEquipData(equips);
            }
        }

    }

    let onClickLink = (data: any) => {
        //console.log(data);
        CommonUtil.SELECT_EQUIP_ID = data.equipmentId;
        CommonUtil.SELECT_EQUIP_AVAIL_ID = data.equipmentListingId;
        CommonUtil.EQUIP_RESERVE_IS_GENERIC = data.isGeneric;
        CommonUtil.SELECT_EQUIPMENT_TYPE = data.equipmentType;
        navigate('equippublic');
    }
    let onLoginError = (err: any) => {
        console.log(err)
    }


    return(
        <>
            <div className="container-fluid container-fluid-padding" style={{paddingTop: '1px'}}>
                <div className={`row fs-7 ${isMobile ? "" : "pt-3"}`}>
                    {
                        isMobile ? ( 
                            <MGrid id="OrgEquipSearchGrid" cols={eqpColumnDefs}
                                rows={equipData} pageSize={10} headerHeight={30} rowHeight={40}
                                ref={gridRef} addNew={false} method={{onClickLink}} />
                        ) : (
                            <div className="ag-theme-alpine gridHeight"
                         style={{height: 'calc(100vh - 110px)', width: '100%', paddingLeft: '8px', paddingRight: '5px'}} >
                        <AGGrid id="OrgEquipSearchGrid" cols={eqpColumnDefs}
                                rows={equipData} pageSize={500} headerHeight={30} rowHeight={40}
                                ref={gridRef} addNew={false} method={{onClickLink}} />
                    </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default SearchDashboard;