import React, {useEffect, useRef, useState} from "react";
import AGGrid from "../../Components/AGGrid";
import orgUserRenderer from "../Organization/OrgUserRenderer";
import {IAGrid} from "../../Components/IInterfaces";
import useStateWithCallback from "use-state-with-callback";
import {CommonUtil} from "../../utils/CommonUtil";
import {NetworkManager} from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";
import OrgUserFieldRenderer from "../Organization/OrgUserFieldRenderer";
import {Constants} from "../Languages/Constants";
import DetectDevice from "../../utils/DetectDevice";
import MGrid from "../../Components/MGrid";
import {Events} from "../Languages/Events";

const DNUDetails = ()=>{

    const [weeklyRate, setWeeklyRate] = useState<any>("")
    const [monthlyRate,setMonthlyRate]= useState<any>("")

    const priceGridRef = useRef<IAGrid>(null);

    const [priceColumns] = useState([
        { field: "equipmentType", headerName: CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type"),resizable:true,flex:1,width:100,sortable:true,filter:'agTextColumnFilter'},
        { field: "dailyRate", headerName: CommonUtil.getMessageText(Constants.DAILY_RATE, "Daily Rate"), resizable: true, flex: 1, width: 100, sortable: true, filter: 'agTextColumnFilter', cellRenderer: OrgUserFieldRenderer },
        { field: "weeklyRate", headerName: CommonUtil.getMessageText(Constants.WEEKLY_RATE, "Weekly Rate"), resizable: true, flex: 1, width: 100, sortable: true, filter: 'agTextColumnFilter', cellRenderer: OrgUserFieldRenderer },
        { field: "monthlyRate", headerName: CommonUtil.getMessageText(Constants.MONTHLY_RATE,"Monthly Rate"),resizable:true,flex:1,width:100,sortable:true,filter:'agTextColumnFilter',cellRenderer: OrgUserFieldRenderer  },
        { field: "distanceRate", headerName: CommonUtil.getMessageText(Constants.DIST_RATE,"Distance Rate"),resizable:true,flex:1,width:100,sortable:true,filter:'agTextColumnFilter', cellRenderer: OrgUserFieldRenderer },
        { field: "engineRate", headerName: CommonUtil.getMessageText(Constants.ENGINE_RATE,"Engine Rate"),resizable:true,flex:1,width:100,sortable:true,filter:'agTextColumnFilter', cellRenderer: OrgUserFieldRenderer }
    ]);

    const [priceData,setPriceData] = useStateWithCallback<any>([],()=>{
        if(priceGridRef.current){
            priceGridRef.current.columnFittoSize();
        }
    });

    const [conditions,setConditions] = useState("");
    const [equipments,setEquipments] = useState("");
    const { isMobile } = DetectDevice();
    var priceArr:any[] = [
        { equipmentType: "Dry Van", dailyRate: "CAD $20", distanceRate: "CAD $0.05", engineRate: "CAD $1.50"},
        { equipmentType: "Plate Dry Van", dailyRate: "CAD $20", distanceRate: "CAD $0.05", engineRate: "CAD $1.50"},
        { equipmentType: "Storage Van", dailyRate: "CAD $20", distanceRate: "CAD $0.05", engineRate: "CAD $1.50"},
        { equipmentType: "Reefer", dailyRate: "CAD $20", distanceRate: "CAD $0.05", engineRate: "CAD $1.50"},
        { equipmentType: "Heated Van", dailyRate: "CAD $20", distanceRate: "CAD $0.05", engineRate: "CAD $1.50"},
        { equipmentType: "Flatbed", dailyRate: "CAD $20", distanceRate: "CAD $0.05", engineRate: "CAD $1.50"}
    ];

    useEffect(()=>{
        getOrgDefaultPrices();
        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
    },[]);

    let onSwitchCustomer = ()=>{
        if(CommonUtil.isRootUser()){
            Events.dispatchBreadCrumbEvent();
            //navigate("orgList1");
        }else{
            getOrgDefaultPrices();
        }
        Events.dispatchBreadCrumbEvent();
    }

    let getOrgDefaultPrices = ()=>{
        setPriceData([]);
        var listAPI:string = "api/organization/getEquipmentTypePricing?saasOrgId="+CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(listAPI,"","","GET",true,onGetEquipmentTypePricing,onLoginError);
    }

    let onGetEquipmentTypePricing = (dataObj:any)=>{
        if(dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode),"Error");
                } else {
                    var orgs = [];
                    setConditions(restData.dnuConditions);
                    setEquipments(restData.dnuEqpConditions);
                    setWeeklyRate(restData.weeklyRate);
                    setMonthlyRate(restData.monthlyRate);
                    if(Array.isArray(restData.equipmentTypePricings)){
                        orgs = restData.equipmentTypePricings;
                    }else{
                        orgs.push(restData.equipmentTypePricings);
                    }
                    var currency:string = "";
                    if(CommonUtil.getCountry() == CommonUtil.CANADA){
                        //currency = CommonUtil.CANADA_CURRENCY;
                    }else{
                        //currency = CommonUtil.US_CURRENCY;
                    }
                    orgs.forEach((item:any,idx:number)=>{
                        //item.dailyRate = item.dailyRate;
                        //item.distanceRate = currency+" "+item.distanceRate;
                        //item.engineRate = currency+" "+item.engineRate;
                    })
                    setPriceData(orgs);
                    //setBlockOrgs(orgs);
                }
            }
        }
    }

    let onClickSavePrice = ()=>{
        setTimeout(()=>{
            saveDailyPrices();
        })
    }

    let saveDailyPrices = ()=>{
        var prices:any[] = [];
        var gridAPI:any = priceGridRef.current?.getGridAPI();
        if(gridAPI){
            //console.log(priceGridRef.current?.getGridAPI());
            var rows:any [] =  gridAPI.rowModel.rowsToDisplay;
            rows.forEach((item,idx)=>{
                //console.log(item.data);
                prices.push(item.data);
            })
        }

        var reqObj:any = {};
        reqObj["dnuConditions"] = conditions;
        reqObj["dnuEqpConditions"] = equipments;
        reqObj["weeklyRate"] = weeklyRate;
        reqObj["monthlyRate"] = monthlyRate;
        reqObj.equipmentTypePricings = prices;
       

        var listAPI:string = "api/organization/saveEquipmentTypePricing?saasOrgId="+CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(listAPI,reqObj,"","POST",true,onSaveEquipmentTypePricing,onLoginError);
    }

    let onSaveEquipmentTypePricing = (dataObj:any)=>{
        if(dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode),"Error");
                } else {
                    ToastManager.showToast(CommonUtil.getMessageText(Constants.UPDATED_SUCCESSFULLY,"Updated Successfully"),"Info");
                }
            }
        }
    }

    let onClickUserDetails = ()=>{

    }
    let onClickEditPrice = ()=>{

    }
    let onLoginError = (err:any)=>{
        console.log(err);
    }
    return(
        <>
            <div className="container-fluid container-fluid-padding" style={{background:"#f9f9f9"}}>

                <div  className="row  pt-3">
                    <div className="col-md-10">
                        <label style={{display:"none"}}>Default Pricing</label>
                        <label style={{display:"none"}}> All the pricing is in Canadian Dollar </label>
                    </div>
                    {
                        isMobile ? (
                        <MGrid id="CustDnuPriceGrid"  cols={priceColumns} rows={priceData} pageSize={10}
                                headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={priceGridRef} method={{onClickUserDetails}}  />
                        ):(
                         <div className="ag-theme-alpine gridHeight  pt-2 mb-15"
                         style={{ height: "calc(100vh - 250px)", width: "100%",paddingRight:"5px",marginBottom:"20px" }}>
                        <AGGrid id="CustDnuPriceGrid"  cols={priceColumns} rows={priceData} pageSize={500}
                                headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={priceGridRef} method={{onClickUserDetails}}  />
                    </div>
                        )
                    }
                </div>
                <div className="row">
                    <div className="">
                        <div className="my-3 float-end">
                            <button style={{display:"none"}} className="btn btn-outline-dark">Discard Changes</button> &nbsp;&nbsp;
                            {CommonUtil.getSelectedOrgName() == "vHub Inc" ?
                            <button type="button" className="btn btn-success" onClick={onClickSavePrice}>{CommonUtil.getMessageText(Constants.SAVE,"Save")}</button> : ""
                            }

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default DNUDetails;
