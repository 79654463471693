import ReactJson from "react-json-view";

const ListDriver = ({xml}: any) => {

     function remove_linebreaks(str: any) {
        return str.replace(/[\r\n]+/gm, "");
    }

         function break_line(str: any) {
             if(!str) return null;
        return str.replace(/\r?brr/g, `<br>`);
    }

    const TitleRender = (cont: any) => {
        let content = cont?.[0];
        return (
            <div className="col-12">
                <h1 className="mb-0">{content['Title']}</h1>
                <p dangerouslySetInnerHTML={{__html:break_line(content?.['Subtitle']?.[0])}} ></p>
                <p dangerouslySetInnerHTML={{__html:break_line(content?.['Subtitle1']?.[0])}} ></p>
            </div>
        )
    };

    const MethodRender = (cont: any) => {
        let content = cont?.[0];
        let details = content['MethodDetails'];
        return (
            <div className={'col-12'}>
                <div className="method-detail">
                    <h3>{content['Methodheader'][0]}</h3>
                    {details && details.map((detail: any, index:any) => {
                        return (
                            <div key={index}>
                                <p className="key http" dangerouslySetInnerHTML={{__html:break_line(detail?.['Methodlabel']?.[0])}} ></p>

                                <p className="label notice value" dangerouslySetInnerHTML={{__html:break_line(detail?.['Methodvalue']?.[0])}} ></p>
                            </div>
                        )
                    })}
                </div>
            </div>)
    }

    const TableRender = (cont: any) => {
        let columns = cont?.[0]?.['Columns'];
        let rows = cont?.[0]?.['Rows'];
        let tableHeader = cont?.[0]?.['TableHeader'];
        return (
            <div className={'col-12'}>
                {tableHeader ? <h3>{tableHeader?.[0]}</h3> : null}
                <table className={`table-striped table-hover w-100 table-ul mb-4 ${tableHeader ? 'mt-0' : 'mt-4'}`}>
                    <thead>
                    {columns && columns.map((col: any, index:any) => {
                        let column = col?.['Column']
                        return (
                            <tr key={index}>
                                {
                                    column && column.map((co: any, colIndex:any) => {
                                        return (
                                            <th className="p-2" key={colIndex} >{co}</th>
                                        )
                                    })
                                }
                            </tr>
                        )
                    })}
                    </thead>
                    <tbody>
                    {rows && rows.map((rawrow: any) => {
                        let row = rawrow?.['Row']
                        return row && row.map((ro: any, roIndex:any) => {
                            let col = ro?.['Column'];
                            return (
                                <tr key={roIndex}>
                                    {col && col.map((co: any, colIndex:any) => {
                                        return (<td key={colIndex} className={'p-2'} style={{ width:"20%",minWidth:'20%' }} ><p dangerouslySetInnerHTML={{__html:break_line(co)}} ></p> </td>)
                                    })}
                                </tr>
                            )

                        })
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
    const JSONRender = (cont: any) => {
        let exampleHeader = cont?.[0]?.['ExampleHeader'];
        let InputTitle = cont?.[0]?.['InputTitle'];
        let Input = cont?.[0]?.['Input']?.[0];
        let OutTitle = cont?.[0]?.['OutPutTitle'];
        let OutPut = cont?.[0]?.['OutPut']?.[0];
        let OutPutString = cont?.[0]?.['OutPutString']?.[0];
        return (
            <div className={'col-12'}>
                <strong className="mb-0">{exampleHeader?.[0]?.['Title']}</strong>
                <p><b>{exampleHeader?.[0]?.['Url']} </b></p>
                {InputTitle && InputTitle ?
                    <>
                        <strong>{InputTitle}</strong>
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#input" role="tab">{OutPutString ? 'String':'JSON'}</a>
                            </li>
                        </ul>

                        <div className="tab-content bg-black">
                            <div className="tab-pane active p-3" id="input" role="tabpanel">
                                <pre>
                                {
                                    OutPutString ? OutPutString : (
                                         <ReactJson src={JSON.parse(Input)}
                                               style={{textAlign: "left", color: "white"}}
                                               theme={'monokai'}
                                               displayDataTypes={false}
                                               displayObjectSize={false}
                                               enableClipboard={false}
                                               onEdit={false}
                                               sortKeys={false}
                                        // quotesOnKeys={false}
                                               name={false}/>
                                    )
                                }   
                                </pre>
                            </div>
                        </div>

                    </> : null}
                {OutTitle && OutTitle ?
                    <div className={'col-12 m-0 p-0 mt-4'}>
                        <strong>{OutTitle}</strong>
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#outputTable" role="tab">{OutPutString ? 'String':'JSON'}</a>
                            </li>
                        </ul>
                        <div className="tab-content bg-black">
                            <div className="tab-pane active p-3" id="outputTable" role="tabpanel">
                                <pre>
                                    {
                                    OutPutString ? OutPutString : (
                                         <ReactJson src={JSON.parse(OutPut)}
                                               style={{textAlign: "left", color: "white"}}
                                               theme={'monokai'}
                                               displayDataTypes={false}
                                               displayObjectSize={false}
                                               enableClipboard={false}
                                               onEdit={false}
                                               sortKeys={false}
                                        // quotesOnKeys={false}
                                               name={false}/>
                                    )
                                }   
                                </pre>
                            </div>
                        </div>

                    </div> : null}
            </div>
        )
    }
    return (
        <div className={'col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 right-content p-4'}>
            <div className={'row'}>
                {xml && xml.length > 0 ? xml.map((objects: any) => {
                        return Object.keys(objects).map((category: any, index:any) => {
                            const TableConsts = ['TableRequired', 'TableQuery', 'TableRequest', 'TableResponse', 'TableErrorCode', 'Table'];
                            return(
                                <div key={index}>
                                    {category === 'TitleMain' ? TitleRender(objects[category]) : null}
                                    {category === 'Endpoint' ? TitleRender(objects[category]) : null}
                                    {category === 'Method' ? MethodRender(objects[category]) : null}
                                    {TableConsts.includes(category) ? TableRender(objects[category]) : null}
                                    {category === 'Example' ? JSONRender(objects[category]) : null}
                                </div>
                            )
                        })
                    }
                ) : null}

            </div>
        </div>
    )
}
export default ListDriver;
