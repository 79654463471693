import React from "react";
import {CommonUtil} from "../../utils/CommonUtil";

const ReservationCellRenderer = (props:any)=>{
    const onClickLink = ()=>{
        if(props.context){
            //localStorage.setItem("userId","krishna");
            /*localStorage.setItem("ReservationTab","true");
            let equipId:string = props.data.equipmentId;
            let reservationId:string = props.data.reservationId;
            let reservationAssetType:string = props.data.assetType;
            let isGeneric:string = props.data.isGeneric;
            let equipType:string = props.data.equipmentType;
             let availId:string = props.data.equipmentListingId;

            let siteOrigin:string = window.location.origin;
            siteOrigin = siteOrigin+"/?requestor=reservationview&equipid="+equipId+"&token="+ localStorage.getItem("token");
            siteOrigin = siteOrigin+"&reservationId="+reservationId;
            siteOrigin = siteOrigin+"&assetType="+reservationAssetType;
            siteOrigin = siteOrigin+"&equipType="+equipType;
            siteOrigin = siteOrigin+"&generic="+isGeneric;
             siteOrigin = siteOrigin+"&availId="+availId;

            var newTab = document.createElement('a');
            newTab.href = siteOrigin;
            newTab.setAttribute('target', '_blank');
            newTab.click();*/

            /*let siteOrigin:string = window.location.origin;
            siteOrigin = siteOrigin+"/?requestor=equipview";
            window.location.assign(siteOrigin);*/
            //props.context.onClickLink(props.data);

            props.context.onClickLink(props.data);
        }
    }
    return(
        <div className={"cellRenderHeader"}>
            <a><span onClick={onClickLink} className={"cellRender"}>{props.data.reservationCode}</span></a>
        </div>

    )
}

export default ReservationCellRenderer;