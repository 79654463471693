import React from 'react';
import exp from "constants";

const Overview = () => {
    return (
        <>
            <div className="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 right-content p-4 bg-white">

                <div className="row">
                    <div className="col-12">
                        <h3 id="first">Welcome to the vHub API.</h3>
                        <p>The API HUB is created to allow customers to access the application details and execute the transactions
                            using the comprehensive set of API offerings. These APIs shall also help the customers build functional
                            applications or integration quickly and easily. The ecosystem of APIs that are available for integrations is
                            strong and diverse, ranging from helping owners of the equipment to create the data to the renters allowing
                            them to confirm the reservations and other features access..</p>

                        <p>All of the vHub APIs are built using the REST API framework - if you've interacted with a RESTful API already,
                            many of the concepts will be very familiar to you. All API calls to vHub should be made to the &nbsp;
                             <a href={"#"}>https://apistaging.2hubapp.com:8080</a>  base domain. JSON will be returned in all API responses,
                            including the errors. The APIs are designed to have predictable, straightforward URLs and to use
                            HTTP response codes to indicate API exceptions.</p>
                        <h3>Getting started with the API integration</h3>
                        <p>If you're considering integration with vHub for commercial use, you can first register
                            your organization in the staging server. The registration process is simple and self-driven.</p>

                        <p>Each vHub customer account may have access to different APIs based on the organization type configuration and
                            the services opted for.</p>

                    </div>
                </div>


            </div>
        </>
    )
}

export default Overview;