import React, { useEffect, useState } from "react";
import { CommonUtil } from "../../utils/CommonUtil";
import { Constants } from "../Languages/Constants";
import { NetworkManager } from "../../Components/NetworkManager";


const CostEstimate = (props: any) => {

  const [pf,setPF] = useState(0);
  const [extraDays,setExtraDays] = useState(0);

  useEffect(()=>{  
    console.log(props.excessDays,props.nDays);
      
    try{
      if(props.startDate,props.endDate,props.movePrice)
        calculatePrice();
    }
    catch(err){
      console.log(err);
    }
  
  },[props.startDate,props.endDate,props?.isPhysicalDamageVisible, props?.isLiabilityVisible,props.movePrice,props.excessDays,props.nDays]);

  let calculatePrice = ()=>{

    var sDate:any = null;
    var eDate:any = null;
   
    if(props.startDate){
        sDate = props.startDate;
    }
    if(props.endDate){
        eDate = props.endDate;
    }  

    // let rDays = CommonUtil.getDays(sDate,eDate);
    var qParams:string = "availabilityId="+CommonUtil.SELECT_EQUIP_AVAIL_ID;
    qParams = qParams+"&distancePerDay="+props.distancePerDay;
    if(eDate){
        console.log(sDate);
        qParams = qParams+"&endDate="+CommonUtil.getReservationDateString(eDate);
    }
    qParams = qParams+"&saasOrgId="+props.saagOrgId;
    if(sDate){
        qParams = qParams+"&startDate="+CommonUtil.getReservationDateString(sDate);
    }
    qParams = qParams+"&enginerHoursPerDay="+props.enginerHoursPerDay;
    qParams = qParams+"&movePrice="+props.movePrice;
    var calculateAPI = "api/availability/costestimate?"+qParams;
    // if(isPublicSearchEnable){
    //     calculateAPI = "api/session/availability/costestimate?"+qParams;
    // }
    NetworkManager.sendJsonRequest(calculateAPI,"","","GET",true,(dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                //chkLiabilityRep.current.checked
                console.log(restData);  
                setPF(restData.platformFees);
                if(props.excessDays && props.nDays)
                  setExtraDays((props.nDays - props.excessDays) > 0 ? (props.nDays - props.excessDays) : 0);
                else
                  setExtraDays(restData.extraDays || 0);
            }
        }
    },onLoginError);    
  }
  let onLoginError = (err:any)=>{
    console.log(err);
  }

  let finalMovePrice =
    Number(props.movePrice) || Number(props?.data?.movePrice?.value);
    const pfees: any = Math.max(
      Number(finalMovePrice) * 0.1,
      Constants.MIN_PLATFORMFEE
    );

//   if (props?.data?.platformFeePaidBy.value === "RENTER") {
//     if (Number(finalMovePrice) < Constants.MIN_PLATFORMFEE)
//       finalMovePrice = Constants.MIN_PLATFORMFEE;
//     else 
//     finalMovePrice = finalMovePrice - Math.max(Constants.MIN_PLATFORMFEE,pfees);
//   }else if(props?.data?.platformFeePaidBy.value === "OWNER"){
//     finalMovePrice = finalMovePrice + Math.max(Constants.MIN_PLATFORMFEE,pfees);
//   }
//  else {
//     finalMovePrice = finalMovePrice - Math.max(Constants.MIN_PLATFORMFEE,pfees);
//   }    

  let totalAmount = 0;
  let insAmt = 0;
  try{
    if(props?.data?.platformFeePaidBy.value === "OWNER" || props?.data?.platformFeePaidBy.value === "RENTER"){    
      
      if(props.isPhysicalDamageVisible){
        insAmt += (props.nDays*props?.data?.PhysicalDamageinsurance.value);
      }
      if(props.isLiabilityVisible){
        insAmt += (props.nDays*props?.data?.liabilityinsurance?.value);
      }    
      
      // if(props?.data?.insurance?.liabilityPaidByRenter || props?.data?.insurance?.physicalDamagePaidByRenter)
      //   finalMovePrice = finalMovePrice - insAmt;
      // console.log(props?.data?.PhysicalDamageinsurance.days*props?.data?.PhysicalDamageinsurance.value,props?.data?.liabilityinsurance?.days*props?.data?.liabilityinsurance?.value);
      totalAmount += (finalMovePrice)
      totalAmount -= Number(props?.data?.excessDays?.value*extraDays)
      totalAmount -= Number(insAmt)
      totalAmount -= Number(props?.data?.AdministrationFee?.value)
      // totalAmount += Number(Math.max(Constants.MIN_PLATFORMFEE,pfees))
    }else{
      if(props.isPhysicalDamageVisible){
        insAmt += (props.nDays*props?.data?.PhysicalDamageinsurance.value);
      }
      if(props.isLiabilityVisible){
        insAmt += (props.nDays*props?.data?.liabilityinsurance?.value);
      }    
      
      // if(props?.data?.insurance?.liabilityPaidByRenter || props?.data?.insurance?.physicalDamagePaidByRenter){
      //   finalMovePrice = finalMovePrice - insAmt;        
      // }
      // finalMovePrice = finalMovePrice - Number(Math.max(Constants.MIN_PLATFORMFEE,pfees));
      // finalMovePrice = finalMovePrice + Number(props?.data?.excessDaysTotal?.value);
      // console.log(props?.data?.PhysicalDamageinsurance.days*props?.data?.PhysicalDamageinsurance.value,props?.data?.liabilityinsurance?.days*props?.data?.liabilityinsurance?.value);
      totalAmount += (finalMovePrice)
      totalAmount -= Number(props?.data?.excessDays?.value*extraDays)
      totalAmount -= Number(insAmt)
      totalAmount -= Number(props?.data?.AdministrationFee?.value)
      totalAmount -= Number(Math.max(Constants.MIN_PLATFORMFEE,pfees))
    }
  
  }
  catch(err){
    console.log(err);  
  }


  return (
    <div style={props?.style}>
      <h6>Estimate</h6>
      <div className="row estimate  mb-2">
        <div className="col-9 pl-0 pr-0">
          <i className="fa fa-usd float-start pt-1"></i>
          <p className="" style={{ paddingLeft: "10px", fontSize: "15px" }}>
            {CommonUtil.getMessageText(
              Constants.PAID_PRICE_CARRIER,
              "Paid price to carrier making the move"
            )}
            <br />
            <small>
              {props.nDays}&nbsp;
              {CommonUtil.getMessageText(
                Constants.DAYS_REPOSITION,
                "day(s) for repositioning"
              )}
            </small>
          </p>
        </div>
        <div className="col-3 pl-0 text-right">
          {props?.data?.currency?.value}&nbsp;
          {(finalMovePrice).toFixed(2) }
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-9 pl-0 pr-0">
          <i className="fa fa-usd float-start pt-1"></i>
          <p style={{ paddingLeft: "10px", fontSize: "15px" }}>
            {props?.data?.excessDaysTotal?.label}
            <br />
            <small>
              {`${extraDays} day(s) X`}&nbsp;
              {`${props?.data?.currency?.value} ${props?.data?.excessDays?.value}/DAY`}
            </small>
          </p>
        </div>
        <div className="col-3 pl-0 text-right">
          {props?.data?.currency?.value}&nbsp;{(Number(props?.data?.excessDays?.value*extraDays) || Number(props?.data?.excessDaysTotal?.value)) > 0 ? "-":''}
          {Number(Number(props?.data?.excessDays?.value*extraDays)).toFixed(2)}
        </div>
      </div>
       <div className="row mb-2">
        <div className="col-9 pl-0 pr-0">
          <i className="fa fa-usd float-start pt-1"></i>
          <p style={{ paddingLeft: "10px", fontSize: "15px" }}>
            {props?.data?.insurance?.label}
            <br />
            <small>{`${props.isLiabilityVisible ? `${props.nDays} day(s) X ${props?.data?.liabilityinsurance?.value}`:'0'} + ${props.isPhysicalDamageVisible ? `${props.nDays} day(s) X ${props?.data?.PhysicalDamageinsurance?.value}`:'0'} `}</small>
          </p>
        </div>
        <div className="col-3 pl-0 text-right">
          {props?.data?.currency?.value}&nbsp;{insAmt> 0 ? "-":''}
          {Number(Number(insAmt)).toFixed(2)}
        </div>
      </div> 
      <div className="row mb-2">
        <div className="col-9 pl-0 pr-0">
          <i className="fa fa-usd float-start pt-1"></i>
          <p style={{ paddingLeft: "10px", fontSize: "15px" }}>
            {props?.data?.AdministrationFee?.label}
            <br />
            <small>{`flat rate of ${props?.data?.AdministrationFee?.value} `}</small>
          </p>
        </div>
        <div className="col-3 pl-0 text-right">
          {props?.data?.currency?.value}&nbsp;-
          {Number(Number(props?.data?.AdministrationFee?.value)).toFixed(2)}
        </div>
      </div>

      {(props?.data?.platformFeePaidBy.value !== "OWNER" && props?.data?.platformFeePaidBy.value !== "RENTER") ? <div className="row mb-2">
        <div className="col-9 pl-0 pr-0">
          <i className="fa fa-usd float-start pt-1"></i>
          <p style={{ paddingLeft: "10px", fontSize: "15px" }}>
            {props?.data?.platformFees?.label}
          </p>
          {/* <br/><small>{`flat rate of ${props?.data?.platformFees?.value} `}</small> */}
        </div>
        <div className="col-3 pl-0 text-right">
          {props?.data?.currency?.value}&nbsp;{  "-" }
          {Number(Math.max(Constants.MIN_PLATFORMFEE,pfees)).toFixed(2)}
        </div>
      </div>:''}

      <div className="row mb-2">
        <div className="col-9 pl-0 pr-0 ">
          <i className="fa fa-usd float-start pt-1"></i>
          <p style={{ paddingLeft: "10px", fontSize: "15px" }}>
            <strong>{"Total " }</strong>
          </p>
          {/* <br/><small>{`flat rate of ${props?.data?.platformFees?.value} `}</small> */}
        </div>
        <div className="col-3 pl-0 text-right">
           <strong>{props?.data?.currency?.value}&nbsp;
           { Number(totalAmount).toFixed(2) }</strong>
        </div>
      </div>
    </div>
  );
};
export default CostEstimate;
