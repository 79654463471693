import React from "react";

export default function DetectDevice() {
const [width, setWidth] = React.useState<any>();
  const isMobile = width <= 768;
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}
    React.useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);
        return {isMobile}
}