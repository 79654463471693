import React from "react";
import {CommonUtil} from "../../utils/CommonUtil";

const OfferCreateRenderer = (props: any) => {
    const onClickLink = () => {
        if (props.context) {
            props.context.onClickLink(props.data);
        }
    }
    return (
        <div className={"cellRenderHeader"} style={{display:(props.data && props.data.allowOffer == "Y")?"":"none"}}>
            <button className="btn btn-success active-status" style={{paddingLeft: "20px"}} onClick={onClickLink}>Create Offer</button>
        </div>

    )
}

export default OfferCreateRenderer;
