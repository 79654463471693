import {useRef, useState} from 'react'
import AGGrid from '../../../../Components/AGGrid'
import {IAGrid} from '../../../../Components/IInterfaces'
import DriverCellRenderer from './DriverCellRenderer'
import {CommonUtil} from "../../../../utils/CommonUtil";
import DetectDevice from '../../../../utils/DetectDevice';
import MGrid from '../../../../Components/MGrid';
import { Constants } from '../../../Languages/Constants';
import noActionButton from '../../ParkingLot/noActionButton';

const DriverList = ({handleShow, onClickEditDriver, driversList}: any) => {
    const userGridRef = useRef<IAGrid>(null)
    const { isMobile } = DetectDevice();
    const onClickNewDriver = (data: any) => {
        handleShow(true)
        handleShow('new')
    }

    const [driverColumns] = useState([
        {
            field: 'name',
            headerName: CommonUtil.getMessageText(Constants.DRIVER_NAME, 'Driver Name'),
            resizable: true,
            flex: 1,
            width: 100,
            cellRenderer: DriverCellRenderer,
            sortable: true,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'phoneNumber',
            headerName: CommonUtil.getMessageText(Constants.PHONE_NO, 'Phone Number'),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agNumberColumnFilter',
        },
        {
            field: 'email',
            headerName: CommonUtil.getMessageText(Constants.EMAIL, 'Email'),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agNumberColumnFilter',
        },
        {
            field: 'truckNumber',
           headerName: 'Truck Number',
          resizable: true,
            flex: 1,
            width: 100,
           sortable: true,
             filter: 'agNumberColumnFilter',
        },
        {
            field: 'carrierName',
            headerName: 'Carrier Name',
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agNumberColumnFilter',
        },
        {
            field: 'usdot',
            headerName: 'USDOT Number',
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agNumberColumnFilter',
        },
        {
            field: 'status',
            headerName: CommonUtil.getMessageText(Constants.STATUS, 'Status'),
            resizable: true,
            flex: 1,
            width: 100,
            sortable: true,
            filter: 'agNumberColumnFilter',
            cellRenderer: noActionButton
        },
    ])

    return (
        <div className={`fs-7 ${isMobile ? '' : 'pt-2'}`}>
        {isMobile ? (
                <MGrid
                    id="driverList"
                    cols={driverColumns}
                    rows={driversList}
                    pageSize={10}
                    addNew={true}
                    tooltip={'Add Drivers'}
                    headerHeight={35}
                    rowHeight={40}
                    ref={userGridRef}
                    method={{onClickEditDriver}}
                    addNewRecord={onClickNewDriver}
                />
        ) : (
            <div
                className="ag-theme-alpine gridHeight"
                style={{height: CommonUtil.isRootUser() ? 'calc(100vh -  170px)' : 'calc(100vh -  120px)',
                    width: '100%', paddingLeft: '0px', paddingRight: '0px'}}>
                <AGGrid
                    id="driverList"
                    cols={driverColumns}
                    rows={driversList}
                    pageSize={500}
                    addNew={true}
                    tooltip={'Add Drivers'}
                    headerHeight={35}
                    rowHeight={40}
                    ref={userGridRef}
                    method={{onClickEditDriver}}
                    addNewRecord={onClickNewDriver}
                />
            </div>
        )}
        </div>
    )
}

export default DriverList
