import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useReleaseInspectorContext } from "../../../Components/ReleaseContext";
import { CommonUtil } from "../../../utils/CommonUtil";
import AttachmentsViewer from "./AttachmentsViewer";
import Confirm from "../../../Components/confirm";
import { NetworkManager } from "../../../Components/NetworkManager";
import ToastManager from "../../../Components/ToastManager";

const NewReleaseEntryValues = ({
  data,
  inspectionItem,
  onAddPictures,
}: any) => {
  let filenameCheck: any = "";
  const { state, dispatch } = useReleaseInspectorContext();
  let taskItem: any = {
    taskId: "",
    taskStatus: "",
    taskNote: "",
  };
  const dropFileObj = React.useRef<any>(null);

  let attachments: any = {
    attachmentUploads: [],
    attachmentDamageDescription: null,
  };

  const [showPicModal, setShowPicModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const handleClose = () => setShowPicModal(false);
  const filenameRef = useRef<any>(null);
  const qPath: any = CommonUtil.getInterchangeDocumentId();

  let imagePath: string = "";

  let onClickRemoveImage = (attachId: string) => {
    Confirm.show("Confirm", "Do you want remove document?", [
      {
        label: "Yes",
        callback: function () {
          if (attachId) {
            //fileAttachmentId = attachId;
            let removeAPI: string =
              "api/equipment/attachment/" +
              attachId +
              "?saasOrgId=" +
              CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(
              removeAPI,
              "",
              "",
              "DELETE",
              true,
              onDeleteEquipmentImage,
              onLoginError
            );
          }
        },
      },
      { label: "No" },
    ]);
  };

  const onDeleteEquipmentImage = (dataObj: any) => {
    if (dataObj == "success") {
      ToastManager.showToast("Removed Successfully", "Info");
      // removeSelectedImage(fileAttachmentId);
    } else {
      ToastManager.showToast("Error", "Error");
    }
  };

  const onLoginError = (err: any) => {
    console.log(err);
  };

  try {
    const item = state?.visualInspection?.task.filter(
      (ele: any) => ele.taskId === data.taskId
    )[0];
    taskItem = {
      taskId: item.taskId,
      taskStatus: item?.taskStatus,
      taskNote: item?.taskNote,
    };
  } catch (e) {
    taskItem = {
      taskId: data.taskId,
      taskStatus: "",
      taskNote: "",
    };
  }

  try {
    const item = state?.visualInspection?.task.filter(
      (ele: any) => ele.taskId === data.taskId
    )[0];
    attachments = {
      attachmentUploads: {
        fullFilePath: item?.attachments.attachmentUploads,
      },
      attachmentDamageDescription: item?.attachents.attachmentDamageDescription,
    };
  } catch (e) {
    attachments = {
      attachmentUploads: [],
      attachmentDamageDescription: "",
    };
  }

  const handleChange = (value: any) => {
    dispatch({
      type: "visualInspection",
      payload: {
        taskId: value.taskId,
        taskStatus: value.taskStatus,
        taskNote: value.taskNote,
      },
    });
    dispatch({
      type: "visualInspection",
      payload: {
        isCompleted: false,
      },
    });
  };

  const selectionNote = (
    <input
      className="textarea"
      type="text"
      value={taskItem?.taskNote}
      onChange={(e) =>
        handleChange({
          ...taskItem,
          taskNote: e.target.value,
          taskId: data?.taskId,
        })
      }
      name={data?.taskNote}
    />
  );
  return (
    <>
      <tr>
        <td>{data?.taskName}</td>
        <td>
          <div className="d-flex text-center" style={{ marginLeft: "10px" }}>
            <div>
              <input
                className="radio-size"
                required
                type="radio"
                name={data?.taskId}
                value={taskItem?.taskStatus}
                checked={taskItem?.taskStatus === "ok"}
                onChange={(e) =>
                  handleChange({
                    ...taskItem,
                    taskStatus: "ok",
                    taskId: data?.taskId,
                  })
                }
              />
            </div>
            <div>
              <input
                className="radio-size"
                required
                name={data?.taskId}
                type="radio"
                value={taskItem?.taskStatus}
                checked={taskItem?.taskStatus === "fail"}
                onChange={(e) =>
                  handleChange({
                    ...taskItem,
                    taskStatus: "fail",
                    taskId: data?.taskId,
                  })
                }
                style={{ marginLeft: "45px" }}
              />
            </div>
          </div>
        </td>
        <td>{selectionNote}</td>
        {taskItem?.taskStatus === "fail" && (
          <td>
            <div className="d-flex ">
              {state?.visualInspection?.task?.filter(
                (item: any) => item.taskId === data?.taskId
              )[0]?.attachments?.attachmentUploads?.length ? (
                <div
                  style={{
                    textDecoration: "underline",
                    color: "blue",
                    padding: "4px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    borderRadius: "3px",
                    marginRight: "5px",
                    height: "25px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    AttachmentsViewer.show({
                      ...state?.visualInspection?.task?.filter(
                        (item: any) => item.taskId === data.taskId
                      )[0].attachments,
                      isDeleteButton: true,
                      resetData: "visualInspection",
                    })
                  }
                >
                  {
                    state?.visualInspection?.task?.filter(
                      (item: any) => item.taskId === data?.taskId
                    )[0]?.attachments?.attachmentUploads?.length
                  }
                </div>
              ) : null}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onAddPictures(data?.taskId, CommonUtil.RELEASES_PICS);
                  dispatch({ type: "setTaskId", payload: data?.taskId });
                }}
              >
                <i className="fa-solid fa-cloud-arrow-up"></i>
              </span>{" "}
              &nbsp;
              {state?.visualInspection?.task?.filter(
                (item: any) => item.taskId === data.taskId
              )[0]?.attachments?.attachmentUploads?.length ? (
                <>
                  <input
                    className="textarea"
                    required
                    type="text"
                    style={{ width: "75%" }}
                    value={
                      state?.visualInspection?.task?.filter(
                        (item: any) => item.taskId === data?.taskId
                      )[0]?.attachments?.attachmentDamageDescription
                    }
                    onChange={(event: any) => {
                      dispatch({
                        type: "addattachments",
                        payload: {
                          taskId: data?.taskId,
                          category: "visualInspection",
                          attachments: {
                            attachmentDamageDescription: event.target.value,
                          },
                        },
                      });
                    }}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </td>
        )}
      </tr>
    </>
  );
};

export default NewReleaseEntryValues;
