import React from "react";

const OrgRoleRenderer = (props: any) => {
    const onClickRole = () => {
        if (props.context) {
            props.context.onClickRoleDetails(props.data);
        }
    }
    return (
        <div className={"cellRenderHeader"}>
            <a><span onClick={onClickRole} className={"cellRender"}>{props.value}</span></a>
        </div>

    )
}

export default OrgRoleRenderer;