import React, {useEffect, useRef, useState} from "react";
import {CommonUtil} from "../../utils/CommonUtil";
import EquipmentAvail from "./EquipmentAvail";
import {NetworkManager} from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";
import EquipmentPrices from "./EquipmentPrices";
import EquipmentDocuments from "./EquipmentDocuments";
import EquipmentDamages from "./EquipmentDamages";

import $ from "jquery";
import BusyLoader from "../../Components/BusyLoader";
import {useNavigate} from "react-router-dom";
import EquipmentReservation from "./EquipmentReservation";
import EquipmentHistory from "./EquipmentHistory";
import ReservationDetails from "./ReservationDetails";
import ReserveActivation from "./ReserveActivation";
import ReserveDeActivation from "./ReserveDeActivation";
import ReserveClaims from "./ReserveClaims";
import DraggableComponent from "../../Components/DraggableComponent";
import SingleSelectComp from "../../Components/SingleSelectComp";
import {Modal} from "react-bootstrap";
import ReservationHistory from "./ReservationHistory";
import {Constants} from "../Languages/Constants";
import PhoneInput from "react-phone-input-2";
import StarRating from "../../Components/Rating";
import { reAdjust, leftClickScroll, rightClickScroll} from "../../utils/ScrollHandler";
import DetectDevice from "../../utils/DetectDevice";
import Confirm from "../../Components/confirm";
import EquipGalleryModal from "./EquipGalleryModal";
import AGGrid from "../../Components/AGGrid";
import EquipAvailTypeRenderer from "../Organization/EquipAvailTypeRenderer";
import EquipCellRenderer from "../Organization/EquipCellRenderer";
import {IAGrid} from "../../Components/IInterfaces";
import ReservationView from "./ReservationView";
import RegistrationHeader from "../Login/RegistrationHeader";

var equiImages:any = [];
var attchIdx:number = 0;
var attachmentList: any = [];
var policyId = "";
var reservationStatus:string = "";
var assignEquipId = "";
const ReservationViewCopy = ()=>{
    const {isMobile} = DetectDevice();
    //const[equipImagesPath,setEquipmentImagesPath] = useState([]);

    useEffect(()=>{

    },[]);

    return(
        <>
            {
                CommonUtil.getSelectedOrgId()?
                    <RegistrationHeader isLogin={"false"} isSearchDB={"false"} isSignUp={"false"} isLogout={"true"} />:
                    <RegistrationHeader isLogin={"false"} isSearchDB={"false"} isSignUp={"false"} />
            }

            <div style={{paddingTop:"40px"}}>
                <ReservationView />
            </div>

        </>
    )
}

export default ReservationViewCopy;
