import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {NetworkManager } from "../../Components/NetworkManager";
import { MapData } from "../../Components/MapData";
import { StatusCodeUtil } from "../../utils/StatusCodeUtil";
import ToastManager from "../../Components/ToastManager";

var email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;

const ForgetPassword = ()=>{

    const navigate = useNavigate();
    const [userName,setUserName] = useState("");
    const [password,setPassword] = useState("");
    const [errorMsg,setErrorMsg] = useState("");

    const formRef = useRef<HTMLFormElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const passRef = useRef<HTMLInputElement>(null);

    let onClickButton = ()=>{
        navigate("/menu");
       // var mp = new MapData();
        //mp.disp();
      }

      let onEmailChange = (e:any):void=>{
           let uname = e.currentTarget.value;
            setUserName(uname);
            setErrorMsg("");

            if(email_regex.test(uname)){
                emailRef.current?.classList.add("is-valid");
                emailRef.current?.classList.remove("is-invalid");
            }else{
                emailRef.current?.classList.add("is-invalid");
                emailRef.current?.classList.remove("is-valid");
            }
      }



      let onClickReset = ()=>{
        let uname = userName.trim();
          if(uname == ""){
              emailRef.current?.classList.add("is-invalid");
          }

        if(uname){
            if(email_regex.test(uname)){
                emailRef.current?.classList.add("is-valid");
                var restObj:any = {};//"userName": "fn2l32n3@yahoo.com","password": "admin1234"};
                restObj.userName = userName;
                //restObj.password = password;
                var qParam = "email="+userName;
               NetworkManager.sendJsonRequest("api/session/createResetPassword","",qParam,"POST",true,onLoginSuccess,onLoginError);
            }else{
                emailRef.current?.classList.add("is-invalid");
            }
        }else{
            
        }
    }

    let onLoginSuccess = (data:any)=>{
        console.log(data);
        if(data){
            var restData = JSON.parse(data);
            if(restData){
                if(restData.statusCode){
                    setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                }else{
                    /* console.log(restData);
                     localStorage.setItem("token",restData.token);
                     localStorage.setItem("refreshToken",restData.refreshToken);
                     navigate("/menu");*/
                }
            }
        }else{
            ToastManager.showToast("Password reset successfully","Info");
            setTimeout(()=>{
                navigate("/");
            },500);
        }
    }

    let onLoginError = (err:any)=>{
        console.log(err);
    }

    return(
        <React.Fragment><header>
        <nav className="navbar fixed-top bg-white">
            <div className="container-fluid">
                <a className="navbar-brand" href="src/Pages/Login/LoginPage" target="_blank">
                    <img src="../../logo.png" />
                    
                </a>
                <div className="navbar-nav ms-md-auto pe-0">
                    <p className=" grey-text fs-7" style={{marginTop: "0.3125rem",marginBottom: "0.3125rem;"}}>
                        <a  className="grey-text text-decoration-none">Search</a> <a  className="grey-text text-decoration-none ps-3">FR</a></p>
                </div>
            </div>
        </nav>
        </header>
        <div className="container-fluid">
            <div className="row vh-100">
                <div className="col-md-8 login-truckbg d-none">
                    <div className="text-banner">
                        <h2 className="banner-main mb-0">Let's find</h2>
                        <p className="banner-sub">the right trailer for your need</p>
                    </div>
                </div>
                <div className="col-md-8 p-0">
				<div id="loginCarousel" className="carousel slide" data-bs-ride="carousel">
				  <div className="carousel-indicators">
					<button type="button" data-bs-target="#loginCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
					<button type="button" data-bs-target="#loginCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
					<button type="button" data-bs-target="#loginCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
				  </div>
				  <div className="carousel-inner">
					<div className="carousel-item active login-banner-bg">
					  <img src="../../images/banner1.png" className="d-block position-absolute top-50 start-50 translate-middle" alt="..."/>
					  <div className="carousel-caption d-none d-md-block">
						<h5>Let's find</h5>
						<p>the right trailer you need.</p>
					  </div>
					</div>
					<div className="carousel-item" style={{"height":"100vh"}}>
					  
					  <div className="h-100 login-banner2"></div>
					  <div className="carousel-caption d-none d-md-block">
						<h5>Let's find</h5>
						<p>the right trailer you need.</p>
					  </div>
					</div>
					<div className="carousel-item" style={{"height":"100vh"}}>
					  
					  <div className="h-100 login-banner3"></div>
					  <div className="carousel-caption d-none d-md-block">
						<h5>Let's find</h5>
						<p>the right trailer you need.</p>
					  </div>
					</div>
				  </div>
				  <button className="carousel-control-prev d-none" type="button" data-bs-target="#loginCarousel" data-bs-slide="prev">
					<span className="carousel-control-prev-icon" aria-hidden="true"></span>
					<span className="visually-hidden">Previous</span>
				  </button>
				  <button className="carousel-control-next d-none" type="button" data-bs-target="#loginCarousel" data-bs-slide="next">
					<span className="carousel-control-next-icon" aria-hidden="true"></span>
					<span className="visually-hidden">Next</span>
				  </button>
				</div>
			</div>

            <div className="col-md-4">
				<div className="row ms-4 mt-3 me-4 border-bottom d-none" style={{"height":"37.5px"}}>
					<div className="col-md-4 ps-0"><a href="src/Pages/Login/LoginPage" target="_blank"> <div className="logo"></div></a></div>
					<div className="col-md-8 pe-0">
						<p className="float-end grey-text fs-6"><a href="src/Pages/Login/LoginPage#" className="grey-text text-decoration-none">Search</a> <a href="src/Pages/Login/LoginPage#" className="grey-text text-decoration-none ps-3">FR</a></p>
					</div>
				</div>
				<div className="mx-auto">
					<div className="row ms-4 mt-3 me-4 login-vertical-center">
						<h4 className="darkgrey-text">Forgot Password</h4>
						<p className="pb-4 darkgrey-text" style={{fontSize:"14px"}}>An email will be sent to the below entered id to reset the password</p>
						<form ref={formRef} className="pb-4 border-bottom needs-validation requires-validation" noValidate>

                            <div className="mb-3 text-center">
								<label className="form-label loginError fs-6" >{errorMsg}</label>
							</div>

							<div className="mb-3">
								<label className="form-label grey-text fs-6" >Email</label>
								<input ref={emailRef} type="email" className="form-control login-input" id="inputEmail" required={true} placeholder="Email" 
                                value={userName} onChange={(e)=>{onEmailChange(e)}}/>
							</div>

                            <button type="button" className="btn login-btn float-end" onClick={onClickReset}>Reset</button>
						</form>
						<p className="pt-2 text-center darkgrey-text account fs-6">Don't have an account? <a  className="signup-text text-decoration-underline">Sign Up</a></p>
					</div>
				</div>
			</div>
            </div>
        </div>
        </React.Fragment>
        
        
    );
}

export default ForgetPassword;
