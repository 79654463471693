import React from "react";
import {CommonUtil} from "../../utils/CommonUtil";

const SearchDBCellRenderer = (props:any)=>{
    const onClickLink = ()=>{
        if(props){
            //localStorage.setItem("userId","krishna");
            /*CommonUtil.SELECT_EQUIP_ID = data.equipmentId;
            CommonUtil.SELECT_EQUIP_AVAIL_ID = data.equipmentListingId;
            CommonUtil.EQUIP_RESERVE_IS_GENERIC = data.isGeneric;
            CommonUtil.SELECT_EQUIPMENT_TYPE = data.equipmentType;*/

            localStorage.setItem("PublicViewTab","true");
            let siteOrigin:string = window.location.origin;
            let equipId:string = props.data.equipmentId;
            let avaiId:string = props.data.availabilityId;
            let isGeneric:string = props.data.isGenericEquipment;
            let equipType:string = props.data.equipmentType;

            siteOrigin = siteOrigin+"/?requestor=equippublicview&equipId="+equipId;//+"&token="+ localStorage.getItem("token");
            siteOrigin = siteOrigin+"&avaiId="+ avaiId;
            siteOrigin = siteOrigin+"&isGeneric="+ isGeneric;
            siteOrigin = siteOrigin+"&equipType="+ equipType;
            var newTab = document.createElement('a');
            newTab.href = siteOrigin;
            newTab.setAttribute('target', '_blank');
            newTab.click();

            /*let siteOrigin:string = window.location.origin;
            siteOrigin = siteOrigin+"/?requestor=equipview";
            window.location.assign(siteOrigin);*/
            //props.context.onClickLink(props.data);

            //props.context.onClickLink(props.data);
        }
    }
    return(
        <div className={"cellRenderHeader"}>
            <button className="btn btn-success active-status" onClick={onClickLink}>Book Now</button>
        </div>

    )
}

export default SearchDBCellRenderer;
