import React from 'react'
import { CommonUtil } from '../../../utils/CommonUtil'


function noActionButton(props:any) {

  return (
   <div className={"cellRenderHeader"} style={{marginTop: props.isMobile ? "2px" : "-3px"}}>
            {
                (props.value && props.value.toLowerCase() == CommonUtil.ACTIVE.toLowerCase()) ?
                    <button className="btn btn-success active-status">{(props.value)}</button> :
                    <button className="btn btn-success in-active-status">{props.value ? props.value : 'Inactive'}</button>
            }

        </div>
  )
}

export default noActionButton