import React, {forwardRef, Ref, useEffect, useImperativeHandle, useState} from "react";
import {Modal} from 'react-bootstrap'
import Draggable from "react-draggable";
import DraggableComponent from "../../Components/DraggableComponent";
import { IGridToolBox } from "../../Components/IInterfaces";
import { Button } from "react-bootstrap";
import MaskGroup20 from '../InspectionCompany/MaskGroup20.svg'
import MaskGroup21 from '../InspectionCompany/MaskGroup21.svg';
import MaskGroup22 from '../InspectionCompany/MaskGroup22.svg';
import MaskGroup23 from '../InspectionCompany/MaskGroup23.svg';
import InspectionSelect from "../InspectionGrid/InspectionSelect";
import Select, { components } from 'react-select'
import { NetworkManager } from "../../Components/NetworkManager";
import { CommonUtil } from "../../utils/CommonUtil";

interface IProps {
    message?: string;
    api?: any;
    hellTest?: () => void
    onClear?: () => void
    exportCSV?: () => void
}


const AssignedOrganizationsToolbar = forwardRef((props: any, ref: Ref<IGridToolBox>) => {
    let totalRows: number = 0;
    let pageSize: number = 500;
    let totalPages: number = 0;
    let currPage: number = 1;
    let totalRowsAfterFilter: number = 0;

    var pageindicator = "";


    const [pages, setPages] = useState('');
    const [showSortPopup, setShowPopup] = useState(false);
    const [showAddPopup,setShowAddPopup] = useState(false);
    const [showColumnPopup, setShowColumnPopup] = useState(false);
    const [columns, setColumns] = useState([]);
    const [sortColumns1, setSortColumns1] = useState<any>([]);
    const [page1, setPage1] = useState('');
    const [avlColumns, setAvlColumns] = useState([]);
    const [selColumns, setSelColumns] = useState([]);
    const [showAddCompanyPopup,setShowAddCompanyPopup] = useState(false)
    

const [isClearable, setIsClearable] = useState(true);
const [isSearchable, setIsSearchable] = useState(true);
const [isDisabled, setIsDisabled] = useState(false);
const [isLoading, setIsLoading] = useState(false);
const [isRtl, setIsRtl] = useState(false);
const [optionsData,setOptionsData] = useState<any[]>([])
const [selectedOptions, setselectedOptions] = useState("")
const [selected, setSelected] = useState(null);



let getAllOrganizations = ()=>{
    var orgUrl = "api/organization/allOwnerOrganizations"
    NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onGetAllOrgs,onLoginError);
  }

  

  let onGetAllOrgs = (data: any) => {
    if (data) {
        var restData = CommonUtil.parseData(data);
        if (restData.organizations) {
          setOptionsData(restData.organizations.map((d:any)=>{
            return {value:d.orgName,label:d.orgName,id:d.orgId}
          }))
        }
    }
  }

  let onLoginError = (err:any)=>{
    console.log(err);
  }



  useEffect(()=>{
    getAllOrganizations();
  },[])


    useEffect(() => {
        if (props && props.api && props.api.rowModel && props.api.rowModel.rootNode && props.api.rowModel.rootNode.allLeafChildren) {
            displayPageInformation();
        }

    }, [props.rowData]);

    let displayPageInformation = () => {
        totalRows = props.api.rowModel.rootNode.childrenAfterFilter.length;
        totalRowsAfterFilter = totalRows;
        pageSize = (props.pageSize) ? props.pageSize : 100;
        if (totalRows == 0) {
            totalPages = 0;
        } else {
            totalPages = Math.ceil(totalRows / pageSize);
        }
        if (totalRows == 0) {
            pageindicator = (0) + "-" + (0) + " of " + 0;
        } else {
            pageindicator = (((pageSize * currPage) - pageSize) + 1) + "-" + ((pageSize * currPage) > totalRows ? totalRows : (pageSize * currPage)) + " of " + totalRows;
        }
        setPages(pageindicator);
    }

    useImperativeHandle(ref, () => ({passColumnFilter}))

    const passColumnFilter = (params: any) => {
        totalRows = params.api.rowModel.rootNode.childrenAfterFilter.length;
        totalRowsAfterFilter = totalRows;
        pageSize = (props.pageSize) ? props.pageSize : 100;
        if (totalRows == 0) {
            totalPages = 0;
        } else {
            totalPages = Math.ceil(totalRows / pageSize);
        }
        if (totalRows == 0) {
            pageindicator = (0) + "-" + (0) + " of " + 0;
        } else {
            pageindicator = (((pageSize * currPage) - pageSize) + 1) + "-" + ((pageSize * currPage) > totalRows ? totalRows : (pageSize * currPage)) + " of " + totalRows;
        }
        setPages(pageindicator);
    }

    let gotoNextPage = () => {
        if (props.api) {
            props.api.paginationGoToNextPage();
            currPage = props.api.paginationGetCurrentPage();
            //console.log(currPage);
            currPage = currPage + 1;
            displayPageInformation();
        }
    }
    let gotoPreviousPage = () => {
        if (props.api) {
            props.api.paginationGoToPreviousPage();
            currPage = props.api.paginationGetCurrentPage();
            // console.log(currPage);
            currPage = currPage + 1;
            displayPageInformation();
        }
    }
    let onSearchFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // console.log(e.currentTarget.value);
        if (props.api && props.api.setQuickFilter) {
            props.api.setQuickFilter(e.currentTarget.value);
            // console.log(props.api.rowModel.rootNode.childrenAfterFilter.length);
            displayPageInformation();
            // allChildrenCount
        }
    }

    let onClickRemoveSearch = () => {
        props.api.setQuickFilter("");
        // console.log(props.api.rowModel.rootNode.childrenAfterFilter.length);
        displayPageInformation();
    }

    let onClickSort = () => {
        //console.log("Sort");
        setShowPopup(true);
        var colArray: object[] = [];
        var colObject: object = {};
        //console.log(props.api.getColumnDefs());
        var cols: any = [];
        var columns: any = props.api.getColumnDefs();
        columns.forEach((column: any, idx: number) => {
            if (column.sort && !column.hide) {
                var colObj: any = {};
                colObj.text = column.headerName;
                colObj.field = column.field;
                colObj.sort = column.sort;
                cols.push(colObj);
            }
        });

        setSortColumns1(cols);
        setColumns(columns);
    }
    let onClosePopup = () => {
        setShowPopup(false);
    }

    let onClickClear = () => {
        props.onClear();
    }

    let onClickExport = () => {
        props.exportCSV();
    }

    let onClickAdd = () => {
        props.onClickAdd();
    }

    let onhandleSelectChange = (params:any)=>{
        props.onhandleSelectChange(params);
    }


    let onAddBtnClickHandler = () => {
        // var sArr = this.state.sortableColumns;
        if (document.getElementById("organizeColumnsSelect")) {
            //var sortColumns:any = sortColumns;
            var element: any = document.getElementById("organizeColumnsSelect")
            var field = element.value;
            if (field) {
                var text = element.options[element.selectedIndex].text;
                var colObj: any = {};
                colObj.text = text;
                colObj.field = field;
                colObj.sort = "asc";
                sortColumns1.push(colObj);
                setSortColumns1(sortColumns1);
                setPage1("" + Math.random() * 1000000);
            }
        }
    }

    let onDescSort = (e: any, field: any) => {
        //console.log(e,field);
        // var sArr = this.state.sortableColumns;
        for (var i = 0; i < sortColumns1.length; i++) {
            if (sortColumns1[i].field == field) {
                sortColumns1[i].sort = e.target.checked ? "desc" : "asc"
                break;
            }
        }
        setSortColumns1(sortColumns1);
        setPage1("" + Math.random() * 1000000);
    }
    let onDelSort = (field: any) => {
        // var sArr = this.state.sortableColumns;
        for (var i = 0; i < sortColumns1.length; i++) {
            if (sortColumns1[i].field == field) {
                sortColumns1.splice(i, 1);
                break;
            }
        }
        setSortColumns1(sortColumns1);
        setPage1("" + Math.random() * 1000000);
        // this.setState({sortableColumns:sArr});
    }

    const ClearIndicator = (props: any) => {
        return (
            <components.ClearIndicator {...props}>
                <i className="fa-solid fa-close black-text fa-3xs"></i>
            </components.ClearIndicator>
        )
    }
    
    const DropdownIndicator = (props: any) => {
        return (
            <components.DropdownIndicator {...props}>
                <i className="fas fa-angle-down black-text fa-3xs"></i>
            </components.DropdownIndicator>
        )
    }
    let onSaveSort = () => {
        //console.log(sortColumns1);
        var sortArr: any = [];
        for (var i = 0; i < sortColumns1.length; i++) {
            var obj: any = {};
            obj.colId = sortColumns1[i].field;
            obj.sort = sortColumns1[i].sort;
            obj.sortIndex = i;
            sortArr.push(obj);
        }
        //sortArr.push({colId: 'model', sort: 'asc'});
        props.api.rowModel.columnApi.applyColumnState({
            state: sortArr,
            defaultState: {sort: null},
        });
        onClosePopup();
    }

    let onCloseColumnPopup = () => {
        setShowColumnPopup(false);
    }


    let onClickShowColumns = () => {
        setShowColumnPopup(true);
        var selectedCols: any = [];
        var avlCols: any = [];
        var columns: any = props.api.getColumnDefs();
        columns.forEach((column: any, idx: number) => {
            var colObj: any = {};
            if (!column.hide) {
                colObj.text = column.headerName;
                colObj.field = column.field;
                selectedCols.push(colObj);
            } else {
                colObj.text = column.headerName;
                colObj.field = column.field;
                avlCols.push(colObj);
            }
        });
        setSelColumns(selectedCols);
        setAvlColumns(avlCols);
        setPage1("" + Math.random() * 1000000);
    }

    let onClickRight = () => {
        var items: any = document.getElementById("leftId");
        if (items && items.options) {
            var avlCols: any = [];
            var selectedCols: any = selColumns;

            for (var k = 0; k < items.options.length; k++) {
                var colObj: any = {};
                colObj.text = items.options[k].text;
                colObj.field = items.options[k].value;
                if (items.options[k].selected == true) {
                    selectedCols.push(colObj);
                } else {
                    avlCols.push(colObj);
                }
            }

            setAvlColumns(avlCols);
            setSelColumns(selectedCols);
            setPage1("" + Math.random() * 1000000);
        }
    }

    let onClickUpRows = () => {

        var arr: any = document.querySelectorAll("#rightId option");
        var box: any = document.getElementById("rightId");
        let findSelected = selColumns.findIndex((sel:any)=>sel.selected === true);

        if (findSelected === 0) {
            return;
        }

        var indexes = [];
        for (var k = 0; k < arr.length; k++) {
            if (arr[k].selected)
                indexes.push(arr[k].value);
        }

        var arrayTemp: [] = swapElement(selColumns, indexes, "dec");

        setSelColumns([...arrayTemp]);
        setPage1("" + Math.random() * 1000000);


      
    }
    let swapElement = (array: any, indexes: any, str: any): any => {
        var tmp = "";

        var arr: any = [];

        if (str == "inc") {
            for (var k = indexes.length - 1; k >= 0; k--) {
                for (var j = array.length - 1; j >= 0; j--) {
                    if (array[j].field == indexes[k]) {
                        arraymove(array, j, j + 1);
                        break;
                    }
                }
            }
        } else {
            for (var k = 0; k < indexes.length; k++) {
                for (var j = 0; j < array.length; j++) {
                    if (array[j].field == indexes[k]) {
                        arraymove(array, j, j - 1);
                        break;
                    }
                }
            }
        }

        return array;
    }

    let arraymove = (arr: any, fromIndex: number, toIndex: number) => {
        var element = arr[fromIndex];
        element.selected = true;
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
        // return arr;
    }

    let onClickDown = () => {
        var arr: any = document.querySelectorAll("#rightId option");
        var box: any = document.getElementById("rightId");
        let selected = selColumns.filter((sel:any)=>sel.selected === true) || [];

        let obj: any = selected.slice(-1)[0]
        let findSelected = selColumns.findIndex((sel:any)=>sel === obj);
        if (findSelected + 1 === selColumns.length) {
            return;
        }
        var indexes = [];
        for (var k = 0; k < arr.length; k++) {
            if (arr[k].selected)
                indexes.push(arr[k].value);
        }

        var arrayTemp: [] = swapElement(selColumns, indexes, "inc");

        setSelColumns([...arrayTemp]);
        setPage1("" + Math.random() * 1000000);

    }

    let onClickLeft = () => {
        var items: any = document.getElementById("rightId");
        if (items && items.options) {
            //console.log(items.options.length);
            var selItem = [];
            var avlCols: any = avlColumns;
            var selectedCols: any = [];
            //console.log(items);
            for (var k = 0; k < items.options.length; k++) {
                var colObj: any = {};
                colObj.text = items.options[k].text;
                colObj.field = items.options[k].value;
                if (items.options[k].selected == true) {
                    avlCols.push(colObj);
                } else {
                    selectedCols.push(colObj);
                }
            }
            setAvlColumns(avlCols);
            setSelColumns(selectedCols);
            setPage1("" + Math.random() * 1000000);
            //console.log(selItem);
        }


     
    }


    const styles = {
        menuList: (base: any) => ({
            ...base,
        }),
        option: (base: any, state: any) => ({
            ...base,
            color: state.isSelected ? 'white' : 'black',
            fontSize: '12px',
            paddingLeft: '12px',
            padding: '0px 2px 1px',
            minHeight: '1.2em',
            paddingBottom: '5px',
        }),
        control: (base: any, state: any) => ({
            ...base,
            minHeight: '30px',
            height: '30px',
            paddingLeft: '2px',
            alignContent: 'center',
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none',
        }),
        clearIndicator: (base: any) => ({
            ...base,
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            alignSelf: 'center',
        }),
        singleValue: (provided: any) => ({
            ...provided,
            fontSize: '12px',
            alignSelf: 'center',
            color: 'black',
        }),
        placeholder: (base: any) => ({
            ...base,
            fontSize: '12px',
            alignSelf: 'center',
        }),
        noOptionsMessage: (base: any) => ({
            ...base,
            fontSize: '12px',
            paddingLeft: '10px',
            padding: '0px 2px 1px',
            minHeight: '1.2em',
        }),
    }


    let onSaveShowHide = () => {
       
        var cols: any = [];
        if (selColumns.length > 0) {
            selColumns.forEach((col: any, idx: number) => {
                cols.push(col.field);
            });
            props.api.columnModel.setColumnsVisible(cols, true);
        }
        props.api.columnModel.moveColumns(cols, 0);
        if (avlColumns.length > 0) {
            cols = [];
            avlColumns.forEach((col: any, idx: number) => {
                cols.push(col.field);
            });
            props.api.columnModel.setColumnsVisible(cols, false);
        }


        onCloseColumnPopup();
    }

    const selectedOption = (e:any, idx:number) => {
        let options : any = [...selColumns]

        if(e.ctrlKey){
            if(options[idx].selected === true){
                options[idx].selected = false;
            }else{
                options[idx].selected = true;
            }
        }else{
            //console.log('no 17')
            options.map(function(x:any) {
                x.selected = false;
            });
            options[idx].selected = true;
        }

        setSelColumns(options);
    }



    return (
        <div>
            <Draggable handle=".modal-header">
                <Modal show={showColumnPopup} dialogAs={DraggableComponent} bgSize='lg' className="fcPopup">
                    <div className="modal-content" style={{"width": "800px", "height": "400px"}}>
                        <div className="modal-header modalPopupHeader">
                            <h6 className="modal-title">{"Column Show/Hide"}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    style={{marginLeft:"0px"}} onClick={onCloseColumnPopup}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div id="organizeColumns" className="tab-pane">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="panel panel-default customPanel">
                                            <div className="panel-heading removeHeaderBackground" style={{
                                                paddingLeft: "0px",
                                                fontSize: "14px",
                                                paddingBottom: "5px"
                                            }}>{"Available Columns"}</div>
                                            <div className="tablePanel"
                                                 style={{height: "220px", overflowY: "hidden", msOverflowX: 'hidden'}}>
                                                <select multiple={true} id="leftId" className="tabSelect form-select"
                                                        style={{
                                                            "paddingLeft": "2px",
                                                            "width": "100%",
                                                            "height": "100%",
                                                            "border": "dashed",
                                                            "borderStyle": "ridge"
                                                        }}>
                                                    {
                                                        avlColumns.map((item: any, idx: number) => {
                                                            var list = <option value={item.field}>{item.text}</option>;
                                                            return list;

                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1" style={{paddingTop: "100px"}}>
                                        <button className="btn cbtn-success grid-button" style={{marginBottom:"5px"}}
                                                type="button" onClick={onClickLeft}>
                                            <i className="fa fa-arrow-left" style={{color:"#FFF"}}></i>
                                        </button>

                                        <button className="btn cbtn-success grid-button" type="button" onClick={onClickRight}>
                                            <i className="fa fa-arrow-right" style={{color:"#FFF"}}></i>
                                        </button>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="panel panel-default customPanel">
                                            <div className="panel-heading removeHeaderBackground" style={{
                                                paddingLeft: "0px",
                                                fontSize: "14px",
                                                paddingBottom: "5px",
                                                fontWeight:"bold"
                                            }}>{"Selected Columns"}</div>
                                            <div className="tablePanel"
                                                 style={{height: "220px", overflowX: "hidden", overflowY: 'hidden'}}>
                                                <select multiple={true} id="rightId" className="tabSelect form-select"
                                                        style={{
                                                            "paddingLeft": "2px",
                                                            "width": "100%",
                                                            "height": "100%",
                                                            "border": "dashed",
                                                            "borderStyle": "ridge"
                                                        }}>
                                                    {
                                                        selColumns.map((item: any, idx: number) => {
                                                            var list = <option value={item.field} onClick={(e)=>{selectedOption(e,idx)}}
                                                                               selected={item.selected ? true : false}>{item.text}</option>;
                                                            return list;

                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1" style={{paddingTop: "100px"}}>
                                        <button className="btn btn-default btn-xs showHideButtonsClass" type="button"
                                                onClick={onClickUpRows}><i className="fa fa-arrow-up"></i></button>
                                        <button className="btn btn-default btn-xs showHideButtonsClass" type="button"
                                                onClick={onClickDown}><i className="fa fa-arrow-down"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={onCloseColumnPopup}
                                    className="btn btn-outline-dark text-dark cbtn">{"Close"}</button>
                            <button type="button" onClick={onSaveShowHide} className="btn btn-success cbtn">{"Save"}</button>
                        </div>
                    </div>
                </Modal>
            </Draggable>
            <Draggable handle=".modal-header">
                <Modal show={showSortPopup} bgSize='lg' dialogAs={DraggableComponent} className="fcPopup">
                    <div className="modal-content" style={{"width": "600px", "height": "400px"}}>
                        <div className="modal-header modalPopupHeader">
                            <h6 className="modal-title">{"Column Sort"}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={onClosePopup} style={{marginLeft:"0px"}}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div id="organizeColumns" className="tab-pane">
                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-8">
                                        <select id="organizeColumnsSelect" className="form-select pull-right"
                                                style={{width: "100%", height: "34px"}}>
                                            {
                                                columns.map((item: any, idx: number) => {
                                                    var list: any = "";
                                                    var flag: boolean = false;
                                                    sortColumns1.map((sItem: any, sIdx: number) => {
                                                        if (sItem.field == item.field) {
                                                            flag = true;
                                                        }
                                                    })
                                                    if (!flag) {
                                                        list = <option value={item.field}>{item.headerName}</option>;
                                                        return list;
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <button type="button" className="btn cbtn-success cbtn"
                                                onClick={onAddBtnClickHandler}>{"Add"}</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="container" style={{height: "220px", overflow: "auto"}}>
                                        <table className="table table-bordered mt-1" style={{fontSize: "14px"}}>
                                            <thead>
                                            <tr>
                                                <th>Column Heading</th>
                                                <th>Desc</th>
                                                <th>Operation</th>
                                            </tr>
                                            </thead>
                                            <tbody className="clickableRow">
                                            {
                                                sortColumns1.map((m: any, index: number) => {
                                                    if (m.text)
                                                        return <tr>
                                                            <td> {m.text}</td>
                                                            <td><input type="checkbox"
                                                                       checked={m.sort == "desc" ? true : false}
                                                                       style={{"width": "20px", "height": "20px"}}
                                                                       onClick={(e) => {
                                                                           onDescSort(e, m.field)
                                                                       }}/></td>
                                                            <td>
                                                                <button type="button" className="btn btn-default"
                                                                        onClick={(e) => {
                                                                            onDelSort(m.field)
                                                                        }}><i className="fas fa-remove"></i></button>
                                                            </td>
                                                        </tr>;
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={onClosePopup} className="btn btn-outline-dark text-dark cbtn">{"Close"}</button>
                            <button type="button" onClick={onSaveSort}
                                    className="btn cbtn  btn-success">{"Save"}</button>
                        </div>

                    </div>
                </Modal>
            </Draggable>

                    <div className="container-fluid mt-1" style={{backgroundColor: '#ffffff'}}>
                    <div className="row">
                        <div className="col-sm-12 col-lg-6 col-xl-8">
                            <div className="row">
                    <div className="col-sm-6 col-lg-6 col-xl-6">
                        <div className="paginationContainerGroup">

                    <Select 
                        options={props.dropdown}
                        
                        onChange={(e:any)=>{
                            onhandleSelectChange(e)
                        }}
                        isClearable={true}
                        isSearchable={true}
                        placeholder={props.placeholder}
                        defaultValue={props.defaultValue}
                        backspaceRemovesValue={true}

                            />
                                  
                        </div>
                        </div>
                        <div className="col-sm-6 col-lg-6 col-xl-6">
                        <div className="paginationContainerGroup">       
                                <button className='button-1' style={{backgroundColor:'#15C39A',color:'white'}} onClick={onClickAdd} title={props.tip}><b>{props.name}</b></button>       
                        </div>
                        </div>
                        </div>
                        </div>


                        <div className="col-sm-12 col-lg-6 col-xl-4">
                            <div className="row">
                        <div className="col-sm-6 col-lg-6 col-xl-6">
                        <div>
           <a><ul className="list-group rounded-0" onClick={onClickSort} title={"Sort"} style={{width:'50px',float:'left',border:'0.5px solid black',borderRight:'0px'}}>
            <li className="list-group-item" ><img src="../../images/az.svg" className='img'></img></li>
            </ul></a>
            <a><ul className="list-group rounded-0"onClick={onClickShowColumns} title={"Columns Show/Hide"} style={{width:'50px',float:'left',border:'0.5px solid black',borderRight:'0px'}}>
            <li className="list-group-item"><img src="../../images/maskgrid.svg" className='img'></img></li>
            </ul></a>
            <a><ul className="list-group rounded-0" onClick={onClickExport} title={"CSV Download"} style={{width:'50px',float:'left',border:'0.5px solid black',borderRight:'0px'}}>
            <li className="list-group-item"><img src="../../images/download.svg" className='img'></img></li>
            </ul></a>
            <a><ul className="list-group rounded-0" onClick={onClickClear} title={"Clear"} style={{width:'50px',float:'left',border:'0.5px solid black'}}>
            <li className="list-group-item"><img src="../../images/clean.svg" className='img'></img></li>
            </ul></a>
       </div>
                        </div>
                        <div className="col-sm-6 col-lg-6 col-xl-6">
                        <div className="paginationContainerGroup">
                        <div className="pagination">
                                                        <a type="button" onClick={gotoPreviousPage}>&laquo;</a>&nbsp;
                                                        <input type="text" className="form-control paginationText rounded-0"
                                       style={{minHeight: '25px', height: '38px',textAlign:'center',border:'0.5px solid black'}} value={pages}
                                       id="roles-table-pagination-count" readOnly/>&nbsp;
                                                        <a type="button" onClick={gotoNextPage}>&raquo;</a>
                                                    </div>
                                            </div>
                        </div>

                        </div>
                        </div>
                    </div>
                    </div> 
    </div>
    );
});

export default AssignedOrganizationsToolbar;
