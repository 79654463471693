import React, {useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle, Ref} from 'react'
import {IAGrid, IGridToolBox} from './IInterfaces'
import MGridToolBar from './MGridToolBar'

const MGrid = forwardRef((props: any, ref: Ref<IAGrid>) => {
    const gridRef = useRef<any>(null)
    const [rowsData, setRowsData] = React.useState<any>([])
    const [mapRows, setMapRows] = React.useState<any>([]);
    const [cols, setCols] = useState<any>(props.cols);
    const [pageNo, setPageNo] = useState<any>(1);
    const [totalPages, setTotalPages] = useState<number>(0);

    useEffect(()=>{
        setRowsData(props.rows)
        setMapRows(props.rows)
    },[props.rows])

    // useImperativeHandle(ref, () => ({}))

    let addRecord = () => {
        props.addNewRecord()
    }
   let setQuickFilter = (val:any) => {
    if(!val.trim()){
      setMapRows(rowsData)
    }else{
      let data:any = [];
      rowsData.map((item:any)=>{
        for(let i = 0; cols.length > i; i++){
          let keys = cols?.[i];
          let text = item[keys?.field.trim()];
            if(text != null){
              if(text?.toString()?.trim()?.toLowerCase()?.includes(val?.toString()?.toLowerCase())){
                return data.push(item)
              }
            }
          }
      })
      setMapRows(data);
    }
  }
    return (
        <React.Fragment>
            <div className={'gridHeight mt-3'}>
                <MGridToolBar ref={gridRef} rowData={mapRows} length={mapRows?.length} pageSize={props.pageSize}
                    columnDefs={props.cols} setQuickFilter={setQuickFilter} pageNo={pageNo} setPageNo={setPageNo}
                    onClickAdd={addRecord} addNew={props.addNew ? true : false}
                />
                <table className="MGrid-table table-striped table-hover tables mt-2">
                    <tbody>
                        {mapRows && mapRows.length > 0 && mapRows.map((item:any, itemIndex:any)=>{
                          let pIndex = itemIndex + 1;
                          let pSize =  pageNo * props.pageSize
                          if(pIndex > pSize - props.pageSize && pIndex <= pageNo * props.pageSize){
                            return (<tr key={itemIndex}>
                                {cols.map((label:any, labelIndex:any)=>{
                                    if(label?.cellRenderer){
                                    return <td data-label={label?.headerName} key={labelIndex} ><label.cellRenderer data={item} value={item[label?.field]} context={props?.method} isMobile={true} /></td>
                                    }
                                    return <td data-label={label?.headerName} key={labelIndex} >{item[label.field]}</td>
                                })}
                                </tr>)
                          }
                          return null;
                        })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
})

export default MGrid
