import {CommonUtil} from "./CommonUtil";

export const loadMapApi = () => {
    var mapsURL = "https://maps.googleapis.com/maps/api/js?";
    mapsURL = mapsURL+"key="+CommonUtil.GOOGLE_MAP_KEY+"&libraries=geometry,places";

    //key=AIzaSyC7SVyTtsbfDk7h7tjArGgNDnunO-ErGCw&libraries=geometry,places`;
    const scripts = document.getElementsByTagName('script');
    // Go through existing script tags, and return google maps api tag when found.
    for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.indexOf(mapsURL) === 0) {
            return scripts[i];
        }
    }

    const googleMapScript = document.createElement('script');
    googleMapScript.src = mapsURL;
    //googleMapScript.async = true;
    //googleMapScript.defer = true;
    ////AIzaSyC7SVyTtsbfDk7h7tjArGgNDnunO-ErGCw&l

    window.document.head.appendChild(googleMapScript);

    return googleMapScript;
};
