import React, {forwardRef, Ref, useEffect, useImperativeHandle, useState} from "react";
import {Modal, ButtonToolbar, DropdownButton, ButtonGroup, Button, Dropdown, NavDropdown} from 'react-bootstrap'
import Draggable, {DraggableCore} from "react-draggable";
import {IGridToolBox} from "./IInterfaces";
import DraggableComponent from "./DraggableComponent";
import {CommonUtil} from "../utils/CommonUtil";
import {Constants} from "../Pages/Languages/Constants";

interface IProps {
    message?: string;
    api?: any;
    hellTest?: () => void
    onClear?: () => void
    exportCSV?: () => void
}

const GridToolBar = forwardRef((props: any, ref: Ref<IGridToolBox>) => {
    let totalRows: number = 0;
    let pageSize: number = 500;
    let totalPages: number = 0;
    let currPage: number = 1;
    let totalRowsAfterFilter: number = 0;

    var pageindicator = "";


    const [pages, setPages] = useState('');
    const [showSortPopup, setShowPopup] = useState(false);
    const [showColumnPopup, setShowColumnPopup] = useState(false);
    const [columns, setColumns] = useState([]);
    const [sortColumns1, setSortColumns1] = useState<any>([]);
    const [page1, setPage1] = useState('');
    const [avlColumns, setAvlColumns] = useState([]);
    const [selColumns, setSelColumns] = useState([]);

    var listStyles = {
        height: "220px",
        "overflowY": "hidden",
        "overflowX": "hidden",
    }

    useEffect(() => {
        // console.log(props);
        // console.log(api);
        if (props && props.api && props.api.rowModel && props.api.rowModel.rootNode && props.api.rowModel.rootNode.allLeafChildren) {
            displayPageInformation();
        }

    }, [props.rowData]);

    let displayPageInformation = () => {
        totalRows = props.api.rowModel.rootNode.childrenAfterFilter.length;
        totalRowsAfterFilter = totalRows;
        pageSize = (props.pageSize) ? props.pageSize : 100;
        if (totalRows == 0) {
            totalPages = 0;
        } else {
            totalPages = Math.ceil(totalRows / pageSize);
        }
        if (totalRows == 0) {
            pageindicator = (0) + "-" + (0) + " of " + 0;
        } else {
            pageindicator = (((pageSize * currPage) - pageSize) + 1) + "-" + ((pageSize * currPage) > totalRows ? totalRows : (pageSize * currPage)) + " of " + totalRows;
        }
        setPages(pageindicator);
    }

    useImperativeHandle(ref, () => ({passColumnFilter}))

    const passColumnFilter = (params: any) => {
        // console.log(params);
        totalRows = params.api.rowModel.rootNode.childrenAfterFilter.length;
        totalRowsAfterFilter = totalRows;
        pageSize = (props.pageSize) ? props.pageSize : 100;
        if (totalRows == 0) {
            totalPages = 0;
        } else {
            totalPages = Math.ceil(totalRows / pageSize);
        }
        if (totalRows == 0) {
            pageindicator = (0) + "-" + (0) + " of " + 0;
        } else {
            pageindicator = (((pageSize * currPage) - pageSize) + 1) + "-" + ((pageSize * currPage) > totalRows ? totalRows : (pageSize * currPage)) + " of " + totalRows;
        }
        setPages(pageindicator);
    }

    let gotoNextPage = () => {
        if (props.api) {
            props.api.paginationGoToNextPage();
            currPage = props.api.paginationGetCurrentPage();
            //console.log(currPage);
            currPage = currPage + 1;
            displayPageInformation();
        }
    }
    let gotoPreviousPage = () => {
        if (props.api) {
            props.api.paginationGoToPreviousPage();
            currPage = props.api.paginationGetCurrentPage();
            // console.log(currPage);
            currPage = currPage + 1;
            displayPageInformation();
        }
    }
    let onSearchFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // console.log(e.currentTarget.value);
        if (props.api && props.api.setQuickFilter) {
            props.api.setQuickFilter(e.currentTarget.value);
            // console.log(props.api.rowModel.rootNode.childrenAfterFilter.length);
            displayPageInformation();
            // allChildrenCount
        }
    }

    let onClickRemoveSearch = () => {
        props.api.setQuickFilter("");
        // console.log(props.api.rowModel.rootNode.childrenAfterFilter.length);
        displayPageInformation();
    }

    let onClickSort = () => {
        //console.log("Sort");
        setShowPopup(true);
        var colArray: object[] = [];
        var colObject: object = {};
        //console.log(props.api.getColumnDefs());
        var cols: any = [];
        var columns: any = props.api.getColumnDefs();
        columns.forEach((column: any, idx: number) => {
            if (column.sort && !column.hide) {
                var colObj: any = {};
                colObj.text = column.headerName;
                colObj.field = column.field;
                colObj.sort = column.sort;
                cols.push(colObj);
            }
        });

        setSortColumns1(cols);
        setColumns(columns);
    }
    let onClosePopup = () => {
        setShowPopup(false);
    }

    let onClickClear = () => {
        try{
            // localStorage.removeItem(props.gridId);
            const data = JSON.parse(String(localStorage.getItem("GridData"))) || {};
            if(data){
                delete data[props.gridId];
            }            
            localStorage.setItem("GridData",JSON.stringify(data));
        }catch(e){}
        props.onClear();
    }

    let onClickExport = () => {
        props.exportCSV();
    }

    let onClickAdd = () => {
        props.onClickAdd();
    }


    let onAddBtnClickHandler = () => {
        // var sArr = this.state.sortableColumns;
        if (document.getElementById("organizeColumnsSelect")) {
            //var sortColumns:any = sortColumns;
            var element: any = document.getElementById("organizeColumnsSelect")
            var field = element.value;
            if (field) {
                var text = element.options[element.selectedIndex].text;
                var colObj: any = {};
                colObj.text = text;
                colObj.field = field;
                colObj.sort = "asc";
                sortColumns1.push(colObj);
                setSortColumns1(sortColumns1);
                setPage1("" + Math.random() * 1000000);
            }
        }
    }

    let onDescSort = (e: any, field: any) => {
        //console.log(e,field);
        // var sArr = this.state.sortableColumns;
        for (var i = 0; i < sortColumns1.length; i++) {
            if (sortColumns1[i].field == field) {
                sortColumns1[i].sort = e.target.checked ? "desc" : "asc"
                break;
            }
        }
        setSortColumns1(sortColumns1);
        setPage1("" + Math.random() * 1000000);
    }
    let onDelSort = (field: any) => {
        // var sArr = this.state.sortableColumns;
        for (var i = 0; i < sortColumns1.length; i++) {
            if (sortColumns1[i].field == field) {
                sortColumns1.splice(i, 1);
                break;
            }
        }
        setSortColumns1(sortColumns1);
        setPage1("" + Math.random() * 1000000);
        // this.setState({sortableColumns:sArr});
    }
    let onSaveSort = () => {
        //console.log(sortColumns1);
        var sortArr: any = [];
        for (var i = 0; i < sortColumns1.length; i++) {
            var obj: any = {};
            obj.colId = sortColumns1[i].field;
            obj.sort = sortColumns1[i].sort;
            obj.sortIndex = i;
            sortArr.push(obj);
        }
        //sortArr.push({colId: 'model', sort: 'asc'});
        props.api.rowModel.columnApi.applyColumnState({
            state: sortArr,
            defaultState: {sort: null},
        });
        onClosePopup();
    }

    let onCloseColumnPopup = () => {
        setShowColumnPopup(false);
    }

    let onClickShowColumns = () => {
        setShowColumnPopup(true);
        var selectedCols: any = [];
        var avlCols: any = [];
        var columns: any = props.api.getColumnDefs();
        columns.forEach((column: any, idx: number) => {
            var colObj: any = {};
            if (!column.hide) {
                colObj.text = column.headerName;
                colObj.field = column.field;
                selectedCols.push(colObj);
            } else {
                colObj.text = column.headerName;
                colObj.field = column.field;
                avlCols.push(colObj);
            }
        });
        setSelColumns(selectedCols);
        setAvlColumns(avlCols);
        setPage1("" + Math.random() * 1000000);
    }

    let onClickRight = () => {
        var items: any = document.getElementById("leftId");
        if (items && items.options) {
            var avlCols: any = [];
            var selectedCols: any = selColumns;

            for (var k = 0; k < items.options.length; k++) {
                var colObj: any = {};
                colObj.text = items.options[k].text;
                colObj.field = items.options[k].value;
                if (items.options[k].selected == true) {
                    selectedCols.push(colObj);
                } else {
                    avlCols.push(colObj);
                }
            }

            setAvlColumns(avlCols);
            setSelColumns(selectedCols);
            setPage1("" + Math.random() * 1000000);
        }
    }

    let onClickUpRows = () => {

        var arr: any = document.querySelectorAll("#rightId option");
        var box: any = document.getElementById("rightId");
        let findSelected = selColumns.findIndex((sel:any)=>sel.selected === true);

        if (findSelected === 0) {
            return;
        }

        var indexes = [];
        for (var k = 0; k < arr.length; k++) {
            if (arr[k].selected)
                indexes.push(arr[k].value);
        }
        // console.log(indexes);

        // var leftList = [];
        // var rightList = [];
        // var selectedCols: any = selColumns;
        // if (selectedCols) {
        //     for (var i = 0; i < selectedCols.length; i++) {
        //         rightList.push({field: selectedCols[i].field, text: selectedCols[i].text});
        //     }
        // }

        //var myList = document.getElementById('myList');
        //box.innerHTML = '';

        var arrayTemp: [] = swapElement(selColumns, indexes, "dec");

        setSelColumns([...arrayTemp]);
        setPage1("" + Math.random() * 1000000);


        /*  var selectedCols:any = selColumns;
          var selItems:any = [];
          var nonSelItems:any = [];
          var items:any = document.getElementById("rightId");
          if(items && items.options){
              for(var k=0; k<items.options.length; k++){
                  var colObj:any = {};
                  colObj.text = items.options[k].text;
                  colObj.field = items.options[k].value;
                  colObj.index = k;
                  colObj.selected = false;
                  if(items.options[k].selected == true){
                      colObj.selected = true;
                      let idx = k;
                      if(idx>0){
                          idx = idx-1;
                          colObj.index = idx;
                      }
                      selItems.push(colObj);
                  }else{
                      nonSelItems.push(colObj);
                  }
              }
          }

          //console.log(selItems,nonSelItems);
          var finalArray:any = selItems.concat(nonSelItems);

          var sortedArray: any = [];
          sortedArray = finalArray.sort((n1:any,n2:any) => {
              if (n1.index > n2.index) {
                  return 1;
              }
              if (n1.index < n2.index) {
                  return -1;
              }

              return 0;
          });
          setSelColumns(sortedArray);
          setPage1(""+Math.random()*1000000);*/

        //console.log(sortedArray);
    }
    let swapElement = (array: any, indexes: any, str: any): any => {
        var tmp = "";

        var arr: any = [];

        if (str == "inc") {
            for (var k = indexes.length - 1; k >= 0; k--) {
                for (var j = array.length - 1; j >= 0; j--) {
                    if (array[j].field == indexes[k]) {
                        arraymove(array, j, j + 1);
                        break;
                    }
                }
            }
        } else {
            for (var k = 0; k < indexes.length; k++) {
                for (var j = 0; j < array.length; j++) {
                    if (array[j].field == indexes[k]) {
                        arraymove(array, j, j - 1);
                        break;
                    }
                }
            }
        }

        return array;
    }

    let arraymove = (arr: any, fromIndex: number, toIndex: number) => {
        var element = arr[fromIndex];
        element.selected = true;
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
        // return arr;
    }

    let onClickDown = () => {
        var arr: any = document.querySelectorAll("#rightId option");
        var box: any = document.getElementById("rightId");
        let selected = selColumns.filter((sel:any)=>sel.selected === true) || [];

        let obj: any = selected.slice(-1)[0]
        let findSelected = selColumns.findIndex((sel:any)=>sel === obj);
        if (findSelected + 1 === selColumns.length) {
            return;
        }
        var indexes = [];
        for (var k = 0; k < arr.length; k++) {
            if (arr[k].selected)
                indexes.push(arr[k].value);
        }
        // console.log(indexes);

        // var leftList = [];
        // var rightList = [];
        // var selectedCols: any = selColumns;
        // if (selectedCols) {
        //     for (var i = 0; i < selectedCols.length; i++) {
        //         rightList.push({field: selectedCols[i].field, text: selectedCols[i].text});
        //     }
        // }

        //var myList = document.getElementById('myList');
        //box.innerHTML = '';

        var arrayTemp: [] = swapElement(selColumns, indexes, "inc");

        setSelColumns([...arrayTemp]);
        setPage1("" + Math.random() * 1000000);

    }

    let onClickLeft = () => {
        var items: any = document.getElementById("rightId");
        if (items && items.options) {
            //console.log(items.options.length);
            var selItem = [];
            var avlCols: any = avlColumns;
            var selectedCols: any = [];
            //console.log(items);
            for (var k = 0; k < items.options.length; k++) {
                var colObj: any = {};
                colObj.text = items.options[k].text;
                colObj.field = items.options[k].value;
                if (items.options[k].selected == true) {
                    avlCols.push(colObj);
                } else {
                    selectedCols.push(colObj);
                }
            }
            setAvlColumns(avlCols);
            setSelColumns(selectedCols);
            setPage1("" + Math.random() * 1000000);
            //console.log(selItem);
        }


        /*if(items){

        }*/
    }

    let onSaveShowHide = () => {
        // console.log("onSaveShowHide");
        //var columns:any = props.api.getColumnDefs();
        //props.api;
        //setSelColumns
        var cols: any = [];
        //if(avlColumns){
        if (selColumns.length > 0) {
            selColumns.forEach((col: any, idx: number) => {
                cols.push(col.field);
            });
            props.api.columnModel.setColumnsVisible(cols, true);
        }
        props.api.columnModel.moveColumns(cols, 0);
        if (avlColumns.length > 0) {
            cols = [];
            avlColumns.forEach((col: any, idx: number) => {
                cols.push(col.field);
            });
            props.api.columnModel.setColumnsVisible(cols, false);
        }


        //}
        try{
            // console.log(props.columnApi);
            const columnApi = props.columnApi;
            const colState = columnApi.getColumnState();
            // console.log(colState);
            const data = JSON.parse(String(localStorage.getItem("GridData"))) || {};
            if(data){
                data[props.gridId]=colState;
            }            
            localStorage.setItem("GridData",JSON.stringify(data));
        }catch(e){}
        // props.api.columnModel.moveColumns(cols,1);
        onCloseColumnPopup();
    }

    const selectedOption = (e:any, idx:number) => {
        let options : any = [...selColumns]

        if(e.ctrlKey){
            //console.log('17')
            if(options[idx].selected === true){
                options[idx].selected = false;
            }else{
                options[idx].selected = true;
            }
        }else{
            //console.log('no 17')
            options.map(function(x:any) {
                x.selected = false;
            });
            options[idx].selected = true;
        }

        setSelColumns(options);
    }


    return (
        // console.log(api);
        <div>

            <Draggable handle=".modal-header">
                <Modal show={showColumnPopup} dialogAs={DraggableComponent} bgSize='lg' className="fcPopup">
                    <div className="modal-content" style={{"width": "800px", "height": "400px"}}>
                        <div className="modal-header modalPopupHeader">
                            <h6 className="modal-title">{CommonUtil.getMessageText(Constants.COLUMNS_SHOW_HIDE, 'Column Show/Hide')}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    style={{marginLeft:"0px"}} onClick={onCloseColumnPopup}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div id="organizeColumns" className="tab-pane">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="panel panel-default customPanel">
                                            <div className="panel-heading removeHeaderBackground" style={{
                                                paddingLeft: "0px",
                                                fontSize: "14px",
                                                paddingBottom: "5px"
                                            }}>{CommonUtil.getMessageText(Constants.AVAILABLE_COLUMNS, 'Available Columns')}</div>
                                            <div className="tablePanel"
                                                 style={{height: "220px", overflowY: "hidden", msOverflowX: 'hidden'}}>
                                                <select multiple={true} id="leftId" className="tabSelect form-select"
                                                        style={{
                                                            "paddingLeft": "2px",
                                                            "width": "100%",
                                                            "height": "100%",
                                                            "border": "dashed",
                                                            "borderStyle": "ridge"
                                                        }}>
                                                    {
                                                        avlColumns.map((item: any, idx: number) => {
                                                            var list = <option value={item.field}>{item.text}</option>;
                                                            return list;

                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1" style={{paddingTop: "100px"}}>
                                        <button className="btn cbtn-success grid-button" style={{marginBottom:"5px"}}
                                                type="button" onClick={onClickLeft}>
                                            <i className="fa fa-arrow-left" style={{color:"#FFF"}}></i>
                                        </button>

                                        <button className="btn cbtn-success grid-button" type="button" onClick={onClickRight}>
                                            <i className="fa fa-arrow-right" style={{color:"#FFF"}}></i>
                                        </button>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="panel panel-default customPanel">
                                            <div className="panel-heading removeHeaderBackground" style={{
                                                paddingLeft: "0px",
                                                fontSize: "14px",
                                                paddingBottom: "5px",
                                                fontWeight:"bold"
                                            }}>{CommonUtil.getMessageText(Constants.SELECTED_COLUMNS, 'Selected Columns')}</div>
                                            <div className="tablePanel"
                                                 style={{height: "220px", overflowX: "hidden", overflowY: 'hidden'}}>
                                                <select multiple={true} id="rightId" className="tabSelect form-select"
                                                        style={{
                                                            "paddingLeft": "2px",
                                                            "width": "100%",
                                                            "height": "100%",
                                                            "border": "dashed",
                                                            "borderStyle": "ridge"
                                                        }}>
                                                    {
                                                        selColumns.map((item: any, idx: number) => {
                                                            var list = <option value={item.field} onClick={(e)=>{selectedOption(e,idx)}}
                                                                               selected={item.selected ? true : false}>{item.text}</option>;
                                                            return list;

                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1" style={{paddingTop: "100px"}}>
                                        <button className="btn btn-default btn-xs showHideButtonsClass" type="button"
                                                onClick={onClickUpRows}><i className="fa fa-arrow-up"></i></button>
                                        <button className="btn btn-default btn-xs showHideButtonsClass" type="button"
                                                onClick={onClickDown}><i className="fa fa-arrow-down"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={onCloseColumnPopup}
                                    className="btn btn-outline-dark text-dark cbtn">{CommonUtil.getMessageText(Constants.CLOSE, 'Close')}</button>
                            <button type="button" onClick={onSaveShowHide} className="btn btn-success cbtn">{CommonUtil.getMessageText(Constants.SAVE, 'Save')}</button>
                        </div>
                    </div>
                </Modal>
            </Draggable>
            <Draggable handle=".modal-header">
                <Modal show={showSortPopup} bgSize='lg' dialogAs={DraggableComponent} className="fcPopup">
                    <div className="modal-content" style={{"width": "600px", "height": "400px"}}>
                        <div className="modal-header modalPopupHeader">
                            <h6 className="modal-title">{CommonUtil.getMessageText(Constants.COLUMN_SORT, 'Column Sort')}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={onClosePopup} style={{marginLeft:"0px"}}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div id="organizeColumns" className="tab-pane">
                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-8">
                                        <select id="organizeColumnsSelect" className="form-select pull-right"
                                                style={{width: "100%", height: "34px"}}>
                                            {
                                                columns.map((item: any, idx: number) => {
                                                    var list: any = "";
                                                    var flag: boolean = false;
                                                    sortColumns1.map((sItem: any, sIdx: number) => {
                                                        if (sItem.field == item.field) {
                                                            flag = true;
                                                        }
                                                    })
                                                    if (!flag) {
                                                        list = <option value={item.field}>{item.headerName}</option>;
                                                        return list;
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <button type="button" className="btn cbtn-success cbtn"
                                                onClick={onAddBtnClickHandler}>{CommonUtil.getMessageText(Constants.ADD, 'Add')}</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="container" style={{height: "220px", overflow: "auto"}}>
                                        <table className="table table-bordered mt-1" style={{fontSize: "14px"}}>
                                            <thead>
                                            <tr>
                                                <th>{CommonUtil.getMessageText(Constants.COLUMN_HEADING, 'Column Heading')}</th>
                                                <th>{CommonUtil.getMessageText(Constants.DESC, 'Desc')}</th>
                                                <th>{CommonUtil.getMessageText(Constants.OPERATION, 'Operation')}</th>
                                            </tr>
                                            </thead>
                                            <tbody className="clickableRow">
                                            {
                                                sortColumns1.map((m: any, index: number) => {
                                                    if (m.text)
                                                        return <tr>
                                                            <td> {m.text}</td>
                                                            <td><input type="checkbox"
                                                                       checked={m.sort == "desc" ? true : false}
                                                                       style={{"width": "20px", "height": "20px"}}
                                                                       onClick={(e) => {
                                                                           onDescSort(e, m.field)
                                                                       }}/></td>
                                                            <td>
                                                                <button type="button" className="btn btn-default"
                                                                        onClick={(e) => {
                                                                            onDelSort(m.field)
                                                                        }}><i className="fas fa-remove"></i></button>
                                                            </td>
                                                        </tr>;
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={onClosePopup} className="btn btn-outline-dark text-dark cbtn">{CommonUtil.getMessageText(Constants.CLOSE, 'Close')}</button>
                            <button type="button" onClick={onSaveSort}
                                    className="btn cbtn  btn-success">{CommonUtil.getMessageText(Constants.SAVE, 'Save')}</button>
                        </div>

                    </div>
                </Modal>
            </Draggable>
            <div className="well well-sm paginationContainer" style={{backgroundColor: '#c1e4dc'}}>
                <div className="row">
                    <div className={"col-sm-12 col-lg-3 col-md-3 col-sm-6 col-xs-6"}>
                        <div className="paginationContainerGroup">
                            <div className="input-group" style={{minHeight: '25px', height: '25px'}}>
                                <span className="input-group-addon pt-2" style={{height: "25px"}}>
                                    <i className="fa fa-search fa-2xs"
                                       style={{fontSize: '12px', alignSelf: 'center'}}></i>
                                </span>
                                <input type="text" placeholder={CommonUtil.getMessageText(Constants.SEARCH_HERE,'Search Here')}
                                       style={{minHeight: '25px', height: '25px'}}
                                       className="form-control searchTextBox" onChange={onSearchFilterChange}/>
                                <span className="input-group-btn" style={{padding: "0px"}}>
                                    <button className="btn btn-default grid-right-button" type="button"
                                            onClick={onClickRemoveSearch} style={{height: "25px"}}>
                                        {/* <i className="fa fa-remove"></i> */}
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={"col-sm-12 col-lg-3 col-md-3 col-sm-6 col-xs-6"}>
                        <div className="paginationContainerGroup">
                            <div className="input-group" style={{minHeight: '25px', height: '25px'}}>
                                <div className="input-group-btn" style={{minHeight: '25px', height: '25px'}}>
                                    <a className="btn btn-default grid-left-button" type="button"
                                       onClick={gotoPreviousPage} style={{height: "25px"}}>
                                        <i className="fa fa-angle-left fa-2xs"></i>
                                    </a>
                                </div>
                                <input type="text" className="form-control paginationText"
                                       style={{minHeight: '25px', height: '25px'}} value={pages}
                                       id="roles-table-pagination-count" readOnly/>
                                <div className="input-group-btn right-side" style={{minHeight: '25px', height: '25px'}}>
                                    <a className="btn btn-default grid-right-button" type="button"
                                       onClick={gotoNextPage} style={{height: '25px'}}>
                                        <i className="fa fa-angle-right fa-2xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.addNew ? (
                            <>
                                <div style={{paddingRight: "10px"}}
                                     className={"col-sm-12 col-lg-5 col-md-5 hidden-xs hidden-sm"}>
                                    <div className="paginationContainerGroup removeBottomMargin">
                                        <div className="btn-group btn-group-justified"
                                             style={{"width": "100%", height: '25px', minHeight: '25px'}}>
                                            <a className="btn btn-default" onClick={onClickSort} title={CommonUtil.getMessageText(Constants.SORT, 'Sort helps to filter the column contents. Multiple-column sort is supported using this feature.')}
                                               style={{height: '25px'}}><i
                                                className="fa fa-sort-alpha-down fa-sm noti-icon  "></i></a>
                                            <a className="btn btn-default" onClick={onClickShowColumns}
                                               style={{height: '25px'}}
                                               title={CommonUtil.getMessageText(Constants.COLUMNS_SHOW_HIDE, 'This feature allows to addition or removal of columns in the grid and also set the column sequence')}><i className="fas fa-th fa-sm"></i></a>
                                            <a className="btn btn-default" onClick={onClickExport} title={CommonUtil.getMessageText(Constants.CVS_DOWNLOAD, 'This allows the user to download the grid contents in the CSV file')}
                                               style={{height: '25px'}}><i
                                                className="fa fa-download fa-sm  "></i></a>
                                            <a className="btn btn-default" onClick={onClickClear} title={CommonUtil.getMessageText(Constants.CLEAR, 'Selecting this option will remove all the sort and show/hide settings of the grid and bring it to the default view')}
                                               style={{height: '25px'}}><i
                                                className="fa fa-eraser fa-sm  "></i></a>
                                        </div>

                                    </div>
                                </div>
                                <div style={{paddingRight: "10px"}}
                                     className={"col-sm-12 col-lg-1 col-md-1 hidden-xs hidden-sm"}>
                                    <div style={{marginLeft:"-10px"}}>
                                        <div className="btn-group btn-group-justified"
                                             style={{width: "50%", height: '25px'}}>
                                            <a className="btn btn-default login-btn" style={{background: "#EFC300",display:"flex",alignItems:"center"}}
                                               onClick={onClickAdd} title={props.tip}><i
                                                className="fas fa-plus fa-sm "></i><span style={{marginLeft:'0px'}}></span></a>
                                        </div>

                                    </div>
                                </div>
                            </>

                        )
                        : <div style={{paddingRight: "10px"}}
                               className={"col-sm-12 col-lg-6 col-md-6 hidden-xs hidden-sm"}>
                            <div className="paginationContainerGroup removeBottomMargin">
                                <div className="btn-group btn-group-justified" style={{"width": "100%"}}>
                                    <a className="btn btn-default" onClick={onClickSort} title={CommonUtil.getMessageText(Constants.SORT, 'Sort helps to filter the column contents. Multiple-column sort is supported using this feature')}><i
                                        className="fa fa-sort-alpha-down  "></i></a>
                                    <a className="btn btn-default" onClick={onClickShowColumns}
                                       title={CommonUtil.getMessageText(Constants.COLUMNS_SHOW_HIDE, 'This feature allows to addition or removal of columns in the grid and also set the column sequence')}><i className="fas fa-th  "></i></a>
                                    <a className="btn btn-default" onClick={onClickExport} title={CommonUtil.getMessageText(Constants.CVS_DOWNLOAD, 'This allows the user to download the grid contents in the CSV file')}><i
                                        className="fa fa-download  "></i></a>
                                    <a className="btn btn-default" onClick={onClickClear} title={CommonUtil.getMessageText(Constants.CLEAR, 'Selecting this option will remove all the sort and show/hide settings of the grid and bring it to the default view')}><i
                                        className="fas fa-eraser  "></i></a>
                                </div>

                            </div>
                        </div>
                    }
                </div>
            </div>

        </div>
    );
});

export default GridToolBar;
