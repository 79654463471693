import "../Interchange.css";
import { useState, useEffect } from "react";
import { NetworkManager } from "../../../Components/NetworkManager";
import { CommonUtil } from "../../../utils/CommonUtil";
import ReactImageGallery from "../ReactImageGallery";
import ImageLoader from "../ImageLoader";

const EntryPicturesInspection = () => {
  const [pictureDetails, setPictureDetails] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);

  let getPicturesInspectionDetails = () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var orgUrl = `api/fetchInspectionDetails/?docId=${qparam}&inspectionStatus=ENTRY`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onGetPicturesInspectionDetails,
      onLoginError
    );
  };

  useEffect(() => {
    getPicturesInspectionDetails();
  }, []);

  let onGetPicturesInspectionDetails = (dataObj: any) => {
    if (dataObj) {
      var visual = [];
      var restData = JSON.parse(dataObj);
      console.log(restData?.picturesInspection);
      if (restData?.picturesInspection?.positionOfTrailer) {
        visual = [];
        if (Array.isArray(restData?.picturesInspection?.positionOfTrailer)) {
          visual = restData?.picturesInspection?.positionOfTrailer;
        } else {
          visual.push(restData?.picturesInspection?.positionOfTrailer);
        }
        setPictureDetails(visual);
        setAttachments(restData?.picturesInspection?.positionOfTrailer);
      }
    }
  };

  console.log(pictureDetails, "PICS");

  let pimages: any = [];
  pictureDetails.map((img: any) => {
    img?.images?.map((arr: any) => {
      pimages.push(arr?.fullFilePath);
    });
  });

  console.log(pimages);

  let onLoginError = (err: any) => {
    console.log(err);
  };

  let attachImages: any = [];
  try {
    attachImages = attachments?.attachmentUploads1?.map(
      (ele: any) => ele.fullFilePath
    );
    console.log(attachImages);
  } catch (e) {
    attachImages = [];
  }
  return (
    <div>
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className="tire-details">
            <div className="picture-tab">
              <div className="row">
                {pictureDetails.map((data: any) => {
                  return (
                    <>
                      <PictureComponent data={data} />
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntryPicturesInspection;

export const PictureComponent = ({ data, classStyle,cardStyle ,notfoundImage=false}: any) => {
  const [pictureImages, setPictureImages] = useState([]);
  const [galleryPop, setGalleryPop] = useState(false);
  const [galleryPopStartIndex, setGalleryPopStartIndex] = useState(0);

  let Capitalize = (str: any) => {
    try{
    str = String(str);
    let strText: any = str.charAt(0).toUpperCase() + str.slice(1);

    return strText.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
    }catch(e){
      return str;
    }
  };

  useEffect(() => {
    let arr: any = [];

    for (let i = 0; i < data?.images?.length; i++) {
      arr.push(data?.images[i]?.fullFilePath);
    }
    setPictureImages(arr);
  }, [data]);
  const findImg = (index: any) => {
    console.log(index);
    setGalleryPop(true);
    console.log(pictureImages);
    // callBack(index);
  };
  const currentClass = classStyle ? classStyle : "col-lg-3 col-md-4 mb-3";
  
  return (
    <>
      <ReactImageGallery
        startIndex={galleryPopStartIndex}
        attachedImages={pictureImages}
        showPop={galleryPop}
        handleClose={() => {
          setGalleryPop(false);
        }}
      />
      <div className={currentClass}>
        <a href="#" className="link">
          <div
            className="card shadow-sm rounded"
            style={{ minHeight: "200px" }}
          >
            <div className="card-body p-1 text-center" style={cardStyle?cardStyle:{}}>
              <ImageLoader
                path={data?.images ? data?.images[0]?.fullFilePath : ""}
                findImg={findImg}
                index={1}
                notfoundImage={notfoundImage}
              />
              <div className="py-1 pick-area">{Capitalize(data?.position)}</div>
              <div className="picture">
                <span className="picture-pop-label">
                  {data?.images?.length}
                </span>
              </div>
              <div></div>
            </div>
            <div
              className="mx-2 mt-5"
              style={{ fontWeight: "600", paddingBottom: "30px" }}
            >
              <div>
                <textarea style={{ width: "100%" }} rows={2} readOnly value={
                  data?.entryImages?.attachmentDamageDescription
                    ? data?.entryImages?.attachmentDamageDescription
                    : data?.description
                }></textarea>
              </div>
              Damage Type :{" "}
              {(data?.type === "C" ? "Cut" : "") ||
                (data?.type === "H" ? "Hole" : "") ||
                (data?.type === "B" ? "Bruise" : "")}
            </div>
          </div>
        </a>
      </div>
    </>
  );
};
