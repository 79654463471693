import React, {useEffect, useState} from "react";
import LoginHeader from "./LoginHeader";
import {subscribe, unsubscribe} from "./CustomEvents";
import {Events} from "../Languages/Events";
import {CommonUtil} from "../../utils/CommonUtil";
import {Constants} from "../Languages/Constants";

const ForgotPasswordReg = ()=>{
    const [page,setPage] = useState(0);
    useEffect(() => {
        subscribe(Events.LANGUAGE_CHANGE_EVENT,(data:any)=>{
            showLanguageMessages();
        });
        return () => {
            unsubscribe(Events.LANGUAGE_CHANGE_EVENT,{});
        }
    }, [])

    let showLanguageMessages = ()=>{
        showMessages();
    }
    let showMessages = () => {
        setPage(Math.random()*100000);
    }

    return(
        <>
            <LoginHeader />
            <div className="container-fluid" style={{background:"#f0f0f0",overflow:"hidden",height:"100vh",padding:"0px"}}>
                <div style={{width:"100%",background:"#FFF",padding:"0px"}} className="container-fluid">
                    <div className="row fs-7 topbar-breadcrumbs" style={{paddingLeft:"3px"}}>
                        <br />
                        <span style={{paddingTop:"3px"}}>Reset Password</span>
                    </div>
                </div>
                <div className="container-fluid" style={{width:"60%",paddingTop:"30px"}}>
                    <section>
                        <h6>Reset Password</h6>
                    </section>
                    <section>
                        <div className="container-fluid" style={{background:"#FFF",padding:"40px",paddingBottom:"0px",paddingTop:"20px"}}>
                            <div  className="container-fluid text-center">
                                <i><img src="../../images/circle.svg" style={{width:"50px"}}/></i>
                                <div className="row fs-7" style={{paddingBottom:"30px",paddingTop:"20px"}}>
                                    <h4 className="text-center">Please check your emails to reset your password</h4>
                                </div>
                                <div className="row fs-7 registration-box"  style={{height:"80px"}}>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-10">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="#ffc107" className="bi bi-exclamation-circle"
                                             viewBox="0 0 16 16" style={{width:"30px",height:"30px"}}>
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                                        </svg>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span style={{color:"#969191"}}>{CommonUtil.getMessageText(Constants.JUNK_MAILS,"Please check your Junk or Spam mail if you did not receive an email from us")}</span>
                                    </div>
                                </div>
                                <div className={"row"} style={{paddingTop:"25px"}}>
                                    <span style={{color:"#92908e"}}>{CommonUtil.getMessageText(Constants.CALL_US_VIA_MAIL,"You didn’t receive an email? Contact the vHub support via email or call us")} <a href="mailto: hello@vhubapp.com">hello@vhubapp.com</a>  {CommonUtil.getMessageText(Constants.OR,"or")} 1-866-849-0189 </span>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>

            </div>
        </>
    )
}

export default ForgotPasswordReg;