import React, { useState } from 'react';
import CSVFileErrorsModal from './CSVFileErrorsModal';

const CSVErrorsRenderer = (params: any) => {

    const [showError, setShowError] = useState(false)

    const onOpenErrorDetails = () => {
        setShowError(true)
    }

    const handleClose = () => {
        setShowError(false)
    }

    return (
        <div>
            <div className={"cellRenderHeader"}>
                {params.data.isPartialFailed === false && params.data.isFullFailed === false ?
                    <button className="btn btn-success" style={{ cursor: 'none' }}>{"Successful Upload"}</button> :
                    params.data.isPartialFailed === false && params.data.isFullFailed === true ?
                        <button className="btn btn-danger" style={{ cursor: 'none' }} >{"File Rejected"}</button> :
                        params.data.isPartialFailed === true && params.data.isFullFailed === false ?
                            <button className="btn btn-warning" style={{ fontWeight: '500' }} onClick={onOpenErrorDetails} >{"Show Errors"}</button> :
                            ''
                }
            </div>
            {showError && <CSVFileErrorsModal data={params.data} open={true} handleClose={handleClose} />}
        </div>
    );
}

export default CSVErrorsRenderer;
