import React from "react";

const OrgUserRenderer = (props: any) => {
    const onClickUser = () => {
        if (props.context) {
            props.context.onClickUserDetails(props.data);
        }
    }
    return (
        <div className={"cellRenderHeader"}>
            <a><span onClick={onClickUser} className={"cellRender"}>{props.value}</span></a>
        </div>

    )
}

export default OrgUserRenderer;