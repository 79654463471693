import React, { useEffect } from 'react'
import { useState,useRef } from 'react';
import useStateWithCallback from "use-state-with-callback";
import { IAGrid } from '../../Components/IInterfaces';
import { CommonUtil } from '../../utils/CommonUtil';
import { Constants } from '../Languages/Constants';
import InspectionRenderer from '../InspectionGrid/InspectionRenderer';
import InterchangeDocumentStatusRenderer from './InterchangeDocumentStatusRenderer';
import { NetworkManager } from '../../Components/NetworkManager';
import InspectionGrid from '../InspectionGrid/InspectionGrid';
import ResponseClaimRenderer from './InspectionCompareDetails/ResponseClaimRenderer';
import EditClaimModalRenderer from './EditClaimModalRenderer';
import InterchangeRenderer from './InterchangeRenderer';
import { useNavigate, useParams } from 'react-router';


const AllClaims = () => {

  let getAllClaims = () =>{
    var orgUrl = `api/claim/orgId/${CommonUtil.getSaasOrgId()}`
    NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onGetAllClaims,onLoginError);
  }

useEffect(()=>{
  getAllClaims();
}, []);
    const [users,setUsers] = useStateWithCallback([],()=>{
        if(userGridRef1.current){
            userGridRef1.current.columnFittoSize();
        }
    });
    const userGridRef1 = useRef<IAGrid>(null);
    let navigate = useNavigate();
    
    const [userColumns] = useState([
        { field: 'inspectionDocumentCode', headerName: CommonUtil.getMessageText(Constants.DOCUMENT_ID, 'Document No'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '12px', 'fontFamily': 'poppins', 'color': 'black' }, headerClass: 'header-white',filter: 'agTextColumnFilter', cellRenderer: InterchangeRenderer },
      { field: 'claimCode', headerName: CommonUtil.getMessageText(Constants.CLAIM, 'Claim Id'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '12px', 'fontFamily': 'poppins', 'color': 'black' }, headerClass: 'header-white',filter: 'agTextColumnFilter', cellRenderer:(params:any) => EditClaimModalRenderer(params, getAllClaims)},
        { field: 'vinNumber', headerName:CommonUtil.getMessageText(Constants.VIN_NO,'Vin Number'),resizable:true,flex:1,width:100,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','paddingTop':'5px' },headerClass: 'header-white',filter: 'agTextColumnFilter' },
        { field: 'unitNumber', headerName: CommonUtil.getMessageText(Constants.UNIT_NUMBER, 'Unit Number'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white',filter: 'agTextColumnFilter' },
        { field: 'claimCreatorName', headerName:CommonUtil.getMessageText(Constants.CREATED_BY,'Created By'),resizable:true,flex:1,width:100,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins' },headerClass: 'header-white',filter: 'agTextColumnFilter'},
        { field: 'claimDateString', headerName: CommonUtil.getMessageText(Constants.CREATED_DATE, 'Created Date'), flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins' }, headerClass: 'header-white',filter: 'agDateColumnFilter' },
        { field: 'claimAmount', headerName: CommonUtil.getMessageText(Constants.CLAIMED_AMOUNT, 'Claimed Amount'), flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins' }, headerClass: 'header-white',filter: 'agTextColumnFilter' },
        { field: 'approvedByOrgName', headerName:CommonUtil.getMessageText(Constants.SETTLED_BY,'Settled By'),resizable:true,flex:1,width:100,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins' },headerClass: 'header-white',filter: 'agTextColumnFilter'},
        { field: 'approvedDate', headerName: CommonUtil.getMessageText(Constants.SETTLED_ON, 'Settled On'), flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins' }, headerClass: 'header-white',filter: 'agDateColumnFilter' },
        { field: 'claimStatus', headerName: CommonUtil.getMessageText(Constants.STATUS, 'Status'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins' }, headerClass: 'header-white',filter: 'agTextColumnFilter', cellRenderer: InterchangeDocumentStatusRenderer },
        {headerName:CommonUtil.getMessageText(Constants.REFERENCE_NUMBER,'Actions'),resizable:true,flex:1,width:100,sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins','paddingTop':'5px' },headerClass: 'header-white',filter: 'agTextColumnFilter',cellRenderer:(params:any)=>ResponseClaimRenderer(params,getAllClaims)},
    ]);
  
let onClickUserDetails = (userData:any) =>{
  localStorage.setItem("inspectionDocumentId", userData.documentId);    
  navigate("/menu/InterchangeApplication/interchangeDocumentDetails");
  }

  let onReceiveData = (userData: any) => {
    localStorage.setItem("claimId",userData.claimId)
   console.log(userData);
   
  }



let onGetAllClaims = (dataObj:any)=>{
    console.log(dataObj);
    if(dataObj) {
        var restData = JSON.parse(dataObj);
        console.log(restData)
        if(restData){
            restData.map((org:any)=>{
                console.log(org.documentId)
            })
        }
        setUsers(restData)
        if(restData){
            var users = [];
            if(Array.isArray(restData)){
                users = restData;
            }else{
                users.push(restData);
                
            }
            let ascendingUsers:any = [...restData].sort((a,b)=>{
                return b.documentId - a.documentId
              }) 
              setUsers(ascendingUsers)    
            console.log(users)   
        }
  
  }
  

    
  
  }

let onLoginError = (err:any)=>{
    console.log(err);
}

  return (
    <div className="container-fluid container-fluid-padding mt-3" style={{backgroundColor:'white'}}>
                    <div className="row fs-7 pt-1">
                    <div className="ag-theme-alpine ag-theme-customeTheme gridHeight" style={{ height: "calc(100vh - 330px)", width: "100%",paddingLeft:"8px",paddingRight:"5px" }}>
                    <InspectionGrid cols={userColumns} rows={users} pageSize={100} addNew={true} tooltip={"Add Company"} name={"Add Company"}
                      headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={userGridRef1} method={{ onClickUserDetails,onReceiveData }} 
                      />
                    </div>
                    </div>
                </div>
  )
}

export default AllClaims