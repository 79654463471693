import React from 'react'

const InterchangeDocumentStatusRenderer = (props:any) => {
  return (
    <div className={"cellRenderHeader"}>

      <p className="badge bg-success-light">{(props.value)}</p>

    </div>
  )
}

export default InterchangeDocumentStatusRenderer