import React, { useEffect } from 'react'
import { useState, useRef } from 'react';
import useStateWithCallback from "use-state-with-callback";
import { IAGrid } from '../../Components/IInterfaces';
import { CommonUtil } from '../../utils/CommonUtil';
import { Constants } from '../Languages/Constants';
import { NetworkManager } from '../../Components/NetworkManager';
import InspectionGrid from '../InspectionGrid/InspectionGrid';
import { useNavigate, useParams } from 'react-router';
import EditBrokerDetails from './EditBrokerDetails';
import BrokerStatusRenderer from './BrokerStatusRenderer';

const BrokersWidget = ({ brokersCount }: any) => {

  let getAllBrokerDetails = () => {
    var orgUrl = `api/broker/all?saasOrgId=${CommonUtil.getSaasOrgId()}`
    NetworkManager.sendJsonRequest(orgUrl, "", "", "GET", true, onGetAllBrokerDetails, onLoginError);
  }

  useEffect(() => {
    getAllBrokerDetails();
  }, [brokersCount]);
  const [users, setUsers] = useStateWithCallback([], () => {
    if (userGridRef1.current) {
      userGridRef1.current.columnFittoSize();
    }
  });
  const userGridRef1 = useRef<IAGrid>(null)

  const [userColumns] = useState([
    { field: 'brokerName', headerName: CommonUtil.getMessageText(Constants.BROKER_NAME, 'Broker Name'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter', cellRenderer: (params: any) => EditBrokerDetails(params, getAllBrokerDetails) },
    { field: 'phoneNumber', headerName: CommonUtil.getMessageText(Constants.PHONE_NUMBER, 'Phone Number'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter' },
    { field: 'email', headerName: CommonUtil.getMessageText(Constants.EMAIL_OPTIONAL, 'Email (Optional)'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter' },
    { field: 'dotNumber', headerName: CommonUtil.getMessageText(Constants.USDOT_NUMBER, 'USDOT Number (Optional)'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter' },
    { field: 'creationDate', headerName: CommonUtil.getMessageText(Constants.CREATED_DATE, 'Creation Date'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter' },
    { field: 'createdBy', headerName: CommonUtil.getMessageText(Constants.CREATED_BY, 'Created By'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter' },
    // { field: 'lastUpdateDate', headerName: CommonUtil.getMessageText(Constants.UPDATED_DATE, 'Updated Date'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter' },
    // { field: 'lastUpdatedBy', headerName: CommonUtil.getMessageText(Constants.UPDATED_BY, 'Updated By'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter' },
    { field: 'brokerStatus', headerName: CommonUtil.getMessageText(Constants.STATUS, 'Broker Status'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', filter: 'agTextColumnFilter', cellRenderer: BrokerStatusRenderer },
  ]);



  let onReceiveData = (userData: any) => {
    localStorage.setItem("claimId", userData.claimId)
    console.log(userData);
  }
  let onGetAllBrokerDetails = (dataObj: any) => {
    console.log(dataObj);
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      if (restData) {
        restData.map((org: any) => {
        })
      }
      setUsers(restData)
      if (restData) {
        var users = [];
        if (Array.isArray(restData)) {
          users = restData;
        } else {
          users.push(restData);
        }
        let ascendingUsers: any = [...restData].sort((a, b) => {
          return b.documentId - a.documentId
        })
        setUsers(ascendingUsers)
      }
    }
  }

  let onLoginError = (err: any) => {
    console.log(err);
  }

  return (
    <div className="container-fluid container-fluid-padding mt-3" style={{ backgroundColor: 'white' }}>
      <div className="row fs-7 pt-1">
        <div className="ag-theme-alpine ag-theme-customeTheme gridHeight" style={{ height: "calc(100vh - 330px)", width: "100%", paddingLeft: "8px", paddingRight: "5px" }}>
          <InspectionGrid cols={userColumns} rows={users} pageSize={100} addNew={true} tooltip={"Add Company"} name={"Add Company"}
            headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={userGridRef1} method={{ onReceiveData }}
          />
        </div>
      </div>
    </div>
  )
}

export default BrokersWidget