import $ from 'jquery';
import { Component } from 'react';

var self
export default class Confirm extends Component {

    componentWillMount() {
        this.setState({
            type: 'confirm',
            width: "100px",
            height: "100px",
            buttons: [{label: "Yes"}, {label: "No"}],
            display: "none",
        });
        self = this;
    }

    setPopupCenter(popId, cId) {
        let windowHeight = window.innerHeight;
        let windowWidth = window.innerWidth;
        windowHeight = windowHeight - 170;
        let popupTop = windowHeight - $("#" + cId).height();
        popupTop = popupTop / 2;
        let popupLeft = windowWidth - $("#" + cId).width();
        popupLeft = popupLeft / 2;
        $("#" + cId).css({"margin": "0px", "top": popupTop, "left": popupLeft});
    }

    static show(type, msg, btns) {
        try {
            self.setState({
                type: type,
                msg: msg,
                buttons: btns,
                display: "block",
            });
            setTimeout(function () {
                self.setPopupCenter("completeAlertDialogue", "alertContent");
            }, 100);

        } catch (e) {
            console.log(e.massage);
        }
    }

    static hide() {
        self.setState({
            display: "none"
        });
    }

    static onBtnClick(obj, e) {
        if (obj) {
            Confirm.hide();
            if (obj.callback) {
                try {
                    obj.callback();

                } catch (e) {
                }
            }
        }
    }

    onModelShowUp(e) {
        //console.log("popup");
    }

    render() {
        return (<div id="completeAlertDialogue"
                     className={(this.state.display === "none") ? "modal fade" : "modal in"} role="dialog"
                     style={{display: this.state.display, zIndex:'1200'}}>
                <div className="modal-dialog" role="document" id="alertContent">
                    <div className="modal-content" style={{"border": "2px solid #999", "padding": "1px"}}>
                        <div className="modal-header" style={{height: "40px"}}>
                            <h5 className="modal-title fw-600">{this.state.type}</h5>
                        </div>
                        <div className="modal-body" style={{paddingTop:'15px'}}>
                            <p style={{fontSize: "14px"}}>{this.state.msg}</p>
                        </div>
                        <div className="modal-footer">
                            {
                                this.state.buttons.map(function (m, index) {
                                    let classType = "pull-right";
                                    let buttonClass = "btn btn-success";
                                    if (!m.callback) {
                                        classType = "pull-left";
                                    }
                                    if (m.label === "No") {
                                        buttonClass = "btn btn-warning";
                                    }
                                    return <div className={classType} key={index}>
                                        <button type="button" className={buttonClass} data-dismiss="modal"
                                                onClick={Confirm.onBtnClick.bind(this, m)}>{m.label}</button>
                                    </div>
                                })
                            }
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

