import React from 'react'
import { CommonUtil } from '../../../utils/CommonUtil'


function TwicRequiredButton(props:any) {

  return (
   <div className={"cellRenderHeader"} style={{marginTop: props.isMobile ? "2px" : "-3px"}}>
            {
                (props.value && props.value.toLowerCase() == "y" ?
                    <img src="../../images/twic.png" className="mx-2" style={{width:"50px"}} /> : "")
            }

        </div>
  )
}

export default TwicRequiredButton
